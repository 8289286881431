import React, { Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {validate_Alpha_Length,validate_Empty} from '../../../helpers/Utilities';

export const ProfessionalSplitOne = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangePersonalInfo,
  handleChangeProfessionalSplitOne,
  values,
  addClick,
  removeClick,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    if (    
      
      validate_Alpha_Length(values.occupation) &&
      // validate_Alpha_Length(values.education.collegeName) &&
     validate_Empty(values.education.universityName) &&
     validate_Empty(values.workingSince) && 
    //  validate_Empty(values.education.countryOfCollege) &&
     validate_Empty(values.education.mainStreamOfCourse) &&
     validate_Empty(values.education.yearOfCompletion) 
     ){       
      nextStep();
     }

     else{
      toast("Please check input fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
     }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleDate=(input)=>{    

    handleChangeProfessionalSplitOne(input)
  }

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
         

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                      <Form.Group>
                        <Form.Label>Select Professional Education</Form.Label>
                        <Form.Text className="text-muted">
                          <p>
                            Try selecting multiple languages, which will help
                            customers to find you filtering relevant regional
                            languages.
                          </p>
                        </Form.Text>{" "}
                      </Form.Group>

                      <Row className="align-item-center justify-content-center ">
                        <Col>

                        {/* OCCUPATION */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Occupation</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Engineer"
                              onChange={handleChangeProfessionalSplitOne("occupation")}
                              value={values.occupation}
                              
                            />
                          </Form.Group>




                        {/* WORKING SINCE */}
                          <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>Working Since(Date of Joining)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="DD/MM/YYYY"
                              onChange={handleChangeProfessionalSplitOne(
                                "workingSince"
                              )}
                              value={values.workingSince}
                              
                          />
                            
                          </Form.Group>

                                   
                        </Col>
                        <Col > 


                          {/* EDUCATION */}                          
                          {/* COLLEGE NAME */}
                          <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>College Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: XYZ Institute"
                              onChange={handleChangeProfessionalSplitOne(
                                "collegeName"
                              )}
                              value={values.education.collegeName}
                              
                          />
                            
                          </Form.Group>             



                           {/* UNIVERSITY NAME */}
                           <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>University Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: VTU"
                              onChange={handleChangeProfessionalSplitOne(
                                "universityName"
                              )}
                              value={values.education.universityName}
                              
                          />
                            
                          </Form.Group>


                          </Col>  


                        <Col> 

                           {/* COUNTRY */}

                           <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>Select Country of College</Form.Label>
                           <Form.Select
                                value={values.education.countryOfCollege}
                                onChange={handleChangeProfessionalSplitOne('countryOfCollege')}
                                name="language"
                               
                                aria-label="Default select"
                              >
                                <option>Select Country</option>
                                <option value="India">India</option>
                                <option value="United Stated of America">United Stated of America</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="Australia">Australia</option>
                                <option value="France">France</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="France">France</option>
                                <option value="Germany">Germany</option>
                                <option value="Canada">Canada</option>
                                <option value="China">China</option>
                                <option value="South Africa">South Africa</option>
                                <option value="Russia">Russia</option>
                              </Form.Select>
                           </Form.Group>  
               
                         
                          {/*  MAIN STREAM OF COURSE */}
                          <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>Main Stream of Course</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Environmental Eng"
                              onChange={handleChangeProfessionalSplitOne(
                                "mainStreamOfCourse"
                              )}
                              value={values.education.mainStreamOfCourse}
                             
                          />
                            
                          </Form.Group>


                           {/*  YEAR OF COMPLETEION */}
                           <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                            <Form.Label>Year Of Completion</Form.Label>
                            <Form.Control
                              type="text"
                              id="dateField"
                              placeholder="DD/MM/YYYY"
                              onChange={handleChangeProfessionalSplitOne(
                                "yearOfCompletion"
                              )}
                              value={values.education.yearOfCompletion}
                              
                          />
                            
                          </Form.Group>

                         
                       
                        </Col>     

                      </Row>

                      <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                    </Form>                
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

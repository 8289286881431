import React, { Component } from 'react'

export default class TalentMarketplace extends Component {
    render() {
        return (
            <div>
                 <div  className="Nature-banner inner-page">
         
         <div className="row market-world-banner">
           <div className="col-md-12">
               
                  <div className="img">
                       <img src="assets/images/market-logo.png" alt="img" />
                       
                          <div className="hire-world-banner-content overlay">
                     <h1>Come and find your Green Technology Specialist here</h1>
                   <p>Connect with specialist that gets you, and hire them to take your business to the next level.</p>
                   
                   <div className="talent-market-btn">
                       <button><a href="">Find Specialist </a></button> 
                        <button><a href="">Find work</a></button>
                   </div>
                     
                     
                      
                 </div>
                  </div>
               
              
                
           </div>
       </div>
       
       <div className="container">
          
              <div className="row mb-4 mt-4">
                 
                   
             <div className="col-md-6 hire">
                       <div className="hire-text-section2">
                            <h2>The best colleagues you’ve never met</h2>
                            <p>Green building designers. Sustainability specialist . Environment consultant . Energy engineer. Make the right connection and it’ll last a lifetime</p>
                          <div className="row Green">
                              
                              <div className="card">
                                  <h6>Sustainability</h6>
                              </div>
                               <div className="card">
                                  <h6>LEED</h6>
                              </div>
                               <div className="card">
                                   <h6>Environment</h6>
                              </div>
                               <div className="card">
                                   <h6>Climate change</h6>
                              </div>
                              <div className="card">
                                   <h6>Commissioning</h6>
                              </div>
                               <div className="card">
                                   <h6>Energy</h6>
                              </div>
                               <div className="card">
                                   <h6>Green building</h6>
                              </div>
                               
                          </div>
                      
                       </div>
                      
                
             </div>
               <div className="col-md-6 hire-img-layout">
                  
                       <img src="assets/images/2.about.jpeg " alt="img" />
                  
                 
             </div>
               
         </div>
       </div>
       
                
       </div>
       
       
            </div>
        )
    }
}

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";
import { ToastContainer, toast } from "react-toastify";


export const BasicInformation = ({
  prevStep,
  nextStep,
  handleChange,
  values,
  selectedProjectType,
}) => {
  const Continue = (e) => {
    e.preventDefault();

    if (
      values.projectName.length >= 1 &&
      values.projectType.length >= 1 &&
      values.description.length >= 1 &&
      values.startDate.length >= 1 &&
      values.endDate.length >= 1 &&
      values.geoLocation.latitude.length >= 1 &&
      values.geoLocation.longitude.length >= 1 &&
      values.address.length >= 1 &&
      values.grossFloorArea.length >= 1 
    ) {

    nextStep();
    }
    else{
      toast("All the Input Fields are Mandatory. Please check!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row>
                        <Col md={12}>
                          <h2>Preliminary Project Information</h2>                         
                        </Col>                       
                      </Row>
                    </Form>

                    <Form>
                      <Row>
                          <Col md={6}>                             
                         
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Smart Building"
                              onChange={handleChange()}
                              name="projectName"
                              value={values.projectName}                              
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid Project Name. 
                            </Form.Text>
                          </Form.Group>


                          <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Project Type*</Form.Label>
                                      <Form.Select size="md" 
                                      value={selectedProjectType}
                                      onChange={handleChange()}
                                      name="projectType"
                                      >
                                      <option value=''>Select</option>
                                      <option>Residential</option>
                                        <option>Commercial</option>
                                        <option>School</option>
                                        <option>HealthCare</option>
                                    </Form.Select>
                            </Form.Group>

                            <Row>
                                <Col>
                                <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Project Start Date(DD-MM-YYYY)</Form.Label>
                            <Form.Control
                              maxlength="10"
                              type="text"
                              placeholder="Eg: 02-02-2022"
                              onChange={handleChange()}
                              name="startDate"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={values.startDate}                              
                            />
                           
                          </Form.Group>
                                </Col>
                                <Col>
                                <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Project End Date(DD-MM-YYYY)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: 02-02-2023"
                              maxlength="10"
                              onChange={handleChange()}
                              name="endDate"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={values.endDate}                              
                            />
                           
                          </Form.Group>
                                </Col>
                                </Row> 


                                <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Gross Floor Area(sq feet)</Form.Label>
                            <Form.Control
                              type="number"  
                              maxlength="12"                           
                              onChange={handleChange()}
                              name="grossFloorArea"
                              value={values.grossFloorArea}                              
                            />
                           
                          </Form.Group>
                        </Col>




                        <Col md={6}>   
                      

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Project Address</Form.Label>
                            <Form.Control
                              type="text"                             
                              onChange={handleChange()}
                              name="address"
                              value={values.address}                              
                            />
                           
                          </Form.Group>

                        <Row>
                        <Form.Label>Geo-Location</Form.Label>
                            <Col>
                            <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                              type="text"                             
                              onChange={handleChange()}
                              name="latitude"
                              value={values.geoLocation.latitude}                              
                            />
                           
                          </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                              type="text"                             
                              onChange={handleChange()}
                              name="longitude"
                              value={values.geoLocation.longitude}                              
                            />
                           
                          </Form.Group>
                        </Col>
                    </Row>
                         
                         

                         

                    Describe the project in general, including information on occupancy and base building
mechanical systems. Address any context or general characteristics of the project not covered
elsewhere in this property application.
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                            as="textarea"
                              type="text"                             
                              onChange={handleChange()}
                              name="description"
                              value={values.description}                              
                            />
                           
                          </Form.Group>

                        </Col>
</Row>
</Form>

<Row>
    <Col>
    Beyond Smart Cities wishes to enter into the Certification Agreement above with the owner of this project. If you are not the building
owner and accept the agreement, please submit the Confirmation of Agent’s Authority indicating your ability to act on the owners
behalf, at your convenience from the Project Details page after registration.
Click continue to agree to terms mentioned above.
</Col>
</Row>


                    <Row className="text-center my-3">
                      <Col>                   
                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>               

                 
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from 'react'

export default class Community extends Component {
    render() {
        return (
            <div>
                <div  class="Nature-banner inner-page">
      <div class="container">
           <div class="row mb-4">
            <div class="col-md-12">
                <h2 class="section-title p-tb-20 text-center">Beyond Smart Cities Community Standards</h2>
                <div class="title-divider"></div>
                <h4><b>Introduction</b></h4>
                <p>We’re cultivating a space for a community that wants to play a part in changing how the world works together. We’ve built a global network of engineers, designers, green building consultants, energy manager, business owners, and professionals across hundreds of industries, who use Beyond Smart work every day to create something they believe in. We’re inspired by each and every one of our community members. We value their perspectives, beliefs, and views—they are what makes Beyond Smart Cities so dynamic.</p>
              <p>In order to maintain a respectful, inclusive, and safe environment for everyone, we’ve created a set of community standards to serve as a moral compass for behavior on our platform. These standards help define what is acceptable in the Beyond Smart Cities community and marketplace, and explain how violations are enforced. They aren’t tied to any law, but are used to govern our platform policies. They reflect our attitudes, expectations, and social norms. Our community standards are rooted in our core values — respect, safety, acceptance, support, and open-mindedness.</p>
              <p>Just like our community, these standards will continue to grow, evolve, and change. We have embedded examples in the community standards for unacceptable activities on Beyond Smart Work. Please note that these are intended to be examples only and not a complete list.  </p>
             <p>We encourage our community to use our reporting tools when encountering anything that is unacceptable. We want to create the best experience for all community members, and ask that you respect and follow these guidelines.</p>
            
            <h4><b>Misrepresentation</b></h4>
            <p>Misrepresentation is defined as fraudulent, negligent, or innocent misstatements, or an incomplete statement of a material fact.</p>
            <h4><b>Misleading profile information</b></h4>
             <h6>Do not:</h6>
               <ul>
                   <li>Post a fake name, location, gender or photo in your user profile.</li> 
                     <li>Present a fake ID document if asked to. </li>
                     <li>Write misleading content in your profile description, skills or education. </li>

               </ul>
               <h4><b>Misrepresenting Service elements </b></h4>
               <h6>Do not:</h6>
               <ul>
                   <li>Copy Service descriptions, packages and other service elements from other users.</li>  
                   <li>Raise the price of your service in personal messages before or after purchase.</li>
                   <li>Bait-and-switch marketing is not acceptable.</li>
                   <li>Have someone else present you in your service. </li>
                   <li>Post non-original images or images you do not have the right to use in your service.</li>
                   <li>Post non-original videos or videos that you do not have the right to use in your service profile. </li>
                   <li>Deliver non-original work or send non-original work samples.</li>

               </ul>
               <h4><b>Misleading</b></h4>
               <p>Requesting or providing services intended to mislead others.</p>
               <h4><b>Testimonial Services</b></h4>
               <p>Do not post, offer or ask for a testimonial which is a written or spoken statement of a person extolling the virtues of a product or a service.  </p>
               <h4><b>Paid reviews</b></h4>
               <p>Do not post, offer or ask for paid reviews, positive or negative feedback on any product, service or performance.  </p>
               <h4><b>Fake engagement and traffic</b></h4>
               <h6>Do not post, offer or ask for:</h6>
               <ul>
                   <li>Followers, subscribers, fans or views on social media platforms.</li>
	                <li>Fake engagement on any platform.</li>
	               <li> Promise a service outcome which is not in your control</li> 

               </ul>
               <p>In certain instances, sellers may be tempted to promise outcomes of their service which is not in their control. For example, guaranteeing to 3rd party green building certification . Please refrain from promising outcomes that are not in your control because it misleads buyers.</p>
             <h4><b>Fake documents</b></h4>
            <p>Do not post, offer or ask for the creation, changing or editing of official documents such as IDs, passports, drivers license, bank statements or death and birth certificates.</p>
            <h4><b>Fake News</b></h4>
            <p>Beyond Smart Cities defines Fake News as false or intentionally misleading information or propaganda that is developed and presented as authentic news. Manipulation, disinformation, false information, and conspiracy theories have always existed, however, technological advances have allowed such information to be well-produced, widely distributed, and consumed on such a scale that it now threatens to undermine democratic institutions worldwide.</p>
            <p>Beyond Smart Cities is committed to fighting the spread of fake news. Therefore, if it is reported or flagged that Beyond Smart Cities platform is being used to create or promote the spread of fake news; we will investigate and take action accordingly. </p>
            <h4><b>Third Party Violations</b></h4>
            <p>We do not allow the Infringement of third parties terms of service on Beyond smart work. As a valued member of the Beyond Smart Cities community, we ask you to respect the broader Internet community and refrain from such violations in your service offerings. Services that will be flagged for violations of third parties terms of service will be removed from the marketplace. </p>
            <p>Do not post, offer or ask for services that Infringe third parties terms of service. </p>
            <h4><b>Unethical Services  </b></h4>
            <p>Beyond Smart Cities marketplace is open for sellers to offer any creative and productive service they wish to propose. With that being said, we ask to refrain from offering any unethical service. For example, taking part in doing someone else’s academic work (which will likely be submitted as the student’s own work) or requesting academic work to be done for you, is unethical since it violates most schools’ Honor Codes and constitutes copyright infringement. Beyond Smart Cities does not allow this type of fraudulent activities and it will not be permitted on our platform.</p>
           <h4><b>Medical-related services</b></h4>
           <ul>
               <li>We do not allow services that are meant to provide any medical treatment, advice, or supply.</li>
               <li>Do not post, offer, or ask for:</li>
                   <p>Medical treatment, advice on any medical condition, or pharmaceutical advice.</p>
                   <li>Any kind of medical drugs or pharmaceuticals. </li> 
                   <li>Medical equipment or pharmaceutical supply.</li>

           </ul>
           
           <h4><b>Spam</b></h4>
           <p>We define spam on Beyond Smart Cities as messaging activity that is unwanted, repeatedly occurring and disruptive to the experience of recipients. Spam can take many forms ranging from unwanted offers to commercially-motivated solicitations that aim to drive traffic or attention to websites, products, and services outside of Beyond smart work.</p>
           <p>Please respect our members’ privacy by not contacting them with offers, questions, suggestions or anything which is not directly related to their services or orders.</p> 
           
           <h6>Do not:</h6>
           <ul>
               <li>Contact our users with offers, questions, suggestions or anything unrelated to their services or orders.</li>
                <li>Send multiple unsolicited or deceptive messages.</li>
                <li>Abuse our communication tools to distribute mass messages.</li>

           </ul>
           <h4><b>Objectionable Content</b></h4>
           <h6>Hate Speech</h6>
           <p>Beyond Smart Cities defines hate speech as words or ideas that are designed to incite violence or hatred against a person, or group of people, defined on the basis of race, ethnicity, sexual orientation, gender, religion, age, disability, or other similar ground. We believe that the spread of hate speech online not only affects the groups or individuals that it targets, it also negatively impacts society in general, including those who speak out for freedom, tolerance, and non-discrimination.</p>
           <p>While Beyond Smart Cities believes deeply in freedom of expression—which not only includes information or ideas that are perceived as positive, inoffensive, or neutral, but also expression that might seem offensive, shocking, or disturbing to some—we’re also committed to moving swiftly and aggressively to investigate conduct that has been flagged as hate speech, and will respond in a manner that is consistent with existing laws and the values of the company. </p>
           <p>We take hate speech violations very seriously, and anyone using hate speech towards another user or towards our customer support representatives will be removed from Beyond smart work. We will also take action against any user asking or delivering hate speech related content on Beyond Smart Cities.</p>
           
           <h6>Do not:</h6>
           <p>Use violent or dehumanising speech against a person or group of people defined on the basis of race, ethnicity, sexual orientation, gender, religion, age, disability, or other similar ground. </p>
           <p>Publish, offer or ask for any content that expresses or supports discriminatory ideas. </p>
           <p>Offer or ask for the writing or editing of texts that supports discriminatory behavior and ideas.<br/>We do allow people to offer or ask for content regarding the research of hate-speech or content that is related to the prevention of hate-speech.</p>
           
           <h4><b>Discrimination</b></h4>
           <p>Under Beyond Smart Cities community standards, discrimination means unfair or unequal treatment of an individual or group based on age, disability, ethnicity, gender, marital status, race, religion, and sexual orientation.</p>
           <p>Beyond smart work will investigate claims of discrimination on a case-by-case basis, and defend protected groups whenever possible.</p>
           <h6>Do not:</h6>
           <p>Express your unwillingness to work with a person or a group of people based on age, disability, ethnicity, gender, marital status, race, religion, sexual orientation, or other similar ground.</p>
           <p>Publish, offer or ask for any content that expresses or supports discriminatory ideas. </p>
           <p>Offer or ask for the writing or editing of texts that support discriminatory  ideas or practices.</p>
           <h4><b>Adult, Nudity and Sexual Activity</b></h4>
            <p>Explicit content meant to be sexually gratifying is not allowed on Beyond Smart Cities. If detected or reported, sexually explicit content will be blocked and participants could be banned from the marketplace. Services and deliveries portraying violent, graphic, or humiliating fetishes are also not allowed. </p>
            <p>Sexually explicit content featuring minors and content that seeks to sexually exploit minors is prohibited on Beyond smart work. We immediately report content that violates this policy to the National Center for Missing and Exploited Children, who work with global law enforcement agencies.</p>
            <h4><b>Documentary Exception</b></h4>
            <p>Beyond Smart Cities will allow nudity when the primary purpose is educational, scientific, or artistic, and it isn't gratuitous. For example, a video edit ordered by a midwife depicting proper breastfeeding technique would be appropriate, while creating a video with clips from the same film but used out of context for the purpose of sexual gratification, would not. Out-of-context nudity from indigenous communities, protests, medical procedures, childbirth, artistic performances, breastfeeding or like, may not meet our documentary exception. </p>
            <h6>Do not post, offer or ask for:</h6>
            <ul>
                <li>Nude or adult related images or videos.</li>
                <li> Sex or sexual partners.</li>
                 <li>Sex chats or conversations.</li>
                 <li>The execution of fetish scenarios.</li>

            </ul>
            <h4><b>Sexually gratifying modeling</b></h4>
            <p>Content that depicts explicit sexual activity, unless the conditions for “documentary exception,” as listed above, exist.</p>
            <p>Beyond smart work will act quickly to block transactions that features child nudity and will flag the offending account for review.</p>
            <h4><b>Intellectual Property</b></h4>
            <p>Beyond Smart Cities content is based on User Generated Content (UGC). If you believe any of the uploaded content violates your copyright or a related exclusive right, you should inform to us.</p>
             <p>Upon receipt of a report from a rights holder or an authorized representative, we will remove or restrict content that engages in:</p>
             <b>Copyright infringement</b><br/>
             <b>Trademark infringement</b>
            </div>
         </div>
         
         
         
         
         
         
         
         
         
         
          </div>
    </div>
    

            </div>

        )
    }
}

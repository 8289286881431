import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const HealthAndWellness = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                    <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>HEALTH AND WELLNESS(HW)</h2>
                          <Row className="mt-2">
                    <p className="mt-2">
                          This BSAS feature requires projects to
provide a guide to occupants that
highlights the relationship between health
and buildings, a description of the BSAS
features pursued by the project,
educational materials on a variety of health
topics and annual communications about
available health resources and programs.
                          </p>
                        </Row>

                          <img
                            className="img-fluid pt-3"
                            src="assets/images/healthwellness.png"
                          ></img>
                        
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                18 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-2 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                                
                                label="Support Physical Activity"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Support Physical Activity"
                                id={`inline-checkbox-1`}
                              checked= {values?.healthAndWellness?.includes('Support Physical Activity') ? true : false}
                              />

                              <Form.Check                                
                                label="Support Onsite Food Production"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Support Onsite Food Production"
                                id={`inline-checkbox-25`}
                              checked= {values?.healthAndWellness?.includes('Support Onsite Food Production') ? true : false}
                              />

                              <Form.Check                                
                                label="Connectedness & Health"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Connectedness & Health"
                                id={`inline-checkbox-38`}
                              checked= {values?.healthAndWellness?.includes('Connectedness & Health') ? true : false}
                              />
                             
                           
                              <Form.Check                                
                                label="Enhance Dynamic Meditation"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Enhance Dynamic Meditation"
                                id={`inline-checkbox-45`}
                              checked= {values?.healthAndWellness?.includes('Enhance Dynamic Meditation') ? true : false}
                              />

                              <Form.Check                                
                                label="Health and Wellness Awareness"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Health and Wellness Awareness"
                                id={`inline-checkbox-45`}
                              checked= {values?.healthAndWellness?.includes('Health and Wellness Awareness') ? true : false}
                              />

                              <Form.Check                                
                                label="Promote planting tree"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Promote planting tree"
                                id={`inline-checkbox-45`}
                              checked= {values?.healthAndWellness?.includes('Promote planting tree') ? true : false}
                              />
                              

                             
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>

                  

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/healthwellness-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

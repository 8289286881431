import React, { Component, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../../helpers/CardBoxLoading";
import { Link } from "react-router-dom";
import { FaFilePdf, FaRocket, FaRupeeSign } from "react-icons/fa";
import CourseLinks from "../Components/CourseLinks";


export default function EnvironmentalImpactAssessment() {
  

  // useEffect(() => {
  //   let elements = document.querySelectorAll(".fixed-top");
  //   elements.forEach((item) => {
  //     item.style.marginTop = "-100px";
  //   });


  //   let element3 = document.querySelectorAll("#CategoriesMenu");
  //   element3.forEach((item) => {
  //     item.style.top = "-0px";
  //   });


    

  //   let element2 = document.querySelectorAll(".page-container");
  //   element2.forEach((item) => {
  //     item.style.marginTop = '20px';
  //   });

  // }, []);
  

  
  return (
    <div>
      <section className="page-container p-2 ">
        <div className="page-inner bg-1 ">
          <Container fluid>
            <Row className="pt-3">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>KnowledgeLab</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <Row className="pb-3 align-items-start pt-3 px-3">
              <Col className="col-md-8">
                <h1 className="heroText1b mb-3 shadeLoader-1"></h1>                            
               <div className="novideoholder d-flex align-items-center justify-contents-center">
                 <h2 className="c1 text-center w-100">Stay Tuned. We are getting Ready!</h2>
                 </div>                    
                        
                
                <h2 className="mt-3 shadeLoader-1"></h2>
                
              </Col>
          
             
             <Col className="my-3">

               <h3>WorkShop By</h3>
               <p className="fs-12 shadeLoader-1"></p>  
               <hr/>
           

             <h3>Read Time</h3>
               <p className="fs-12 shadeLoader-1"></p>  
               <hr/>
               <h3>Course Type</h3>
               <p className="fs-12 shadeLoader-1"></p>  

               <hr/>  

               <h3>What to Expect?</h3>
               <p className="pt-3 shadeLoader-1"> 
                 
               </p> 
               <hr/>
               <h2>Reference links</h2>
               <p className="fs-12 shadeLoader-1"></p>  
               <p className="fs-12 shadeLoader-1"></p>  


             <hr/>
             </Col>
           </Row>

          </Container>

          <CourseLinks />
        </div>
      </section>
    </div>
  );
}
import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";

class ProSubCategories extends Component {
  async getProSubCategories() {
    const token = await JSON.parse(localStorage.getItem("token"));
    return await axios
      .get(API_URL + "proSubCategory/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(function (response) {
        console.log(response);

        if (response.data.success === true) {
          return response.data;
        }
      });
  }


async getProSubCategoriesbyId(id) {
  const token = await JSON.parse(localStorage.getItem("token"));
  return await axios
    .get(API_URL + "proSubCategory/byCategory/" + id, {
      headers: {
        auth: token,
        Accept: "application/json",
      },
    })

    .then(function (response) {
      console.log('SCID',response);

      if (response.data.success === true) {
        return response.data;
      }
    });
}
}

export default new ProSubCategories();

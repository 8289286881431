import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { BiCheck, BiRupee } from "react-icons/bi";
import { connect } from "react-redux";
import { FaFileDownload } from "react-icons/fa";

const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

class ProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state={
      productId:this.props.match.params.id,     
     
      productData:'',
      galleryItems:''   ,
      serviceList:'' 
    }
    // this.addNewProperty = this.addNewProperty.bind(this);
  }

  componentDidMount() {
    //this.getProductInformation();
    this.getProducts()
  }


  




  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
    const win = window.open('/service-information/'+serviceId+'/'+ specId, "_blank");
  win.focus();

//this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }

 

  async getProductInformation() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "service/" + this.state.serviceId, {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('RRR',response);

          if (response.data.success === true) {
            self.setState({
              serviceData:response.data.serviceData
            });
            let gal=[]
            response.data.serviceData?.serviceGallery?.map(i=>
           
            gal.push({              
              "original":i.secureUrl,
              "thumbnail":i.secureUrl   
            })
                
            )
            console.log(gal)
            self.setState({
              galleryItems:gal,
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }



  async getProducts() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));
        await axios
      .get(API_URL + "product/public/list/",  
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('Products',response);

          if (response.data.success === true) {
            self.setState({
              productData:response.data.productsList.filter((item)=>item._id===self.state?.productId)
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });

            let gal=[]
            console.log(response.data.productsList.filter((item)=>item._id===self.state?.productId)[0].productInfo?.image)
            response.data.productsList.filter((item)=>item._id===self.state?.productId)[0].productInfo?.image?.map(i=>
           
            gal.push({              
              "original":i.secureUrl,
              "thumbnail":i.secureUrl   
            })
                
            )
            self.setState({
              galleryItems:gal,
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });

            console.log('gal',self.state.galleryItems)

          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
          <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>{this.state.productData[0]?.productInfo?.brandName}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
            </Row>

            <div className=" card-r p-4">
            
            <Row className="mb-4 align-items-top"> 
              
             {this.state.productData[0]?.productInfo?.image?.length?
              this.state.galleryItems?.length &&
                      <div class="col-md-5 p-2 gallery-image-holder">                             
                             <ImageGallery items={this.state?.galleryItems} /> 
                         </div>
                         :
                         <div class="col-md-5 p-2 bg-4 gallery-image-holder" style={{minHeight:300}}>                             
                            <img src="https://picsum.photos/id/1031/5446/3063.jpg" className="img-fluid" />
                         </div>
  }
                         
                         <div class="col-md-7 ">
                         <h2 className="heroText1a">{this.state.productData[0]?.productInfo?.brandName}</h2>

                         {this.state.productData[0]?.adminVerified?
                         <p className="verified-badge">Verified</p>
                          :
                          <p className="not-verified-badge">Not Verified</p>                          
                         }
                         <p style={{fontSize:16,color:'#444',marginBottom:0}}><span className="product-detail-desc-holder">Model:</span>  {this.state.productData[0]?.productInfo?.model}</p>
                         <p style={{fontSize:16,color:'#444',marginBottom:0}}><span className="product-detail-desc-holder">Product ID:</span> {this.state.productData[0]?.productId}</p>
                         <p style={{fontSize:16,color:'#444',marginBottom:0}}><span className="product-detail-desc-holder">Category:</span> {this.state.productData[0]?.productInfo?.categoryId.name}</p>
                         <p style={{fontSize:16,color:'#444',marginBottom:0}}><span className="product-detail-desc-holder">Sub Category: </span>{this.state.productData[0]?.productInfo?.subCategoryId.name}</p>
                         <p style={{fontSize:16,color:'#444',marginBottom:0}}><span className="product-detail-desc-holder">Manufacturer:</span> {this.state.productData[0]?.manufacturerId?.applicantInfo?.nameOfCompany}</p>


<hr/>
                        
                         <p style={{fontSize:14,marginVertical:10}}>Keywords: {this.state.productData[0]?.productInfo?.keyWord}</p>


                             
                         </div>
                         
               </Row>
              
               <Row className="mt-3">
                   <Col className="pl-3">
                   <h2 className="heroText2">Product Description</h2>

                   <p style={{fontSize:14}}>{this.state.productData[0]?.productInfo?.description}</p>

                   </Col>
               <Col className="m-5 p-5 text-center bdr-2 py-4 my-5">
                To get in touch with Manufacturer, please email to info@beyondsmartcities.in
                </Col>

               </Row>

               <Row className="mt-1">
               <p style={{marginBottom:0, marginTop:20}}>View Supported Documents</p>
                         {this.state.productData[0]?.productInfo?.supportDocuments &&

                        this.state.productData[0]?.productInfo?.supportDocuments.map(item=>
                           <a style={{fontSize:26}} href={item.secureUrl} target ="_blank" > <FaFileDownload /></a>
                            )                      

                         } 
               </Row>
               </div>
               
         
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  const { cartItems } = state.cart;
  console.log(cartItems)
  return {
    user,
    isLoggedIn,
    cartItems
  };
}

export default connect(mapStateToProps)(ProductInformation);

import React, { Component, Fragment } from "react";
import ImageUploading from 'react-images-uploading';
import authHeader from "../../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../../config";
import { BasicProductDetails } from "./BasicProductDetails";
import { UploadProductImages } from "./UploadProductImages";
import Confirmation from "./Confirmation";
import Success from "./Success";
import { UploadProductDocuments } from "./UploadProductDocuments";
import { UploadProductVideo } from "./UploadProductVideo";
import { Redirect } from "react-router";


const maxNumber = 4;

export default class SubmitProduct extends Component {
  

  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
  
    this.state = {
      userData: JSON.parse(localStorage.getItem("user")),

      submitLoading: false,
      isdataSubmitted: false,
      errorMessage:'',
      images: [],
      step: 1,
      documentFile:[],
      selectedDocumentFiles:[],
      selectedProCategory:'',
      selectedProSubCategory:'',
      data: {
        productInfo: {
          model: "",
          description: "",
          brandName: "",
          keyWord: "",
          categoryId: "",
          serviceId: "",
          subCategoryId: "",
        },
        documents: [],
        productImages: [],
        video: [],
        manufacturerId: "",
      },
    };
  };


 componentDidMount(){
  //  SUBSCRIPTION GATE
   let user=JSON.parse(localStorage.getItem("user"))  
  // if (!user?.manufacturerId?.isPrimeMember) {
  //   this.props.history.push("/manufacturer-membership?membercheck=true");
  // }
  
 }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };


  
  firstStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 4 });
  };


  // Handle fields change
  handleChange =()=>(e) => {
    // this.setState({ [input]: e.target.value });    
    console.log(e)
     
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            productInfo: {
              ...prevState.data.productInfo,             
                [e.target.name]:e.target.value,              
            },
          },
        }));      
        
        console.log(this.state.data)
        console.log(this.state)

   
  };


  handleCategory =(e) => {
    console.log('e here',e)
     
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            productInfo: {
              ...prevState.data.productInfo,             
                categoryId:e.categoryId,              
            },
          },
        }));      

      this.setState({
      selectedProCategory: e,
    });

    console.log('DATA',this.state.data)
        console.log(this.state)      
        return Promise.resolve(e.categoryId)
   
  };


  handleSubCategory =(e) => {
    console.log('rrrr',e)
     
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            productInfo: {
              ...prevState.data.productInfo,             
                subCategoryId:e.subCategoryId,              
            },
          },
        }));      

      this.setState({
      selectedProSubCategory: e,
    });
    localStorage.setItem('selectedProSubCategory',JSON.stringify(e))
        console.log(this.state)
   
  };


   // Handle fields change
//    handleChangeProfilePhoto = (imageList, addUpdateIndex) =>  {    
//      console.log(imageList)
//     this.setState((prevState) => ({
//       data:{
//         ...prevState.data, 
//         profilePhoto:imageList[0].file
//     },
//     }));

//     console.log("PERS", this.state.data);
//   };



handleChangeProductImages = (imageList, addUpdateIndex) =>  {
    let file = imageList.map(a => a.file);
    console.log('ONLYfile', file)

    console.log(imageList, addUpdateIndex); 
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
        productImages:imageList
    },
    }));

    console.log("PERS", this.state.data);
  };


  handleChangeProductDocuments=(e)=>{   
    console.log(e)
     this.setState({ 
       selectedDocumentFiles: 
      //  [...this.state.selectedDocumentFiles, URL.createObjectURL(e.target.files[0])] 
       [...this.state.selectedDocumentFiles, e.target.files[0]]           
       });
       
       this.setState((prevState) => ({
        data:{
          ...prevState.data, 
          documents:
          [...this.state.data.documents, e.target.files[0]]          
      },
    }));
    
  }

 deleteFile=(e)=> {
      const s = this.state.data.documents.filter((item, index) => index !== e);
      this.setState((prevState) => ({
        data:{
          ...prevState.data, 
          documents: s          
      },
    }));
      // this.setState({documents:s});
      console.log(s);
    
  }

  

  async handleRegister(e) {
    e.preventDefault();
    const { data } = this.state.data;   
    var self = this;

    console.log('CAPTURED WHEN CLICKED SUBMIT DATA',this.state.data)  

    this.setState({
      step: 5,
      submitLoading: true,
      errorMessage:''
    });

  let formData = new FormData();
    formData.append('productInfo[model]', this.state.data.productInfo.model);
    formData.append('productInfo[brandName]', this.state.data.productInfo.brandName);
    formData.append('productInfo[description]', this.state.data.productInfo.description);
    formData.append('productInfo[keyWord]', this.state.data.productInfo.keyWord);
    formData.append('productInfo[categoryId]', this.state.data.productInfo.categoryId)
    formData.append('productInfo[subCategoryId]', this.state.data.productInfo.subCategoryId)
    //formData.append('documents', this.state.data.documents);

      
    for (let i = 0; i < this.state.data.productImages.length; i++) {
    formData.append('productImages', this.state.data.productImages[i].file);
    }

    for (let i = 0; i < this.state.data.documents.length; i++) {
      formData.append('documents', this.state.data.documents[i]);
      }

    // formData.append('video', this.state.data.video[0]);
    
  
    // Display the values
for (var value of formData.values()) {
  console.log('FD',value);
}

const token = await JSON.parse(localStorage.getItem('token'));
// Post the form, just make sure to set the 'Content-Type' header
await axios
.post(API_URL + "product/create", formData, {
  headers: {
    auth: token,  }
})

  .then(function (response) {
    //handle success
    console.log(response)
    if (response.data.success === true) {
      self.setState({
        submitLoading: false,
        isdataSubmitted: true,
      });     
    }
    else{
      self.setState({
        errorMessage:response.data.error,
        submitLoading: false,          
      });
    }
},

(error) => {
  const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString()
   // console.log(message)
    this.setState({
      errorMessage:message,
      submitLoading: false,          
    });
}
//   .catch(function (response) {
//     //handle error
//     console.log(response);
// });  
);
  
  }



  render() {
    const { step } = this.state;
    const {
      firstName,
      lastName,
      desription,
      username,
      password,
      country,
      levelOfEducation,
    } = this.state;
    const values = {
      firstName,
      lastName,
      desription,
      password,
      country,
      levelOfEducation,
    };

    switch (step) {
      case 1:
        return (
          <BasicProductDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={this.state.data.productInfo}
            step={this.state.step}
            handleCategory={this.handleCategory}
            handleSubCategory={this.handleSubCategory}
            selectedProCategory={this.state.selectedProCategory}
            selectedSubProCategory={this.state.selectedProSubCategory}
          />
        );

        case 2:
            return (
              <UploadProductImages
              prevStep={this.prevStep}
                nextStep={this.nextStep}                
                handleChangeProductImages={this.handleChangeProductImages}
            ImageUploading={ImageUploading}
            maxNumber={maxNumber}
                values={this.state.data.productImages}
                step={this.state.step}

              />
            );  

            case 3:
                return (
                  <UploadProductDocuments
                  prevStep={this.prevStep}
                    nextStep={this.nextStep}                
                    handleChangeProductDocuments={this.handleChangeProductDocuments}               
                maxNumber={maxNumber}
                    values={this.state.data.documents}
                    file={this.state.data.documents}
                    deleteFile={this.deleteFile}
                    step={this.state.step}
                  />
                );  

                // case 4:
                //     return (
                //       <UploadProductVideo
                //       prevStep={this.prevStep}
                //         nextStep={this.nextStep}                
                //         handleChangeProductVideo={this.handleChangeProductVideo}
                //     ImageUploading={ImageUploading}
                //     maxNumber={maxNumber}
                //         values={this.state.data.video}
                //         step={this.state.step}
                //       />
                //     );  
    

      case 4:
        return (
          <Confirmation
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleRegister={this.handleRegister}
            submitData={this.state.data}
            values={this.state.data}
            step={this.state.step}
            // documents={this.selectedDocumentFiles}
          />
        );
      case 5:
        return <Success 
            submitLoading={this.state.submitLoading}
            isdataSubmitted={this.state.isdataSubmitted}
            errorMessage={this.state.errorMessage}
            handleRegister={this.handleRegister}
            firstStep={this.firstStep}
            />;
      default:
      // do nothing
    }
  }
}

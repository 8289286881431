import { Box } from "@chakra-ui/layout";
import { useState } from "react";
import Chatbox from "../components/Chatbox";
import MyChats from "../components/MyChats";
import SideDrawer from "../components/miscellaneous/SideDrawer";
import  { ChatState } from "../Context/ChatProvider";
import { useParams } from 'react-router-dom';
import {useLocation} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';
 import ChatProvider from '../Context/ChatProvider';
 import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
} from "react-bootstrap";
import DashboardSidebar from "../../DashboardSidebar";


const Chatpage = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const { user } = ChatState();

  //const user = JSON.parse(localStorage.getItem("user"));

  const search = useLocation().search;
  const chatUserId = new URLSearchParams(search).get('chatUserId');
  
 
  console.log(chatUserId)

  


 // const user=true
 //const user = JSON.parse(localStorage.getItem("user"));
  return (
   
    
    <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">

                  <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Chats</Breadcrumb.Item>
                      </Breadcrumb>
                  

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar"> 
                        <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  My Chats
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end"></div>
                              </Col>
                            </Row>
                          </div>                         

                          <ChakraProvider> 
                      <div style={{ width: "100%",marginTop:10 }}>
                        {user && <SideDrawer searchKey={chatUserId} />}
                        <Box d="flex" justifyContent="space-between" w="100%" h="61.5vh" p="10px">
                          {user && <MyChats fetchAgain={fetchAgain} />}
                          {user && (
                            <Chatbox fetchAgain={fetchAgain}  setFetchAgain={setFetchAgain} />
                          )}
                        </Box>
                      </div>
                      </ChakraProvider>
                      </Col>
                      </Row>
                      </Container>
                      </div>
                      </div>
                      </div></div></div></section>
                      </div>






   
  );
};

export default Chatpage;
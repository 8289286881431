import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./config";
import Loader from "react-js-loader";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageok, setMessageok] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (email.length) {
      console.log(email);

      let data={
          email:email
      }

      setLoading(true);

      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .put(API_URL + "user/forgetPassword", data, {
          headers: {
            Accept: "application/json"
          },
        })

        .then(function (response) {
          //handle success
          setLoading(false);
          console.log(response);
          setMessage(response?.data?.notice);
          setMessageok(response?.data?.message);
        },(error) => {
            const m =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.notice ||
              error.toString();
              setMessage(m)
              setLoading(false);
          })
        
    } else {
        setMessage("Please enter email address")      
    }
  };

  return (
    <div className="row" style={{ paddingTop: 200, paddingBottom: 200 }}>
      <div className="col-md-6 offset-md-3">
        <h2>To reset your password, Please enter your email address</h2>

        <input
          className="form-control"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input
          onClick={() => handleSubmit()}
          className="btn btn-outline-success"
          type="submit"
          value="Submit"
        />
        {message && <div className="alert alert-warning mt-3">{message}</div>}
        {messageok && <div className="salert alert-success mt-3 p-3">{messageok}</div>}

        {loading ? (
          <Loader
            type="spinner-default"
            bgColor={"#0A989C"}
            title={"Please Wait!"}
            color={"#0A989C"}
            size={60}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ForgetPassword;

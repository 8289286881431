import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component{
    render(){
        return(
            <div>
               <section className="footer-section py-5 py-md-3">
    <div className="container-fluid">

       <h2 className="heroText1a text-center footer-quote-color pt-3" >Knowledge is Power</h2>
       {/* KNOWLEDGE IS POWER */}
       <h5 className="text-center footer-quote-color pb-4" >Find the Talent needed to Get Your Business Growing</h5>
   <div className="row footer-content">
       <div className="col-6 col-md-3">
       <h4>Categories </h4>
           
                   <ul>
                        <li><Link to="/category/sustainability-technology">Sustainability</Link></li>
                         <li>  <Link to="/category/smartcities-infrastructure">Smart Cities</Link> </li>
                          <li><Link to="/category/green-building-technology">Building Tech</Link></li>
                           <li> <Link to="/category/energy-efficiency-commissioning">Energy &amp; CXA</Link> </li> 
                            <li>  <Link to="/category/health-safety-environment-fire-safety">HSE &amp; Fire Safety</Link> </li>
                             <li> <Link to="/category/greenHouse-gases-climate-change">GHG &amp; Climate Change</Link> </li>
                              <li>  <Link to="/category/emerging-design-technology">Emerging Tech</Link> </li>
                              <li>  <Link to="/smartpro-products">Smart Products</Link> </li>
                              <li>  <Link to="/BSAS-approved-properties">Smart Properties</Link>

                     </li>
                                
                     <li> <Link to="/learning-lab">Learning Lab  </Link></li>
                                  <li><Link to="/sitemap">Site Map</Link> </li>
                                 
           
                             </ul>
               
       </div>

       <div className="col-6 col-md-3">

<h4>For Specialists</h4>
<ul>

<li> <Link to="/howtofindwork">How to Find Projects </Link></li>
<li> <Link to="/specialist-level-system">Specialist’s Level System </Link></li>
<li> <Link to="/selling-on-beyond-smart-cities">Selling on Beyond Smart Cities </Link></li> 
{/* <li><Link to="/freelanceworldwide">Find Freelance Jobs Worldwide </Link></li> */}
{/* <li><Link to="/freelancegcc">Find Freelance Jobs in the GCC</Link></li> */}
               
</ul>

<h4 className='pt-4'>For Manufacturers</h4>
<ul>
<li> <Link to="/how-to-promote-products">How to Promote Products  </Link></li>
<li> <Link to="/smart-pro-products-services">Smart<span className='smart-pro'>Pro</span> Products & Services</Link></li>
<li> <Link to="/smartpro-products">Smart<span className='smart-pro'>Pro</span> Product Database</Link></li> 
<li> <Link to="/smartpro-faq">Smart<span className='smart-pro'>Pro</span>FAQ</Link></li> 
<li><Link to="/smartpro-product-rating-systems">SmartPro Product Rating Systems</Link> </li>



</ul>
</div> 




<div className="col-6 col-md-3">
<h4>For Clients</h4>
<ul>          
<li><Link to="/howtohire"> How to Find Services</Link>  </li>
{/* <li><Link to="/talentmarketplace">Talent Marketplace</Link>  </li> */}
<li><a href="/smartpro-products">Find Smart Products</a> </li>
<li><Link to="/self-certification">Self Certification of property </Link></li>
<li><a target="_blank" href='http://knowledgelab.beyondsmartcities.in/index.php/category/guides/'>Self Certification of property Resources</a></li>
<li><Link to="/hireinworld">Hire in worldwide </Link></li>
<li><Link to="/hiremiddleeast">Hire in the Middle East</Link> </li>
<li><Link to="/bsas-rating">BSAS Rating Systems</Link> </li>
</ul>
<h4 className='pt-4'>Resources</h4>
<ul>            
<li><Link to="/help-support">Help & Support</Link> </li>
<li><Link to="/trustsafety">Trust & safety</Link></li>

    <li><a href="https://knowledgelab.beyondsmartcities.in/index.php/category/guides/">Buying on Beyond Smart Cities</a> </li>
    <li> <Link to="/smart-pro-product-resources">Smart<span className='smart-pro'>Pro</span> Product Resources</Link></li>
    <li> <Link to="/sustainability-tools-resources">Sustainability Tools and Resources</Link></li> 
    <li> <Link to="/faqs">FAQ</Link></li> 
    <li><Link to="/greenbuildingtools">Green Building Tools</Link> </li>
  </ul>
</div> 



<div className="col-6 col-md-3">
<h4>Company</h4>
<ul>
    <li><Link to="/about">About Us</Link> </li>
     <li><Link to="/leadership">Leadership</Link></li>
    <li><Link to="/InvestorRelations">Investor Relations</Link></li>
    <li> <a target="_blank" href="https://krishnajipawar.in/">Beyond Green Building Council(BGBC)</a></li>
    <li> <Link to="/beyondbusiness">Beyond Business</Link></li>
    <li> <Link to="/specialist-prime-membership"><span className='spe-prime'>Prime</span> Membership  </Link></li>
    <li><Link to="/careers">Careers</Link></li>
    <li><Link to="/contact">Contact</Link></li>     
    
      </ul>

</div> 




</div>
<hr/>

<div className="row footerlinksinline">
  <ul className='footer-links'>
<li className='list-inline'>  <a className='c4' href="https://beyondsmartcities.in/terms-conditions.html" target="_blank">Terms of Service</a></li>
     <li className='list-inline'><a href="https://beyondsmartcities.in/privacy-policy.html" target="_blank">Privacy Policy</a></li>
     <li className='list-inline'> <Link to="/whats-new">What's New?</Link></li> 

</ul>

</div>

</div>
  
</section>
<div className="row copyright">   
    <div className="col-md-4 dash">
          <p className="text-center"> © Beyond Smart Cities Private Limited - 2022. Version:1.9.6. </p>
  
    </div>

    <div className="col-md-4 dash">
          <p className="text-center"> Encrypted by SSL. </p>
  
    </div>

    <div className="col-md-4 dash">
       <ul className="footer-links">
		<li><a href="https://www.facebook.com/beyondsmartcities" target="_blank"><i className="fab fa-facebook"></i></a></li>
		<li><a href="https://www.instagram.com/beyond_smart_cities/" target="_blank"><i className="fab fa-instagram"></i></a></li>
		<li><a href="https://twitter.com/beyondsmartcity" target="_blank"><i className="fab fa-twitter"></i></a></li>
		<li><a href="https://www.linkedin.com/company/beyond-smart-cities/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
		<li><a target="_blank" href="https://www.youtube.com/channel/UCUtIOMV7VbhzDA_wktBiBkg"><i className="fab fa-youtube"></i></a></li>
		</ul>
           </div>
</div>
            </div>
        )
    }
}


export default Footer;
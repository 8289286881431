import React, { Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {validate_Alpha_Length, validate_Empty} from '../../../helpers/Utilities';

export const ProfessionalSplitTwo = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangePersonalInfo,
  handleChangeProfessionalSplitTwo,
  values,
  addClick,
  removeClick,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
    // if (    
      
    //  validate_Empty(values.education.licenseName) &&     
    //  validate_Empty(values.education.licenseId) &&
    //  validate_Empty(values.education.licenseIssuedBy) 

   
    //  ){       
    //   nextStep();
    //  }

    //  else{
    //   toast("Please check input fields", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    //  }

  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
      

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                      <Form.Group>
                        <Form.Label>Enter Professional Credentials : LEED AP, LEED GA or CEM</Form.Label>
                        <Form.Text className="text-muted">
                          
                        </Form.Text>{" "}
                      </Form.Group>

                      <Row className="align-item-center justify-content-center ">
                        <Col>
                          {/*  LICENSE NAME */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>License Name</Form.Label>
                            {/* <p>Enter a valid License Identification</p> */}
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "licenseName"
                              )}
                              value={values.education.licenseName}
                              defaultValue={values.education.licenseName}
                            />
                          </Form.Group>

                          {/*  LICENSE ID */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>License ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "licenseId"
                              )}
                              value={values.education.licenseId}
                              defaultValue={values.education.licenseId}
                            />
                          </Form.Group>

                          {/*  LICENSE EXPIRY DATE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>License Expiry Date</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "licenseExpiryDate"
                              )}
                              value={values.education.licenseExpiryDate}
                              defaultValue={values.education.licenseExpiryDate}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          {/*  LICENSE ISSUED BY */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>License Issued By</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "licenseIssuedBy"
                              )}
                              value={values.education.licenseIssuedBy}
                              defaultValue={values.education.licenseIssuedBy}
                            />
                          </Form.Group>

                          {/*  LICENSE ISSUED DATE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>License Issue Date</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "licenseIssueDate"
                              )}
                              value={values.education.licenseIssueDate}
                              defaultValue={values.education.licenseIssueDate}
                            />
                          </Form.Group>

                          {/*  IS LIFE TIME CERTIFICATE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Is it Life Time Certificate?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "isLifeTimeCertificate"
                              )}
                              value={values.education.isLifeTimeCertificate}
                              defaultValue={values.education.isLifeTimeCertificate}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          {/*  CREDENTIALS ID */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Credentials ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "credentialId"
                              )}
                              value={values.education.credentialId}
                              defaultValue={values.education.credentialId}
                            />
                          </Form.Group>

                          {/*  CREDENTIALS URL */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Credentials URL</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitTwo(
                                "credentialUrl"
                              )}
                              value={values.education.credentialUrl}
                              defaultValue={values.education.credentialUrl}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="text-center my-3">
                        <Col>
                          <Button
                            onClick={Previous}
                            type="submit"
                            className="btn btn-primary-trans btn-block me-2"
                          >
                            Previous
                          </Button>

                          <Button
                            onClick={Continue}
                            type="submit"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={false}
                            className="btn btn-primary btn-block c1"
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


 export const states= 
 [{"code": "Andaman and Nicobar Islands","name": "Andaman and Nicobar Islands"},
 {"code": "Andhra Pradesh","name": "Andhra Pradesh"},
 {"code": "Arunachal Pradesh","name": "Arunachal Pradesh"},
 {"code": "Assam","name": "Assam"},
 {"code": "Bihar","name": "Bihar"},
 {"code": "Chandigarh","name": "Chandigarh"},
 {"code": "Chhattisgarh","name": "Chhattisgarh"},
 {"code": "Dadra and Nagar Haveli","name": "Dadra and Nagar Haveli"},
 {"code": "Daman and Diu","name": "Daman and Diu"},
 {"code": "Delhi","name": "Delhi"},
 {"code": "Goa","name": "Goa"},
 {"code": "Gujarat","name": "Gujarat"},
 {"code": "Haryana","name": "Haryana"},
 {"code": "Himachal Pradesh","name": "Himachal Pradesh"},
 {"code": "Jammu and Kashmir","name": "Jammu and Kashmir"},
 {"code": "Jharkhand","name": "Jharkhand"},
 {"code": "Karnataka","name": "Karnataka"},
 {"code": "Kerala","name": "Kerala"},
 {"code": "Lakshadweep","name": "Lakshadweep"},
 {"code": "Madhya Pradesh","name": "Madhya Pradesh"},
 {"code": "Maharashtra","name": "Maharashtra"},
 {"code": "Manipur","name": "Manipur"},
 {"code": "Meghalaya","name": "Meghalaya"},
 {"code": "Mizoram","name": "Mizoram"},
 {"code": "Nagaland","name": "Nagaland"},
 {"code": "Odisha","name": "Odisha"},
 {"code": "Puducherry","name": "Puducherry"},
 {"code": "Punjab","name": "Punjab"},
 {"code": "Rajasthan","name": "Rajasthan"},
 {"code": "Sikkim","name": "Sikkim"},
 {"code": "Tamil Nadu","name": "Tamil Nadu"},
 {"code": "Telangana","name": "Telangana"},
 {"code": "Tripura","name": "Tripura"},
 {"code": "Uttarakhand","name": "Uttarakhand"},
 {"code": "Uttar Pradesh","name": "Uttar Pradesh"},
 {"code": "West Bengal","name": "West Bengal"}]




import React ,{useEffect, useState} from "react";
import { Container, Row, Col, Form,Button } from "react-bootstrap";
import ProCategories from "../../../services/ProCategories";
import FormTopbar from '../SubmitProduct/FormTopbar'
import Select from "react-select";
import ProSubCategories from "../../../services/ProSubCategories";
import { ToastContainer, toast } from "react-toastify";

export const BasicProductDetails = ({ prevStep, nextStep, handleChange,handleCategory,handleSubCategory,selectedProCategory,selectedProSubCategory, values, step }) => {

 const [procategories, setProcategories] = useState([])
 const [prosubcategories, setProsubcategories] = useState([])

 const localselectedproItem= JSON.parse(localStorage.getItem('selectedProSubCategory'))
  const Continue = (e) => {
    e.preventDefault();

    if (
      values.brandName.length >= 1 &&
      values.model.length >= 1 &&
      values.description.length >= 1 &&
      values.keyWord.length >= 1 &&
      values.categoryId.length >= 1 &&
      values.subCategoryId.length >= 1 
    ) {

    nextStep();
    }
    else{
      toast("All the Input Fields are Mandatory. Please check!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };


  useEffect(() => {
   getProCategories()
  console.log(selectedProSubCategory)
  // getProSubCategories()
  },[]);


  const getProCategories=()=>{
  
  ProCategories.getProCategories().then((responseCat) => {
    let cat = [];
    for (let i = 0; i < responseCat.proCategoriesList.length; i++) {
     // console.log(responseCat.proCategoriesList[i].name);
      cat.push({
        value: responseCat.proCategoriesList[i].name,
        label: responseCat.proCategoriesList[i].name,
        categoryId: responseCat.proCategoriesList[i]._id,
      });
    }
    setProcategories(cat);
   // console.log("cc", cat);

    console.log("CAT22", responseCat.proCategoriesList);
  });


  
}

const getProSubCategories=(e)=>{
  console.log('r',e)
  handleCategory(e)
  ProSubCategories.getProSubCategoriesbyId(e.categoryId).then((responseCat) => {
    let subcat = [];
    for (let i = 0; i < responseCat?.proSubCategoryData?.length; i++) {
     // console.log(responseCat.proSubCategoryData[i].name);
      subcat.push({
        value: responseCat?.proSubCategoryData[i]?.name,
        label: responseCat?.proSubCategoryData[i]?.name,
        subCategoryId: responseCat?.proSubCategoryData[i]?._id,
      });
    }
    setProsubcategories(subcat);
    console.log("subcc", subcat);
    console.log("CAT", responseCat.proCategoriesList);
  });
}


// const handleCategoryTemp=()=>{
//   handleCategory()
// }


  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">        
        <div className="container-fluid"> 
          <div className="user-form-content">              
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                <Row>
                      <FormTopbar step={step} />
                    </Row>
                  <Container fluid>                  

                  <div className="row">
            <div class="col">
              <h2>Submit your Product and Get Ratings</h2>
              <p>
                Submit your product and get approval in 4 business days.You will
                be notified via email on approval.
              </p>
            </div>
          </div>

                    <Form>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail" >
                            <Form.Label>Model Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: John"
                              onChange={handleChange()}
                              name="model"
                              value={values.model}                              
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid Model Name. This will be used
                              for verification.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Brand Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Kirloskar"
                              onChange={handleChange()}
                              name="brandName"
                              value={values.brandName}
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid Brand Name. This will be used
                              for verification.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Keyword</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Machine"
                              onChange={handleChange()}
                              name="keyWord"
                              value={values.keyWord}
                            />

                            <Form.Text className="text-muted">
                              Please Enter the keyword that matches your product
                            </Form.Text>
                          </Form.Group>

                        </Col>
                        <Col>



                        <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Choose Category*</Form.Label>
                                      <Select
                                        value={selectedProCategory}
                                        onChange={e=>{                                         
                                         getProSubCategories(e)                                        
                                        }}
                                        name="categoryId"
                                        aria-label="Default select"                                       
                                        options={
                                          procategories
                                            ? procategories
                                            : ""
                                        }
                                      />
                            </Form.Group>   


                              <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail2"
                                    >
                                      <Form.Label>Choose Sub Category*</Form.Label>
                                      <Select
                                        value={selectedProSubCategory || localselectedproItem }
                                        onChange={(e)=>handleSubCategory(e)}
                                        name="subCategoryId"
                                        aria-label="Default select2"                                       
                                        options={
                                          prosubcategories
                                            ? prosubcategories
                                            : ""
                                        }
                                      />
                            </Form.Group>                              

                    




                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                              A Small Description About You
                            </Form.Label>
                            <Form.Control as="textarea" rows={3}
                            onChange={handleChange()}
                            name="description"
                            value={values.description}                           
                             />
                          </Form.Group>
                        
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center">
                        <Col>                       
                    <Button                
                      onClick={Continue}
                      type="submit"
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      disabled={false}                                
                      className="btn btn-primary btn-block c1"                     
                    >
                      Continue
                    </Button>
                    </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

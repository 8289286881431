import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      successful: false,
      submitProgress: false,
      spinner: false,
      redirectspinner: false,
    };
  }

  componentDidMount() {

    if (this.props.isLoggedIn) {
     // return <Redirect to="/dashboard" />;
     // this.props.history.push("/dashboard");
     // window.location.reload();
      // setTimeout(() => {       
      //   return <Redirect to="/profile" />;
      // }, 3000);       
    }
  }


  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }




  handleLogin(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    this.form.validateAll();

    const { dispatch, history, success } = this.props;

    if (this.checkBtn.context._errors.length === 0) {
      this.setState({
        spinner: true,
        submitProgress: true,
      });

      dispatch(login(this.state.email, this.state.password))
        .then(() => {
          this.setState({
            successful: true,
            spinner: false,
            submitProgress: false,
          });

          this.setState({
            redirectspinner: true,
          });

          setTimeout(() => {
            history.push("/dashboard");
            //window.location.reload();
          }, 3000);

          this.setState({
            spinner: false,
            submitProgress: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            spinner: false,
            submitProgress: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { isLoggedIn, message, success } = this.props;

    if (isLoggedIn) {           
        return <Redirect to="/dashboard" />;
      }

       
    // }

    return (
      <div>
        <section class="lightgrey-bg user-area log-in-area">
          <div class="container">
            <div class="user-form-content">
              <div class="row">
                <div class="col-sm-6 d-flex align-items-center p-2 order-md-first order-2">
                  <img
                    src="assets/images/login-image.png"
                    alt="login image"
                    className="img-fluid"
                  />
                </div>

                <div class="col-sm-6 py-5 order-md-last order-1">
                  <div class="sign-layout form-card">
                    <div class="login-form-holder position-relative">
                      <div class="form-group text-center">
                        <div class="form-group text-center">
                          {!this.state.successful ? (
                            <div className="formHeader">
                              <h2 class="mx-2">
                                {" "}
                                <span className="m-2">
                                  <FiUser />
                                </span>
                                Welcome to Beyond Smart Cities
                              </h2>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      


                      <Form
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        ref={(c) => {
                          this.form = c;
                        }}
                      >
                        {!this.state.successful && (
                          <div>
                            <div className="form-group">
                              <label htmlFor="email">Email Address</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                validations={[required, email]}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="password">Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                validations={[required, vpassword]}
                              />
                            </div>

                            <div className="form-group py-3">
                              <Button
                             onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                disabled={this.state.submitProgress}
                                onClick={this.handleLogin}
                                className="btn btn-primary btn-block c1"
                              >
                                {this.state.spinner ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )}
                                <span className="d-inline p-2">Login</span>
                              </Button>
                            </div>

                            <div class="form-group">
                              <div class="col-12">
                                <p class="create">
                                  New User?
                                  <Link
                                    to="/register"
                                    class="text-color-orange"
                                  >
                                    Signup
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}

                        {message && (
                          <div className="form-group">
                            <div
                              className={
                                this.state.successful
                                  ? "alert alert-success text-center"
                                  : "alert alert-danger"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        <CheckButton
                          style={{ display: "none" }}
                          ref={(c) => {
                            this.checkBtn = c;
                          }}
                        />

                        {success && this.state.successful ? (
                          <div>
                            <h1 className="text-center c2">
                              <FaCheck />
                            </h1>
                            <h2 className="text-center">Welcome Back!</h2>
                            <p class="text-center">
                              Now Explore Green Build Freelance Services,
                              SmartPro Product Rating and Smart Property Rating
                              System at One Location.
                            </p>
                            <div className="row">
                              <div className="col text-center">
                                {this.state.redirectspinner ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )}
                                <span className="mx-2">
                                  Please Wait..while we take to you the
                                  dashboard.
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Form>
                    </div>
                  </div>
                  {!this.state.successful ? (
                    <div class="form-group mt-2">
                      <div class="col-12">
                        <p class="create">
                          Forgot Password?&nbsp;
                          <Link to="/forgetPassword" class="text-color-orange">
                            Reset Now
                          </Link>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  const { success } = state.success;
  console.log("SUCCESS", success);
  return {
    isLoggedIn,
    message,
    success,
  };
}

export default connect(mapStateToProps)(Login);

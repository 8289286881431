import React, { Component } from "react";
import { Accordion } from "react-bootstrap";

export default class SmartProFaq extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="container-fluid ">


          <div class="row mb-4">
            <div class="col-md-6 bg-21 p-0">
              <div class="p-5">
                <h1 className="heroText1">
                 FAQ
                </h1>
                <ul className="mt-5">                  
                <li className="heroText2a"><a href="#one">About SmartPro Rating System</a></li>
                <li className="heroText2a"><a href="#two">Certification Process</a></li>
                <li className="heroText2a"><a href="#three">SmartPro Products and Services</a></li>
                <li className="heroText2a"><a href="#four">SmartPro Product Standards</a></li>
                </ul>              
              </div>
            </div>
            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/How-to-Promote-Products.jpg" alt="img" />
            </div>
          </div>





            <div className="row mb-4">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0" flush>
                  <h2 id="one" className="section-title p-tb-20 text-center ">
                  About SmartPro Rating System
                  </h2>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    What is Smart ProRating System?
                    </Accordion.Header>
                    <Accordion.Body>
                    SmartPro –Product Rating system is developed by Beyond Smart Cities in India. SmartPro assesses ‘How Green is a product’ based on a holistic product life cycle
framework and highlights the way forward to achieve excellence in environmental performance.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      What is Smart Pro mission?
                    </Accordion.Header>
                    <Accordion.Body>
                    Our mission is to transform the economy for a healthier, greener world. Our vision is a healthy society in balance with nature.

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    What is Smart Pro Products Certification ?

                    </Accordion.Header>
                    <Accordion.Body>
                    SmartPro Products certification is a process that ensures that a product or service meets the rigorous performance, health, and environmental criteria in SmartPro
Product’s environmental leadership standards. SmartPro Products’s specialists and experts comprehensively validate that a product or services meets our sciencebased standards before awarding certification. SmartPro certification validates a company’s sustainability leadership and helps purchasers and consumers make
healthier, greener choices with confidence. 
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      Does Smart Pro certify Companies ?
                    </Accordion.Header>
                    <Accordion.Body>
                    We do not certify entire companies. We certify individual products, services and spaces made or provided by companies.

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      Why Smart Pro Products rating trustworthy?
                    </Accordion.Header>
                    <Accordion.Body>
                    SmartPro Products standards are developed based on science through an open, transparent process that prioritizes public and stakeholder input. Our reputation for
rigor and credibility makes SmartPro Products one of the most trusted ecolabels worldwide.
                     
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      {" "}
                      How can I get in contact with someone at Smart Pro Products rating Services at Beyond Smart Cities ?
                    </Accordion.Header>
                    <Accordion.Body>
                    You may contact SmartPro Products & Services by email or by phone at +91 6363032722. You can also find contact information on our Contact Us page.

                    </Accordion.Body>
                  </Accordion.Item>

              
                </Accordion>



      
                <Accordion defaultActiveKey="0" flush>
                  <h2 id="two" className="section-title p-tb-20 text-center ">
                  SmartPro Certification Process
                  </h2>


                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      {" "}
                      How do I apply for certification?
                    </Accordion.Header>
                    <Accordion.Body>
                    Visit our Certification page to fill out a Certification Interest Form. Our Customer Success team will contact you within 2-3 business days. You can also contact us at
info@beyondsmartcities.in.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      {" "}
                      What are the fees associated with certification?

                    </Accordion.Header>
                    <Accordion.Body>
                    Our mission is to transform the economy for a healthier, greener world. Our vision is a healthy society in balance with nature.

                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      {" "}
                      What is Smart Pro Products Certification ?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                        <p>
                        A number of factors affect the certification fee, including the types and number of products or services on the application. Our Customer Success team is happy to
provide you an estimate. Contact the team here or visit our Certification page to submit a certification interest form.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      {" "}
                      How long does the certification process take?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <p>
                      The certification review process for a full product generally takes between 1-3 months, once you submit all of the required documentation. Factors that help reduce
the overall time include being prepared and familiar with the standard, providing complete information early in the process, and using the client portal and
information templates.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      {" "}
                      How does Smart Pro Products Certification handle confidential information?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <p>
                      Beyond Smart Cities -SmartPro Products takes confidentiality very seriously, and confidentiality protocols and agreements are an important piece of the certification
process. Confidential information is never shared without the explicit agreement of the original party. We have been trusted with handling the confidential
information of hundreds of companies, large and small, since our start in 2020.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      {" "}
                      How long does certification last?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <p>
                      Beyond Smart Cities -SmartPro Products conducts periodic monitoring to ensure certified products continue to meet our high standards. Your product will remain
certified as long as you remain in compliance with the latest version of our standards
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>


                <Accordion defaultActiveKey="0" flush>
                <h2 id="three" className="section-title p-tb-20 text-center ">
                SmartPro Products and Services
                  </h2>

                       <Accordion.Item eventKey="9">
                     <Accordion.Header>
                    {" "}
                    Where can I find a list of Smart Pro Products and services?

                     </Accordion.Header>
                           <Accordion.Body>
                  
                       <div className="smart-list">
                       <p>
                       All SmartPro Certified products and services can be found on our Certified Product and Services database page.

                        </p>
                      
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
                  
              

                       <Accordion.Item eventKey="10">
                     <Accordion.Header>
                    {" "}
                    What types of products and services do you certify?

                   
                     </Accordion.Header>
                           <Accordion.Body>
                  
                           SmartPro Products certifies a wide variety of Construction materials ,Energy efficiency ,water efficiency ,Construction materials and technology Services

                  </Accordion.Body>
                </Accordion.Item>
               
        
                  <Accordion defaultActiveKey="0" flush>
                  <h2 id="four" className="section-title p-tb-20 text-center ">
                  SmartPro Product Standards                  </h2>
                 <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    {" "}
                    Who decides what requirements go into the standards?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                    The requirements in our standards are the result of research by our environmental scientists, who identify the major impacts of a product category on health and
environment and set requirements that represent leadership in that product category. We then get input from a broad variety of stakeholders to ensure that our
criteria are challenging and achievable, and accurately capture the environmental impacts and the current market practice
                        </p>

                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    {" "}
                    Who are the stakeholders participating in the standard development process?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                  We invite manufacturers, raw material suppliers, distributors, retailers, product users, purchasers, regulators, researchers, trade groups, health and environmental
groups, other NGOs, advocates and anyone else who may be affected by the products or their use. Our process is open to the public and anyone who wants to
participate is welcome!
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    {" "}
                    How often does Green Seal update the standards?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                    Beyond Smart Cities -SmartPro Products re-visits each standard every three years or as needed. If we notice a significant change in the market, or if a stakeholder
highlights a major shift in the market, and we see that shift being adopted by a significant minority (15-20% of the market), then we will announce a Standard
Revision.
If you have a question about our standards, please info@beyondsmartcities.in.
                        </p>
                       
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    {" "}
                    What is Beyond Smart Cites Manufacturer Account?
                    
                  </Accordion.Header>
                  <Accordion.Body>
                  
                  This is a portal available to all existing applicants and licensee of the Beyond Smart Cities SmartPro rating services.They will be able to do the following:<br/>
• Provide an overview of the Existing application and Certification<br/>
• Update their company details<br/>
• Submit a new application<br/>
• Renew your existing certificate<br/>
• View the progress of application<br/>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    {" "}
                    Who will have access to the account?                  </Accordion.Header>
                  <Accordion.Body>
                  
                  By default, an account will be created for the main contact person of the company. The login details will only be sent to the email address which is registered with
us during the initial Beyond SmartPro Product Application.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    {" "}
                    Does Beyond Smart Cities certify the company or the products?

                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                    The Beyond Smart Cities SmartPro only certifies products. The SmartPro does not certify the companies who own the products. To eco-certify a company,
applicants can refer to the Beyond Smart Cities BSAS Certification for manufacturing facilities .
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>
                    {" "}
                    When can I receive my Smart Pro Rating Certificate?

                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                    The SmartPro certificate is only issued when payment is received by the Beyond Smart Cities Team. Lease allow for up to 5 working days for the certificate to be
mailed to you after payment is received and upon approval.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>
                    {" "}
                    How long will the SmartPro application process take?

                  </Accordion.Header>
                  <Accordion.Body>
                  
                  Standard processing takes 8-10 working day from the moment the Beyond Smart Cities Team receives the application form with a complete set of supporting
documents. 
                  </Accordion.Body>
                </Accordion.Item>  
            
                </Accordion>
              

                </div>
              </div>
            </div>
          </div>
        
      </div>
    );
  }
}



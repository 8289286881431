import React, { Component } from "react";
import { Col } from "react-bootstrap";

export default class SpecialistPrimeMembership extends Component {
  render() {
    return (
      <div>
        <section>
          <div className="Nature-banner inner-page">
            <div className="container">
              <div className="row">
                <Col>
                  <h2 className="heroText1 text-center pb-4">Specialist Prime Membership</h2>
                </Col>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: '100%',objectFit:'cover',objectPosition:'top',}}
                    src="assets/images/Specialist-Prime-membership.jpg"
                    alt=""
                    className="img-fluid about-img "
                  />
                 
                </div>
                <div className="col-md-6 p-tb-20">
                  <p>
                  We personally verify our Specialists to exceed your
expectations. Put a Pro in charge of your next project and
prepare to be wowed.Our rigorous application process
ensures that every Pro you connect with has the highest
level of quality and service.
<strong>We want your Prime experience to be flawless. </strong>
That's why we offer all Pro customers 24/7 premium access
to our Pro Customer Experience team, who'll respond to
your query in under an hour.
                  </p>
                  <h3>Prime Subscription Benefits</h3>
                  <ul>
                      <li>Specialists profile will be verified with Virtual Meeting.</li>
                      <li>Free access to Knowledge Lab(more than 10
Professional courses )</li>
{/* <li>Free self property certification (3 star Certification )</li>
<li>Free Promotion of your product (Manufacturers only)</li> */}
                  </ul>
                  
                  
                </div>
              </div>



             
       

             



            </div>
          </div>
        </section>



        <section>        
            <div className="container">
              <div className="row">
                <Col>
                  <h2 className="heroText1 text-center pb-4">Manufacturer Prime Membership</h2>
                </Col>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: '100%',objectFit:'cover',objectPosition:'top',}}
                    src="assets/images/manufacturer.jpg"
                    alt=""
                    className="img-fluid about-img "
                  />
                 
                </div>
                <div className="col-md-6 p-tb-20">
                  <p>
                  The Beyond Smart Cities provides market leaders with
the opportunity to gain a competitive advantage by
differentiating their products and adding value to the
lives of their customers.Beyond Smart Cities created
Smart Pro to respond to the need for a measurable and
credible solution to prove the business case for building
green and unlock financial investment. Smart Pro
includes a cloud-based platform to calculate the cost of
going green and utility savings. The state-of-the-art
engine has a sophisticated set of city-based climate and
cost data, consumption patterns and algorithms for
predicting the most accurate performance results.
                  </p>
                  <h3>Prime Subscription Benefits</h3>
                  <ul>
                      <li>Manufacturers will verified with Virtual meeting.</li>
                      <li>Free Promotion of your product with Beyond smart
cities.</li>

<li>Free Green Building Certification for your
manufacturing facility.</li>
<li>Free access to Knowledge Lab(more than 10
Professional courses )</li>
{/* <li>Free self property certification (3 star Certification )</li>
<li>Free Promotion of your product (Manufacturers only)</li> */}
                  </ul>
                  
                  
               
              </div>



             
       

             



            </div>
          </div>
        </section>






        <section>
         
            <div className="container">
              <div className="row">
                <Col>
                  <h2 className="heroText1 text-center pb-4">Client Prime Membership</h2>
                </Col>
              </div>

              <div className="row mt-4">
                <div className="col-md-6">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: '100%',objectFit:'cover',objectPosition:'top',}}
                    src="assets/images/client-prime.jpg"
                    alt=""
                    className="img-fluid about-img "
                  />
                 
                </div>
                <div className="col-md-6 p-tb-20">
                  <p>
                  The Beyond Smart Cities provides market leaders with
the opportunity to gain a competitive advantage by
differentiating their properties and adding value to the
lives of their customers.Beyond Smart Cities created
BSAS to respond to the need for a measurable and
credible solution to prove the business case for building
green and unlock financial investment.
A global network of certifiers and accredited Green
building Specialists support the collective ambition to
mainstream green buildings and help fight climate
change.
                  </p>
                  <h3>Prime Subscription Benefits</h3>
                  <ul>
                      <li>Free Green Building Certification for your property.
</li>
                      <li>Free Promotion of your product with Beyond smart
cities</li>
<li>Free access to Knowledge Lab(more than 10
Professional courses )</li>
<li>Free Promotion of your property with Beyond smart
cities.</li>
{/* <li>Free self property certification (3 star Certification )</li>
<li>Free Promotion of your product (Manufacturers only)</li> */}
                  </ul>
                  
                  
                </div>
              </div>



             
       

             



           
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import CategoryShortLinks from "./Components/CategoryShortLinks";

export default class FreelanceWorldWide extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="row find-world-banner">
            <div className="col-md-12">
              <div className="find-world-banner-content">
                <h1 className="heroText1">Find the best specialist jobs</h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mb-4 mt-4 hire-world-text">
              <div className="col-md-12">
                <h2 className="text-center heroText1a mt-5">
                  Explore the Type of Work
                </h2>
                <p className="heroText2 px-md-5 text-center mx-md-5">
                  Browse jobs posted on Beyond Smart Cities, or jump right in
                  and create a f ree prof ile to f ind the work that you love to
                  do.
                </p>

                <div className="col-md-6 offset-md-3">
                  <CategoryShortLinks />
                </div>
              </div>
              <hr className="my-5" />
              <div class="ghostBtn mt-3 ms-3 mb-5 text-center">
                <a href="/dashboard">Register Your Profile</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from "react";
import { Certificates } from "./Certificates";
import Confirmation from "./Confirmation";
import { LanguagesSpeak } from "./LanguagesSpeak";
import { Personal } from "./Personal";
import { ProfessionalSplitOne } from "./ProfessionalSplitOne";
import { ProfessionalSplitThree } from "./ProfessionalSplitThree";
import { ProfessionalSplitTwo } from "./ProfessionalSplitTwo";
import { Sociallinks } from "./Sociallinks";
import Success from "./Success";
import ImageUploading from 'react-images-uploading';
import authHeader from "../../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../../config";
import { CertificatesSplitTwo } from "./CertificatesSplitTwo";

const maxNumber = 4;

export default class ApplySpecialist extends Component {

  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
  
    this.state = {
       submitLoading: false,
      isdataSubmitted: false,
      errorMessage:'',
    images:[],
    step: 1,
    data:{
    personalInfo: {
      firstName: "",
      lastName: "",
      description: "",
      communication: [
        {
          language: "",
          proficiencyLevel: "",
        },
        {
          language: "",
          proficiencyLevel: "",
        },
      ],
    },
    professionalInfo:{
      occupation:'',
      workingSince:'',
        education:{
          collegeName:'',
          universityName:'',
          countryOfCollege:'',
          mainStreamOfCourse:'',
          yearOfCompletion:'',
          licenseId:'',
          licenseName:'',
          licenseExpiryDate:'',
          licenseIssuedBy:'',
          licenseIssueDate:"",          
          isLifeTimeCertificate:'',
          credentialId:'',
          credentialUrl:'',
          organizationalMembership:{
            nameOfOrganization:'',
            workingSince:'',
            honorsAndAwards:[],
            personalWebsite:'',
          }
        }, 
    },
    linkedAccounts:{
      socialPresence:[''],
      professionalPresence:[''],
    },
    license:'',    
    certification:'',
    expCertificate:'',
    orgCertificates:[],   
    profilePhoto:'', 
  },
  licensePreviewUrl:'',
  profilePreviewUrl:'',
  expCertificatePreviewUrl:''
}
  };

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };


    // proceed to the next step
    firstStep = () => {
      const { step } = this.state;
      this.setState({ step: 1 });
    };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

 
  // Handle fields change
  handleChangePersonalInfo = (input) => (e) => {
    this.setState((prevState) => ({
      data:{
        ...prevState.data,
        personalInfo: {
        ...prevState.data.personalInfo,
        [input]: e.target.value,
      },
    }
    }));
    

    console.log("PERS", this.state.data);
  };

  handleChangeLanguage = (i) => (e) => {
    console.log("TAR", e.target.value);
    let values = [...this.state.data.personalInfo.communication];
    if (e.target.name === "language") {
      values[i].language = e.target.value;
    } else {
      values[i].proficiencyLevel = e.target.value;
    }
    console.log("VALUE", values);
    this.setState((prevState) => ({
      data:{
        ...prevState.data,
      personalInfo: {
        ...prevState.data.personalInfo,
        communication: values,
      }
    },
    }));
    console.log("FINAL2", this.state.data);
  };


  // Handle fields change
  handleChangeProfessionalSplitOne = (input) => (e) => {
    var inp = ['occupation', 'workingSince'];   
    if(inp.includes(input)){         
    this.setState((prevState) => ({
      data:{
        ...prevState.data,
      professionalInfo: {
        ...prevState.data.professionalInfo,
        [input]: e.target.value,
      }
    },
    }));
    console.log("PROFFESSION", this.state.data );
  }
  else{
   
    this.setState((prevState) => ({   
      data:{  
        ...prevState.data,
      professionalInfo: {
        ...prevState.data.professionalInfo,
        education:{
          ...prevState.data.professionalInfo.education,
          [input]: e.target.value,
        }       
      }
    },
    }));
    console.log("PROFFESSION", this.state.data );
  }

    
  };



  // Handle fields change
  handleChangeProfessionalSplitTwo = (input) => (e) => { 
    this.setState((prevState) => ({ 
      data:{   
        ...prevState.data, 
      professionalInfo: {
        ...prevState.data.professionalInfo,
        education:{
          ...prevState.data.professionalInfo.education,
          [input]: e.target.value,
        }       
      }
    },
    }));
    console.log("PROFFESSION", this.state.data );

}


  // Handle fields change
  handleChangeProfessionalSplitThree = (input) => (e) => {
    //organizationalMembership
  //   if (e.lengthComputable) {
  //     let percentComplete = ((e.loaded / e.total) * 100);
  //    console.log('uploadcalc', percentComplete)
  // }

  var inp = ['socialPresence', 'professionalPresence'];

  if(inp.includes(input)){   
  this.setState((prevState) => ({   
    data:{
    ...prevState.data, 
    personalInfo:{
      ...prevState.data.personalInfo,  
    },
    professionalInfo:{
      ...prevState.data.professionalInfo,  
    },
    linkedAccounts:{
      ...prevState.data.linkedAccounts,
      [input]: [e.target.value],
    } 
  }   
  }));
  console.log("PROFFESSION", this.state.data);
  }

  else if(input==="honorsAndAwards"){

    this.setState((prevState) => ({   
      data:{
      ...prevState.data, 
      personalInfo:{
        ...prevState.data.personalInfo,  
      },      
      linkedAccounts:{
        ...prevState.data.linkedAccounts,     
      },
      professionalInfo: {
        ...prevState.data.professionalInfo,
        education:{
          ...prevState.data.professionalInfo.education,
        organizationalMembership:{
        ...prevState.data.professionalInfo.education.organizationalMembership,
          [input]: [e.target.value],
        }          
        }       
      }
    }   
    }));

  }

  else{
    this.setState((prevState) => ({   
      data:{  
        ...prevState.data, 
        personalInfo:{
          ...prevState.data.personalInfo,  
        },
      professionalInfo: {
        ...prevState.data.professionalInfo,
        education:{
          ...prevState.data.professionalInfo.education,
        organizationalMembership :{
        ...prevState.data.professionalInfo.education.organizationalMembership,
          [input]: e.target.value,
        }          
        }       
      },
      linkedAccounts:{
        ...prevState.data.linkedAccounts,       
      } 
    },
    }));
    console.log("PROFFESSION", this.state.data );

  }


  };


   // Handle fields change
   handleChangeCertificates = (imageList, addUpdateIndex) =>  {
    let file = imageList.map(a => a.file);
    console.log('ONLYfile', file)

    console.log(imageList, addUpdateIndex); 
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
        orgCertificates:imageList
    },
    }));

    console.log("PERS", this.state.data);
  };



   // Handle fields change
   handleChangeProfilePhoto = (imageList, addUpdateIndex) =>  {    
     console.log(imageList)
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
        profilePhoto:imageList[0].file
    },
    }));

    console.log("PERS", this.state.data);
  };



  addClick = () => {
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
      personalInfo: {
        ...prevState.data.personalInfo,
        communication: [...prevState.data.personalInfo.communication, {}],
      }
    },
    }));
    console.log("FINAL2", this.state.data);
  };

  removeClick = (i) => {
    let values = [...this.state.data.personalInfo.communication];
    values.splice(i, 1);
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
      personalInfo: {
        communication: values,
      }
      },
    }));
  };



  handleChangeCertificatesSplitTwo=(input)=>(e)=> {
    e.preventDefault();
    console.log(this.state.profilePreviewUrl)

    if(input==="profilePhoto")
    {

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState((prevState) => ({
        data:{   
          ...prevState.data,     
        profilePhoto: file,        
      },
      profilePreviewUrl: reader.result
      }));
    }

    reader.readAsDataURL(file)

  }

  else if(input==="license")
    {

      let reader = new FileReader();
      let file = e.target.files[0];
  
      reader.onloadend = () => {
        this.setState((prevState) => ({
          data:{   
            ...prevState.data,     
          license: file,        
        },
        licensePreviewUrl: reader.result
        }));
      }
  
      reader.readAsDataURL(file)
  
    }

    else if(input==="expCertificate"){
      {
  
        let reader = new FileReader();
        let file = e.target.files[0];
    
        reader.onloadend = () => {
          this.setState((prevState) => ({
            data:{   
              ...prevState.data,     
            expCertificate: file,        
          },
          expCertificatePreviewUrl: reader.result
          }));
        }
    
        reader.readAsDataURL(file)
    
      }
  
  }

  console.log(this.state.profilePreviewUrl)
  }





  async handleRegister(e) {
    let self=this
    //e.preventDefault();
    self.setState({
      step: 9,
      submitLoading: true,
      errorMessage:''
    });
    const { data } = this.state;
   

    console.log('DATA',this.state.data)
    console.log('DATA',this.state.data.orgCertificates)
    console.log('COMM',this.state.data.personalInfo.communication)
    
  let formData = new FormData();
    formData.append('personalInfo[firstName]', this.state.data.personalInfo.firstName);
    formData.append('personalInfo[lastName]', this.state.data.personalInfo.lastName);
    formData.append('personalInfo[description]', this.state.data.personalInfo.description);
    formData.append('personalInfo[communication]', JSON.stringify(this.state.data.personalInfo.communication));
    formData.append('professionalInfo[occupation]', this.state.data.professionalInfo.occupation)
    formData.append('professionalInfo[workingSince]', this.state.data.professionalInfo.workingSince)
    formData.append('professionalInfo[education][collegeName]', this.state.data.professionalInfo.education.collegeName);
    formData.append('professionalInfo[education][universityName]', this.state.data.professionalInfo.education.universityName);
    formData.append('professionalInfo[education][countryOfCollege]', this.state.data.professionalInfo.education.countryOfCollege);
    formData.append('professionalInfo[education][mainStreamOfCourse]', this.state.data.professionalInfo.education.mainStreamOfCourse);
    formData.append('professionalInfo[education][isLifeTimeCertificate]', false);
    formData.append('professionalInfo[education][licenseExpiryDate]', '');
    formData.append('professionalInfo[education][licenseIssuedBy]', '');
    formData.append('professionalInfo[education][licenseId]', '');
    formData.append('professionalInfo[education][licenseName]', '');
    formData.append('licenseIssueDate', '');
    formData.append('professionalInfo[education][credentialId]', '');
    formData.append('professionalInfo[education][credentialUrl]', '');
    formData.append('professionalInfo[education][yearOfCompletion]', this.state.data.professionalInfo.education.yearOfCompletion);  
    formData.append('professionalInfo[education][organizationalMembership][nameOfOrganization]', this.state.data.professionalInfo.education.organizationalMembership.nameOfOrganization);
    formData.append('professionalInfo[education][organizationalMembership][workingSince]', this.state.data.professionalInfo.education.organizationalMembership.workingSince);
    formData.append('professionalInfo[education][organizationalMembership][honorsAndAwards]', JSON.stringify(this.state.data.professionalInfo.education.organizationalMembership.honorsAndAwards));
    formData.append('professionalInfo[education][organizationalMembership][personalWebsite]', this.state.data.professionalInfo.education.organizationalMembership.personalWebsite);
    formData.append('license', this.state.data.license);
    formData.append('certification', this.state.data.license);
    formData.append('expCertificate', this.state.data.expCertificate);
    for (let i = 0; i < this.state.data.orgCertificates.length; i++) {
    formData.append('orgCertificates', this.state.data.orgCertificates[i].file);
    }
    formData.append('linkedAccounts[socialPresence]', JSON.stringify(this.state.data.linkedAccounts.socialPresence));
    formData.append('linkedAccounts[professionalPresence]', JSON.stringify(this.state.data.linkedAccounts.professionalPresence));
    formData.append('profilePhoto', this.state.data.profilePhoto);
  
    // Display the values
for (var value of formData.values()) {
  console.log(value);
}
console.log('DATA2',this.state.data)
const token = await JSON.parse(localStorage.getItem('token'));
console.log('here') 

// Post the form, just make sure to set the 'Content-Type' header
await axios
.post(API_URL + "specialist/create", formData, {
  headers: {
    auth: token,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
})

.then(function (response) {
  console.log(response);
  //handle success

  if (response.data.success === true) {
    self.setState({
      submitLoading: false,
      isdataSubmitted: true,
    });

    // setTimeout(function(){
    //   self.props.history.push("/profile");

    //  }, 4000);
  }
  else{
    self.setState({
      errorMessage:response.data.error,
      submitLoading: false,          
    });
  }
},
(error) => {
  const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString()
   // console.log(message)
    self.setState({
      errorMessage:message,
      submitLoading: false,          
    });
}
);

}



  render() {
    const { step } = this.state;
    const {
      firstName,
      lastName,
      desription,
      username,
      password,
      country,
      levelOfEducation,
    } = this.state;
    const values = {
      firstName,
      lastName,
      desription,
      password,
      country,
      levelOfEducation,
    };

    switch (step) {
      case 1:
        return (
          <Personal
            nextStep={this.nextStep}
            handleChangePersonalInfo={this.handleChangePersonalInfo}
            values={this.state.data.personalInfo}
          />
        );

      case 2:
        return (
          <LanguagesSpeak
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChangeLanguage={this.handleChangeLanguage}
            addClick={this.addClick}
            removeClick={this.removeClick}
            values={this.state.data.personalInfo.communication}
          />
        );

      case 3:
        return (
          <ProfessionalSplitOne
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChangeProfessionalSplitOne={this.handleChangeProfessionalSplitOne}
          addClick={this.addClick}
          removeClick={this.removeClick}
          values={this.state.data.professionalInfo}
          />
        );

        case 4:
          return (
            <ProfessionalSplitTwo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChangeProfessionalSplitTwo={this.handleChangeProfessionalSplitTwo}
            addClick={this.addClick}
            removeClick={this.removeClick}
            values={this.state.data.professionalInfo}
            />
          );  

          case 5:
          return (
            <ProfessionalSplitThree
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChangeProfessionalSplitThree={this.handleChangeProfessionalSplitThree}
            addClick={this.addClick}
            removeClick={this.removeClick}
            handleRegister={this.handleRegister}
            values={this.state.data.professionalInfo}
            />
          );      

      case 6:
        return (
          <Certificates
            prevStep={this.prevStep}
            nextStep={this.nextStep}            
            handleChangeCertificates={this.handleChangeCertificates}
            ImageUploading={ImageUploading}
            maxNumber={maxNumber}
            values={this.state.data.orgCertificates}
          />
        );

        case 7:
        return (
          <CertificatesSplitTwo
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            licensePreviewUrl={this.state.licensePreviewUrl}
            profilePreviewUrl={this.state.profilePreviewUrl}
            expCertificatePreviewUrl={this.state.expCertificatePreviewUrl}
            handleChangeCertificatesSplitTwo={this.handleChangeCertificatesSplitTwo} 
            values={this.state.data.license}
          />
        );

      // case 8:
      //   return (
      //     <Sociallinks
      //       prevStep={this.prevStep}
      //       nextStep={this.nextStep}
      //       handleChange={this.handleChange}
      //       values={values}
      //     />
      //   );
      case 8:
        return (
          <Confirmation
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleRegister={this.handleRegister}
            submitData={this.state.data}
            values={this.state.data}
            imageData={this.state}
          />
        );
      case 9:
        return <Success 
        submitLoading={this.state.submitLoading}
        isdataSubmitted={this.state.isdataSubmitted}
        errorMessage={this.state.errorMessage}
        handleRegister={this.handleRegister}
        firstStep={this.firstStep}
        />;
      default:
      // do nothing
    }
  }
}

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, Image,Breadcrumb, Spinner } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { BiUserCircle, BiAperture, BiCart, BiCertification, BiChat, BiCog, BiUser, BiEnvelope, BiPhone, BiBadge, BiCalendar, BiPencil, BiCalendarStar, BiUserX, BiChair, BiLocationPlus, BiBuilding, BiBuildings, BiStar, BiListMinus } from "react-icons/bi";
import {BsStarFill, BsStarHalf} from "react-icons/bs";
import {FiAlertTriangle, FiCheck, FiHelpCircle} from "react-icons/fi"
import validator from "validator";

import moment from 'moment';
import DashboardSidebar from '../../DashboardSidebar';

import axios from "axios";
import { API_URL } from "../../../config";
import Loader from "react-js-loader";
import { HiArrowRight } from 'react-icons/hi';

export default class ProductLists extends Component {

    constructor(props) {
        super(props);
        this.addNewProduct = this.addNewProduct.bind(this);  

             
    
        this.state = {
          productsList:[],
          dataLoading:false,
          notice:false,
          userData: JSON.parse(localStorage.getItem('user')),
            dobError:'',
            genderError:'',
            professionError:'',
            streetError:'',
            stateError:'',
            cityError:'',
            countryError:'',
            pincodeError:'',

          data: {
            profile: {
              dob: "",
              gender: "",
              profession: "",
              address: {
                street: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
              },
            },
          },
        };
}
      componentDidMount() {
    
        if (this.props.isLoggedIn) {
          this.props.history.push("/dashboard");             
        }

        if(this.state.userData.isEmailVerified){
          this.setState({notice:false})
        }
        else{
          setTimeout(() => {
            this.setState({notice:true})
          }, 3000);
          

        }

        this.getProductList()
        
      }

      editProfile=()=>{
        this.props.history.push("/profile-edit"); 

      }

dismissNotice=()=>{  
    this.setState({notice:!this.state.notice})
  }
 
      
    
  addNewProduct=()=>{
this.props.history.push('/submit-product')
}
   validateField(fieldName)  {
        if (fieldName === "dob") {         

        }
    }



    async getProductList() {     
      var self = this;     
      self.setState({
        step: 10,
        submitLoading: true,
        errorMessage: "",
        dataLoading:true
      });
  
      console.log("DATA", self.state.data);
      const token = await JSON.parse(localStorage.getItem("token"));     
      await axios
        .get(API_URL + "product/manufacturer/me", {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })
  
        .then(
          function (response) {
            console.log(response);           
  
            if (response.data.success === true) {
              self.setState({
                productsList:response.data.productsList
              })
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                dataLoading:false
              });  
              
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                dataLoading:false
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              dataLoading:false
            });
          }         
        );
    }     

    render() {   
      
      const userData=this.state.userData
      const createdDate=moment(userData?.createdAt).format('LL');
        return (
          <div>
            <section className="lightgrey-bg user-area log-in-area topadjust">
              <div className="container-fluid">
                
                <div className="user-form-content">
                  
                  <div className="row">             

                    <div className="col-md-12">
                      <div className="form-card mt-3">                    
                     
                        <Container fluid>
                        <Breadcrumb>
  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
  <Breadcrumb.Item href="">
    Dashboard
  </Breadcrumb.Item>
  <Breadcrumb.Item active>Products</Breadcrumb.Item>
</Breadcrumb>

                          {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                          <Row>
                            <Col
                              xs
                              lg="2"
                              className="dashboard-sidebar"
                            >
                              <DashboardSidebar />
                            </Col>

                            <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                                  <Row>
                                    <Col>
                                      <div className="dashboard-content-title">
                                        My Products
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="dashboard-content-action float-end">
                                        <Button
                                          onClick={this.addNewProduct}
                                          type="submit"
                                          onKeyPress={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        > {this.state.spinner ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <span className="d-inline p-2">Submit New Product</span>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              <Row>
                                <Col className="position-relative">
                               

                                {this.state.userData?.role?.includes('manufacturer')?
                          
                            <ul class="tableList">
                            <li className="listHeader shd-1">
                                <Row className="align-items-center">
                                    <Col md={1}>                                   
                                    </Col>
                                    <Col md={4}>
                                    <div className="property-title">Product Name</div>                                                                 
                                    </Col>

                                    <Col md={2}>
                                    <div className="property-status">Image</div>
                                    </Col>
                                 
                                    <Col md={2}>
                                    <div className="prod">Product Status</div>
                                    </Col>

                                   

                                    <Col>
                                    <div className="property-rating">
                                   Rating
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className="property-view">
                                    <Link>Action</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                              </li>  


                              {this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}


{this.state.productsList && this.state.productsList?.length?
(
this.state.productsList?.map((item)=>(
                              <li className="shd-1 py-3">
                                <Row className="align-items-center">
                                    <Col md={1}>
                                    <BiBuildings className="ms-2 fs-2"/>
                                    </Col>
                                    <Col md={4}>
                                    <div className="property-title fw-700"> <Link to={`/product-details/${item._id}`}>{item?.productInfo?.brandName}</Link></div>
                                    <div className="property-added-date fs-12">Applied On: {item?.createdAt}</div>                                   
                                    </Col>  

                                      <Col md={2}>
                                    <div className="product-thumb-image"><img src={item?.productInfo?.image[0]?.secureUrl} /></div>
                                    </Col>

                                    <Col md={2}>
                                    <div className="property-status fw-700 c9"><FiAlertTriangle className="me-1" /> {item?.adminVerified ? " Approved " : " Pending"}</div>
                                    </Col>
                                    <Col>
                                    <div className="property-rating">
                                    <BsStarFill className="c2" />
                                   <BsStarFill className="c2" />
                                   <BsStarFill className="c2" />
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className="property-view">
                                    <Link to={`/product-details/${item._id}`}>View</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                              </li>  
))
):


(
  !this.state.dataLoading?
  <li className="text-center p-3 bdr-none">
     <BiListMinus className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">Oops! You have not added any properties yet. If you are looking to get a green build certification, Submit your property now.</h3>
<p className="text-center">You submitted properties will be listed here after approvals.</p>

    </li>
    :''
)
}




                            
                           
                            </ul>

:
    
<ul>
<li className="text-center p-3 bdr-none list-inline">
<BiListMinus className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">Oops! You are not a Manufacturer. Become a manufacturer now.</h3>
<Link
                                  to="/apply-manufacturer"
                                  class="btn btn-primary-trans fw-700 mt-3"
                                >
                                 <HiArrowRight />{' '} Submit Application
                                </Link>
</li>
</ul> 





}

                            </Col>
                           
                          
                            </Row>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

import React, { Component } from 'react'

export default class GHGClimateChange extends Component {
    render() {
        return (
            <div>
                       <div>
                      <div  class="Nature-banner inner-page">

<div class="row mb-4">
<div class="col-md-12">
 <h2 class="section-title p-tb-20 text-center">GHG & Climate Change</h2>

 
 </div>
 
 
 
 
 
 
 
       </div>
<div class="container-fluid">


<div class="row mb-4">


<div class="col-md-4 articles-btn">

 <ul class="navlist-items">
           <h6>Sustainability Specialist Services</h6>
           <li><a href="sustainable-building-design">Sustainable Building Design</a></li>
            <li><a href="sustainable-building-design">Sustainability Assessments & Reports</a></li>
              <li><a href="sustainable-building-design">Sustainability & ESG Strategy Development</a></li>
                 <li><a href="sustainable-building-design">Corporate Social Responsibility (CSR)</a></li>
             <li><a href="sustainable-building-design">Shading Modeling & Analysis</a></li>
              <li><a href="sustainable-building-design">Sustainability Health Check</a></li>
               <li><a href="sustainable-building-design">Sustainable Procurement</a></li>
           <li><a href="sustainable-building-design">Life Cycle Assessment (LCA) </a></li>
             <li><a href="sustainable-building-design">Feasibility & Impact Studies</a></li>
             <li><a href="sustainable-building-design">Stakeholder Engagement</a></li>
              <li><a href="sustainable-building-design">Archaeology & Heritage</a></li>
              
               <h6>Social Sustainability</h6> 
                  <li><a href="sustainable-building-design">Social Impact Assessment</a></li>
           <li><a href="sustainable-building-design">Social Impact Assessment</a></li>
           <h6>Sustainability Strategy & Communications</h6>
             <li><a href="sustainable-building-design">Sustainability Reporting Assurance & Advice</a></li>
           <li><a href="sustainable-building-design">Strategy Development</a></li>
           <li><a href="sustainable-building-design">Environmental Social Governance Due Diligence & Reporting</a></li>
             <li><a href="sustainable-building-design">Environmental Approvals & Management Systems</a></li>
              <li><a href="sustainable-building-design">National Pollutant Inventory Reporting</a></li>
               <h6>Geosciences</h6>
         
              <li><a href="sustainable-building-design">Geology</a></li>
             <li><a href="sustainable-building-design">Ecology</a></li>
              <li><a href="sustainable-building-design">Hydrogeology</a></li>
               <li><a href="sustainable-building-design">Contamination Assessment & Remediation</a></li>
           <li><a href="sustainable-building-design">Geotechnical</a></li>  
            <li><a href="sustainable-building-design">Land Quality & Remediation</a></li>
             <li><a href="sustainable-building-design">Contaminated Land Studies Services</a></li>
              <li><a href="sustainable-building-design">Risk Assessment & Toxicology</a></li>
               <h6>Soil Services</h6>
           <li><a href="sustainable-building-design">Field & Sampling</a></li>
             <li><a href="sustainable-building-design">Geotechnical Services</a></li>
              <li><a href="sustainable-building-design">Laboratory Outsourcing</a></li>
               <li><a href="sustainable-building-design">Environmental Data Management</a></li>
           <li><a href="sustainable-building-design">Interpretation & Modelling</a></li>
                <li><a href="sustainable-building-design">Environmental Assessment & Management</a></li>
           <li><a href="sustainable-building-design">Risk Assessment</a></li>
           <h6>Water</h6>
           <li><a href="sustainable-building-design">Hydrology & Hydrodynamics</a></li>
             <li><a href="sustainable-building-design">Impact Assessment & Permitting</a></li>
              <li><a href="sustainable-building-design">Integrated Water Management</a></li>
               <li><a href="sustainable-building-design">Wastewater & Stormwater Collection Systems</a></li>
           <li><a href="sustainable-building-design">Wastewater Treatment & Recycling</a></li>
           <li><a href="sustainable-building-design">Water Treatment & Desalination</a></li>
             <li><a href="sustainable-building-design">Water Transmission & Distribution</a></li>
           <li><a href="sustainable-building-design">Water Quality</a></li>
               <li><a href="sustainable-building-design">Water Audits</a></li>
           <li><a href="sustainable-building-design">Marine science</a></li>
           <h6>Air Quality & Noise</h6>
            <li><a href="sustainable-building-design">Adverse Amenity</a></li>
             <li><a href="sustainable-building-design">Air Quality</a></li>
              <li><a href="sustainable-building-design">Building Acoustics</a></li>
               <li><a href="sustainable-building-design">Expert Witness</a></li>
           <li><a href="sustainable-building-design">CFD</a></li>
           <li><a href="sustainable-building-design">Aviation Noise</a></li>
             <li><a href="sustainable-building-design">Acoustics & Vibration</a></li>
              <h6>Waste Management</h6>
              <li><a href="sustainable-building-design">Waste Minimization Audits</a></li>
               <li><a href="sustainable-building-design">Organic Waste Management Plan</a></li>
           <li><a href="sustainable-building-design">Supply Chain Management</a></li>
            <li><a href="sustainable-building-design">Solid Waste Management Plan</a></li>
               <li><a href="sustainable-building-design">Construction Waste Management Plan</a></li>
               <h6>Waste Design & Planning Services</h6>
           <li><a href="sustainable-building-design">Waste Design Services</a></li>
           <li><a href="sustainable-building-design">Technical Waste Services</a></li>
             <li><a href="sustainable-building-design">Waste Transaction Advisory Services</a></li>
              <li><a href="sustainable-building-design">Solid Waste Management</a></li>
               <li><a href="sustainable-building-design">Waste management strategies</a></li>
           <li><a href="sustainable-building-design">Waste to energy solutions</a></li>
           <h6>Environmental Specialist Services</h6>
           <li><a href="sustainable-building-design">Planning, Policy & Development </a></li>
           <li><a href="sustainable-building-design">Environmental, Social & Governance (ESG) Disclosures</a></li>
            <li><a href="sustainable-building-design">Environmental Impact Assessments(EIA)</a></li>
               <li><a href="sustainable-building-design">Strategic Environmental Assessment(SEA)</a></li>
           <li><a href="sustainable-building-design">Landscape & Visual Impact Assessment</a></li>
          <li><a href="sustainable-building-design">Environmental Monitoring & Modeling</a></li>
             <li><a href="sustainable-building-design">Environmental Site Supervision</a></li>
                   
              <li><a href="sustainable-building-design">ISO 14001 Environmental management</a></li>
               <li><a href="sustainable-building-design">Construction Environment Management Plan(CEMP)</a></li>
           <li><a href="sustainable-building-design">Standards, KPI & Framework Development</a></li>
                 <h6>Environmental Management, Planning & Approvals</h6>
                      <li><a href="sustainable-building-design">Community Planning</a></li>
            <li><a href="sustainable-building-design">Policy Conception & Implementation</a></li>
               <li><a href="sustainable-building-design">Occupational Hygiene</a></li>
            <li><a href="sustainable-building-design">Environmental Management, Permitting, & Compliance</a></li>
           <li><a href="sustainable-building-design">Landscape Architecture</a></li>
             <li><a href="sustainable-building-design">Eco-Reinforcement</a></li>
              <li><a href="sustainable-building-design">Oil Spill Prevention, Preparedness, & Response Plans</a></li>
                     
       </ul>
</div>
<div class="col-md-8">
<div class="row  Sustainable-inner-design">
<div class="col-md-4">
<a href="sustainable-building-design">


<div class="card-r">
<img src="assets/images/products/Measurement &amp; Verification.jpeg" alt="img" />
<h6>Sustainable Building Design</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Design Review &amp; Technical Services.jpeg" alt="img" />
<h6>Sustainability Assessments & Reports</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Environmental Impact Assessments(EIA).jpeg" alt="img" />
<h6>Sustainability & ESG Strategy Development</h6>
</div>
</a>
</div>
</div>
<div class="row  Sustainable-inner-design">
<div class="col-md-4">
<a href="sustainable-building-design">


<div class="card-r">
<img src="assets/images/products/Measurement &amp; Verification.jpeg" alt="img" />
<h6>Sustainable Building Design</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Design Review &amp; Technical Services.jpeg" alt="img" />
<h6>Sustainability Assessments & Reports</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Environmental Impact Assessments(EIA).jpeg" alt="img" />
<h6>Sustainability & ESG Strategy Development</h6>
</div>
</a>
</div>
</div>
<div class="row  Sustainable-inner-design">
<div class="col-md-4">
<a href="sustainable-building-design">


<div class="card-r">
<img src="assets/images/products/Measurement &amp; Verification.jpeg" alt="img" />
<h6>Sustainable Building Design</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Design Review &amp; Technical Services.jpeg" alt="img" />
<h6>Sustainability Assessments & Reports</h6>
</div>
</a>
</div>
<div class="col-md-4">
<a href="sustainable-building-design">
<div class="card-r">
<img src="assets/images/products/Environmental Impact Assessments(EIA).jpeg" alt="img" />
<h6>Sustainability & ESG Strategy Development</h6>
</div>
</a>
</div>
</div>


</div>
</div>
</div>

     </div>
  </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class SelfCertification extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="productrating-layout self">
            <div className="patner-trs">
              <div className="container">
                <div className="row mt-4">
                  <h2 className="heroText1 c1 text-center">
                    Smart Buildings Equal Smart Business
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container mb-4">
            <div className="row mt-4">
              <div className="col-md-12">
                <ul className="breadcrumb">
                  <li>
                    <a href="bsasrating">BSAS Rating Systems</a>
                  </li>
                  <li>
                    <Link to="/whygreenbuilding">Why GREEN Building</Link>
                  </li>
                  <li>
                    <a href="green-building-tools">Green Building Tools</a>
                  </li>
                  <li>
                    <a href="#">Resources</a>
                  </li>
                  <li>
                    <a href="termsandservice">Terms of Service</a>
                  </li>
                </ul>
              </div>
            </div>




            <div className="row">
              <div className="col-md-12">
                <h2 className="heroText1a p-tb-20 text-center">
                  Application Process for BSAS Property Rating System 
                </h2>

                <p className="heroText2 p-tb-20 text-center">
                Smart Property owners aspiring for BSAS Certification for their properties have to follow the step
by step process mentioned below.
                </p>

                <div className="text-center">
                  <div class="ghostBtn mt-3 ms-3 mb-5">
                    <a href="/whygreenbuilding">Why GREEN Building</a>
                  </div>
                </div>

                <h2 className="heroText1a section-title p-tb-20 text-center">
                  We can’t just consume our way to a more 2 sustainable world.
                </h2>
                <p className="heroText2 p-tb-20 text-center">
                  Launched in June 2020 after ten years of research and
                  development, the BSAS building standard is the leading
                  standard for buildings, interior spaces and communities
                  seeking to implement, validate and measure features that
                  support and advance sustainability, energy efficiency ,water
                  efficiency, human health and wellness.
                </p>
              </div>
            </div>








            <div className="row">
              <div className="col-md-12">
                <h2 className="heroText1a p-tb-20 text-center">
                  New Green Building Rating System
                </h2>

                <p className="heroText2 p-tb-20 text-center">
                  Beyond Sustainability Assessment System (BSAS) is a unique
                  project to reduce the carbon footprint of the India,
                  showcasing the world the commitment we have made to save the
                  planet earth from the impact of climate change. India will be
                  a pioneer in using technology to deliver sustainability.
                  Beyond Sustainability Assessment System (BSAS) is a rating
                  tool that helps people assesses the performance of their
                  building against certain internationally acceptable
                  benchmarks.{" "}
                </p>

                <div className="text-center">
                  <div class="ghostBtn mt-3 ms-3 mb-5">
                    <a href="/whygreenbuilding">Why GREEN Building</a>
                  </div>
                </div>

                <h2 className="heroText1a section-title p-tb-20 text-center">
                  We can’t just consume our way to a more 2 sustainable world.
                </h2>
                <p className="heroText2 p-tb-20 text-center">
                  Launched in June 2020 after ten years of research and
                  development, the BSAS building standard is the leading
                  standard for buildings, interior spaces and communities
                  seeking to implement, validate and measure features that
                  support and advance sustainability, energy efficiency ,water
                  efficiency, human health and wellness.
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-5">
                <img
                  src="assets/images/BASS RATING (1).png"
                  className="img-fluid product4-img"
                />
              </div>
              <div className="col-md-7">
                <p className="heroText2 p-tb-20">
                  The Beyond Smart Cities is committed to a sustainable,
                  prosperous future through BSAS, the leading program for green
                  buildings and communities worldwide. Our vision is that
                  buildings and communities will regenerate and sustain the
                  health and vitality of all life within a generation. Our
                  mission is to transform the way buildings and communities are
                  designed, built, and operated, enabling an environmentally and
                  socially responsible, healthy, and prosperous environment that
                  improves the quality of life.
                </p>
                <p className="heroText2 p-tb-20">
                  BSAS was developed by integrating scientific and medical
                  research and literature on environmental health, behavioral
                  factors, health outcomes and demographic risk factors that
                  affect health with leading practices in building design,
                  construction and management. The BSAS Building Standard
                  underwent a comprehensive expert peer review process, which
                  included three phases - scientific, practitioner and health
                  review
                </p>

                <div class="ghostBtn mt-3 ms-3 mb-5">
                  <a href="/dashboard">Register your Project</a>
                </div>
              </div>
            </div>

            <h2 className="section-title p-tb-20 text-center">
              BEYOND SUSTAINABILITY ASSESSMENT(BSAS) PRINCIPLES
            </h2>
            <p className="text-center">
              BSAS V.1 is founded on the following principles:
            </p>

            <div className="row ">
              <div className="col-md-4">
                <div className="self-content">
                  <div className="card">
                    <img
                      src="assets/images/icons/Equitable.png"
                      className="img-fluid"
                    />
                    <h2 className="text-center heroText1a mt-3">Equitable</h2>
                    <p className="heroText2 p-tb-20">
                      Provides the greatest benefit to the largest number of
                      people, inclusive of all demographic and economic groups
                      and with special consideration to less advantaged or
                      vulnerable populations.
                    </p>
                  </div>
                </div>

                <div className="self-content">
                  <div className="card">
                    <img
                      src="assets/images/icons/Global.png"
                      className="img-fluid"
                    />
                    <h4 className="text-center heroText1a mt-3">Global</h4>
                    <p className="heroText2 p-tb-20">
                      Proposes interventions that are feasible, achievable and
                      relevant across many applications throughout the world.
                      BSAS v1 offers regionally appropriate pathways for meeting
                      sustainability intents based on where projects are
                      located.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <img
                  src="assets/images/about-2.jpg"
                  className="img-fluid product4-img"
                />

                <div className="self-content">
                  <div className="card">
                    <img
                      src="assets/images/icons/Customer-Focused.png"
                      className="img-fluid"
                    />
                    <h4 className="text-center heroText1a mt-3">
                      Customer Focused
                    </h4>
                    <p className="heroText2 p-tb-20">
                      Defines program requirements through a dynamic development
                      process, with multiple opportunities for stakeholder
                      engagement, and by tapping the expertise of established
                      leaders in science, health, business, design and
                      operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="self-content">
                  <div className="card">
                    <img
                      src="assets/images/icons/Evidence-Based.png"
                      className="img-fluid"
                    />
                    <h4 className="text-center heroText1a mt-3">
                      Evidence-based
                    </h4>
                    <p className="heroText2 p-tb-20">
                      Promotes strong, validated research leading to conclusions
                      that can reasonably be accepted by the scientific
                      community.
                    </p>
                  </div>
                </div>

                <div className="self-content">
                  <div className="card">
                    <img
                      src="assets/images/icons/Technically-Robust.png"
                      className="img-fluid"
                    />
                    <h4 className="text-center heroText1a mt-3">
                      Technically Robust
                    </h4>
                    <p className="heroText2 p-tb-20">
                      Draws upon industry best practices and proven strategies,
                      offering consistency in findings across the relevant field
                      or discipline.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="section-title p-tb-20 hertoText1a">
              Explore concepts Learn More About BSAS V.1 Features Within Each
              BSAS Concepts
            </h2>
            <section className="self-icons">
              <div className="row mb-2 ">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/Reduced pollution.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Smart Transportation (ST)</h4>
                  <p className="heroText2 p-tb-20">
                    The Smart Transportation [ST] category is one of the main
                    ways smart cities are improving the daily lives of citizens
                    and improving sustainability.
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/Building Tech.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Green Site (GS)</h4>
                  <p className="heroText2 p-tb-20">
                    The Green Site[GS] category is concerned with the design of
                    the proposed development having a direct impact on both the
                    site of the development itself as well as any adjacent
                    sites.
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/engrgy and Cxa.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Energy Efficiency (EE) </h4>
                  <p className="heroText2 p-tb-20">
                    The Energy Efficiency category is concerned with improving
                    the design and energy performance of the development having
                    a direct and positive impact on both the consumption of
                    resources and environmental quality including climate
                    change.
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/Meet ESG goals.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Water Efficiency (WE)</h4>
                  <p className="heroText2 p-tb-20">
                    The Water category is concerned with water conservation for
                    indoor and outdoor use. The natural water cycle is a system
                    in which water resources are continuously exchanged between
                    the atmosphere, soil water, surface water, ground water,
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/buldingtech.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Materials and Resources (MR)</h4>
                  <p className="heroText2 p-tb-20">
                    The Materials category is concerned with the conservation of
                    natural resources and the use or reuse of materials and
                    structure to have the least environmental impact. fossil
                    fuel depletion, air pollution and human comfort, health and
                    well-being
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/GHG and climate change.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Indoor Environment (IE)</h4>
                  <p className="heroText2 p-tb-20">
                    The Indoor Environment category is concerned with the
                    quality of the indoor environment for the comfort, health
                    and well-being of occupants and users.{" "}
                  </p>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/HES and fire safety.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Health and Wellness (HW) </h4>
                  <p className="heroText2 p-tb-20">
                    Green Buildings help to create an environment which is
                    healthy and promote overall wellbeing of users. Spreading
                    awareness regarding benefits of various eco-friendly
                    practices benefits society at a larger scale. Fitness:
                    Fitness is an essential .component of the wellbeing.
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-2">
                  <img
                    src="assets/images/products/EMERGING DESIGN &amp;TECHNOLOGY.png"
                    alt="icon"
                  />
                </div>
                <div className="col-md-9">
                  <h4>Emerging Design and Technology (EDT) </h4>
                  <p className="heroText2 p-tb-20">
                    Green Building Technology, also known as Green Construction,
                    is the implementation of design in structures, buildings and
                    commercial spaces that are environmentally accountable and
                    resource-efficient throughout the building’s life cycle,
                    i.e., from planning to design, construction, operation,
                    maintenance, renovation and demolition
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

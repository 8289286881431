import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from "../config";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getManufacturerBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getServiceProviderBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();
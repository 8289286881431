import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  FaCompass,
  FaEuroSign,
  FaBox,
  FaPercent,
  FaPaperPlane,
  FaFileVideo,
  FaRupeeSign,
  FaUser,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCircle,
  FaUserNinja,
  FaUsersCog,
} from "react-icons/fa";
import { Accordion } from "react-bootstrap";

export default class HowtoPromoteProducts extends Component {
  render() {
    return (
      <div>
        <div class="Nature-banner inner-page">
          <div class="row mb-4">
            <div class="col-md-6 bg-21 p-0">
              <div class="p-5">
                <h1 className="heroText1">
                  Environmentally-Friendly Green Manufacturers Committed To
                  Sustainability
                </h1>
                <p className="heroText2">
                  Green marketing is a trend that many businesses have chosen to
                  adopt, while for others it is also a way of doing business.
                </p>
                <div class="solidBtn mt-3">
                  <a class="fw-600" href="/dashboard">
                    Create Profile
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/How-to-Promote-Products.jpg" alt="img" />
            </div>
          </div>

          <div class="container px-md-5">
            <div class="row bmd-layout">
              <h2 class="section-title p-tb-20 text-center heroText1a mb-4">
                How it works
              </h2>

              <div class="col-md-3 ">
                <FaUsersCog className="float-end" style={{ fontSize: 60 }} />
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">
                  Create your Manufacturer Profile (it’s free)
                </h5>
                <p>
                  An eye-catching title and client-focused overview help us
                  match you to the work you want. Include your professional
                  history, your skills, and your photo. Add more, like an
                  introduction, to create a Manufacture profile really stands
                  out.
                </p>
              </div>
            </div>
            <div class="row bmd-layout pt-3">
              <div class="col-md-3">
                <FaCompass className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Explore ways to promote</h5>
                <p>
                  These days, consumers demand businesses to be "green" — from
                  green packaging to green solutions to green food — a green
                  company offers sustainable or renewable products or operates
                  in an environmentally-friendly way.Adopting environmentally
                  friendly processes and energy-efficient practices has numerous
                  business benefits and are already at the center of many
                  corporate social responsibility policies and marketing
                  strategies.
                </p>
              </div>
            </div>
            <div class="row bmd-layout pt-3 mb-4">
              <div class="col-md-3">
                <FaFileVideo className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">
                  Get Certified with Smart Pro Rating
                </h5>
                <p>
                  This ability to attract more buyers and win more business is
                  the most obvious benefit of sustainability. However, it's not
                  the only one.The manufacturing landscape is a crowded one, and
                  you need to do everything you can to gain a competitive
                  advantage. Embracing sustainability and green-friendly
                  practices is one way you can do just that.
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row pb-4 pt-2">
              <h2 className="heroText1a pt-3 text-center">
                Frequently Asked Questions
              </h2>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What is Beyond Smart Cites Manufacturer Account?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      This is a portal available to all existing applicants and
                      licensee of the Beyond Smart Cities SmartPro rating
                      services.They will be able to do the following:
                    </p>
                    <ul>
                      <li>
                        Provide an overview of the Existing application and
                        Certification{" "}
                      </li>
                      <li>Update their company details</li>
                      <li>Submit a new application</li>
                      <li>Renew your existing certificate</li>
                      <li>View the progress of application</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Who will have access to the account?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      By default, an account will be created for the main
                      contact person of the company. The login details will only
                      be sent to the email address which is registered with us
                      during the initial Beyond SmartPro Product Application.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Does Beyond Smart Cities certify the company or the
                    products?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Beyond Smart Cities SmartPro only certifies products.
                      The SmartPro does not certify the companies who own the
                      products. To eco-certify a company, applicants can refer
                      to the Beyond Smart Cities BSAS Certification for
                      manufacturing facilities .
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    When can I receive my Smart Pro Rating Certificate?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The SmartPro certificate is only issued when payment is
                      received by the Beyond Smart Cities Team. Lease allow for
                      up to 5 working days for the certificate to be mailed to
                      you after payment is received and upon approval.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    How long will the SmartPro application process take?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Standard processing takes 8-10 working day from the moment
                      the Beyond Smart Cities Team receives the application form
                      with a complete set of supporting documents
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

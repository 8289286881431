import React, { Component } from 'react'

export default class Partnering extends Component {
    render() {
        return (
            <div>
                <div  className="Nature-banner inner-page">
    
    <div className="row  patner-shop-layout">
  <div className="col-md-12">
      <div className="patner-trs">
            <div className="patner-content">
                <h4><b>Let's be Friends Interested in partnering with us?</b></h4>
                   <form className="user-form" action="/" method="POST" enctype="">
                      
                      <div className="form-group">
                       
                          <input className="form-control" type="text" name="name" required="" placeholder=" Name" />
                                                          </div>
                      <div className="form-group">
                       
                          <input className="form-control" type="email" name="email" required="" placeholder="Email" />
                                                          </div>
                     
                       <div className="form-group">
                       
                          <input className="form-control" type="email" name="" required="" placeholder="Company Name" />
                                                          </div>
                      <div className="form-group">
                       
                          <input className="form-control" type="email" name="" required="" placeholder="How can we partner ?" />
                                                          </div>
                    <div className="form-group">
                          <div className="col-12">
                               <button><a href="#">Submit</a></button>
                          </div>
                   
                     </div>
                     </form>
        
             
          
      </div>
     
            
     </div> 

</div>
</div>

            </div>
            </div>
        )
    }
}

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const SmartTransportation = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>Smart Transportation(ST)</h2>
                          <Row>
                    <p className="mt-2">
                            The Smart Transportation [ST] category is one of the
                            main ways smart cities are improving the daily lives
                            of citizens and improving sustainability.
                          </p>
                      </Row>
                          <img
                            className="img-fluid pt-3"
                            src="assets/images/smart-transportation.jpg"
                          ></img>
                         
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                24 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                         
                          <h3 className="mt-5 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                               
                                label="Reduce Traffic Congestion"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Reduce Traffic Congestion"
                                id={`inline-checkbox-1`}
                                value={true}
                                checked= {values?.smartTransportation?.includes('Reduce Traffic Congestion') ? true : false}
                              />

                              <Form.Check                                
                                label="Reduced Parking Footprint"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Reduced Parking Footprint"
                                id={`inline-checkbox-2`}
                                checked= {values?.smartTransportation?.includes('Reduced Parking Footprint') ? true : false}

                              />

                              <Form.Check                                
                                label="Bicycle Infrastructure"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Bicycle Infrastructure"
                                id={`inline-checkbox-3`}
                                checked= {values?.smartTransportation?.includes('Bicycle Infrastructure') ? true : false}

                              />

                              <Form.Check                                
                                label="Vehicle Noise"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Vehicle Noise"
                                id={`inline-checkbox-4`}
                                checked= {values?.smartTransportation?.includes('Vehicle Noise') ? true : false}

                              />
                            </Col>
                            <Col>
                              <Form.Check                                
                                label="Public Transportation"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Public Transportation"
                                id={`inline-checkbox-1`}
                                checked= {values?.smartTransportation?.includes('Public Transportation') ? true : false}

                              />

                              <Form.Check                               
                                label="Private Transportation"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Private Transportation"
                                id={`inline-checkbox-2`}
                                checked= {values?.smartTransportation?.includes('Private Transportation') ? true : false}

                              />

                              <Form.Check                               
                                label="Proximity to Amenities"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Proximity to Amenities"
                                id={`inline-checkbox-3`}
                                checked= {values?.smartTransportation?.includes('Proximity to Amenities') ? true : false}

                              />

                              <Form.Check                               
                                label="High-Performance Electric Mobility "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="High-Performance Electric Mobility"
                                id={`inline-checkbox-4`}
                                checked= {values?.smartTransportation?.includes('High-Performance Electric Mobility') ? true : false}

                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>


                      <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>
                   
                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>

                   

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/smart-transportation-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../../helpers/CardBoxLoading"
import { Link } from "react-router-dom";
import {FaRocket, FaRupeeSign, FaBell, FaEject, FaRegHourglass} from "react-icons/fa";
export default class Specialists extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
      category:this.props.match.params.cat,
      subCategory:this.props.match.params.subcat,
      childCategory:this.props.match.params.childcat,
      specialistInfo:[],
      isdataFetched:false,
      serviceId:'',
      actualQueryDataFecthed:false,
      nothingFound:false,

    }
  }

  componentDidMount() {
    this.getCategories();
    console.log(this.props)
    this.getSpecialistsWithQuery();
  }


  componentDidUpdate(prevProps) {
   // console.log('ii',prevProps.match.params.childcat)

    if (this.props.match.params.childcat !== prevProps.match.params.childcat) {
   
      // call the fetch function again
      console.log('yes')

      this.setState({
        category:this.props.match.params.cat,
        subCategory:this.props.match.params.subcat,
        childCategory:this.props.match.params.childcat,
      })
    
       this.getSpecialistsWithQuery();
   
  }

  
}
 

  goToSpecialistDetail(specialistId){
    console.log('p')
    this.props.history.push({
      pathname: '/specialist-information/'+specialistId           
    })

// this.props.history.push('/specialist-information')

  }


  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }




  async getSpecialistsWithQuery() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));
    let data={
      category: this.state.category,
      subCategory: this.state.subCategory,
      childCategory:this.state.childCategory
    }
    console.log("datareal", data);

    let urlNext = "";
    if (this.state.category && this.state.subCategory && this.state.childCategory ) {
      urlNext = "service/search/specialist/level-three";
      console.log('3')
    }
    else if(this.state.category && this.state.subCategory && !this.state.childCategory){
      urlNext = "service/search/specialist/level-two";
      console.log('2')
    }    
    
    else {
      urlNext = "service/search/specialist/level-one";
      console.log('1')
    }

    await axios
      .get(API_URL + urlNext, 

      { params: {
        category: self.state.category,
        subCategory:self.state.subCategory,
        childCategory:self.state.childCategory
      }},
      
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('SPECINFO11',response);

          if (response.data.success === true) {

            if(response.data.specialistInfo.length==0){
              self.setState({
                actualQueryDataFecthed:false
              })
              self.getSpecialistsWithQueryTryforSubCat()

            }
            else{

            self.setState({
              specialistInfo:response.data.specialistInfo
            });
            self.setState({
              actualQueryDataFecthed:true,
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          }
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }






  async getSpecialistsWithQueryTryforSubCat() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));   

    let urlNext = "";   
      urlNext = "service/search/specialist/level-two";        

    await axios
      .get(API_URL + urlNext, 

      { params: {
        category: self.state.category,
        subCategory:self.state.subCategory        
      }},
      
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('SPECINFOLEVEl2',response);

          if (response.data.success === true) {
            if(response.data.specialistInfo.length==0){
              self.setState({               
                isdataFetched: false, 
                actualQueryDataFecthed:false,               
              });
              self.getSpecialistsWithQueryTryforOnlyCat()

            }
            else{

            self.setState({
              specialistInfo:response.data.specialistInfo
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          }
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }




  async getSpecialistsWithQueryTryforOnlyCat() {
    var self = this;

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));   

    let urlNext = "";   
      urlNext = "service/search/specialist/level-one";        

    await axios
      .get(API_URL + urlNext, 

      { params: {
        category: self.state.category       
      }},
      
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('SPECINFOLEVEL1',response);

          if (response.data.success === true) {    
            
            if(response.data.specialistInfo.length==0){
              self.setState({nothingFound:true})
            }

            self.setState({
              specialistInfo:response.data.specialistInfo
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async getCategories() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "category/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('CAT',response);

          if (response.data.success === true) {
            self.setState({
              //propertyData:response.data.propertyData
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }


  goToCategory=(cat)=>{
    this.setState({
      category:cat,
      subCategory:undefined,
      childCategory:undefined
    })
    this.getSpecialistsWithQuery()
  }


  goTosubCategory=(cat,subcat)=>{
    this.setState({
      category:cat,
      subCategory:subcat,
      childCategory:undefined
    })
    this.getSpecialistsWithQuery()
   
  }
  render() {
    

    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>                  
                  <Breadcrumb.Item active>Services</Breadcrumb.Item>
                  {/* to={"/specialists/" + this.state.category} */}
                  <Breadcrumb.Item active><Link onClick={()=>this.goToCategory(this.state.category)}  >{this.state.category}</Link></Breadcrumb.Item>
                  {this.state.subCategory &&
                  <Breadcrumb.Item active><Link onClick={()=>this.goTosubCategory(this.state.category,this.state.subCategory)}>{this.state.subCategory}</Link></Breadcrumb.Item>
                  }
                  {this.state.childCategory &&
                  <Breadcrumb.Item active>{this.state.childCategory}</Breadcrumb.Item>
                  }
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="top-notice">
                  {!this.state.actualQueryDataFecthed && this.state.isdataFetched ? (
                    <p className="text-center">No Specialist Service found for your selection. Attempting to list the best match.</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2>Listing Specialist Services</h2>
              </Col>
            </Row>
            <Row class=" Sustainable-inner-design">
              {this.state.specialistInfo.length ? (
                this.state.specialistInfo.map((item) => (
                  <div class="col-md-3 cursor-pointer mb-3">                    
                      <div class="card-r  shd-1">

                      {item.serviceGallery.length ?
                            <img
                            onClick={() => this.goToServiceDetail(item.overview.slug,item.createdBy._id)}
                              src={item.serviceGallery[0]?.secureUrl}
                              alt="Person"                             
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                            :
                            <img
                            onClick={() => this.goToServiceDetail(item.overview.slug,item.createdBy._id)}
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              height="180"
                              style={{objectFit:'cover'}}
                            />}
                       
                        <div class="card-content">
                          <div class="chip">
                            {item.createdBy?.customerId?.profile?.image?.secureUrl ?
                            <img
                              src={item.createdBy?.customerId?.profile?.image?.secureUrl}
                              alt="Person"
                              width="96"
                              height="96"
                            />
                            :
                            <img
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              width="96"
                              height="96"
                            />}
                            <Link
                              onClick={() =>
                                this.goToSpecialistDetail(item.createdBy?._id)
                              }
                            >
                              {" "}
                              <span className="fw-900">{item.createdBy?.personalInfo?.firstName}</span>
                            </Link>
                          </div>
                          <h3 className="mb-4 c3"
                          onClick={() => this.goToServiceDetail(item.overview.slug,item.createdBy?._id)}>
                            {item.overview.gigTitle}                           
                          </h3>
                          <Row>
                            <Col md={4}>
                              <p>⭐4.6(10+)</p>
                            </Col>
                            <Col md={1}>
                              {/* <i class="fas fa-heart"></i> */}
                            </Col>
                            <Col>
                              <h3 className="float-end c4">Starts{ } <FaRupeeSign /> {item.packageDetails[0].price}</h3>
                            </Col>
                          </Row>
                        </div>
                      </div>                   
                  </div>
                ))
              ) : (
                
               
                <> {!this.state.nothingFound &&
                  <>
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                  </>
                }
                </>
              )}

{this.state.nothingFound &&
<div className="p-5">
<h2 className="text-center"><FaRegHourglass /></h2>
<h2 className="text-center">Sorry, We couldnt find anything for you.</h2>
</div>
  }




            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

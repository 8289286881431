import React, { Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

export const LanguagesSpeak = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangePersonalInfo,
  handleChangeLanguage,
  values,
  addClick,
  removeClick,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    console.log(values)

    if (values.length > 1 && values.every((x)=>x.language != '' && x.proficiencyLevel != '') ) 
    {
      nextStep();
  }
     else{
      
      toast("Please enter atleast 3 languages", {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
     }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">          
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                      <Form.Group>
                        <Form.Label>
                          Select Language and Proficiency level
                        </Form.Label>
                        <Form.Text className="text-muted">
                          <p>
                            Try selecting multiple languages, which will help
                            customers to find you filtering relevant regional
                            languages.
                          </p>
                        </Form.Text>{" "}
                      </Form.Group>

                      {values.map((el, i) => (
                        <Fragment key={i}>
                          <Row className="align-item-center justify-content-center ">
                            <Col>
                              <Form.Select
                                value={el.language || ""}
                                onChange={handleChangeLanguage(i)}
                                name="language"
                                aria-label="Default select"
                              >
                                <option>Select Language</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Kannada">Kannada</option>
                                <option value="Tamil">Tamil</option>
                                <option value="Telugu">Telugu</option>
                                <option value="Malayalam">Malayalam</option>
                                <option value="Marathi">Marathi</option>
                                <option value="Oriya">Oriya</option>
                                <option value="Gujarathi">Gujarathi</option>
                                <option value="Rajasthani">Rajasthani</option>
                                <option value="Bengali">Bengali</option>
                                <option value="Urdu">Urdu</option>
                              </Form.Select>
                            </Col>
                            <Col className="align-self-center">
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label="Poor"
                                  name={`proficiencyLevel-${i}`}
                                  type={"radio"}
                                  checked={
                                    el.proficiencyLevel === "Poor"
                                      ? true
                                      : false
                                  }
                                  value={"Poor"}
                                  onChange={handleChangeLanguage(i)}
                                  id={`inline-${i}-1`}
                                  className={`inline-${i}-1`}
                                />
                                <Form.Check
                                  inline
                                  label="Good"
                                  name={`proficiencyLevel-${i}`}
                                  type={"radio"}
                                  checked={
                                    el.proficiencyLevel === "Good"
                                      ? true
                                      : false
                                  }
                                  value={"Good"}
                                  onChange={handleChangeLanguage(i)}
                                  id={`inline-${i}-2`}
                                  className={`inline-${i}-2`}
                                />
                                <Form.Check
                                  inline
                                  label="Excellent"
                                  name={`proficiencyLevel-${i}`}
                                  type={"radio"}
                                  value={"Excellent"}
                                  checked={
                                    el.proficiencyLevel === "Excellent"
                                      ? true
                                      : false
                                  }
                                  onChange={handleChangeLanguage(i)}
                                  id={`inline-${i}-3`}
                                  className={`inline-${i}-3`}
                                />
                              </div>
                            </Col>
                            <Col className="col-md-2">
                              <input
                                type="button"
                                value="remove"
                                onClick={removeClick.bind(this, i)}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      ))}
                    </Form>
                    <Row className="px-md-5">
                      <Col>
                        <input
                          type="button"
                          value="Add more"
                          onClick={addClick.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row className="text-center">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";

class ProCategories extends Component {
  async getProCategories() {
    const token = await JSON.parse(localStorage.getItem("token"));
    return await axios
      .get(API_URL + "proCategory/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(function (response) {
        console.log(response);

        if (response.data.success === true) {
          return response.data;
        }
      });
  }
}

export default new ProCategories();

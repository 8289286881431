import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const EnergyEfficiency = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>ENERGY EFFICIENCY (EE)</h2>
                          <Row>
                    <p className="mt-2">
                            The Energy Efficiency category is concerned with
                            improving the design and energy performance of the
                            development having a direct and positive impact on
                            both the consumption of resources and environmental
                            quality including climate change, fossil fuel
                            depletion, air pollution and human comfort, health
                            and well-being.
                          </p>
                      </Row>


                          <img
                            className="img-fluid pt-3"
                            src="assets/images/greencity.jpg"
                          ></img>
                         
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                30 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-5 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                                
                                label="Fundamental Commissioning"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Fundamental Commissioning"
                                id={`inline-checkbox-1`}
                                checked= {values?.energyEfficiency?.includes('Fundamental Commissioning') ? true : false}

                              />

                              <Form.Check                                
                                label="Energy-efficient Design"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Energy-efficient Design"
                                id={`inline-checkbox-25`}
                                checked= {values?.energyEfficiency?.includes('Energy-efficient Design') ? true : false}

                              />

                              <Form.Check                                
                                label="Building Energy Performance"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Building Energy Performance"
                                id={`inline-checkbox-38`}
                                checked= {values?.energyEfficiency?.includes('Building Energy Performance') ? true : false}

                              />

                              <Form.Check                                
                                label="Measurement and Verification (M&V)"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Measurement and Verification (M&V)"
                                id={`inline-checkbox-44`}
                                checked= {values?.energyEfficiency?.includes('Measurement and Verification (M&V)') ? true : false}

                              />

                              <Form.Check                                
                                label="Smart Energy Metering"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Smart Energy Metering"
                                id={`inline-checkbox-45`}
                                checked= {values?.energyEfficiency?.includes('Smart Energy Metering') ? true : false}

                              />
                            </Col>
                            <Col>
                              <Form.Check                                
                                label="Green Energy"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Green Energy"
                                id={`inline-checkbox-12`}
                                checked= {values?.energyEfficiency?.includes('Green Energy') ? true : false}

                              />

                              <Form.Check                                
                                label="Enhanced Commissioning"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Enhanced Commissioning"
                                id={`inline-checkbox-21`}
                                checked= {values?.energyEfficiency?.includes('Enhanced Commissioning') ? true : false}

                              />

                              <Form.Check                                
                                label="Green Refrigerant Management"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Green Refrigerant Management"
                                id={`inline-checkbox-43`}
                                checked= {values?.energyEfficiency?.includes('Green Refrigerant Management') ? true : false}

                              />

                              <Form.Check                                
                                label="Smart Security & Surveillance "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Smart Security & Surveillance"
                                id={`inline-checkbox-34`}
                                checked= {values?.energyEfficiency?.includes('Smart Security & Surveillance') ? true : false}

                              />

                              <Form.Check                                
                                label="Greenhouse Gas Accounting "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Greenhouse Gas Accounting"
                                id={`inline-checkbox-24`}
                                checked= {values?.energyEfficiency?.includes('Greenhouse Gas Accounting') ? true : false}

                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                   
                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/energyefficiency.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../helpers/CardBoxLoading"
import { Link } from "react-router-dom";
import {FaRocket, FaRupeeSign } from "react-icons/fa";
export default class BSASApprovedProperties extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
    //   category:this.props.match.params.cat.replace(/-/g, " "),
    //   subCategory:this.props.match.params.subcat.replace(/-/g, " "),
    //   childCategory:this.props.match.params.childcat.replace(/-/g, " "),
    propertiesList:[],
      isdataFetched:false,
      serviceId:''
    }
  }

  componentDidMount() {
    this.getProperties();
  }


  

  goToSpecialistDetail(specialistId){
    console.log('p')
    this.props.history.push({
      pathname: '/specialist-information/'+specialistId           
    })


  }


  goToPropertyDetail(propertyId){
 this.props.history.push('/BSAS-property-information/'+propertyId)
  }




  async getProperties() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));
        await axios
      .get(API_URL + "property/public/list/",  
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('Properties',response);

          if (response.data.success === true) {
            self.setState({
              propertiesList:response.data.propertiesList
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async getCategories() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "category/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('CAT',response);

          if (response.data.success === true) {
            self.setState({
              //propertyData:response.data.propertyData
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>                  
                  <Breadcrumb.Item active>Smart Products</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="top-notice">
                  {this.state.propertiesList &&
                  !this.state.propertiesList.length &&
                  this.state.isdataFetched ? (
                    <p>No Products found for your selection</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2>Listing BSAS Approved Properties</h2>
              </Col>
            </Row>
            <Row class=" Sustainable-inner-design">
              {this.state.propertiesList &&
               this.state.propertiesList.length ? (
                this.state.propertiesList.map((item) => (
                  <div class="col-md-3 cursor-pointer mb-3">                    
                      <div class="card-r  shd-1">

                      {item?.propertiesList?.image.length ?
                            <img
                            onClick={() => this.goToPropertyDetail(item._id)}
                              src={item.propertiesList?.image[0]?.secureUrl}
                              alt="Person"                             
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                            :
                            <img
                            onClick={() => this.goToPropertyDetail(item._id)}
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              height="180"
                              style={{objectFit:'cover'}}
                            />}
                       
                        <div class="card-content">        
                        {item?.propertiesList?.projectName}                   
                          <h3 className=" mt-2 mb-1 c3"
                          onClick={() => this.goToPropertyDetail(item._id,item.createdBy?._id)}>
                          {item.projectName}  
                          </h3>
                        </div>
                      </div>                   
                  </div>
                ))
              ) : (
                <>
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { BiCheck, BiRupee } from "react-icons/bi";
import { connect } from "react-redux";
import { FaFileDownload } from "react-icons/fa";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

class BSASPropertyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyId: this.props.match.params.id,

      propertyData: "",
      galleryItems: "",
      serviceList: "",
    };
    // this.addNewProperty = this.addNewProperty.bind(this);
  }

  componentDidMount() {
    //this.getPropertyInformation();
    this.getPropertyInformation();
  }

  goToServiceDetail(serviceId, specId) {
    console.log("p", serviceId, specId);
    const win = window.open(
      "/service-information/" + serviceId + "/" + specId,
      "_blank"
    );
    win.focus();

    //this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }

  async getPropertyInformation() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "property/public/" + this.state.propertyId, {
        headers: {
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log("PropertyInfo", response);

          if (response.data.success === true) {
            self.setState({
              propertyData: response.data.propertyData,
            });
            let gal = [];
            response.data.propertyData?.serviceGallery?.map((i) =>
              gal.push({
                original: i.secureUrl,
                thumbnail: i.secureUrl,
              })
            );
            console.log(gal);
            self.setState({
              galleryItems: gal,
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async getProducts() {
    var self = this;

    console.log("called");

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(
        API_URL + "property/public/list/",

        {
          headers: {
            Accept: "application/json",
          },
        }
      )

      .then(
        function (response) {
          console.log("Property", response);

          if (response.data.success === true) {
            self.setState({
              propertyData: response.data.productsList.filter(
                (item) => item._id === self.state?.productId
              ),
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });

            let gal = [];
            console.log(
              response.data.productsList.filter(
                (item) => item._id === self.state?.productId
              )[0].productInfo?.image
            );
            response.data.productsList
              .filter((item) => item._id === self.state?.productId)[0]
              .productInfo?.image?.map((i) =>
                gal.push({
                  original: i.secureUrl,
                  thumbnail: i.secureUrl,
                })
              );
            self.setState({
              galleryItems: gal,
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });

            console.log("gal", self.state.galleryItems);
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {this.state.propertyData[0]?.productInfo?.brandName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <div className=" card-r p-4">
              <Row className="mb-4 align-items-top">
                {this.state.propertyData[0]?.drawingsOfProject?.length ? (
                  this.state.galleryItems?.length && (
                    <div class="col-md-5 p-2 gallery-image-holder">
                      <ImageGallery items={this.state?.galleryItems} />
                    </div>
                  )
                ) : (
                  <div
                    class="col-md-5 p-2 bg-4 gallery-image-holder"
                    style={{ minHeight: 300 }}
                  >
                    <img
                      src="https://picsum.photos/id/1031/5446/3063.jpg"
                      className="img-fluid"
                    />
                  </div>
                )}

                <div class="col-md-7 ">
                  <h2 className="heroText1a">
                    {this.state.propertyData?.projectName}
                  </h2>

                  {this.state.propertyData?.status === "Approved" ? (
                    <p className="verified-badge">Verified</p>
                  ) : (
                    <p className="not-verified-badge">Pending for Approval</p>
                  )}
                  <p style={{ fontSize: 16, color: "#444", marginBottom: 0 }}>
                    <span className="product-detail-desc-holder">Project:</span>{" "}
                    {this.state.propertyData?.projectName}
                  </p>
                  <p style={{ fontSize: 16, color: "#444", marginBottom: 0 }}>
                    <span className="product-detail-desc-holder">
                      Project ID:
                    </span>{" "}
                    {this.state.propertyData.projectID}
                  </p>
                  <p style={{ fontSize: 16, color: "#444", marginBottom: 0 }}>
                    <span className="product-detail-desc-holder">
                      Location:
                    </span>{" "}
                    {this.state.propertyData?.address}
                  </p>
                  <p style={{ fontSize: 16, color: "#444", marginBottom: 0 }}>
                    <span className="product-detail-desc-holder">
                      Description:
                    </span>{" "}
                    {this.state.propertyData?.description}
                  </p>

                  <hr />
                </div>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  const { cartItems } = state.cart;
  console.log(cartItems);
  return {
    user,
    isLoggedIn,
    cartItems,
  };
}

export default connect(mapStateToProps)(BSASPropertyInformation);

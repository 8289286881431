import validator from "validator";

export const validate_Alpha_Length=(val)=>{     
  return val.length >=1 && validator.isAlpha(val) ? true : false     
}

export const validate_Alpha_Email_Length=(val)=>{     
 return val.length >=1 && validator.isEmail(val) ? true : false     
}

export const validate_MobilePhone_Length=(val)=>{     
console.log(val.length)
return val.length == 10 && validator.isMobilePhone(val) ? true : false     
}


export const validate_Desc100_Length=(val)=>{     
  console.log(val.length)
  return val.length > 50 ? true : false     
  }

export const validate_Length_DOB=(val)=>{     
  console.log(val.length)
  return val.length > 7 ? true : false     
  }

  export const validate_Empty=(val)=>{     
   
    if(val){
    return val.length > 2 ? true : false     
    }  
    else{
      return false
    }
  }


import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class SignIn extends Component {
    render() {
        return (
            <div>
                    
          <section class="user-area log-in-area">
        <div class="container">
            <div class="user-form-content">

                <div class="row">
                   

                    <div class="sign-layout">
                        <div class="login-form-holder position-relative">
                            <div class="form-group text-center">
                                <img src="assets/images/logo.png" alt=""  />

                                <h4 class="mt-4">Sign up</h4>

                                
                            </div>
                            <form class="user-form" action="index" method="POST" enctype="">
                                
                                <div class="form-group">
                                    <label>Name</label>
                                    <input class="form-control" type="text" name="name" required="" />
                                                                    </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input class="form-control" type="email" name="email" required="" />
                                                                    </div>
                        
                                <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="password" name="password" required="" />
                                                              
                                    <label>Repeat password</label>
                                    <input class="form-control" type="password" name="password_confirmation" required="" />
                                </div>
                                <div class="form-group mb-4">
                                    <label for="agree">
                                        <input type="checkbox" name="checkbox" value="check" id="agree" checked="" /> I
                                        agree to the <Link to="/termsservice"  class="text-color-orange" target="_blank">Terms and Conditions</Link> and <Link to="/privacypolicy" class="text-color-orange" target="_blank">Privacy
                                            Policy</Link>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary default-btn se-primary-btn"  type="submit">
                                        Register now
                                    </button>
                                </div>
                                <div class="form-group">
                                    <div class="col-12">
                                        <p class="create">Already have an account? <a href="signin" className="text-color-orange">Log
                                                In</a></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
    </div></div></div></section>
          
        
         </div>
           
        )

    }
}

import React, { Component } from 'react'

export default class HireMiddleEast extends Component {
    render() {
        return (
            <div>
                
     <div  className="Nature-banner inner-page">
           <div className="row mb-4">
               <div className="col-md-6 hire p-0">
                         <div className="hire-text-learn">
                              <h1 className='heroText1'>Hire Specialists in the Middle East</h1>
                         <p className='heroText2'>Work with the world’s best specialist on Beyond Smart Cities – the top freelancing website trusted by smart businesses worldwide.</p>
                         <div class="solidBtn mt-3"><a class="fw-600" href="#">Coming Soon</a></div>
                       
                         </div>
                        
                  
               </div>
                 <div className="col-md-6 hire-img-layout p-0">
                    
                         <img src="assets/images/middle.jpg" alt="img" />
                    
                   
               </div>
           </div>
           
          
            </div>
    

            </div>
        )
    }
}

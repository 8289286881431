import React, { Component, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../../helpers/CardBoxLoading";
import { Link } from "react-router-dom";
import { FaFilePdf, FaRocket, FaRupeeSign } from "react-icons/fa";
import CourseLinks from "../Components/CourseLinks";


export default function GreenBuildingCommissioning() {
  


  return (
    <div>
      <section className="page-container p-2 ">
        <div className="page-inner bg-1 ">
          <Container fluid>
            <Row className="pt-3">
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>KnowledgeLab</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <Row className="pb-3 align-items-start pt-3 px-3">
              <Col className="col-md-8">
                <h1 className="heroText1b mb-3">Green Building Commissioning Workshop</h1>                            
                <video width="100%" height="400px" controls style={{backgroundColor:'#000',borderRadius:8}}>
                  <source
                    src="https://smartwork.s3.ap-south-1.amazonaws.com/KnowledgeLab/Green+Building+Commissioning+.mp4"
                    type="video/mp4"
                  />
                </video>                       
                        
                
                <h2 className="pt-3">Understanding the LEED Enhanced Commissioning</h2>
               
            
                <p>
                  Coordinate and facilitate a 3 session in India to develop the
                  recommended commissioning approach and plan for the projects
                  referenced below:
                  <ol>
                    <li>USGBC LEED</li>
                    <li>IGBC LEED</li>
                    <li>GORD GSAS</li>
                  </ol>
                  Work LEED/GSAS/IGBC project teams to develop the commissioning
                  goals for the projects and an acceptable approach to execute
                  commissioning in the subsequent construction process.Identify
                  commissioning team members and definition of tasks and
                  responsibilities to execute the commissioning activities from
                  this point forward to meet the LEED prerequisite requirements.
                  Provide documentation and/or tools to facilitate each step in
                  the Cx process. Develop a proposal for follow-on commissioning
                  plan execution and oversight to assure the commissioning
                  objectives are achieved for the project.
                </p>
              </Col>
          
             
             <Col className="my-3">

               <h3>WorkShop By</h3>
               <p className="fs-12">by Beyond Smart Cities</p>  
               <hr/>
           

             <h3>Read Time</h3>
               <p className="fs-12">3 Hours</p>  
               <hr/>
               <h3>Course Type</h3>
               <p className="fs-12">Free for BSC users</p>  

               <hr/>  

               <h3>What to Expect?</h3>
               <p className="pt-3">
                 This three session webinar series will review the technical
                 requirements of the commissioning goals for the projects and
                 acceptable approach to execute commissioning in the subsequent
                 construction process.The series will cover each commissioning
                 credit category, touch on every prerequisite and credit, and
                 highlight differences specific to project type adaptations.
               </p> 
               <hr/>
               <h2>Reference links</h2>
             <a href="https://smartwork.s3.ap-south-1.amazonaws.com/KnowledgeLab/GreenBuilding/1.Introduction+to+Commissioning+%40+Beyond+Smart+Cities.pdf" target="_blank" ><FaFilePdf style={{fontSize:30,color:'#d56c4b'}} /></a>

             <a href="https://smartwork.s3.ap-south-1.amazonaws.com/KnowledgeLab/GreenBuilding/2.Enhanced+Commissioning+%40+Beyond+Smart+Cities.pdf" target="_blank" ><FaFilePdf style={{fontSize:30,color:'#d56c4b'}} /></a>

             <a href="https://smartwork.s3.ap-south-1.amazonaws.com/KnowledgeLab/GreenBuilding/3.Commissioning+Green+Buildings+%40+Beyond+Smart+Cities.pdf" target="_blank" ><FaFilePdf style={{fontSize:30,color:'#d56c4b'}} /></a>

             <hr/>
             </Col>
           </Row>

          </Container>

          <CourseLinks />
        </div>
      </section>
    </div>
  );
}


import React, { Component } from 'react'

export default class InvestorRelations extends Component {
    render() {
        return (
            <div>
                 <div  className="Nature-banner inner-page">
         
         <div className="row Investor-banner">
           <div className="col-md-12">
                 <div className="Investor-banner-content">
                     <h1 className="heroText1a">Investor Relations</h1>
                    
                 </div>
           </div>
       </div>
       
       
       
       
       
    <div className="container">
            <div className="row mb-4">
          <div className="col-md-12">
              <h2 className="heroText1a py-4">Company Overview</h2>
        
              
           
               <p>Beyond Smart Cities is changing the way businesses find, hire, and pay highly skilled service provider for short-term and longer-term projects. As an increasingly connected and decentralized workforce goes online, knowledge work — similarly to software, shopping, and content before it — is shifting online as well. We see people and planet living in harmony without compromising the future of both. Our solution is to give the market exactly what it is required for and more, by pairing Expert Knowledge, Economic, Environmental, and Social Vitality throughout the world to achieve more with less.</p>
              <div className='bdr-3 p-5'>
                 <a href="#"><b style={{color:'red'}}>Company Presentation</b></a><br/>
                 <a><b style={{color:'gray'}}>Financial Results(Coming Soon)</b></a><br/>
                 <a><b  style={{color:'gray'}}>Events(Coming Soon) </b></a><br/>
                 <a><b  style={{color:'gray'}}>Meet Beyond Smart Cities(Coming Soon)</b></a> <br/>                  
                  <a><b style={{color:'gray'}}>Investor Email Alerts(Coming Soon)</b></a><br/>
                  <a><b  style={{color:'gray'}}>Quick Links(Coming Soon)</b></a><br/>
                  </div>    
             </div> 
                  
          </div>
            <div className="row  mb-4">
      <div className="col-md-12"> 
          <div className="contact mb-3">           
            <h2>In case, You have any questions about this, Please contact us at  <a href="mailto: investors@beyondsmartcities.in"><b> investors@beyondsmartcities.in</b></a></h2>
          </div>
          
      </div>
      
  </div>
          
  </div>
  </div>
  
            </div>
        )
    }
}

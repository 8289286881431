import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle, FiX } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";

import moment from "moment";
import DashboardSidebar from "../DashboardSidebar";
import { SidebarCommonMenuItem } from "../SidebarCommonMenuItem";
import axios from "axios";
import { API_URL } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-js-loader";

const mailsList = [
  
];

export default class Inbox extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);

    this.state = {
      dataLoading: false,
      mailDetailsInformation: "",
      mailsList: "",
      propertyId: this.props.match.params.id,
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      currentSelectedDashboard: localStorage.getItem(
        "currentSelectedDashboard"
      ),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    console.log(this.state.propertyId);
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    this.getmailsList();
    this.setState({
      mailDetailsInformation: mailsList[0],
    });
  }

  async getmailsList() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "inbox/me/1", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log(response);

          if (response.data.success === true) {
            self.setState({
              mailsList: response.data.mailsList,
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }



  async makeRead(id) {   

    this.setState({ spinner: true });
     var self = this;
     self.setState({
       submitLoading: true,
       errorMessage: "",
     });

     let data={        
           read : true,             
     }
     const token = await JSON.parse(localStorage.getItem("token"));
     // Post the form, just make sure to set the 'Content-Type' header
     await axios
       .put(API_URL + "mail/read/update/"+id,  {
         headers: {
           auth: token,
           Accept: "application/json",
         },
       })

       .then(
         function (response) {
           console.log('INB',response);
           //handle success
console.log(response)
           if (response.data.success === true) {
               
             
            const index = mailsList.findIndex(x =>x === id);


 // 1. Make a shallow copy of the items
 //let items = [...this.state.mailsList];
 let items = [...mailsList];
 // 2. Make a shallow copy of the item you want to mutate
 let item = {...items[index]};
 // 3. Replace the property you're intested in
 item.mailRead = true;
 // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
 items[index] = item;
 // 5. Set the state to our new copy
 this.setState({mailsList:items});







             setTimeout(() => {
               self.setState({
                 submitLoading: false,
                 isdataSubmitted: true,
                 spinner: false,
               });               
            
             }, 1000);
           } else {
             self.setState({
               errorMessage: response.data.error,
               submitLoading: false,
               spinner: false,
             });            
           }
         },
         (error) => {
           const message =
             (error.response &&
               error.response.data &&
               error.response.data.message) ||
             error.message ||
             error.toString();
           // console.log(message)
           self.setState({
             errorMessage: message,
             submitLoading: false,
             spinner: false,
           });          
         }
       );
   } 

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }

  showMailDetails(id) {
    this.setState({
      mailDetailsInformation: mailsList.find((x) => x._id == id),
    });
    console.log(this.state.mailDetailsInformation);
    //this.makeRead(id)
  }

  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Propert Detail</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                          <SidebarCommonMenuItem
                            currentSelectedDashboard={
                              this.state.currentSelectedDashboard
                            }
                          />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link to="/property-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                  My Inbox
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col className="position-relative">
                              <div className="shd-1 p-3">
                                {/* MAIL INBOX STARTS */}
                                <Row>
                                  <Col md={4}>
                                    <ul className="mailList">
                                      {this.state.mailsList && this.state.mailsList.length ? (
                                        mailsList.map((item) => (
                                          <li
                                            onClick={() =>
                                              this.showMailDetails(item._id)
                                            }
                                            className="cursor-pointer"
                                          >
                                            <Row className="align-items-center">
                                              <Col md={2}>
                                                <div className="mail-avatar">
                                                  <BiBadge />
                                                </div>
                                              </Col>
                                              <Col md={7}>
                                                <h3 className={item._id==this.state.mailDetailsInformation._id? "c2" : item.mailRead ? "c14" : "c3"}>{item.mailFromName}</h3>
                                                <p className={item._id==this.state.mailDetailsInformation._id? "c2" : ''}>{item.mailSubject}</p>
                                              </Col>
                                              <Col className="float-end">
                                                <p className="fs-10 float-end">
                                                  {item.createdAt}
                                                </p>
                                              </Col>
                                            </Row>
                                          </li>
                                        ))
                                      ) : !this.state.dataLoading ? (
                                        <li className="text-center p-3 bdr-none">
                                          <BiEnvelope className="ms-2 fs-48 c5 text-center" />
                                          <h3 className="text-center mx-4 mt-4 px-2">
                                            No Recent Mails found!
                                          </h3>
                                        </li>
                                      ) : (
                                        ""
                                      )}
                                    </ul>
                                  </Col>

                                  <Col>
                                    {this.state.dataLoading ? (
                                      <div className="content-spinner">
                                        <Loader
                                          type="spinner-default"
                                          bgColor={"#0A989C"}
                                          title={""}
                                          color={"#0A989C"}
                                          size={60}
                                        />
                                      </div>
                                    ) : 
                                    (                                   
                                      <div className="mailListDetails">
                                        <Row>
                                          <h2>
                                            {
                                              this.state.mailDetailsInformation
                                                ?.mailSubject
                                            }
                                          </h2>
                                        </Row>

                                        <Row>
                                          <p>
                                            {" "}
                                            {
                                              this.state.mailDetailsInformation
                                                ?.mailBody
                                            }
                                          </p>

                                          <p>
                                           
                                            <br />
                                            {
                                              this.state.mailDetailsInformation
                                                ?.mailSignature
                                            }
                                          </p>
                                        </Row>
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                                {/* MAIL INBOX ENDS */}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

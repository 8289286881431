import React from 'react'
import { FaCheck } from "react-icons/fa";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Loader from "react-js-loader";




const Success = ({prevStep, nextStep, values, submitLoading,isdataSubmitted,errorMessage,handleRegister,firstStep  }) => {
  console.log(values);
 
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <Container component="main" maxWidth="xs" className=" p-5 topadjust">
      <div className="p-5 text-center">
        <div>
          {isdataSubmitted ? (
            <div>
              <h1 className="successTick c2 mx-auto">
                <FaCheck />
              </h1>              
              <h2  className="text-center c3">Yay!, Your Product has been submitted. Thank you for showing interest on us. Allow our team to review your profile. We will get back to you if any further information is needed. </h2>
          <div>  <Link to="/profile" class="btn btn-primary">
              Take me to Dashboard
              </Link></div>
            </div>
          ) : null}
          {submitLoading ? (
            <Loader
              type="spinner-default"
              bgColor={"#0A989C"}
              title={"Sit tight while we submit your application!"}
              color={"#0A989C"}
              size={60}
            />
          ) : null}

{errorMessage ? 
( 
  <Row className="text-center my-3">
      <h2 >Oops, There was an Error!</h2>
     <h3 className="mb-4">{errorMessage}</h3>
      <Col> 
      <Button
                           onClick={firstStep}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>
        <Button                      
          type="submit"
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          onClick={handleRegister}
          disabled={false}
          className="btn btn-primary btn-block c1"
        >
          Submit Again
        </Button>
      </Col>
    </Row>
)
              
              
              :null}

        </div>

        <br />
      </div>
    </Container>
  );
}

export default Success
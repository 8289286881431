import React, { Component } from "react";
import { Accordion } from "react-bootstrap";

export default class Faqs extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page page-container">
          <div className="container ">
            <div className="row mb-4">
              <div className="col-md-12">
                <Accordion defaultActiveKey="0" flush>
                  <h2 className="section-title p-tb-20 text-center ">
                    Service Visibility Related
                  </h2>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Service Visibility Related
                    </Accordion.Header>
                    <Accordion.Body>
                      You can see Video tutorials here or read our article on
                      profile completion to understand how to create a strong,
                      convincing and a promising Smartwork Profile.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      Completion of all mandatory Information in the profile
                    </Accordion.Header>
                    <Accordion.Body>
                      Smartwork Profile is approved by Beyond Smart Cities
                      Expert onboarding Team. Profile approval takes around 2-3
                      Weeks on Smartwork. Following are the criterias and steps
                      that leads to profile verification and approval without
                      any hassle:
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How is Smartwork Profile approved or criteria’s for
                      profile approval?
                    </Accordion.Header>
                    <Accordion.Body>
                      Not sharing personal contact Information by any means in
                      the profile <br />
                      Uploading correct KYC Documents
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      Agreeing on the standard mandatory operating guidelines
                    </Accordion.Header>
                    <Accordion.Body>
                      Answering a questionnaire based on Smartwork Tutorials,
                      rules and policies
                      <br />
                      Interview with Smartwork Team
                      <br />
                      Profile Approval
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      How to create Services?
                    </Accordion.Header>
                    <Accordion.Body>
                      There are 2 types of services that you can create on
                      Smartwork. Standard services which consists of basic,
                      standard and premium packages. Custom Services you can
                      create for a custom offer you want to send to a potential
                      buyer. You can see a detailed video tutorial here or read
                      our article on service creation to know more.
                      <br />
                      Profile Approval
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      {" "}
                      Services live versus Services not live?
                    </Accordion.Header>
                    <Accordion.Body>
                      When you create a service on Smartwork, you will see that
                      service by default is not live because Smartwork approves
                      the service after reviewing it. If the service is not live
                      we send you suggestions on changes to be made in the
                      service in order to make it live.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      {" "}
                      What are fixed priced services or standardised services?
                    </Accordion.Header>
                    <Accordion.Body>
                      Smartwork has pre fixed prices for certain services. You
                      will see the services under “Products & Services” in your
                      dashboard. Experts who agree on the prices of these
                      services are assigned leads/clients automatically by
                      algorithm.{" "}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      {" "}
                      Who decides the price to be charged to the client for the
                      services? Is it pre-decided or can I ask a different price
                      from the client?
                    </Accordion.Header>
                    <Accordion.Body>
                      Beyond Smart Cities functions in two ways for booking of
                      services:
                      <br />
                      <div class="smart-list">
                        <p>
                          <b>Service Booking:</b> All Experts are free to price
                          their own respective services. Smartwork has put and
                          Minimum and maximum Limit to maintain fairness in
                          pricing.
                        </p>
                        <p>
                          <b>Fixed Price Services:</b> Smartwork has fixed
                          prices services and packages. Prices for these
                          services and packages are set by Smartwork. Experts
                          who agree to these prices are considered eligible for
                          assignment of any booking that is done on these
                          services or packages
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>



      
                <Accordion defaultActiveKey="0" flush>
                  <h2 className="section-title p-tb-20 text-center ">
                    Order Fees & Price Calculation & Booking Amount
                  </h2>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      {" "}
                      How does a Service appear on the first page?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                        <p>
                          Services appear on top for Badge Holders. Beyond Smart
                          Cities has different badges. Apart from that all other
                          Services appear based on various factors like no. of
                          Service views, responsiveness of Expert, pricing of
                          Expert etc.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      {" "}
                      How will leads find my Service?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <p>
                          Leads can find your service on our website by
                          selecting that particular service from the top
                          category menu. Leads can filter Services based on
                          pricing, location, language and experience
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      {" "}
                      Can I share my Services on other social platforms?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <p>
                          Leads can find your service on our website by
                          selecting that particular service from the top
                          category menu. Leads can filter Services based on
                          pricing, location, language and experience
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>


                <Accordion defaultActiveKey="0" flush>
                <h2 className="section-title p-tb-20 text-center ">
                    Seller Service Charges, TDS & Settlements
                  </h2>

                       <Accordion.Item eventKey="9">
                     <Accordion.Header>
                    {" "}
                    What are the Order booking slabs for Buyers on{" "}
                      <b>Beyond Smart Cities</b>?
                     </Accordion.Header>
                           <Accordion.Body>
                  
                       <div className="smart-list">
                       <p>
                          The Buyer who books a service with a Seller has to pay
                          a booking amount to book the service. The booking is
                          calculated as per below slab:
                        </p>
                        <ul style={{ listStyle: "none" }}>
                          <li>Order Fees Up to Rs. 3000 = Full</li>
                          <li>
                            Order Fees from to Rs. 3000 to 10,000 = Rs. 3000
                          </li>
                          <li>
                            Order Fees from to Rs. 10000 to Rs. 20000 = Rs. 6000
                          </li>
                          <li>
                            Order Fees from Rs. 20000 to Rs. 40,000 = Rs. 8000
                          </li>
                          <li>
                            Order Fees from Rs. 40,000 to Rs. 80,000 = Rs, 12000
                          </li>
                          <li>Order Fees above Rs. 80,000 = Rs. 15,000</li>
                        </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
                  
              

                       <Accordion.Item eventKey="10">
                     <Accordion.Header>
                    {" "}
                    How is total Order Fees calculated on{" "}
                      <b>Beyond Smart Cities</b> for Standard servcies?
                   
                     </Accordion.Header>
                           <Accordion.Body>
                  
                       <div className="smart-list">
                       <h4>
                          <b>For Domestic Buyers:</b>
                        </h4>
                        <p>
                          Total Order Fees or Total Order Value = Seller Fees +
                          WC GST (18%)
                        </p>

                        <h4>
                          <b>For Domestic Buyers:</b>
                        </h4>
                        <p>
                          Total Order Fees or Total Order Value = Seller Fees +
                          WC Service Fee (5%)
                        </p>

                        <h4>
                          <b>Here is an Example</b>
                        </h4>
                        <p>
                          Let’s say you have priced your standard Gig as below:
                        </p>
                        <p>Basic Package: Rs. 1000</p>
                        <p>Standard Package : Rs. 2000</p>
                        <p>Premium Packag e: Rs. 3000</p>
                        <p>
                          Therefore, <b>Total Order Fees for Domestic Buyer</b>{" "}
                          would be:
                        </p>
                        <h4>
                          <b>Total Order Fees for Domestic Buyer</b>
                        </h4>
                        <p>For a Custom Order or Custom Gig created.</p>
                        <p>The Custom service Value will be:</p>

                        <h4>
                          <b>For Domestic Buyers :</b>
                        </h4>
                        <p>
                          Total Order Fees or Total Order Value = Seller Fees +
                          WC GST (18%)
                        </p>
                        <h4>
                          <b>Example:</b>
                        </h4>
                        <p>Seller Fees for Custom Gig: Rs. 1000</p>
                        <p>
                          Therefore Total Order Value = Rs. 1000 + 180 ( WC GST
                          18%) = Rs. 1180
                        </p>
                        <h4>
                          <b>For International Buyers ( GST is exempted):</b>
                        </h4>
                        <p>
                          Total Order Fees or Total Order Value = Seller Fees +
                          WC Service Fee 5%
                        </p>
                        <h4>
                          <b>Example:</b>
                        </h4>
                        <p>Seller Fees for Custom Gig: Rs. 1000</p>
                        <p>
                          Therefore Total Order Value = Rs. 1000 + 50 (WC
                          Service Fee 5%) = Rs. 1050
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      {" "}
                      Does Beyond Smart Cities charge Service Fee from Buyers?
                    </Accordion.Header>
                    <Accordion.Body>
                    
                      <div className="smart-list">
                      <b>For Domestic Buyers:</b>
                        <p>
                          Smartwork does not charge any Service Fee from
                          Domestic Buyers
                        </p>
                        <b>For International Buyers:</b>
                        <p>
                          Smartwork charges 5% Service Fee from International
                          Buyers. This is because International Payment Gateway
                          charges and transaction fees are usually 5% and
                          higher.
                        </p>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
        
                  <Accordion defaultActiveKey="0" flush>
                  <h2 className="section-title p-tb-20 text-center ">
                    Completing Orders on time and efficiently
                  </h2>
                 <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    {" "}
                    Does Smartwork charge Service Fee from Sellers?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, Smartwork charges Service Fee from all Sellers
                          for a completed Order as per below slab:
                        </p>

                        <p>10% of the Seller Fees for All Services except</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    {" "}
                    What is deducted from Seller Fees at time of Settlement?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>Here, how the Seller Settlement is calculated: </p>
                        <p>
                          Seller Settlement = Seller Fees – Specialists Service
                          Fees - TDS{" "}
                        </p>
                        <p>
                          As per Indian Income Tax Laws, it is compulsory to
                          deduct TDS from service providers as per provisions of
                          Section of Section 194J and Section 194C.{" "}
                        </p>
                        <p>
                          To check all TDS related questions and percentage of
                          TDS applicable to you please carry on to read the
                          FAQs.
                        </p>
                        <b>Example: </b>
                        <p>A Seller has priced a service at Rs. 1000.</p>
                        <b> Seller Fees Settlement:</b>
                        <p> Rs. 1000 - Rs. 100 (10% WC Fee ) = Rs. 900</p>
                        <p>Rs. 900 - Rs. 10 (TDS) = Rs. 890</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    {" "}
                    When and how is the Seller Fees Settled?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          The Seller Fees is only settled when the Seller has
                          completed the Order, the Buyer has marked the Order as
                          completed and the Seller has generated an Invoice from
                          the Dashboard to Smartwork.
                        </p>
                        <p>
                          Smartwork settles the Seller Fees on 15th and 30th of
                          every month.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    {" "}
                    How to generate Seller Invoice to Smartwork for completed
                      Order?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Follow this procedure only when the Order has been
                          marked as completed:
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    {" "}
                    Go to Web Dashboard
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          On top Right Corner, profile dropdown, click on
                          Invoicing and Settlement Summary
                        </p>
                        <p>
                          On the Summary page, spot the Order and click on
                          “generate invoice”
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    {" "}
                    Is it compulsory to generate Seller Invoice to Smartwork
                      for Fee Settlement on a Completed Order?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, it is compulsory to generate Seller Invoice to
                          Smartwork in order to get your Fees settled in your
                          bank account.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>
                    {" "}
                    What to do if the Buyer is not marking the Order as
                      Completed?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          If the Buyer does not mark the Order has completed
                          because the Buyer has become inactive and is not
                          responding, then the Order will be marked as completed
                          after 14 days from the date of Order Completion
                          Request initiated by the Seller.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>
                    {" "}
                    What are the TDS Rates applicable on different categories
                      of Sellers or services?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <table>
                          <th>Type of Service/Seller</th>
                          <th>TDS Rate Applicable Normally</th>
                          <th> TDS Rate during Covid Time</th>
                          <tr>
                            <td>Tax, Compliance, Professional Services</td>
                            <td> 10% </td>
                            <td> 7.5%</td>
                          </tr>
                        </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                
              </Accordion>
                    
              <Accordion defaultActiveKey="0" flush>
              <h2 className="section-title p-tb-20 text-center ">
                    Completing Orders on time and efficiently
                  </h2>
              <Accordion.Item eventKey="20">
                  <Accordion.Header>
                    {" "}
                    Mandatory Procedure to follow when Order is booked with
                      you on Smartwork?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          After completion of your profile, you have to agree on
                          Standard Operating Mandatory Guidelines. Read this
                          article to learn about the mandatory procedure to
                          follow once an order is booked with you on Beyond
                          Smart Cities. You can also watch this video tutorial
                          to learn about our standard mandatory guidelines.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                  <Accordion.Header>
                    {" "}
                    What happens if I don’t reply to my client on time?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <h4>
                          <b>Standard Response time on Beyond Smart Cities:</b>
                        </h4>
                        <p>When you are hired before 7 PM: Within 6 Hours</p>
                        <p>When you are hired after 7 PM: Within 16 hours</p>
                        <p>
                          Mark Unavailable: If you are busy or not available due
                          to any reason, you can make yourself as busy from your
                          dashboard or mobile app.
                        </p>
                        <p>
                          If you do not reply within the standard response time,
                          Beyond Smart Cities assigns the Client to a different
                          expert.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="22">
                  <Accordion.Header>
                    {" "}
                    How will I communicate with clients for receiving data and
                      sending reports? Is it exclusively on Smartwork portal or
                      I have to share my contact details?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, You have to exclusively use Beyond Smart Cities
                          Dashboard and Mobile App to communicate with the leads
                          and clients. You can chat and call using your
                          dashboard and mobile app. You also have to share files
                          and documents using Beyond Smart Cities .Any attempts
                          to communicate outside of Beyond Smart Cities leads to
                          delisting and blocking from the platform.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="23">
                  <Accordion.Header>
                    {" "}
                    How to create and track milestones?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          It is mandatory for you to create milestones with
                          payment and due dates or attach payments and due dates
                          to pre-defined milestones. The inclusions and
                          deliverables that you define in your Standard services
                          and Custom services are automatically created as
                          milestones for tracking purpose. Watch this tutorial
                          to learn about editing fees on Beyond Smart Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="24">
                  <Accordion.Header>
                    {" "}
                    How to edit/increase payments or my fees on existing
                      Order?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          In case the scope of work has increased or decreased
                          for an Order, You can edit the Order Fees using
                          “Manage Order” on your Mobile App or Dashboard. You
                          also have to give a reason for such increase or
                          decrease in Order Fees. Watch this tutorial to learn
                          about editing fees on Beyond Smart Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="25">
                  <Accordion.Header>
                    {" "}
                    How to set and edit Delivery Time for a booked Order?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          In case the scope of work has increased or decreased
                          for an Order, You can edit the Delivery Time using
                          “Manage Order” on your Mobile App or Dashboard. You
                          also have to give a reason for such increase or
                          decrease in Delivery Time. Watch this tutorial to
                          learn about editing Delivery Time on Beyond Smart
                          Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="26">
                  <Accordion.Header>
                    {" "}
                    Where can I see all the files/documents relating to a
                      particular job?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          You can see all the files shared on a particular Order
                          in your dashboard and mobile app. See the tutorial to
                          learn about the same.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="27">
                  <Accordion.Header>
                    {" "}
                    When does Smartwork forfeit my fees and/or holds my fees?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Beyond Smart Cities forfeits my fees and/or holds my
                          fees in following situations:
                        </p>
                        <p>
                          Where you breached standard operating mandatory
                          guidelines of Beyond Smart Cities.
                        </p>
                        <p>
                          Where you attempted to personally communicate with the
                          client or accept payments outside of Beyond Smart
                          Cities
                        </p>
                        <p>
                          Where the work promised and agreed upon was not
                          delivered on time or not delivered at all.
                        </p>
                        <p>
                          Where there are questions with respect to quality of
                          work
                        </p>
                        <p>
                          Where Clients demands a refund for the service booked
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="28">
                  <Accordion.Header>
                    {" "}
                    What happens when a Client does not pay my fees even when
                      I have completed the work?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          You have to make sure the final milestones or work is
                          held back and not delivered till the time the fees is
                          deposited with Beyond Smart Cities.
                        </p>
                        <p>
                          Beyond Smart Cities Team checks the disputed Order
                          with both the Client and the Expert. Where it is found
                          that the work was completed and delivered on time and
                          the client has not paid, Beyond Smart Cities
                          reimburses the Expert’s fees. This dispute resolution
                          process takes about 3 weeks from the date the dispute
                          as reported.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="29">
                  <Accordion.Header>
                    {" "}
                    How to make sure Client pays your fees in escrow on time?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          You have to make sure the Client deposits fees for
                          each milestone in advance in the escrow account on
                          time. Where you see the client is hesitating or not
                          interested in depositing the milestone fees, you need
                          to inform the Beyond Smart Cities Team.
                        </p>
                        <p>
                          You have to make sure the final milestones or work is
                          held back and not delivered till the time the fees is
                          deposited with Beyond Smart Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" flush>
                <h2 className="section-title p-tb-20 text-center ">
                    Completing Orders on time and efficiently
                  </h2>
                <Accordion.Item eventKey="30">
                  <Accordion.Header>
                    {" "}
                    When do Clients rate me on Smartwork?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Clients give feedback to the Experts after the Order
                          is completed. Clients give feedback in form of ratings
                          and a written review. Ratings are given on 3 different
                          parameters: Responsiveness, Tracking Milestones and
                          Meeting deadlines.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="31">
                  <Accordion.Header>
                    {" "}
                    How Does Smartwork give ratings and feedback to me?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, Beyond Smart Cities also gives ratings and
                          feedback to the expert based on how well they have
                          completed their profile and how well they deliver
                          Orders on Beyond Smart Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="32">
                  <Accordion.Header>
                    {" "}
                    Does Ratings & Feedback affect my profile?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, Experts who get better feedback and ratings from
                          Clients on Beyond Smart Cities are automatically hired
                          more through rating based algorithms.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                <Accordion defaultActiveKey="0" flush>
                <h2 className="section-title p-tb-20 text-center ">
                    For Sales & Order Related
                  </h2>
                  <Accordion.Item eventKey="33">
                  <Accordion.Header>
                    {" "}
                    Does Beyond Smart Cities delists or blocks Freelance
                      Experts?
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Yes, Beyond Smart Cities delists or blocks the Experts
                          from using the platform in following situations:
                        </p>
                        <p>
                          Where you breached standard operating mandatory
                          guidelines of Smartwork.
                        </p>
                        <p>
                          Where you attempted to personally communicate with the
                          client or accept payments outside of Beyond Smart
                          Cities.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="34">
                  <Accordion.Header>
                    {" "}
                    Where the work promised and agreed upon was not delivered
                      on time or not delivered at all.
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <div className="smart-list">
                    <p>
                          Smartwork issues a gentle warning in case there is
                          violation of rules and policies before delisting the
                          Expert.
                        </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                </Accordion>

               
                  <a
                    href="mailto:support@beyondmsmartcities.in"
                    style={{ color: "green" }}
                  >
                    support@beyondmsmartcities.in
                  </a>
                  <h4>
                    <b style={{ color: "green" }}>
                      For Seller Profile and Verification:
                    </b>
                  </h4>
                  <a
                    href="mailto:seller@beyondsmartcities.in"
                    style={{ color: "green" }}
                  >
                    seller@beyondsmartcities.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    );
  }
}



export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_SUCCESS = "SET_SUCCESS";

export const SET_SERVICE_CART = "SET_SERVICE_CART";  
export const CLEAR_SERVICE_CART = "CLEAR_SERVICE_CART";  
export const GET_SERVICE_CART = "GET_SERVICE_CART";  


export default function authHeader() {
    const token = JSON.parse(localStorage.getItem('token'));
  console.log(token)
    if (token) {
      return { auth: token, 
        Accept: 'application/json',
        'Access-Control-Allow-Origin': true,
        "Content-Type": "multipart/form-data" };
    } else {
      return {};
    }


    // if (user && user.accessToken) {
    //   return { auth: user.accessToken };
    // } else {
    //   return {};
    // }

  }
import React, { Component } from "react";


import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link,withRouter } from "react-router-dom";

import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiStation,
  BiBook,
  BiBellOff,
  BiNotepad,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle } from "react-icons/fi";
import validator from "validator";

import moment from "moment";
import DashboardSidebar from "../../DashboardSidebar";
import axios from "axios";
import { API_URL } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-js-loader";
import SpecialistOrderDetails from "./SpecialistOrderDetails";
import SpecialistDashboardSidebar from "../SpecialistDashboardSidebar";

class SpecialistOrdersLists extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);

    this.state = {
      dataLoading:false,      
      ordersList:'',
      filesList:'',
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    this.getOrdersList()
    this.getDeliveryList() 
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  limit (string = '', limit = 0) {  
    var trimmedString= string.substring(0, limit)
    return trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
  }

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }



  async getOrdersList() {     
    var self = this;     
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading:true
    });

    const token = await JSON.parse(localStorage.getItem("token"));     
    await axios
      .get(API_URL + "order/specialist/me/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('order',response.data.ordersList);           

          if (response.data.success === true) {
            self.setState({
              ordersList:response.data.ordersList
            })
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading:false
            });  
            
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading:false
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading:false
          });
        }         
      );
  }  


  goToOrderDetails(itemid){
  //   console.log('here')
  //  let orderDetails=this.state.ordersList.filter(item=>item._id==itemid)

   this.props.history.push('/specialist-order-details/'+itemid)
 

  }




  async getDeliveryList() {     
    var self = this;     
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading:true
    });

    const token = await JSON.parse(localStorage.getItem("token"));     
    await axios
      .get(API_URL + "delivery/specialist/me/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('delivery',response);           

          if (response.data.success === true) {
            self.setState({
              filesList:response.data.filesList
            })
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading:false
            });  
            
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading:false
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading:false
          });
        }         
      );
  }  


  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Orders</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                        <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  My Orders
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end"></div>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col className="position-relative">
                              <ul class="tableList">
                                <li className="listHeader shd-1">
                                  <Row className="align-items-center">
                                    <Col md={1}></Col>
                                    <Col md={2}>
                                      <div className="property-title">
                                        Order ID
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="property-status">
                                        Service
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div className="property-status">
                                        Payment Status
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div className="property-status">
                                        Order Status
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="property-view">
                                        <Link>Action</Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </li>

                                {this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}

          {this.state.ordersList && this.state.ordersList.length?
                                        (                                        
                                          this.state.ordersList.map((item)=>(

                                <li className="shd-1 py-3">
                                  <Row className="align-items-center"> 

                                    <Col md={1}>
                                      <BiBook className="ms-2 fs-2" />
                                    </Col>
                                    <Col md={2}>
                                      <div className="property-title fw-700">
                                        {" "}
                                        <Link onClick={()=>this.goToOrderDetails(item._id)} >
{item.orderId}                                        </Link>
                                      </div>
                                      <div className="property-title fw-700 cap">
                                        {" "}
                                        <Link onClick={()=>this.goToOrderDetails(item._id)} >
                                        {item.customerId?.firstName}{' '}  {item.customerId?.lastName}                                      </Link>
                                      </div>
                                      <div className="property-added-date fs-12">
                                         {item.customerId?.phoneNumber}<br/>                                     
                                        { moment(item.createdAt).format("LL")}
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      {/* <div className="property-title fw-700 c3">
                                       {item.specialistId.personalInfo.firstName}
                                      </div> */}
                                      <p>
                                      {this.limit(item.specialistId.personalInfo.description,100)}{'...'}
                                      </p>
                                    </Col>
                                    <Col md={2}>
                                      <div className="property-status fw-700 c5 text-capitalize">
                                        {/* <FiAlertTriangle className="me-1" /> */}
                                        {item.paymentId?.order_status}

                                      </div>
                                    </Col>
                                    {item?.isDelivered?

<Col md={2}>
  <div className="property-status fw-700 c2">
    <FiCheck className="me-1" />{" "}
   Delivered
  </div>
</Col>
:
<Col md={2}>
<div className="property-status fw-700 c9">
  <FiAlertTriangle className="me-1" />{" "}
  Pending
</div>
</Col>

}
                                    <Col>
                                      <div className="property-view">
                                        <Link onClick={()=>this.goToOrderDetails(item._id)}>View Details</Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </li>

                                ))
                                ):
                                (
                                  !this.state.dataLoading?
                                  <li className="text-center p-3 bdr-none">
                                     <BiNotepad className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">No Recent Orders found!</h3>
<p className="text-center">You receive notifications on new alerts and offers from smartcity platform on regular basis.</p>

                                    </li>
                                    :''
                                )
                              
                              }  
                                
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(SpecialistOrdersLists);

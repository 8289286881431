import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FormErrors } from "./FormErrors";
import validator from "validator";
import  {states}  from "../../../services/States";
import { countries } from "../../../services/Countries";


export const CompanyInfo = ({
  prevStep,
  nextStep,
  handleChangeCompanyInfo,
  values,
}) => {
  // CHECK ERRORS
  const [nameOfCompanyError, setnameOfCompanyError] = useState(false);
  const [companyWebsiteError, setcompanyWebsiteError] = useState(false);
  const [streetError, setstreetError] = useState(false);
  const [stateError, setstateError] = useState(false);
  const [cityError, setcityError] = useState(false);
  const [countryError, setcountryError] = useState(false);


  const validateField = (fieldName) => (e) => {
    if (fieldName === "nameOfCompany") {
      handleChangeCompanyInfo("nameOfCompany", null, e.target.value);

      values.nameOfCompany
        ? setnameOfCompanyError(false)
        : setnameOfCompanyError(true);
    } else if (fieldName === "companyWebsite") {
      handleChangeCompanyInfo("companyWebsite", null, e.target.value);
      values.companyWebsite
        ? setcompanyWebsiteError(false)
        : setcompanyWebsiteError(true);
    } else if (fieldName === "street") {
      handleChangeCompanyInfo("street", "address", e.target.value);
      values.address.street.length <= 30
        ? setstreetError(true)
        : setstreetError(false);
    } else if (fieldName === "state") {
      handleChangeCompanyInfo("state", "address", e.target.value);
      values.address.state.length <= 1
        ? setstateError(true)
        : setstateError(false);
    } else if (fieldName === "city") {
      handleChangeCompanyInfo("city", "address", e.target.value);
      values.address.city.length <= 1
        ? setcityError(true)
        : setcityError(false);
    } else if (fieldName === "country") {
      console.log(e.target.value)
      handleChangeCompanyInfo("country", "address", e.target.value);
      values.address.country.length <= 1
        ? setcountryError(true)
        : setcountryError(false);
    }
  };

  const Continue = (e) => {
    e.preventDefault();
    
    if (
      values.nameOfCompany.length >= 1 &&
      values.companyWebsite.length >= 1 &&
      validator.isURL(values.companyWebsite) &&
      values.address.street.length >= 29  &&
      values.address.state.length >= 1 &&
      values.address.city.length >= 1 &&
      values.address.country.length >= 1

    ) {     
      console.log('herer')
      nextStep();
    } else {
      console.log('herer2')
      values.nameOfCompany.length <= 1
        ? setnameOfCompanyError(true)
        : setnameOfCompanyError(false);
      values.companyWebsite.length <= 1
        ? setcompanyWebsiteError(true)
        : setcompanyWebsiteError(false);
       validator.isURL(values.companyWebsite) 
        ? setcompanyWebsiteError(false)
        : setcompanyWebsiteError(true);       
      values.address.street.length <= 30
        ? setstreetError(true)
        : setstreetError(false);
      values.address.state.length <= 1
        ? setstateError(true)
        : setstateError(false);
      values.address.city.length <= 1
        ? setcityError(true)
        : setcityError(false);
      values.address.country.length <= 1
        ? setcountryError(true)
        : setcountryError(false);
    }
    
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <div className="row">
                      <div class="col">
                        <h2>Get Registered as Manufacturer</h2>
                        <p>
                          Submit your details and get approval in 4 business
                          days.You will be notified via email on approval.
                        </p>
                      </div>
                    </div>

                    <Form>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: Google Inc"
                              onChange={validateField("nameOfCompany")}
                              value={values.nameOfCompany}
                              className={
                                nameOfCompanyError ? "hasError" : "valid"
                              }
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid Company Name. This will be used
                              for verification.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Company Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: www.google.com"
                              onChange={validateField("companyWebsite")}
                              value={values.companyWebsite}
                              className={
                                companyWebsiteError ? "hasError" : "valid"
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Street</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={validateField("street")}
                              value={values.address.street}
                              className={streetError ? "hasError" : "valid"}
                            />
                            <Form.Text className="text-muted">
                              Please Enter Valid Address. This will be used for
                              verification(Min 30 Characters).
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Country</Form.Label>
                            {/* <Form.Control
                              type="text"
                              onChange={validateField("country")}
                              className={countryError ? "hasError" : "valid"}
                              value={values.address.country}
                            /> */}

                      <Form.Select 
                      className={countryError ? "hasError" : "valid"}
                      onChange={validateField("country")} aria-label="Default select example">
                        {values?.address?.country ?
                            <option value={values.address.country}>{values.address.country }</option>
                            :
                            <option value=''>Select Country</option>
                            }
                            {countries ?
                            countries.map((item)=>(
                            <option value={item.code}>{item.name}</option>
                            ))
                          :''}

                          </Form.Select>

                          </Form.Group>


                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>State</Form.Label>
                           

                            <Form.Select  
                            className={stateError ? "hasError" : "valid"}
                            onChange={validateField("state")} aria-label="Default select example">
                            {values?.address?.states ?
                            <option value={values.address.states}>{values.address.states }</option>
                            :
                            <option value=''>Select State</option>
                            }
                            {states ?
                            states.map((item)=>(
                            <option value={item.code}>{item.name}</option>
                            ))
                          :''}

                          </Form.Select>

                            {/* <Form.Control
                              type="text"
                              onChange={validateField("state")}
                              className={stateError ? "hasError" : "valid"}
                              value={values.address.state}
                            /> */}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={validateField("city")}
                              className={cityError ? "hasError" : "valid"}
                              value={values.address.city}
                            />
                          </Form.Group>

                        
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center">
                      <Col>
                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

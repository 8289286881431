import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class SmartProProductRatingSystems extends Component {
    render() {
        return (
            <div className='bsprs'>
                 <div  className="Nature-banner inner-page">
         
         <div className="productrating-layout">
     
           <div className="patner-trs">
                 <div className="productrating-content">
                   
                     <div className="container">
                         
                             
                         
                         
                           <div className="row mt-4">
         <div className="col-md-4">
               <div className="card productrating-card">
           <h4><b>India’s Leading</b></h4> 
              <div className="container">
              
               <p>Sustainability Standard And Certification Mark</p>
                <button> <a href="">Veiw Director</a></button>
                         </div>
                </div>
         </div>
          <div className="col-md-4">
                <div className="card productrating-card">
          <h4><b>Smartpro Rating System</b></h4> 
              <div className="container">
             <p>That Ensures the Most
Stringent Environmental
Standards in the Market</p>

                 <button><Link to="/aboutsmartpro">Veiw More About Smartpro</Link></button>
                         </div>
                </div>
         </div>
          <div className="col-md-4">
              <div className="card productrating-card">
           <h4><b>Widest Range</b></h4> 
              <div className="container">
              <p>Of Green Labels With Over
70 Product Categories</p>
               <button> <a href="">View Product Categories</a></button>
                         </div>
                </div>
                </div>
         </div>
   </div>
    </div>
                  
               
          
          
                 
          </div> 
          </div>


    
 <div className="container mb-4 mobile-smartpro">
                <div className="row mt-4">
         <div className="col-md-12">
              <ul className="breadcrumb">
<li><a href="#">About Smart-Pro Rating</a></li>
<li><a href="#">Product Directory</a></li>
<li><a href="#">Application Process</a></li>
<li><a href="#">Resources</a></li>


</ul>

         </div>
     </div>
           <div className="row">
       <div className="col-md-12">
           <h2 className="section-title p-tb-20 text-center">About Smart-pro Product Rating System</h2>
          
           
           <p>While the demand for environmentally sustainable products has only grown exponentially in recent years, the need for
sustainable solutions is a timeless problem.SmartPro –Product Rating system is developed by Beyond Smart Cities in India.
SmartPro assesses ‘How Green is a product’ based on a holistic product life cycle framework and highlights the way forward to
achieve excellence in environmental performance. The certification system guides the manufacturers to position their
products as ‘Green’.</p>
           
           </div>
           
           
           
           
           
           
                 </div>
                 
                 <div className="row mt-3 ">
                     <div className="col-md-4">
                         <img src="assets/images/about-2.jpg" className="img-fluid product4-img" />
                     </div>
                     <div className="col-md-7">
                         <h2 className="section-title p-tb-20 text-center">Beyond Smart Cities Product Services</h2>
                         <p>The Beyond Smart Cities Product Services forges public-private partnerships to
foster innovative industry solutions across the entire building and construction
value chain. Through its green building product and green building services
labelling programmes, BSC sets high standards and benchmarks for green
building solutions both locally and regionally to help build more sustainable
cities for better living.</p>
<p>SmartPro encourages the product manufacturer to implement green measures
at every stages of product life cycle including product design, product
performance during use, raw materials, manufacturing process, recycling/
disposal, etc.</p>
</div>
                     <h2 className="section-title p-tb-20 ">Smart-pro Rating System</h2>
                     <p>Our goal at SmartPro Rating system is to highlight products that are healthy for
you and healthy for the planet. To that end, we created the rating system you see
outlined below.</p>
<p>We rate all the products in the smartPro directory with our smartPro rating
system. If a product appears on this site, it is trustworthy and sustainably made
with as little environmental impact as possible, however, some products are
more pristine than others and budget plays a role as well. Where information is
available, we factor in the carbon emissions and other climate impacts associated
with the production and operation of a product.</p>
                    
                 </div>
                     <h2 className="section-title p-tb-20 text-center">Certification Benefits</h2>
        
                 
                      <div className="row ">
                         
           
        <div className="col-md-4">
                         <img src="assets/images/product-4.png" className="img-fluid product4-img" />
                     </div>
           <div className="col-md-7 market-points">
            <h5>New Market Opportunities</h5>
           <p>Enhances the market reach with credible and precise
information on the Green features</p>
           <h5>Gain a Competitive Advantage</h5>
           <p>Differentiates the Green product from the competition</p>
           <h5>Reduce Environmental Impact</h5>
           <p>Assures environmental performance of the products and
equipment .Ensures Toxic and Carcinogen free products.</p>
<h5>Cost Savings</h5>
<p>Increase efficiency of manufacturing practices by removing
redundant processes, thus leading to cost savings in energy
and water usage in the sustainably-manufactured product.</p>
          
           </div>
         
                 <p className="text-center"><b>The company will be entitled to use the Smart Product Certification logo in all their promotional
activities of the certified products.</b></p>

           <h2 className="section-title p-tb-20 text-center">Inspiring Membership</h2>
           <p>BSC membership is the best way for you to connect with and contribute to the
green building cause. Our members come from big companies and small
businesses to non-profit organisations and governments. The benefits of
membership are indeed extensive. Our members are a key priority for us. We are
continuously enhancing the value of membership by offering them new
opportunities for connecting individuals and businesses with experts, partners.
Also, we provide members with access to information and ideas, solutions and
best practices which they need for the rapidly growing green building industry –
both locally and overseas.</p>
        
     </div>
</div>

 <div className="row productrating-layout2">
           <div className="col-md-6">
               <div className="rating-btn">
                   <button><a href="">Join Beyond Smart Cities</a></button>
               </div>
           </div>
            <div className="col-md-6">
               <div className="rating-btn">
                   <button><a href="">Register Your Products</a></button>
               </div>
           </div>
       </div>


            </div>
         </div>
        )
    }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";

class ChildCategories extends Component {
  async getCategories() {
    const token = await JSON.parse(localStorage.getItem("token"));
    return await axios
      .get(API_URL + "childCategory/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(function (response) {
        console.log('ccc',response);
        if (response.data.success === true) {
          return response.data;
        }
      });
  }


  async getCategoriesbyId(id) {
    const token = await JSON.parse(localStorage.getItem("token"));
    return await axios
      .get(API_URL + "childCategory/bysubCategory/" + id, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(function (response) {
        console.log('ddd',response);
        if (response.data.success === true) {
          return response.data;
        }
      });
  }


}

export default new ChildCategories();

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const WaterEfficiency = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>WATER EFFICIENCY (WE)</h2>
                          <Row>
                    <p className="mt-2">
                          The Water category is concerned with
water conservation for indoor and outdoor
use. The natural water cycle is a system in
which water resources are continuously
exchanged between the atmosphere, soil
water, surface water, ground water, and
plants. 
                          </p>
                      </Row>

                          <img
                            className="img-fluid pt-3"
                            src="assets/images/waterefficiency.png"
                          ></img>
                       
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                15 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-5 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                                
                                label="Outdoor Water Use Reduction"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Outdoor Water Use Reduction"
                                id={`inline-checkbox-1`}
                                checked= {values?.waterEfficiency?.includes('Outdoor Water Use Reduction') ? true : false}
 
                              
                              />

                              <Form.Check                                
                                label="Indoor Water Use Reduction"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Indoor Water Use Reduction"
                                id={`inline-checkbox-25`}
                              checked= {values?.waterEfficiency?.includes('Indoor Water Use Reduction') ? true : false}

                              />

                              <Form.Check                                
                                label="Sustainable Wastewater Management"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Sustainable Wastewater Management"
                                id={`inline-checkbox-38`}
                                checked= {values?.waterEfficiency?.includes('Sustainable Wastewater Management') ? true : false}

                              
                              />

                             
                            </Col>
                            <Col>
                             
                            <Form.Check
                               
                                label="Condensate Water Recovery"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                         
                                name="Condensate Water Recovery"
                                id={`inline-checkbox-44`}
                                checked= {values?.waterEfficiency?.includes('Condensate Water Recovery') ? true : false}

                                
                              />

                              <Form.Check                                
                                label="Smart Water Metering"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Smart Water Metering"
                                id={`inline-checkbox-45`   }
                                checked= {values?.waterEfficiency?.includes('Smart Water Metering') ? true : false}

                              
                              />
                              
                            


                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>

                   

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/waterefficiency-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

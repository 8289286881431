import React, { Component } from 'react'

export default class AboutSmartPro extends Component {
    render() {
        return (
            <div>
                <div  className="Nature-banner inner-page">
         
        
         <div className="container mb-4">
             
             
             
                           <h2 className="section-title p-tb-20 text-center">SmartPro Rating System</h2>
                             <p className="text-center">The SmartPro Products Rating adopts a holistic approach based on the ‘Life Cycle’ of the product. The rating system encourages the product manufacturers to implement
   measures that would result in environmental, health and wellbeing benefits at the following stages of the life cycle of the products.
   </p>
   
       <div className="row ">
                                   
                   
                <div className="col-md-4">
                   
                              <div className="self-content">
                                   <div className="card">
                                       <img src="assets/images/icons/Product-Design.png" className="img-fluid" />
                                  <h4 className="text-center">Product Design & Performance</h4>
                                  <p>Certification necessitates the manufacturer to demonstrate its top management commitment towards
   improvement of the environmental performance of the product.</p>
                              </div>
                              </div>
                               
                                <div className="self-content">
                                     <div className="card">
                                            <img src="assets/images/icons/Raw-Materials-_-Additives.png" className="img-fluid" />
                                  <h4 className="text-center">Materials & Resources</h4>
                                  <p>Certification demands for efforts to bring down the use of virgin materials through the
   use of re-cycled material & elimination of toxic & hazardous content in the input
   materials for product manufacturing.</p>
                              </div>
                              </div>
                                  
                                   <div className="self-content">
                                        <div className="card">
                                              <img src="assets/images/icons/Manufacturing-Process.png" className="img-fluid" />
                                  <h4 className="text-center">Manufacturing Technology</h4>
                                  <p>SmartPro product Certification recognizes the efforts taken by the product manufacturer to
   reduce the resource consumption during the manufacturing process.</p>
                              </div>
                              </div>
                             </div>
                              <div className="col-md-4">
                                   
                                 <img src="assets/images/1-star (1).png" className="img-fluid product5-img" />
                              
                             </div>
                                <div className="col-md-4">
                                     
                              <div className="self-content">
                                   <div className="card">
                                       
                                          <img src="assets/images/icons/Life-Cycle-Approach.png" className="img-fluid" />
                                  <h4 className="text-center">Life Cycle Approach</h4>
                                  <p>Certification encourages the product manufacturer to carry out Life cycle analysis for the
   products and implement measures to reduce the overall life-cycle environmental impacts.</p>
                              </div>
                              </div>
                               
                                <div className="self-content">
                                     <div className="card">
                                         <img src="assets/images/icons/CO2-Emissions.png" className="img-fluid" />
                                  <h4 className="text-center">Reduce Carbon Footprint</h4>
                                  <p>Certification identifies the measures implemented by the manufacturers to reduce CO2
   emissions or carbon footprint .</p>
                              </div>
                             </div>
                                 
                                   <div className="self-content">
                                        <div className="card">
                                              <img src="assets/images/icons/Product-Stewardship.png" className="img-fluid" />
                                  <h4 className="text-center">Product Stewardship</h4>
                                  <p>Certification recognizes the measures implemented by the manufacturers to reduce
   environmental and health impacts in product transportation, use and recycling / disposal.</p>
                              </div>
                              </div>
                             </div>
                  
                   </div>
                   
                   
                   </div>
   </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { FaCompass, FaEuroSign,FaBox,FaPercent, FaPaperPlane, FaFileVideo,FaRupeeSign, FaUser, FaUserAlt, FaUserAstronaut, FaUserCircle, FaUserNinja, FaUsersCog } from 'react-icons/fa'

export default class SellingOnBSC extends Component {
  render() {
    return (
      <div>
        <div class="Nature-banner inner-page">
          <div class="row mb-4">
            <div class="col-md-6 hire p-0">
              <div class="hire-text-learn">
                <h1 className="heroText1">
                  How to start selling on Green Technology Marketplace
                </h1>
                <p className="heroText2">
                  Find the right work for you, with great clients, at the
                  world’s work marketplace.
                </p>
                <div class="solidBtn mt-3">
                  <a class="fw-600" href="/dashboard">
                    Create Profile
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/selling-on-bsc.jpg" alt="img" />
            </div>
          </div>

          <div class="container px-md-5">
            <div class="row bmd-layout">
              <h2 class="section-title p-tb-20 text-center heroText1a mb-4">
                How it works
              </h2>

              <div class="col-md-3 ">
                <FaUsersCog className="float-end" style={{ fontSize: 60 }} />
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Create your profile (it’s free)</h5>
                <p>
                Firstly, you will need to create your seller profile. Your profile is how you present yourself to the community. You are
encouraged to present yourself professionally.
                </p>
              </div>
            </div>
            <div class="row bmd-layout pt-3">
              <div class="col-md-3">
                <FaCompass className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Creating your Service</h5>
                <p>
                Your Gig is the service that you sell on Green Technology Marketplace. Creating your Service is an opportunity to
show off your talent and provide buyers with all the information they need to help them decide to do business with you.
                </p>
              </div>
            </div>
            <div class="row bmd-layout pt-3 mb-4">
              <div class="col-md-3">
                <FaFileVideo className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Video requirements overview</h5>
                <p>
                Adding a video to introduce your service is one of the best ways to attract buyers. It adds a personal touch to the
service you are offering.
                </p>
              </div>
            </div>

            <div class="row bmd-layout pt-3 mb-4">
              <div class="col-md-3">
                <FaBox className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Offering packages</h5>
                <p>
                Packages are a way for you to offer three different compelling bundles to sell on your Service Page. It’s a great
new way to give your buyers even more choice, and to push your average order value higher! The sky's the limit
                </p>
              </div>
            </div>


            <div class="row bmd-layout pt-3 mb-4">
              <div class="col-md-3">
                <FaPaperPlane className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Upselling - Monetizing your Service</h5>
                <p>
                You can maximize your revenue by upselling your Service with extras before, during, and after the order.
                </p>
              </div>
            </div>


            <div class="row bmd-layout pt-3 mb-4">
              <div class="col-md-3">
                <FaPercent className="float-end" style={{ fontSize: 60 }} />{" "}
              </div>

              <div class="col-md-7">
                <h5 className="heroText1b">Sending custom offers/buyer requests</h5>
                <p>
                Sending Offers allows you to be proactive and offer your services to potential buyers in a customized manner.                </p>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

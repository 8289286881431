import React, {Component } from "react";
import { useEffect } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../config";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaCircle,
  FaDashcube,
  FaDotCircle,
  FaLink,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";

export default class CatHSCFireSafety extends Component  {


    constructor(props) {
        super(props);
        this.state={
            subCategoryData:[],
            childCategoryData:[],
            currentActiveSub:'',
            dataLoading:false,
            sidebarOpen:true,
            windowWidth: window.innerWidth,
            loadCount:1
        }
        // this.addNewProperty = this.addNewProperty.bind(this);
      }
    
      componentDidMount() {       
        this.getSubCategories()       
      }
    


      async callChildCategory(id, name){

        

        var self = this;
// Loadcount is used as first time we dont to hide the left sidebar
if(self.state.loadCount==1){
  self.setState({sidebarOpen:true})       
  this.setState({
      loadCount:this.state.loadCount+1
  }) 
 }
 else{
  self.setState({sidebarOpen:false})
 }



        self.setState({
          step: 10,
          submitLoading: true,
          dataLoading:true,
          errorMessage: "",
         
          currentActiveSub:name
        });
        const token = await JSON.parse(localStorage.getItem("token"));
        await axios
          .get(API_URL + "childCategory/bysubCategory/" + id, {
            headers: {
              auth: token,
              Accept: "application/json",
            },
          })
    
          .then(
            function (response) {
              console.log('CAT2',response);
    
              if (response.data.success === true) {
                self.setState({
                    childCategoryData:response.data.childCategoryData
                });
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  dataLoading: false,
                  
                 
                });
              } else {
                self.setState({
                  errorMessage: response.data.error,
                  submitLoading: false,
                  dataLoading: false,
                });
              }
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              // console.log(message)
              self.setState({
                errorMessage: message,
                submitLoading: false,
                dataLoading: false,
              });
            }
          );
      }

    async getSubCategories() {
        var self = this;
        self.setState({
          step: 10,
          submitLoading: true,
          errorMessage: "",
          dataLoading: true,
        });
    
        const token = await JSON.parse(localStorage.getItem("token"));
        await axios
          .get(API_URL + "subCategory/byCategory/61b412e372b4bc6520c97e10", {
            headers: {
              auth: token,
              Accept: "application/json",
            },
          })
    
          .then(
            function (response) {
              console.log('CAT',response);
    
              if (response.data.success === true) {
                self.setState({
                    subCategoryData:response.data.subCategoryData
                });              


                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  dataLoading: false,
                });

                let firstItemID=response.data.subCategoryData[0]?._id
                let firstItemName=response.data.subCategoryData[0]?.slug
                self.callChildCategory(firstItemID,firstItemName)


              } else {
                self.setState({
                  errorMessage: response.data.error,
                  submitLoading: false,
                  dataLoading: false,
                });
              }
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              // console.log(message)
              self.setState({
                errorMessage: message,
                submitLoading: false,
                dataLoading: false,
              });
            }
          );
      }


      goToSpecialists(childCat){       
    this.props.history.push('/specialists/Health-Safety-Environment-and-Fire-Safety/'+this.state.currentActiveSub+'/'+ childCat)
      }


      openSubSidebar(){ 
        console.log(this.state.sidebarOpen)         
      this.setState({sidebarOpen:!this.state.sidebarOpen})
    }

      render() {     
  return (
    <div>
      <div className="page-container inner-page bg-grad1">
      <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Category</Breadcrumb.Item>
                  <Breadcrumb.Item active>HSE and Fire Safety</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
         </Row>
         <Row className="position-relative">
             <Col>
             <div className="offset-cat-menu" onClick={()=>this.openSubSidebar()}>Category List</div>
             </Col>
         </Row>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-md-3 onlyDesktop">
              <ul className="navlist-items" style={{
                  backgroundColor: '#fdfdfd',borderRadius:10, padding:10}}>
                  {this.state.subCategoryData &&
                  this.state.subCategoryData.map(item=>
                     <h3 className={item.name===this.state.currentActiveSub? "activeCat":"inactive" } style={{paddingLeft:25,position:'relative', cursor:'pointer', marginBottom:5, paddingTop:5,paddingBottom:5, borderWidth:1,borderBottomColor:'#eaeaea',borderBottomStyle:'solid',color:'#5e5e5e'}} onClick={()=>this.callChildCategory(item._id, item.slug)}><FaArrowRight style={{color:'#a1d1d3',fontSize:14, marginRight:10,position:'absolute',left:0}} />{item.name}</h3>
                    )}               
              </ul>
            </div>

               {/* MOBILE */}

               {this.state.sidebarOpen ?
            <div className="col-md-3 offset-mobile-menu" >
              <ul className="navlist-items" style={{
                  backgroundColor: '#fdfdfd',borderRadius:10, padding:10}}>
                  {this.state.subCategoryData &&
                  this.state.subCategoryData.map(item=>
                     <h3 className={item.name===this.state.currentActiveSub? "activeCat":"inactive" } style={{paddingLeft:25,position:'relative', cursor:'pointer', marginBottom:5, paddingTop:5,paddingBottom:5, borderWidth:1,borderBottomColor:'#eaeaea',borderBottomStyle:'solid',color:'#5e5e5e',fontSize:18,fontWeight:600}} onClick={()=>this.callChildCategory(item._id, item.slug)}><FaArrowRight style={{color:'#a1d1d3',fontSize:14, marginRight:10,position:'absolute',left:0}} />{item.name}</h3>
                    )}               
              </ul>
            </div>
            :
            ''}
            {/* MOBILE END */}


            <div className="col-md-9">
              <div className="row  Sustainable-inner-design">               
                  <h2 className="heroText1c mb-4 pb-3 c13"><span style={{color:'#565656',fontWeight:300}}>Listing Services for</span><span style={{textDecoration:'underline'}}> { this.state.currentActiveSub ?  this.state.currentActiveSub :''}</span></h2>
              </div>  
              <div className="row  Sustainable-inner-design">   

              {this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : (
<>

                  {this.state.childCategoryData &&
                  this.state.childCategoryData.map((item,i)=>
                <div className="col-md-4 cursor-pointer" onClick={()=>this.goToSpecialists(item.slug)}>                 
                    <div className="card-r p-4 box-shadow-none mb-4 position-relative">   
                    <span className="childCatindex">{i+1}</span>                   
                      <h2 className="heroText2 mt-2 c13">{item.slug}</h2>
                    </div>                 
                </div>
                )}

                </>
          )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

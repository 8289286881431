
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiRupee,
  BiCheck,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "./../../../config";
import validator from "validator";
import {
  validate_Alpha_Length,
  validate_Length_DOB,
  validate_Empty,
} from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import SpecialistDashboardSidebar from "../SpecialistDashboardSidebar";
import  Categories  from '../../../services/Categories'
import  SubCategories  from '../../../services/SubCategories'
import DashboardSidebar from "../../DashboardSidebar";
import Loader from "react-js-loader";
import ChildCategories from "../../../services/ChildCategories";
import Select from "react-select";

const specialistId = JSON.parse(localStorage.getItem("user"));
console.log(specialistId?.specialistId?._id)

export default class ServiceEdit extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,
      categories:[],
      subcategories:[],  
      childcategories:[],    
      gigTitleError:'',
      serviceData:'',
      selectedOptionCat: null,
      selectedOptionsubCat: null,
      selectedOptionchildCat: null,
      isCategoryEditEnabled:false,
      formErrors: {
        gigTitle: "",
        description: "",
        gigMegaData: "",
        searchTags: "",
        declaration: "",
        priceOne: "",
        priceTwo: "",
        priceThree: "",
      },
    //   userData: JSON.parse(localStorage.getItem("user")),
      serviceId: this.props.match.params.id,

      data: ''
  }
}
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");    
    }
    Categories.getCategories().then((responseCat) => {
      let cat = [];
      for (let i = 0; i < responseCat.categoriesList.length; i++) {
       // console.log(responseCat.categoriesList[i].name);
        cat.push({
          value: responseCat.categoriesList[i].name,
          label: responseCat.categoriesList[i].name,
          categoryId: responseCat.categoriesList[i]._id,
        });
      }
      this.setState({ categories: cat });
     // console.log("cc", cat);

      console.log("CAT", responseCat.categoriesList);
    });   

    

    this.getServiceDetails()
  }


  
  fetchSubCategories(id){
    SubCategories.getSubCategoriesbyId(id).then((responseSubcat) => {
      let subcat = [];
      for (let i = 0; i < responseSubcat.subCategoryData.length; i++) {
        console.log(responseSubcat.subCategoryData[i].name);
        subcat.push({
          value: responseSubcat.subCategoryData[i].name,
          label: responseSubcat.subCategoryData[i].name,
          subCategoryId: responseSubcat.subCategoryData[i]._id,
        });
      }
      this.setState({ subcategories: subcat });
      console.log("cc", subcat);
      // this.setState({subcategories:responseSubcat.subCategoriesList})
      // console.log(responseSubcat)
    });
  }




  fetchChildCategories(id){
    ChildCategories.getCategoriesbyId(id).then((response) => {
      console.log('ff',response)
      let cat = [];
      for (let i = 0; i < response.childCategoryData.length; i++) {
        console.log(response.childCategoryData[i].name);
        cat.push({
          value: response.childCategoryData[i].name,
          label: response.childCategoryData[i].name,
          childCategoryId: response.childCategoryData[i]._id,
        });
      }
      this.setState({ childcategories: cat });
      console.log("cc2", cat);
      console.log("CAT", response.childCategoryData);
      
    });
  }


   
 
  async handleUpdate(e) {

    console.log(this.state.data)
    e.preventDefault();
    if (
      validate_Empty(this.state.data?.overview?.gigTitle) &&
      validate_Empty(this.state.data?.overview?.gigMegaData) &&
      validate_Empty(this.state.data?.overview?.description) &&     
      validate_Empty(this.state.data?.overview?.categoryId?._id) &&
      validate_Empty(this.state.data?.overview?.subCategoryId?._id)&&
      validate_Empty(this.state.data?.overview?.childCategoryId?._id)
      
    ) {
      this.setState({ spinner: true });

      
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });


    let formData = new FormData();
    console.log('atsubmit',this.state.data?.overview?.searchTags)
    formData.append('overview[gigTitle]', this.state.data?.overview?.gigTitle);
    formData.append('overview[description]', this.state.data?.overview?.description);
    formData.append('overview[gigMegaData]', this.state.data?.overview?.gigMegaData);
    // formData.append('overview[searchTags]', JSON.stringify(this.state.data?.overview?.searchTags))

   
      for(let i=0;i<this.state.data?.overview?.searchTags.length;i++)
      {
          formData.append('overview[searchTags][]', this.state.data?.overview?.searchTags[i]);
  
      }
    
   
    formData.append('overview[categoryId]', this.state.data?.overview?.categoryId._id);
    formData.append('overview[subCategoryId]', this.state.data?.overview?.subCategoryId._id)
    formData.append('overview[childCategoryId]', this.state.data?.overview?.childCategoryId._id)
    formData.append('faq[0][question]', this.state.data?.faq[0].question)
    formData.append('faq[1][question]', this.state.data?.faq[1].question)
    formData.append('faq[2][question]', this.state.data?.faq[0].question)
    formData.append('faq[0][answer]', this.state.data?.faq[0].answer)
    formData.append('faq[1][answer]', this.state.data?.faq[1].answer)
    formData.append('faq[2][answer]', this.state.data?.faq[2].answer)
    formData.append('specialistId', specialistId?.specialistId._id)
   

   
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .put(API_URL + "service/"+this.state.serviceId, formData, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log(response);
            //handle success
console.log(response)
            if (response.data.success === true) {
                
              // localStorage.setItem(
              //   "user",
              //   JSON.stringify(response.data.userInfo)
              // );
              setTimeout(() => {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                });

                self.props.history.goBack();
                toast("Service Updated Successfully", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }, 1000);
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast(message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } else {
      toast("Error in the input fields. Please check!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }


  handleCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          categoryId:{
            ...this.state.data.overview.categoryId,
            _id: selectedOption.categoryId,
          } 
        },
      },
    });
    this.setState({
      selectedOptionCat: selectedOption,
    });

    this.fetchSubCategories(selectedOption.categoryId)
  };

  handleSubCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          subCategoryId:{
            ...this.state.data.overview.subCategoryId,
            _id:selectedOption.subCategoryId,
          } 
        },
      },
    });
    this.setState({
      selectedOptionsubCat: selectedOption,
    });

    this.fetchChildCategories(selectedOption.subCategoryId)
  };


  handleChildCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          childCategoryId:{
            ...this.state.data.overview.childCategoryId,
            _id:selectedOption.childCategoryId,
          } 
        },
      },
    });
    this.setState({
      selectedOptionchildCat: selectedOption,
    });

  };
  
  validateOverviewField=(e)=>{
    let name = e.target.name;
    let val = e.target.value;

   
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          overview: {
            ...prevState.data.overview,            
              [name]: e.target.value,            
          },
        },
      }));
     
   this.validateAll(name, val);
  };

  validateOverviewSearchField=(e)=>{

    let val=e.target.value
    let name = e.target.name;

    if (val.indexOf(',') > -1) 
    { 
      console.log('here2',val.split(','))
      // val.split(',')
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          overview: {
            ...prevState.data.overview,            
              [name]: val.split(','),            
          },
        },
      }));
  
  }
    else{
      console.log('here')    
      let arr=[] 
      arr.push(e.target.value)
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          overview: {
            ...prevState.data.overview,            
              [name]: arr,            
          },
        },
      }));
    }


   
    // let arr=e.target.value.split(',');
    // let val = []
    // val.push(arr)
    // console.log(val,'arr')



   
      
     
   this.validateAll(name, val);
  };



  validatePackageField=(input, i)=>(e)=>{
   
    let name = e.target.name;
    let val = e.target.value;

let values = [...this.state.data?.packageDetails];
    if (e.target.name === "name") {
      values[i].name = e.target.value;
    } else {
      values[i].price = e.target.value;
    }   
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          overview: {
            ...prevState.data.overview
          },
          packageDetails:         
           values         
        
        },
      }));
     
   this.validateAll(name, val);
  };




  validatePackageFeatureField=(i,j)=>(e)=>{   
    let name = e.target.name;
    let val = e.target.value;

let values = [...this.state.data?.packageDetails];

    if (e.target.name === "feature") {
      values[i].featuresList[j] = e.target.value;
    } 
      this.setState((prevState) =>({
        data: {
          ...prevState.data,
          overview: {
            ...prevState.data.overview
          },  
            packageDetails:
              values           
        
        },
      }));     
   this.validateAll(name, val);
  };

 


  validatePackageFAQField=(i)=>(e)=>{   
    let name = e.target.name;
    let val = e.target.value;
    

let values = [...this.state.data?.faq];
    if (e.target.name === "question") {
      values[i].question = e.target.value;
    } 
    else{
      values[i].answer = e.target.value; 
    }
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          // overview: {
          //   ...prevState.data.overview
          // },  
          // packageDetails:{
          //   ...prevState.data.packageDetails,  
          // }, 
          faq: values         
        },
      }));
     
   this.validateAll(name, val);
  };


  


  validateAll(name, val) {
    console.log('DD', this.state.data)
    let errorName = name + "Error";
    validate_Empty(val)
      ? this.setState({ [errorName]: false })
      : this.setState({ [errorName]: true });
  
  }


  async getServiceDetails() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "service/" + this.state.serviceId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
         
console.log('SERVICEDATA',response.data)
          if (response.data.success === true) {
           

            self.setState((prevState) => ({
                data: response.data.serviceData,
                selectedOptionCat:response.data?.serviceData?.overview?.categoryId?.name
                // data:{   
                //   ...prevState.data,     
                // overview: {
                //     ...prevState.overview,
                //     categoryId:self.state.data.overview?.categoryId._id,
                //     subCategoryId:self.state.data.overview?.subCategoryId._id
                // }        
              //},

            }));
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {

    if(this.state.categories===null){
     return null; //Or some other replacement component or markup
  }
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust specialist-dashboard">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit Service</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form>
                      {this.state.data?
                        <Row>
                          <Col
                            xs
                            lg="2"                            
                          >
                           
                        <DashboardSidebar />
                        
                          </Col>

                          <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                           
                              <Row>
                                  
                                <Col>
                                  <div className="dashboard-content-title">
                                    <Link
                                      onClick={() =>
                                        this.props.history.goBack()
                                      }
                                    >
                                      {" "}
                                      <IoReturnUpBackOutline className="mx-3" />
                                    </Link>
                                    Edit Service 
                                  </div>
                                </Col>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.handleUpdate}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Update
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                           

                            <Row  className="shd-1 p-3">
                              <Col>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Service Title</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={this.validateOverviewField}
                                        value={
                                          this.state.data?.overview?.gigTitle
                                        }
                                        name="gigTitle"
                                        className={
                                          this.state.gigTitleError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      
                                    </Form.Group>




                                    <Form.Group>

                                    <Form.Label>Selected Categories</Form.Label>
                                      <h3 className="fs-12 bg-3 p-1 c1 bdr-rad-4">Category:{' '}<BiCheck /> {this.state.data?.overview?.categoryId?.name}</h3>
                                      <h3 className="fs-12 bg-3 p-1 c1 bdr-rad-4">Sub Category:{' '}<BiCheck /> {this.state.data?.overview?.subCategoryId?.name}</h3>
                                      <h3 className="fs-12 bg-3 p-1 c1 bdr-rad-4">Category Services:{' '}<BiCheck /> {this.state.data?.overview?.childCategoryId?.name}</h3>

                        < a className="btn-btn-primary cursor-pointer fw-700 mb-4 d-inline-block bdr-1 px-2" onClick={()=>
                        this.setState(prevState => ({
                          isCategoryEditEnabled:!this.state.isCategoryEditEnabled,
                          selectedOptionCat:'',
                          selectedOptionsubCat:'',
                          selectedOptionchildCat:'',
                          data:{
                            ...prevState.data,
                          overview:{
                            ...prevState.data.overview,
                            categoryId:
                            {
                              ...prevState.data.overview.categoryId,
                              _id:'',
                              name:''
                            },
                            subCategoryId:
                            {
                              ...prevState.data.overview.subCategoryId,
                              _id:'',
                              name:''
                            },
                            childCategoryId:
                              {
                              ...prevState.data.overview.childCategoryId,
                                _id:'',
                                name:''
                              ,
                            }
                          }
                          }
                          
                        }))}

                        >Change</a>
                                    </Form.Group> 
{this.state.isCategoryEditEnabled &&
<>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                       
                                      <Form.Label>Choose Category</Form.Label>
                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionCat || ""
                                        }
                                        onChange={this.handleCategory}
                                        name="categoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.categoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                        options={
                                          this.state.categories
                                            ? this.state.categories
                                            : ""
                                        }
                                      />
                                         { this.state.data?.overview?.categoryId?
                                                (
                                                <option value={this.state.data?.overview?.categoryId._id}>
                                                  {this.state.data?.overview?.categoryId?.name}
                                                </option>
                                                )
                                                :''
                                        }                                      
                                     
                                     
                                    </Form.Group>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Choose Sub Category
                                      </Form.Label>

                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionsubCat || ""
                                        }
                                        onChange={this.handleSubCategory}
                                        name="subCategoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.categoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                        options={
                                          this.state.subcategories
                                            ? this.state.subcategories
                                            : ""
                                        }
                                      />                                      
                                   
                                     
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Choose Category Service
                                      </Form.Label>

                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionchildCat || ""
                                        }
                                        onChange={this.handleChildCategory}
                                        name="childCategoryId"
                                        aria-label="Default select"                                        
                                        options={
                                          this.state.childcategories
                                            ? this.state.childcategories
                                            : ""
                                        }
                                      />
                                      
                                    </Form.Group>
            </>
            }


                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Meta Data</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={this.validateOverviewField}
                                        value={
                                          this.state.data?.overview?.gigMegaData
                                        }
                                        name="gigMegaData"
                                        className={
                                          this.state.gigMegaDataError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlTextarea1"
                                    >
                                      <Form.Label>Description</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={this.validateOverviewField}
                                        name="description"
                                        value={
                                          this.state.data?.overview?.description
                                        }
                                        className={
                                          this.state.descriptionError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Declaration</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={this.validateOverviewField}
                                        value={
                                          this.state.data?.overview?.declaration
                                        }
                                        name="declaration"
                                        className={
                                          this.state.declarationError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Search Tags</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={
                                          this.validateOverviewSearchField
                                        }
                                        searchTags                                        value={
                                          this.state.data?.overview?.searchTags?.toString()
                                        }
                                        name="searchTags"
                                        className={
                                          this.state.searchTagsError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>                            

                            {/* package */}

                            <Row className="mt-5 shd-1 p-3">
                              <h2>Package Details</h2>
                              <p>
                                Define your package name and price with the
                                below 3 options for the customers to choose
                                from. The information mentioned here will be
                                shown to the customer while ordering the service
                                for you.
                              </p>
                              <Col className="create-package-box">
                                <div className="package-number-float">1</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder="Eg: Basic"
                                    onChange={this.validatePackageField(
                                      "name",
                                      0
                                    )}
                                    // value={
                                    //   this.state.data?.packageDetails[0].name
                                    // }

                                    value={"Basic"}
                                    disabled
                                    name="name"
                                    // className={
                                    //   this.state.packageDetailsNameError
                                    //     ? "hasError"
                                    //     : "valid"
                                    // }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Price</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1} >
                                      <BiRupee className="fs-32  c4"  />
                                    </Col>
                                    <Col md={5}>

                                  <Form.Control
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 8);
                                    }}
                                    placeholder="Eg:3200"
                                    onChange={this.validatePackageField(
                                      "price",
                                      0
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0].price
                                    }
                                    name="price"
                                    className={
                                      this.state.packageDetailsError
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                  </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      0
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      1
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      2
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      3
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      4
                                    )}
                                    value={
                                      this.state.data?.packageDetails[0]
                                        .featuresList[4]
                                    }
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col className="create-package-box">
                              <div className="package-number-float">2</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageField(
                                      "name",
                                      1
                                    )}
                                    // value={
                                    //   this.state.data?.packageDetails[1].name
                                    // }
                                    value={"Standard"}
                                    disabled
                                    name="name"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Package Price{" "}
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1} >
                                      <BiRupee className="fs-32  c4"  />
                                    </Col>
                                    <Col md={5}>
                                      <Form.Control
                                        type="number"
                                        onInput={(e) => {
                                          e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                          )
                                            .toString()
                                            .slice(0, 8);
                                        }}
                                        placeholder="Eg: 5400"
                                        onChange={this.validatePackageField(
                                          "price",
                                          1
                                        )}
                                        value={
                                          this.state.data?.packageDetails[1]
                                            .price
                                        }
                                        name="price"
                                        className={
                                          this.state.packageDetails
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      0
                                    )}
                                    value={
                                      this.state.data?.packageDetails[1]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      1
                                    )}
                                    value={
                                      this.state.data?.packageDetails[1]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      2
                                    )}
                                    value={
                                      this.state.data?.packageDetails[1]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      3
                                    )}
                                    value={
                                      this.state.data?.packageDetails[1]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      4
                                    )}
                                    value={
                                      this.state.data?.packageDetails[1]
                                        .featuresList[4]
                                    }
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col className="create-package-box">
                              <div className="package-number-float">3</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageField(
                                      "name",
                                      2
                                    )}
                                    // value={
                                    //   this.state.data?.packageDetails[2].name
                                    // }
                                    value={"Advanced"}
                                    disabled
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Price</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1} >
                                      <BiRupee className="fs-32  c4"  />
                                    </Col>
                                    <Col >

                                  <Form.Control
                                    type="number"
                                    onInput={(e) => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 8);
                                    }}
                                    placeholder="Eg: 6500"
                                    onChange={this.validatePackageField(
                                      "price",
                                      2
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2].price
                                    }
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                  </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      0
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      1
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      2
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      3
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      4
                                    )}
                                    value={
                                      this.state.data?.packageDetails[2]
                                        .featuresList[4]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="mt-5 shd-1 p-3">
                              <h2>Describe Frequently Asked Questions</h2>

                              <Col>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Questions</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder="Eg: How do you deliver?"
                                    onChange={this.validatePackageFAQField(0)}
                                    value={this.state.data?.faq[0].question}
                                    name="question"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Answer</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder="Eg: Via PDF Reports"
                                    onChange={this.validatePackageFAQField(0)}
                                    value={this.state.data?.faq[0].answer}
                                    name="answer"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mt-5"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Question</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder="Eg:What is the average time to deliver the certificate?"
                                    onChange={this.validatePackageFAQField(1)}
                                    value={this.state.data?.faq[1].question}
                                    name="question"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Answer</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder="Eg: 10 to 15 days"
                                    onChange={this.validatePackageFAQField(1)}
                                    value={this.state.data?.faq[1].answer}
                                    name="answer"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mt-5"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Question</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder="Eg: What happens if i did not received the Report?"
                                    onChange={this.validatePackageFAQField(2)}
                                    value={this.state.data?.faq[2].question}
                                    name="question"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Answer</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFAQField(2)}
                                    value={this.state.data?.faq[2].answer}
                                    name="answer"
                                    className={
                                      this.state.faq ? "hasError" : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            
                            
                            
                          </Col>
                        </Row>
                        : (
                          <div className="content-spinner">
                            <Loader
                              type="spinner-default"
                              bgColor={"#0A989C"}
                              title={""}
                              color={"#0A989C"}
                              size={60}
                            />
                          </div>
                        )

                        
                    }

                    

                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


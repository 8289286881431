import React from 'react'
import { useHistory } from "react-router";


export default function CategoryShortLinks(props, style) {
    const history = useHistory();


   const searchService = (keyword) => {
        console.log(keyword);
        history.push({
          pathname: "/specialists/" + keyword,
        });
      };



  return (
      <>
    <div
    className="banner btns"
    data-aos="fade-left"
    data-aos-easing="linear"
    data-aos-duration="800"
  >
    <p className="cursor-pointer">
      <a
        onClick={() =>
          searchService(
            "Sustainability-and-Technology"
          )
        }
      >
        Sustainability
      </a>
    </p>
    <p className="cursor-pointer">
      <a
        onClick={() =>
          searchService(
            "Smart-Cities-and-Infrastructure"
          )
        }
      >
        Smart Cities
      </a>
    </p>
    <p className="cursor-pointer">
      <a
        onClick={() =>
          searchService(
            "Green-Building-Technology"
          )
        }
      >
        Building Tech
      </a>
    </p>
    <p className="cursor-pointer">
      <a
        onClick={() =>
          searchService(
            "Energy-Efficiency-and-Commissioning"
          )
        }
      >
        Energy Tech &CxA
      </a>
    </p>
  </div>
     <div
     className="banner btns"                   
     data-aos="fade-left"
     data-aos-easing="linear"
     data-aos-duration="800"
   >
     <p className="cursor-pointer">
       <a
         onClick={() =>
           this.searchService(
             "GreenHouse-Gases-and-Climate-Change"
           )
         }
       >
         Climate Change
       </a>
     </p>
     <p className="cursor-pointer">
       <a
         onClick={() =>
           this.searchService(
             "Health-Safety-Environment-and-Fire-Safety"
           )
         }
       >
         HSE&Fire safety
       </a>
     </p>
     <p className="cursor-pointer">
       <a
         onClick={() =>
           this.searchService(
             "Emerging-Design-and-Technology"
           )
         }
       >
         Emerging Design & Tech
       </a>
     </p>
   </div>
   </>
  )
}

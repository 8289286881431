import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class TermsService extends Component {
    render() {
        return (

            <div>
                <div  className="Nature-banner inner-page">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h2 className="section-title p-tb-20 text-center">Beyond Smart Cities Terms of Service</h2>
                <div className="title-divider"></div>
            </div>
         </div>
         <div className="row">
               <div className="col-md-12">
             <p>This page states the Terms and Conditions (“Terms”) under which you, the Web Site visitor ("You" or “Your” or “Yourself”) may use this Web site (“Our Site”), which is owned by the <b>Beyond Smart Cites Private Limited</b>. (“We” or “Us” or “Our”). By using this Web Site, You agree to be bound by all of the Terms and Conditions set forth below. If You do not accept these Terms and Conditions, please do not use this Web site. We may, in Our sole discretion, revise these Terms and Conditions at any time; therefore, You should visit this page periodically to review the Terms and Conditions. Having such access to and use of the Site (including the content of such Site)through Us is collectively referred to as "the Service(s).</p> 
        
        <b style={{color:'red'}}>" PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICES. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE THE SERVICES OR OTHERWISE ACCESS THE SITE" .</b>
        <div className="smart-list">
         <h4>By using the Services, you agree and state that :</h4>
        <ul>
           
            <li>You form a binding contract with <b>Beyond Smart Cities</b>.</li>
            <li>You are not a person who is barred from receiving the Services under the laws of India or any other applicable jurisdiction</li>
            <li>You will comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations.</li>
            <li>You may not use the Services and may not accept the Terms if you are not of legal age to form a binding contract with <b>Beyond Smart Cities</b>.</li>
         </ul>
    </div>
   </div>
     </div>
    <div className="row mb-4">
         <div className="col-md-12"> 
         <div className="collapsible-list">
           <button type="button" className="collapsible">1 . Key Terms</button>
<div className="content">

   <div className="smart-list">
         <h6>Section 1 contains definitions of certain important terms used through these Terms of Service. We advise our users to know what these terms mean :</h6>
         <ul>
             <li>The term "Service" refers to the Services by <b>Beyond Smart Cities</b>, including without limitation to <b>Beyond Smart Cities</b> website <b>("https://www.beyondsmartcities.in")</b>, the <b>Beyond Smart Cities service</b>, or any applications <b>(including mobile applications)</b> made available by <b>Beyond Smart Cities</b>, use of <b>Beyond Smart Cities</b> Web and Mobile Applications in their entirety and of online community, blog and forum and support.</li>
           <li>The term “Website” refers to <b>Beyond Smart Cities Website ("https://www.beyondsmartcities.in")</b>, including all pages, sub pages and sub domains, including all information, content, products, and services made available through the website.</li>
          <li><b>“Beyond Smart Cities”</b>, “We”, “Us” or “Our” refers to our parent entity Beyond Smart Cities Private limited</li>
          <li>The term “User(s)” refers to any party whether a person, company or firm who visits and/or registers itself on <b>Beyond Smart Cities</b> Website and/or Service.</li>
         <li>The term “Seller” refers to any Person, firm, Company or agency providing and/or selling any business services who registers on <b>Beyond Smart Cities</b> Website and/or Service to sell services to Buyers. Sellers are not agents or employees of <b>Beyond Smart Cities</b> does not refer to them as any work or Client.</li>
         <li>The term “Buyer” refers to any Person, Company or Firm or any other business which registers itself on <b>Beyond Smart Cities</b> Website and/or Service and buys or books a service.</li>
         <li>The term “Business Services” refers to services sold by Sellers on <b>Beyond Smart Cities</b>.</li>
         <li>The term “Content” refers to any Content displayed on <b>Beyond Smart Citie's</b> Website and Mobile Application including without limitation to any information, text, data, graphics, videos, art, sound, design and any other material.</li>
         <li>The term “User Generated Content” refers to any content created, posted or published by Users on <b>Beyond Smart Citie's</b>Website and Mobile Application and/or Service.</li>
         <li>The term “Order” refers to the order placed by a Buyer with a Seller for a particular Service.</li>
         <li>The term “Service” refers to the service offered by a Seller on <b>Beyond Smart Cities</b>.</li>
         <li>The term “Order Booking Date” refers to the date on which an order is placed by the buyer with a seller by paying a booking amount.</li>
         <li>The term “Order in progress” refers to an order created/booked between Buyer and Seller on <b>Beyond Smart Cities</b> which is not marked as completed.</li>
         <li>The term “Completed Order” refers to an order created/booked between Buyer and Seller on Beyond Smart Cities which is marked as completed.</li>
         <li><b>Limited License</b></li>
         <li>Permission is hereby granted to those wishing to use, reproduce, and/or display all or any portion of any BSAS Rating System appearing on <b>Beyond Smart Cities</b> the website in the form of a limited, royalty-free, nonexclusive, revocable license, so long as the user attributes the permission of and authorship and copyright to the <b>Beyond Smart Cities</b> Private Limited. in any such use, reproduction, and/or display. To the extent that there are any questions concerning this limited license, or if greater use of any BSAS Rating System is sought, please contact <a href="mailto:info@beyondsmartcities.in">info@beyondsmartcities.in</a></li>
         </ul>
       </div>
   </div>
   <button type="button" className="collapsible">2 . Beyond Smart Cities Purpose, Basic Terms, and Relationship with Users</button>
   <div className="content">

   <div className="smart-list">
      <ul>
          <li><b>Beyond Smart Cities</b> is a tech driven interactive platform for Buyers to find, hire and book services with Sellers. Buyers can discover Services, discover Sellers based on various filters and buy any service they want. Buyers can also post their projects to request proposals from Sellers. <b>Beyond Smart Cities</b> does not force Buyers to book any service from any Seller.</li>
          <li>Sellers include freelancers, firms, green building consultants ,environmental consultants, agencies and companies in the categories of Green Building Design, Civil engineering, Sustainability Consulting, Green Building Certification Consulting, Energy consultancy, environmental engineering, GHG Accounting, Content & Marketing, Building commissioning, Environmental Legal & Compliances. </li>
         <li>Sellers on <b>Beyond Smart Cities</b>  sell their services by creating Services and/or by sending proposals to projects posted by Potential Buyers.</li>
        <li>We put our efficiency to verify Seller's registering on <b>Beyond Smart Cities</b> through a tough screening process which includes profile creation, KYC Verification, Interview and screening process and quality checks. However, we do not warranty, guarantee or represent any inability, incompetency, quality or qualifications of/for any Seller.</li>
       <li>Sellers have full freedom and are solely responsible for determining the Services they want to sell on <b>Beyond Smart Cities</b>, set prices for their services, set delivery time for their services. Sellers are solely responsible for any content they upload and/or share while creating Services.</li>
       <li>Beyond Smart Cities does not force any Seller to register on <b>Beyond Smart Cities</b> and sell his/her Services. It is totally free for any Seller to register on  <b>Beyond Smart Cities</b> out of their own wish. If any laws, regulations, rules, and guidelines stop or prohibit any Seller from registering and selling Services on  <b>Beyond Smart Cities</b>, then the Seller has voluntarily violated such laws, regulations, rules and guidelines by registering on  <b>Beyond Smart Cities</b>. Seller takes sole responsibility for violating any such laws & regulations.</li>
       <li><b>Beyond Smart Cities</b> reserves the following rights :</li>
       <ul>
           <li>Verify and approve Sellers to sell Services on <b>Beyond Smart Cities</b></li>
           <li>Verify and approve Services/Gigs created by Sellers</li>
           <li>Deactivate Seller Accounts</li>
           <li>Suspend Seller Accounts</li>
            <li>Deactivate Buyer Accounts</li>
             <li>Suspend Buyer Accounts</li>

       </ul>
       </ul>
     </div>
   </div>
    <button type="button" className="collapsible">3 . Provision of Service and Terms</button>
   <div className="content">

   <div className="smart-list">
      <ul>
          <li>We’re relentlessly improving our Services by creating new features and functions. That means we may add or remove features, products, or functionalities. We might not always update you of such changes.</li>
       <li>We at times revise our Terms of Service and Privacy Policy due to current Laws and Regulations and/or any material changes in <b>Beyond Smart Cities</b> Services and/or processes. When we revise our Terms of Services and privacy Policy, we shall communicate such revisions and updations to you through our email(s) or post(s). Your decision to use <b>Beyond Smart Cities</b> after any such communication means your implied acceptance to the revised Terms of Service and Privacy Policy.</li>
      <li>While we hope you remain a lifelong user on <b>Beyond Smart Cities</b>, you can terminate these Terms at any time and for any reason by deactivating your account.</li>
      <li><b>Beyond Smart Cities</b> may also terminate these Terms with you at any time, for any reason, and without advanced notice. That means that we may stop providing you with any Services or impose new or additional limits on your ability to use our Services. For example, we may deactivate your account due to prolonged inactivity, and we may reclaim your username at any time for any reason.</li>
      </ul>
   
   </div>
   </div>
   
   <button type="button" className="collapsible">4 . Beyond Smart Cities Buyer Account</button>
   <div className="content">
       <div className="smart-list">
      <ul>
       <li>Registration and Acceptance :</li>
           <ul>
               <li>By registering on<b>Beyond Smart Cities's</b> Service, by using the <b>Beyond Smart Cities's</b> Website and/or Mobile Application after the effective date, or by clicking the to accept the Terms of Service when prompted on the site, you agree to abide by these Terms. If you create an account as an Employee or agent of any Person, Firm or Company or any other form of entity, you represent and warrant that you’re authorized to enter into binding contracts, including Terms of Service, on behalf of yourself and the Entity you represent.</li>
           </ul>
           <li>Account Permission :</li>
           <ul>
               <li>You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit, except that an authorized employee or agent may create an Account on behalf of your business. By granting other Users permissions under your Account, including as a Team Member or Agency Member, you represent and warrant that (a) the User is authorized to act on your behalf, (b) you are financially responsible for the User’s actions taken in accordance with those permissions, including, if applicable, entering into binding contracts on behalf of the owner of the Account, and (c) you are fully responsible and liable for any action of any User to whom you have provided any permissions and any other person who uses the Account, including making payments and entering into Service Contracts and the Terms of Service. If any such User violates the Terms of Service, it may affect your ability to use the Site. Upon closure of an Account, <b>Beyond Smart Cities</b> may close any or all related Accounts.</li>
           </ul>
           <li>Communication Permission :</li>
           <ul>
               <li> You hereby grant <b>Beyond Smart Cities</b> permission to communicate with you through Calls, Emails, SMSs or via posts.</li>
              
           </ul>
            <li>Identity and Location Verification : </li>
              <ul>
                  <li>When you register for an Account and from time to time thereafter, your Account will be subject to verification, including, but not limited to, validation against third-party databases or the verification of one or more official government or legal documents that confirm your identity, your location, and your ability to act on behalf of your business on <b>Beyond Smart Cities</b>. You authorize <b>Beyond Smart Cities</b>, directly or through third parties, to make any inquiries necessary to validate your identity, your location, and confirm your ownership of your email address, phone/mobile number subject to applicable law. When requested, you must timely provide us with complete information about yourself and your business.</li>
              </ul>
              <li>Usernames and Passwords : </li>
              <ul>
                  <li>When you register for an Account, you will be asked to choose a username and password for the Account. You are entirely responsible for safeguarding and maintaining the confidentiality of your username and password and agree not to share your username or password with any person who is not authorized to use your Account. You authorize <b>Beyond Smart Cities</b> to assume that any person using the Site with your username and password, either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You further agree not to use the Account or log in with the username and password of another User of the Site if (a) you are not authorized to use either or (b) the use would violate the Terms of Service.</li>
              </ul>
              <li>User Generated Content : </li> 
               <ul>
             <li>Read section 8 of our terms and conditions relating to User Generated Content.</li>
             </ul>
              <li>Feedback Permission : </li>
              <ul><li>
              Buyers provide feedback to <b>Beyond Smart Cities</b> and to Sellers after completion of work order by giving ratings and written testimonial. <b>Beyond Smart Cities</b> reserves the right to make the feedback live and display it on <b>Beyond Smart Cities's</b> website and/or mobile applications.</li>
            </ul>
            <li>Account Security & Privacy : </li>
             <ul><li>Your privacy matters to us. You can learn how we handle your information when you use our Services by reading our Privacy Policy. We encourage you to give the Privacy Policy a careful look because, by using our Services, you agree that <b>Beyond Smart Cities</b> can collect, use, and share your information consistent with that policy. <b>Beyond Smart Cities</b> takes all measures to protect Buyer Account Security and Privacy. Refer our <b>privacy policy</b> here.</li></ul>
        </ul>
   </div>
   </div>
    <button type="button" className="collapsible">5 . Beyond Smart Cities Seller Account, Profile, Standard Operating Procedure, Fees and Relationship</button>
   <div className="content">
       <div className="smart-list">
      <ul>
          <li>Registration : </li>
          <ul>
              <li>Sellers can register for free on <b>Beyond Smart Cities</b>  by creating a Seller Account by providing necessary details at the time of sign up. Sign up details include First Name, Last Name, Mobile Number, Location, Email ID and password creation. By registering on <b>Beyond Smart Cities's</b>  Service, by using the <b>Beyond Smart Cities's</b>  Website and/or Mobile Application after the effective date, and/or by clicking the to accept the Terms of Service when prompted on the site, you agree to abide by these Terms. If you create an account as an Employee or agent of any Person, Firm or Company or any other form of entity, you represent and warrant that you’re authorized to enter into binding contracts, including Terms of Service, on behalf of yourself and the Entity you represent.</li>
           <li>Contractual Relationship :</li>
           <p>Each Seller who registers and/or Sells Services on <b>Beyond Smart Cities</b> creates a Service Contract with <b>Beyond Smart Cities</b>. The Seller is always shall be an independent consultant in all matters relating to this Agreement. Neither this Agreement nor the cooperation of the parties contemplated under this Agreement will be deemed or construed to create any partnership, joint venture and employer-employee or agency relationship between them. Neither Party is, nor will either Party hold itself out to be vested with any power or right to bind the other Party contractually or act on behalf of the other Party as a broker, agent or otherwise.</p>
          </ul>
          <li>Profile Creation : </li>
          <ul>
              <p>After you have registered as a Seller, you must create a Seller Profile on <b>Beyond Smart Cities</b>. Creating a Seller Profile involves completing the following mandatory steps :</p>
             <li>Basic Details</li>
              <li>Brief Bio</li>
              <li>Experience, professional certificates & Education Details</li>
             <li>Business Details</li>
             <li>Creation of Gigs/Services</li>
	       <li>Skills</li>
	            <li>Uploading KYC Documents</li>
               <li>Agreeing to Standard Operating Procedure and mandatory Guidelines of <b>Beyond Smart Cities</b>.</li>
          </ul>
          <li>Profile Verification and Approval : </li>
          <p>After profile creation, <b>Beyond Smart Cities</b> screens, quality checks, schedules interview for Profile Verification and Approval of Seller Account. <b>Beyond Smart Cities</b> solely reserves the right to not verify and approve any Seller Account.</p>
     <li>Services Approvals : </li>
     <p>As a Seller you can create any number of Services on <b>Beyond Smart Cities</b> after Profile verification and approval. However, <b>Beyond Smart Cities</b> reserves the right to approve and disapprove each and every Service to go live on the <b>Beyond Smart Cities</b> Website and/or Mobile Applications.</p>
    <li>Profile Information and infringement Liability :</li>
    <p>As a Seller, you are solely responsible for the information you furnish in your Profile and in your Services including but not limited to Experience, education, Bio, Name, Number, Email, KYC Documents, Images, Videos and Service Descriptions. Beyond Smart Cities shall not be responsible for any information furnished by you.</p>
   <li>Service Creation :</li>
     <p> As a Seller, you solely own and are responsible for all the information you furnish while creating Services including but not limited to Service title, description, portfolio links and portfolio samples, images and videos or any other type of content. You set prices and fees for your own Services along with a Delivery Time for the Services. If it is discovered that any content and/or information furnished by you is not real and is not created by you, <b>Beyond Smart Cities</b> reserves the right to deactivate the Service, deactivate and/or suspend Seller Account without any warning. Further, <b>Beyond Smart Cities</b> reserves the right to sue or initiate any legal proceedings in case of any Trademark or Copyright Infringement found in the content created and/or uploaded on <b>Beyond Smart Cities</b> as well as on any impersonation by the Seller. The Delivery Time set by you for your Services will be deemed as the Delivery Time from the Order Booking Date for completion of Services.</p>
    <li>Mandatory Standard Operating Procedure and Guidelines: As a Seller, you have to follow <b>Beyond Smart Cities's</b> Mandatory Standard Procedure (SOP) and guidelines while using <b>Beyond Smart Cities</b>.</li>
     <ul>
           <li>Standard Response time :</li>
           <h6><b>Moment you are hired or assigned a job on Beyond Smart Cities, you have to respond within :</b></h6>
           <ul>
           <li>8 hours if hired before 8 PM</li>
           <li>24 hours if hired after 8 PM</li>
           </ul>
           <p>If you fail to reply, then the job is transferred to a different expert.</p>
         <li>Standard First Message: Greeting the Client</li>
         <p>A standard greeting text is pre-filled in the chat text box. You have to always send that greeting to the client who has hired you or assigned it to you.</p>
         <li>Standard Second Message: Share Documents checklist or Ask for Information :</li>
         <p>Create a document checklist or information checklist and send it to the Client on chat window</p>
         <li>Standard Third Message: Mention Delivery Time</li>
         <ul>
             <li>Mention delivery time in days after complete submission of information required. Example: This work will be completed in 14 days after complete submission of all documents and information.</li>
             <li>In case of services, the delivery time mentioned at the time of service creation and displaying on your service shall be considered as the delivery time.</li>
             <li>In case of Custom services, the delivery time mentioned at the time of service Creation shall be considered and displayed and the Delivery Time.</li>
         </ul>
         <li>Next step is Creating Milestones : </li>
         <ul>
             <li>Create milestones with due dates and balance fee amount breakup</li>
             <li>Check first milestone</li>
             <li>Put a delivery deadline to last milestone</li>
             
         </ul>
         <li>Regular Updates on Work Progress :</li>
         <p>Provide regular updates on Work Progress</p>
         <li>Send Job Completion Request and ask for feedbackb :</li>
         <ul>
             <li>Once the job is completed from your end, send a job completion request to the client from your app or dashboard.</li>
             <li>When you click on “Request Completion Button” you will be asked to enter the work delivered.</li>
             <li>Also ask for positive feedback for your work. Client rates on responsiveness, meeting deadlines and overall performance and gives written review.</li>
            
         </ul>
          <li>Extension of Delivery Time and Cost :</li>
          <b>In case where in the middle of a running job, the delivery date and/or cost is increased due to any reasons:</b>
          <ul><li>Due to increase in scope of work by client</li>
          <li>Due to Government Policies and Regulations</li>
          <li>Due to any unforeseen event</li></ul>
          <b>Following steps are needed to be taken mandatorily :</b>
          <ul>
              <li>Increase Delivery Time on Dashboard and mention reason for same</li>
              <li>Mention on Chat</li>
              <li>Increase Cost on Job and mention reasons</li>
          </ul>
          
           </ul>
          <li>Time for Seller Fees Settlement : </li>
          <p> <b>Beyond Smart Cities </b>settles Seller Fees after the order completion and approval by the Buyer. If the Buyer is not active for 20 days from the date of order completion request initiated by the Seller,  <b>Beyond Smart Cities </b> deems the order to be completed and settles the fees within the next 10 days to the Seller.</p>
    
      <li>Sellers providing Green Building Certification services :</li>
      <p>The Seller agrees to provide green building consulting and/or buy other 3rd party certification services, Energy services and sustainability and other related Services in the Buyer’s Name using Buyer’s Email Id and Password on third party organizations. </p>
   <li>Sellers providing Green Building Solutions, Green Building Consulting, Environmental Consulting and other related services: The Seller agrees to assign commercial license of the solutions to the Buyer after the Buyer has made the Order Payment. The Design is the intellectual Property of the Buyer once the Buyer has paid for it. The Seller agrees to provide the Completed Designs or reports along with committed file extensions of the finished designs to the Buyer on  <b>Beyond Smart Cities's </b> Website and Mobile Applications.</li>
   
   <li>Seller Fees Settlement after Beyond Smart Cities Service Fees and Taxes :</li>
    <b>Beyond Smart Cities settles Seller Fees after deducting its Service Fee and TDS if applicable :</b>
    <p><b>Beyond Smart Cities </b> Service and Platform Usage Fee as per respective Categories and Services. Check our slab below.</p>
    <p>TDS as per respective Seller Category and Profession and the relevant TDS Laws. TDS is deducted after deduction of <b>Beyond Smart Cities </b> Service Fee.</p>
    <li><b>Beyond Smart Cities </b> Service and Platform Usage Fees :</li>
   <p><b>Beyond Smart Cities </b> charges Service and Platform Usage Fee from all from all Orders created on Beyond Smart Cities as below :</p> 
   <p>18% of the Seller Fees for All Services </p>
   <p><b>Beyond Smart Cities </b> reserves the right to change and vary it’s Service and Platform usage fee from time to time and from service to service and from category to category. Any such change in Service Fee and Platform Usage Fee will be communicated in 2 days advance to all the Sellers by an email “Service Fee Revision Announcement”. Also, any such change in Fee will not be applicable on existing orders in progress. The changes will be applicable from the Formal announcement Email date on all the new Orders created between Buyers and Sellers.</p>
    <li>Forfeiture of Seller Fees: <b>Beyond Smart Cities </b> reserves the right to forfeit the outstanding Seller Fees or any part of outstanding Fees in any of the following circumstances:</li> 
     <ul>
         <li>Seller fails to deliver the complete order</li>
         <li>Seller fails to deliver the order in committed Delivery Time</li>
         <li>Seller fails to track milestones and order delivery</li>
         <li>Seller fails to communicate on the Beyond Smart Cities Applications</li>
         <li>Seller fails to deliver the committed quality</li>
         <li>Seller fails to follow mandatory Standard Operating Procedure and Guidelines</li>
         <li>Seller accepts payments from outside Beyond Smart Cities</li>
         <li>Seller influences Buyer or any user to not use Beyond Smart Cities</li>
         <li>Seller becomes inactive</li>
         <li>Seller wishes to discontinue an Order in Progress</li>
         <li>Seller Communicates outside of Beyond Smart Cities</li>
     </ul>
    <li>Service Contract with <b>Beyond Smart Cities</b>: </li>
    <p>You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit, except that an authorized employee or agent may create an Account on behalf of your business. By granting other Users permissions under your Account, including as a Team Member or Agency Member, you represent and warrant that (a) the User is authorized to act on your</p>
    <li>Seller Ratings and Feedback :</li>
    <p>Seller ratings on <b>Beyond Smart Cities</b> are given by Buyers who book orders with them and by <b>Beyond Smart Cities</b> through its proprietary algorithm. Seller Feedback is given by Buyers in form of written reviews after the order is completed. <b>Beyond Smart Cities</b> reserves the right to display Ratings and Feedback on Seller Profile. <b>Beyond Smart Cities</b> maintains utmost transparency with Ratings and Feedback so that Buyers can make fair order decisions.</p>
    <li>Non-Disclosure Agreement with  <b>Beyond Smart Cities</b>:</li>
      <ul>
          <li>Confidential information shall mean any information disclosed by <b>Beyond Smart Cities</b> to the Seller in any form including without limitation information, documents, trade secrets, business plans, designs, Software code, data, policy and process, software, technical/ financial/ marketing/ customer/ business information, specifications, analysis, designs, drawings, data, computer programs, and include information disclosed by third parties at the direction of Beyond Smart Cities Services and/or disclosed by <b>Beyond Smart Cities</b>. The Seller hereby agrees and acknowledges not to share or to disclose any confidential information to any third party.</li>
          <li>Confidential Information shall however, exclude any information which (i) is/ was publicly known or comes into the public domain; (ii) is received by the Seller from a third party without breach of this Agreement; (iii) was already in the possession of the Seller, without confidentiality restrictions, at the time of disclosure by the <b>Beyond Smart Cities</b> ; (iv) is permitted for disclosure by the <b>Beyond Smart Cities</b> in writing; (v) independently developed by the Seller without the use of Confidential Information; (vi) is required to be disclosed by the Seller pursuant to any order or requirement from court, administrative or governmental agency, provided that the Seller shall give the <b>Beyond Smart Cities</b> prompt written notice of such order or requirement and an opportunity to contest or seek an appropriate protective order. The Seller agrees that disclosure of Confidential Information of the shall be limited by the Seller to Resources/employees of the Sellers and its affiliates, consultants, advisors having a need to know or to conduct any quality audits or training.</li>
         <li>The buyer and <b>Beyond Smart Cities</b> agree that the Seller may use the Buyer's name, logo, design solutions created, feasibility report developed, portfolio and self-promotional matters.</li>
         <li>Notwithstanding the disclosure of any Confidential Information by the <b>Beyond Smart Cities</b> to the Seller, <b>Beyond Smart Cities</b> shall retain title thereto and to all intellectual property and proprietary rights therein, and the Seller shall have no rights, by license or otherwise, to use the Confidential Information except as expressly provided herein. The Seller shall not alter or obliterate any trademark, trademark notice, copyright notice, confidentiality notice or any notice of any other proprietary right of <b>Beyond Smart Cities</b> on any copy of the Confidential Information, and shall faithfully reproduce any such mark or notice on all copies of such Confidential Information.</li>
        <li>The Seller shall promptly return all Confidential Information or copies thereof which are in its possession to <b>Beyond Smart Cities</b> on demand or shall destroy the same in the manner so specified by <b>Beyond Smart Cities</b>.</li>
       <li>The obligations under this section shall survive for a period of two (2) years post termination or expiration of the Agreement.</li>
      </ul>
      <li>Seller Account Badges and Incentives:  <b>Beyond Smart Cities</b> has created certain Badges and incentives which are given to the Sellers who have worked exceptionally well on  <b>Beyond Smart Cities</b>. Here are the criteria’s which are considered for earning Badges on  <b>Beyond Smart Cities</b>. These criteria’s need not occur together or be fulfilled together.</li>
        <ul>
            <li>Completing At least 8 Jobs with great feedback having a rating of at least 4</li>
            <li>Setting up and updating services in a very comprehensive manner with proper descriptions, images and pricing</li>
            <li>Sharing and Spreading services socially</li>
            <li>Getting Number of Unique Views on smart works</li>
            <li>Referring Customers to Hire you on Beyond Smart Cities.</li>
            <li>Responsiveness on services</li>
            <li>Conversion Rate from smart works Chat to Order in Progress</li>
            <li>Minimum disputed jobs and jobs left uncompleted</li>
            <li>Following Standard Operating Procedure and Compulsory Guidelines of Beyond Smart Cities</li>
            <li>Kind Attitude, Soft skills and Ethics in dealing with Team Beyond Smart Cities, Leads and Clients</li>
            <li>Marking correct availability on Beyond Smart Cities</li>

        </ul>
     <li>Account and Profile Permission:</li>
     <ul>
         <li> You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit, except that an authorized employee or agent may create an Account on behalf of your business. By granting other Users permissions under your Account, including as a Team Member or Agency Member, you represent and warrant that (a) the User is authorized to act on your behalf, (b) you are financially responsible for the User’s actions taken in accordance with those permissions, including, if applicable, entering into binding contracts on behalf of the owner of the Account, and (c) you are fully responsible and liable for any action of any User to whom you have provided any permissions and any other person who uses the Account, including making payments and entering into Service Contracts and the Terms of Service. If any such User violates the Terms of Service, it may affect your ability to use the Site. Upon closure of an Account, <b>Beyond Smart Cities</b>  may close any or all related Accounts.</li>
     <li>Public Profile Permission: You allow <b>Beyond Smart Cities</b> to display your Public Profile on <b>Beyond Smart Cities</b> and its services after your profile is verified and approved.</li>
    <li>Permission to promote Content & Profile: You allow  <b>Beyond Smart Cities</b> to publicly promote and market your Public Profile, Services, Portfolio and/or any other content created by You on <b>Beyond Smart Cities</b>.  <b>Beyond Smart Cities</b> shall give you credit in the form of mentioning your name while promoting your Public Profile, Services/Gigs, Portfolio and/or any other content created by You.</li>
     
     </ul>
     <li>Communication Permission: You hereby grant <b>Beyond Smart Cities</b> permission to communicate with you through Calls, Emails, SMSs, WhatsApps or via posts.</li>
    <li>Identity and Location Verification: When you register for an Account and from time to time thereafter, your Account will be subject to verification, including, but not limited to, validation against third-party databases or the verification of one or more official government or legal documents that confirm your identity, your location, and your ability to act on behalf of your business on<b>Beyond Smart Cities</b>. You authorize <b>Beyond Smart Cities</b>, directly or through third parties, to make any inquiries necessary to validate your identity, your location, and confirm your ownership of your email address, phone/mobile number subject to applicable law. When requested, you must timely provide us with complete information about yourself and your business.</li>
    <li>Usernames and Passwords :</li>
    <p>When you register for an Account, you will be asked to choose a username and password for the Account. You are entirely responsible for safeguarding and maintaining the confidentiality of your username and password and agree not to share your username or password with any person who is not authorized to use your Account. You authorize <b>Beyond Smart Cities</b>  to assume that any person using the Site with your username and password, either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You further agree not to use the Account or log in with the username and password of another User of the Site if (a) you are not authorized to use either or (b) the use would violate the Terms of Service.</p>
    <li>Seller Invoicing : </li>
    <p>The Seller will raise his or her Invoice to <b>Beyond Smart Cities</b> on Order Completion. <b>Beyond Smart Cities</b> can also raise an invoice on Seller’s behalf to itself on order Completion.</p>
    </ul>
   </div>
   </div>
    <button type="button" className="collapsible">6 . Orders Creation, Booking, Payments, Delivery, Completion Non Disclosure and Dispute Resolution</button>
   <div className="content">
       <div className="smart-list">
      <ul>
       <li>Order Creation : </li>
       <p>An order is created when a Buyer books a Service/smart work with a Seller by paying a minimum booking amount or full order fee. The date on which Order is created is the Order Booking Date.</p>
      <p><b>Order Deliverables:</b> The deliverables set by the Seller and/or Beyond Smart Cities at the time of Order Creation are called Order Deliverables. The Seller agrees to clearly define what is included and what is not included in Order Deliverables at the time of Order Creation.</p>
   <li>Order Value:</li>
   <p>Order Value is the total amount for which the Order is created or booked by a Buyer with a Seller on Beyond Smart Cities. Order Value is inclusive of all applicable taxes and fees.</p>
    <li>Increase or Decrease in Order Value : </li>
    <p>While the Order is in progress, there can be situations where the Order Value either has to be increased or decreased due the proportionate increase or decrease in scope of Order and Order deliverables. The Seller agrees to update any increase or decrease in Order value along with the reason for such increase or decrease on the Order Information and Order Delivery Time for the respective Order on Beyond Smart Cities’s Website and Mobile Applications.</p>
   <li>Beyond Smart Cities Fees & Taxes :</li>
   <p><b>Service & Platform Fee:</b> For Domestic Buyers, Beyond Smart Cities does not charge any Service & Platform Fees. For international Buyers, Beyond Smart Cities Charges 8% Service and Platform Fee to recover Payment Gateway Expenses and other Platform Usage Costs.</p>
   <p>GST & other applicable Taxes: Beyond Smart Cities charges GST as applicable as per Indian Laws on all Orders. For Domestic Buyers, Beyond Smart Cities charges 18% GST on all orders. For International Buyers, GST is exempted on export of services as per Indian GST laws & Regulations.</p>
  <li>Order Payments :</li>
  <p>Every Buyer who books a Service with a Seller by creating an Order on Beyond Smart Cities has to make Order Payments either in full or in Milestones. All payments made are held by Beyond Smart Cities in its account and is settled to the Seller either on Milestone completion or on Order completion. Buyer agrees to deposit all payments with Beyond Smart Cities before the complete delivery of Order. Where a Buyer defaults in depositing the complete Order payments with Beyond Smart Cities before the completion of the Order, Beyond Smart Cities reserves the right to put the Order on standby and/or cancel the order and/or forfeit any Order payments already made on the respective Order. Further, Beyond Smart Cities Assurance will be revoked and is not applicable. Beyond Smart Cities shall not take any responsibility for Order completion and Order delivery. Beyond Smart Cities shall not refund any Order Amount paid or Order payments made by the Buyer. Further, Beyond Smart Cities reserves the right to forfeit the Order Amount paid or Order payments made by the Buyer for the respective Order. Beyond Smart Cities also reserves the right to deactivate and/or suspend the Buyer Account from immediate effect.</p>
   <li>Order File Sharing & Communications :</li>
   <ul>
       <li>Both Buyer and Seller agree to share all files and documents including but not limited to Documents, Sheets, Images, Videos in any format on Beyond Smart Cities’s Website and Mobile Applications. In case where either the Buyer or the Seller cannot share files due to any size issues or technical issues then they shall take consent of Beyond Smart Cities Support Team to share the respective files outside of Beyond Smart Cities.</li>
      <li>Both Buyer and Seller agree to communicate with each other using Beyond Smart Cities’s Website and Mobile Applications. Beyond Smart Cities’s website and mobile applications enable both the Buyer and the Seller to communicate with each other using inbuilt Chat Software and Audio and Video Calling features. In case where either the Buyer or the Seller cannot communicate with each other due to any technical issues then they shall email their concern to the Beyond Smart Cities Support Team at <a href="mailto:support@beyondsmartcities.in">support@beyondsmartcities.in</a>.</li>
      <li>In cases where a Buyer breaches the clause 6.5.1 and 6.5.2, the Beyond Smart Cities Assurance is revoked and is not applicable. Beyond Smart Cities shall not take any responsibility for Order completion and Order delivery. Beyond Smart Cities shall not refund any Order Amount paid or Order payments made by the Buyer. Further, Beyond Smart Cities reserves the right to forfeit the Order Amount paid or Order payments made by the Buyer for the respective Order. Beyond Smart Cities also reserves the right to deactivate and/or suspend the Buyer Account from immediate effect.</li>
     <li>In cases where a Seller breaches the clause 6.5.1 and 6.5.2, the Beyond Smart Cities Assurance is revoked and is not applicable. Beyond Smart Cities shall not take any responsibility for Order completion and Order delivery. Beyond Smart Cities shall not settle any Order Amount paid or Order payments to the Seller. Further, Beyond Smart Cities reserves the right to forfeit the Order Amount paid or Order payments made by the Buyer for the respective Order. Beyond Smart Cities also reserves the right to deactivate and/or suspend the Seller Account from immediate effect.</li>
 
   </ul>
       <li>Order Tracking : </li>
       <p>Both Buyer and Seller can track Order Delivery or Order Completion by tracking Milestones predefined by Beyond Smart Cities and/or created by the Seller for the respective Order. It is Seller’s responsibility to track the Order Delivery by marking milestones as completed. Where a Seller fails to track the Milestones for an Order Beyond Smart Cities shall not take any responsibility for Order completion and Order delivery. Beyond Smart Cities shall not settle any Order Amount paid or Order payments to the Seller. Further, Beyond Smart Cities reserves the right to forfeit the Order Amount paid or Order payments made by the Buyer for the respective Order. Beyond Smart Cities also reserves the right to deactivate and/or suspend the Seller Account from immediate effect</p>
  <li>Order Delivery Time : </li>
  <p>Every Seller is compulsorily required to communicate a Delivery Time to the Buyer at the time of Order Creation. Where an Order is created from predefined Services, then the delivery time set by the Seller at the time of creating the Service shall be deemed as the Order Delivery Time. Where an order is created from a Custom Offer or a Custom Gig created by a Seller, then the delivery time set by the Seller at the time of creating the Custom Offer or Custom service shall be deemed as the Order Delivery Time.</p>
   <li>Extension of Delivery Time:</li>
   <p>While the Order is in progress, there can be situations where the Order Delivery Time either has to be increased or decreased due the proportionate increase or decrease in scope of Order and Order deliverables. The Seller agrees to update any increase or decrease in Order Delivery Time along with the reason for such increase or decrease on the Order Information and Order Delivery Time for the respective Order on Beyond Smart Cities’s Website and Mobile Applications.</p>
   <li>10 Order in Progress : </li>
   <p>The status of an Order created between a Buyer and a Seller till the time the Order is marked as Completed is referred as Order in Progress.</p>
   <li>Order Completion: </li>
   <p>An Order created by a Buyer with a Seller is completed when the Order is marked completed by the Buyer. The Seller initiates an Order Mark Completion request to the Buyer when the Order is completed. The buyer approves the completion by marking it as completed. Where the Buyer has responded to the Order Completion Request initiated by the Seller within 15 Days from the date of Order Completion Request, the Order will be marked as completed automatically.</p>
   <ul>
       <li>Order Content : </li>
       <p>The content including but not limited website, design, animation, business registration, plan, deck, content, blogs, articles, ads, licences, tax filing, accounting report, compliances, agreements, marketing reports and any other content included in Order Deliverables delivered by the Seller at the time of Order Completion is called “Order Content”</p>
   </ul>
   <li>Order Permissions :</li>
   <p>Both Buyer and Seller allow Beyond Smart Cities to have access to all Order communications, files and information shared on a particular Order. This helps Beyond Smart Cities to settle any disputes that arise on a particular Order between the Buyer and the Seller. You allow Beyond Smart Cities to reproduce this information in case of: (a) claim for refund made by the Buyer regarding non fulfillment or false fulfillment of Order (b)Court’s direction to reproduce any information (c) To give updates on Order Status and Tracking (d) To track Order Status (e) To respond to any complaints made by either the Buyer or the Seller on a particular Order.</p>
   <li>Order Rating and Feedback :</li>
   <p>On Order Completion, the Buyer gives a rating and a written feedback for the Seller and Beyond Smart Cities. Beyond Smart Cities reserves the right to make Order Rating and Feedback public. The Feedback given by the Buyer also appears on the public profile of the Seller.</p>
  <li>Non Circumvention by Buyer & Seller :</li>
  <ul>
      <li>Both Buyer and Seller hereby agree to exclusively use Beyond Smart Cities’s website and Service to make and receive payments, to do communication and to share files, documents, information, images, graphics and other similar information. You agree not to circumvent the Communication, File Sharing and Payment Methods offered by the Site and the Service. By way of illustration and not in limitation of the foregoing, you agree not to: (a) Submit proposals or solicit parties identified through the Site to contract, hire, work with, or pay outside the Site. (b) Accept proposals or solicit parties identified through the Site to contract, invoice, or receive payment outside the Site. (c) Refer a User you identified on the Site to a third-party who is not a User of the Site for purposes of making or receiving payments off the Site.</li>
   <li>You agree to notify Beyond Smart Cities immediately if a user suggests to you making or receiving payments outside of the Site in violation of this Section. If you are aware of a breach or potential breach of this non-circumvention agreement, please submit a confidential report to Beyond Smart Cities by sending an email message to: <a href="mailto:support@beyondsmartwork.com">support@beyondsmartwork.com.</a></li>
   <li>If Beyond Smart Cities determines, in its sole discretion, that you have violated Section 6.14 and Section 9, Beyond Smart Cities or its Affiliates may, to the maximum extent permitted by law (a) close and suspend your Account and revoke your authorization to use the Site and Services, and/or (b) charge you for all losses and costs (including any and all time of Beyond Smart Cities’s employees) and reasonable expenses (including attorneys’ fees) related to investigating such breach and collecting such fee.</li>
  </ul>
  <li>Order Dispute:</li>
  <p>Order Dispute:For disputes arising between a Buyer and a Seller, you agree to abide by the dispute resolution process that is explained in the Order Dispute Resolution in section 5.16 below.</p>
   <li>Order Dispute Resolution:</li>
   <ul>
       <li>Where an Order in Progress has run into a conflict or a dispute, the Seller and/or Buyer shall report such conflict with detailed reasons and explanations in form of an email to <a href="mailto:support@beyondcities.in"><b>support@beyondcities.in</b></a> Buyer or Seller can also report a dispute or a conflict by clicking on “Terminate Contract” on Beyond Smart Cities Dashboard or Mobile Applications.</li>
     <li>Where the Buyer is not satisfied with Completed Order, Buyer shall report such a problem with detailed reasons and explanations in form of an email to <a href="mailto:support@beyondcities.in"><b>support@beyondcities.in</b></a> within 15 days from the Date of Order Completion.</li>
   <li>Once Beyond Smart Cities received any email regarding a possible dispute or a conflict and/or request for Contract Termination as per clause 6.16.1 and 6.16.2, the respective Order shall be marked as “Disputed Order”.</li>
   <li>Beyond Smart Cities on receiving any Conflict or Dispute Reporting mail whether from the Buyer or the Seller shall initiate a “Dispute Resolution Process” on the Disputed Order.</li>
   <li>Communication to Buyer : </li>
   <p>Beyond Smart Cities shall communicate via emails with the Buyer for seeking details and clarifications on Disputed Order</p>
   <li>Communication to Seller :</li>
   <p>Beyond Smart Cities shall communicate via emails with the Seller for seeking details and clarifications on Disputed Order.</p>
   <li>Tracking Order Files, Communications and Payments :</li>
   <p>Beyond Smart Cities shall track all the communications that happened between the Buyer and the Seller via Beyond Smart Cities Messenger, Chats, Audio and Video Calling, Files shared , Order Milestones tracked and Order payments made including committed Order Deliverables.</p>
  <li>Proposing Mutual Resolution : </li>
  <p>Beyond Smart Cities shall get both the Buyer and the Seller together to discuss a Mutual Resolution. Where a consensus is reached and mutual resolution is decided, both the Buyer and the Seller shall mutually cooperate and execute the mutual resolution agreed upon.</p>
  <li>Buyer Refund: </li>
  <p>Where it is found the Seller has defaulted in Order Completion due to any of the reasons mentioned in this clause, the Buyer is eligible to get a full refund. In a case where a full refund is issued, Beyond Smart Cities will not be responsible to deliver the Order or any work or service covered under the Order for which the refund is issued. Further, the Buyer agrees to not make any further monetary or non monetary claims other than the Refund of Order Amount on the basis of time loss, non delivery of order or for any other reason. The Buyer shall be eligible for a full refund in case below mentioned reasons :</p>
    <ul>
        <li>The Seller did not complete the Order</li>
         <li>The Seller did not complete the Order as per Order Deliverables</li>
         <li>The Seller did not complete the Order in the committed Timeline, unless, the Seller could not completed because of unforeseen circumstances mentioned in clause 5.7.8</li>

    </ul>
    <li>Exception to 6.16.9 Where the Seller could not complete the delivery of work due the below mentioned reasons, the Buyer shall not be eligible for any refund.:</li>
    <ul>
        <li>Changes in Government Policy and Regulations</li>
        <li>Where the delivery of the Order is dependent on Government Authorities</li>
        <li>Due to acts of God, war, terrorism, earthquakes, Curfew, hurricanes, explosions, fire, plagues or epidemics or pandemics</li>
        <li>Such other acts or events beyond the control of the Users</li>
        <li>Where the Buyer opts to not reply to to Order Completion Request initiated by the Seller within 15 days from the Date on which Order Completion Request was initiated. The Buyer Shall reply to Order Completion Request within 15 Days either by marking the Order as completed or by rejecting the Order by writing on the Chat Window given on Website Dashboard and Mobile Application or by using Calling Options given on Website Dashboard and Mobile Application.</li>

    </ul>
  
   </ul>
     <li>Order Proprietary Rights :</li>
     <p>Beyond Smart Cities shall own all right, title and interest including all intellectual property rights in and to the “Order Content” and “Order Deliverables”. Upon receipt of full payment by the Buyer, the rights, title and interest in Content will be assigned to the Buyer by Beyond Smart Cities. The Buyer agrees that, Beyond Smart Cities shall not be liable for matters arising from (a) combination of the Content with any hardware, software, graphics, data, or other materials not provided and approved by the Beyond Smart Cities; or (b) Beyond Smart Cities’s compliance with the Buyer’s specifications, information, designs or requirements; or (c) Buyer’s use of Order Content for a purpose other than specified under this Agreement or for any illegal purpose or (d) Buyer’s failure to comply with Beyond Smart Cities’s instructions mentioned in these Terms.</p>
   <li>Order Confidential Information and Non-Disclosure :</li>
     <ul>
         <li>Order Confidential information shall mean any information disclosed by the Buyer to the Seller and/or Beyond Smart Cities in any form including without limitation information, documents, trade secrets, business plans, designs, Software code, data, policy and process, software, technical/ financial/ marketing/ customer/ business information, specifications, analysis, designs, drawings, data, computer programs, and include information disclosed by third parties at the direction of the Buyer and/or disclosed by Buyer. The Seller and and/or Beyond Smart Cities hereby agrees and acknowledges not to share or to disclose any confidential information to any third party.</li>
        <li>Confidential Information shall however, exclude any information which (i) is/ was publicly known or comes into the public domain; (ii) is received by the Seller and/or Beyond Smart Cities from a third party without breach of this Agreement; (iii) was already in the possession of the Seller and/or Beyond Smart Cities, without confidentiality restrictions, at the time of disclosure by the Buyer; (iv) is permitted for disclosure by the Buyer in writing; (v) independently developed by the Seller and/or Beyond Smart Cities without the use of Confidential Information; (vi) is required to be disclosed by the Seller and/or Beyond Smart Cities pursuant to any order or requirement from court, administrative or governmental agency, provided that the Seller and/or Beyond Smart Cities; shall give the Buyer prompt written notice of such order or requirement and an opportunity to contest or seek an appropriate protective order. The Seller and/or Beyond Smart Cities agrees that disclosure of Confidential Information of the shall be limited by the Seller/or Beyond Smart Cities to Resources/employees of the Sellers and its affiliates, consultants, advisors having a need to know or to conduct any quality audits or training.</li>
        <li>The buyer agrees that the Seller and/or Beyond Smart Cities may use the Buyer's name, logo, designs created, reports developed, content written in proposal, webpage, portfolio and self-promotional matters.</li>
       <li>Notwithstanding the disclosure of any Confidential Information by the Buyer to the Seller and/or Beyond Smart Cities, the Buyer shall retain title thereto and to all intellectual property and proprietary rights therein, and the Seller and/Beyond Smart Cities shall have no rights, by license or otherwise, to use the Confidential Information except as expressly provided herein. The Seller and/or Beyond Smart Cities shall not alter or obliterate any trademark, trademark notice, copyright notice, confidentiality notice or any notice of any other proprietary right of the Buyer on any copy of the Confidential Information, and shall faithfully reproduce any such mark or notice on all copies of such Confidential Information.</li>
      <li>The Seller and/or Beyond Smart Cities shall promptly return all Confidential Information or copies thereof which are in its possession to the Buyer on demand or shall destroy the same in the manner so specified by the Buyer.</li>
     <li>The obligations under this section shall survive for a period of two (2) years post termination or expiration of the Agreement.</li>
      
     </ul>
     <li>Order Invoicing : </li>
     <p>Beyond Smart Cities will raise Order Payments Invoices to the Buyer. On Milestone payments, Beyond Smart Cities will raise Proforma Invoices with GST and will raise a final Invoice or Order Completion. The Buyer can find these invoices in their Beyond Smart Cities Dashboard.</p>
  
   </ul>
   </div>
   </div>
   
      <button type="button" className="collapsible">7 . Promotional Codes and Credits</button>
   <div className="content">
       <div className="smart-list">
        <ul>       
       <li><b>Beyond Smart Cities</b> may, in its sole discretion, create promotional codes that may be redeemed for account credit, or other features or benefits related to a Seller’s Services, subject to the following terms and any additional terms that <b>Beyond Smart Cities</b> establishes on a per promotional code basis (“Promo Codes''). Certain specific terms govern Sellers and payment. From time to time, Buyers may have access to discounts supplied by <b>Beyond Smart Cities</b> which, in part, use <b>Beyond Smart Cities's</b> funds to pay for a portion of the Order Value paid by Buyers to Sellers. Promo codes may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public forum or otherwise), unless expressly permitted by <b>Beyond Smart Cities</b>. Promo Codes have no cash value and may expire or be disabled by <b>Beyond Smart Cities</b> at any time, for any reason, prior to your use. <b>Beyond Smart Cities</b> reserves the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that <b>Beyond Smart Cities</b> determines or believes that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms.</li>
   
   
     </ul>
   </div>
   </div>
      <button type="button" className="collapsible">8 . User Generated Content</button>
   <div className="content">
       <div className="smart-list">
        <ul>   
        <p>Any Buyer, Seller or any other User who has signed up or registered on Beyond Smart Cities can create content “User Generated Content” in form of Community Posts, Profiles including but not limited to work samples, portfolio, images, graphics, videos, documents and links on Beyond Smart Cities.</p>
       <li>You hereby acknowledge and agree that Users publish and allow Beyond Smart Cities to publish on their behalf information on the Site and the Service about the User, such as feedback, composite feedback, geographical location, or verification of identity or credentials. However, such information is based solely on data that Business Professionals or Clients voluntarily submit to Beyond Smart Cities and does not constitute and will not be construed as an introduction, endorsement, or recommendation by Beyond Smart Cities; Beyond Smart Cities provides such information solely for the convenience of Users</li>
      <li>You own your content you create and post, but you allow us certain rights to it, so that we can display and share the content you post. We have the right to remove content if we need to.</li>
      <li>You may create content, written or otherwise, while using the Service (“User-Generated Content”). You are solely responsible for the content of, and any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that content. Any liability, loss or damage that occurs as a result of the use of any User-Generated Content that you make available or access through your use of the Service is solely your responsibility. We are not responsible for any public display or misuse of your User-Generated Content.</li>
     <li>You represent and warrant that you have the right to post all User-Generated Content you submit. Specifically, you warrant that you have fully complied with any third party licenses relating to User-Generated Content, and have taken all steps necessary to pass through to end users any required terms.</li>
    <li>We have the right (though not the obligation) to, in our sole discretion, determine whether or not any User-Generated Content appropriate and complies with these Terms of Service, or refuse or remove any User-Generated Content that, in our reasonable opinion, violates any Beyond Smart Cities policy or is in any way harmful, inappropriate, or objectionable. Beyond Smart Cities further reserves the right to make formatting and edits and change the manner any User-Generated Content is displayed on the Website.</li>
    <li>Except for Content that originates from Beyond Smart Cities, we do not claim ownership of any Content that is transmitted, stored, or processed in your account. You retain all ownership of, control of, and responsibility for User-Generated Content you post. You may control access to your User-Generated Content through settings in your user account.</li> 
     <li>License :</li>
     <p>You Solely allow Beyond Smart Cities to use Content you upload to the Service reasonably without violating any rights you have in it, you grant us the following rights: by posting any Content via the Website, you expressly grant Beyond Smart Cities and our successors a worldwide, sublicenseable, fully-paid and royalty-free, and non-exclusive license to use, reproduce, display, modify, adapt, distribute, and perform the Content in connection with Beyond Smart Cities’s business purpose. This license does not grant Beyond Smart Cities the right to sell User-Generated Content or otherwise distribute it outside of our Website. This license will terminate at the time when the Content is removed from the Website.</p>
     </ul>
   </div>
   </div>
     <button type="button" className="collapsible">9. Non-Circumvention by Buyer and Seller</button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>Both Buyer and Seller hereby agree to exclusively use Beyond Smart Cities’s website and Service to make and receive payments, to do communication and to share files, documents, information, images, graphics and other similar information. You agree not to circumvent the Communication, File Sharing and Payment Methods offered by the Site and the Service. By way of illustration and not in limitation of the foregoing, you agree not to: (a) Submit proposals or solicit parties identified through the Site to contract, hire, work with, or pay outside the Site. (b) Accept proposals or solicit parties identified through the Site to contract, invoice, or receive payment outside the Site. (c) Refer a User you identified on the Site to a third-party who is not a User of the Site for purposes of making or receiving payments off the Site.</li>
   <li>You agree to notify Beyond Smart Cities immediately if a user suggests to you making or receiving payments outside of the Site in violation of this Section. If you are aware of a breach or potential breach of this non-circumvention agreement, please submit a confidential report to Beyond Smart Cities by sending an email message to: <a href="mailto:support@beyondsmartwork.com"><b>support@beyondsmartwork.com.</b></a></li>
   <li>If Beyond Smart Cities determines, in its sole discretion, that you have violated Section 9, Beyond Smart Cities or its Affiliates may, to the maximum extent permitted by law (a) close and suspend your Account and revoke your authorization to use the Site and Services, and/or (b) charge you for all losses and costs (including any and all time of Beyond Smart Cities’s employees) and reasonable expenses (including attorneys’ fees) related to investigating such breach and collecting such fee</li>
    </ul>
   </div>
   </div>
  <button type="button" className="collapsible">10. Respecting Other People's Rights</button>
   <div className="content">
       <div className="smart-list">
        <ul>
       <p>Beyond Smart Cities respects the rights of others. And so should you. You therefore may not use the Services in a manner that :</p>
       <li>violates or infringes someone else's rights of publicity, privacy, copyright, trademark, or other intellectual-property right</li>
<li>bullies, harasses, or intimidates our users</li>
<li>defame our users</li>
<li>spams or solicits our users</li>
<li>You must also respect Beyond Smart Cities  rights. These Terms do not grant you any right to:</li>
<li>use branding, logos, designs, photographs, videos, or any other materials used in our Services</li>
<li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms</li>
<li>Use the Services, any tools provided by the Services, or any content on the Services for any commercial purposes without our consent</li>
<li>In short:</li>
<p>You may not use the Services or the content on the Services in ways that are not authorized by these Terms. Nor may you help anyone else in doing so.</p>

    </ul>
   </div>
   </div>
     <button type="button" className="collapsible">11. Safety </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <p>We try hard to keep our Services a safe place for all users. But we can't guarantee it. That's where you come in. By using the Services, you agree that:</p>
            <li>You will not use the Services for any purpose that is illegal or prohibited in these Terms</li>
            <li>You will not use any robot, spider, crawler, scraper, or other automated means or interface to access the Services or extract other user's information</li>
            <li>You will not use or develop any third-party applications that interact with the Services or other users' content or information without our written consent</li>
            <li>You will not use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services</li>
            <li>You will not use or attempt to use another user's account, username, or password without their permission</li>
            <li>You will not solicit login credentials from another user</li>
            <li>You will not post content that contains pornography, graphic violence, threats, hate speech, or incitements to violence</li>
            <li>You will not upload viruses or other malicious code or otherwise compromise the security of the Services</li>
            <li>You will not attempt to circumvent any content-filtering techniques we employ, or attempt to access areas or features of the Services that you are not authorized to access</li>
            <li>You will not probe, scan, or test the vulnerability of our Services or any system or network</li>
            <li>You will not encourage or promote any activity that violates these Terms</li>
          <p>If you use a service, feature, or functionality that is operated by a third party and made available through our Services (including Services we jointly offer with the third party), each party's terms will govern the respective party's relationship with you. Beyond Smart Cities is not responsible or liable for a third party's terms or actions taken under the third party's terms.</p>
     <p>You (and also any third party for whom you operate an account or activity on the Service) agree to defend (at Beyond Smart Cities 's request), indemnify and hold the Beyond Smart Cities Parties harmless from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable attorney's fees and costs, arising out of or in any way connected with any of the following (including as a result of your direct activities on the Service or those conducted on your behalf): (i) your Content or your access to or use of the Service; (ii) your breach or alleged breach of these Terms of Use; (iii) your violation of any third-party right, including without limitation, any intellectual property right, publicity, confidentiality, property or privacy right; (iv) your violation of any laws, rules, regulations, codes, statutes, ordinances or orders of any governmental and quasi-governmental authorities, including, without limitation, all regulatory, administrative and legislative authorities; or (v) any misrepresentation made by you. You will cooperate as fully required by Beyond Smart Cities in the defense of any claim. Beyond Smart Cities reserves the right to assume the exclusive defense and control of any matter subject to indemnification by you, and you will not in any event settle any claim without the prior written consent of Beyond Smart Cities.</p>
    </ul>
   </div>
   </div>
    <button type="button" className="collapsible">12. Disclaimer of Warranties </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>THE SERVICE, INCLUDING, WITHOUT LIMITATION, ON BEYOND SMART CITIES , IS PROVIDED ON AN "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS" BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER BEYOND SMART CITIES NOR ITS AFFILIATE COMPANY NOR ANY OF THEIR EMPLOYEES, MANAGERS, OFFICERS OR AGENTS (COLLECTIVELY, THE " BEYOND SMART CITIES PARTIES") MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: (A) THE SERVICE; (B) THE BEYOND SMART CITIES CONTENT; (C) USER CONTENT; OR (D) SECURITY ASSOCIATED WITH THE TRANSMISSION OF INFORMATION TO BEYOND SMART CITIES OR VIA THE SERVICE. IN ADDITION, THE BEYOND SMART CITIES PARTIES HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.</li>
           <li>THE BEYOND SMART CITIES PARTIES DO NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE SERVICE OR THE SERVER THAT MAKES THE SERVICE AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES. THE BEYOND SMART CITIES PARTIES DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE SERVICE IS ACCURATE, COMPLETE, OR USEFUL. YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE BEYOND SMART CITIES PARTIES DO NOT WARRANT THAT YOUR USE OF THE SERVICE IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND THE BEYOND SMART CITIES PARTIES SPECIFICALLY DISCLAIM SUCH WARRANTIES. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU TO THE EXTENT SUCH JURISDICTION'S LAW IS APPLICABLE TO YOU AND THESE TERMS OF USE.</li>
            <li>BY ACCESSING OR USING THE SERVICE YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SERVICE. THE BEYOND SMART CITIES PARTIES DO NOT ENDORSE CONTENT AND SPECIFICALLY DISCLAIM ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER BASED UPON OR RESULTING FROM ANY CONTENT.</li>
            </ul>
   </div>
   </div>
    <button type="button" className="collapsible">13. Limitation of Liability </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>TO THE MAXIMUM EXTENT PERMITTED BY LAW, BEYOND SMART CITIES AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF BEYOND SMART CITIESHAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL BEYOND SMART CITIES'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE GREATER OF 1000 Rs OR THE AMOUNT YOU PAID BEYOND SMART CITIES, IF ANY, IN THE LAST 12 MONTHS</li>
             </ul>
   </div>
   </div>
    <button type="button" className="collapsible">14. Refunds and Cancellations </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>Questions about refunds and cancellations should be addressed to <a href="mailto:support@beyondsmartwork.com"><b>support@beyondsmartwork.com</b></a></li>
     </ul>
   </div>
   </div>
    <button type="button" className="collapsible">15. Time Limitation on Claims </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>You agree that any claim you may have arising out of or related to your relationship with Beyond Smart Cities must be filed within one year after such claim arose; otherwise, your claim is permanently barred.</li>
       </ul>
   </div>
   </div>
    <button type="button" className="collapsible">16. Waiver and Severability </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>The failure of Beyond Smart Cities to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in full force and effect.</li>
             </ul>
   </div>
   </div>
     <button type="button" className="collapsible">17. Applicable Law and Jurisdiction </button>
   <div className="content">
       <div className="smart-list">
        <ul>
            <li>Beyond Smart Cities services is controlled by Beyond Smart Cities Private Limited from our offices located in the state of Karnataka, Bengaluru, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of New Delhi, India by accessing our website, you agree that the statutes and laws of Bengaluru, India without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any products or services through this site.</li>
            <li>Furthermore, any action to enforce these Terms shall be brought in the federal or state courts located in Bengaluru, Karnataka, India. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.</li>
            </ul>
   </div>
   </div>
            
   
   
   
   
   
   
   
   
   
   
   
   
   </div>
    </div>
    </div>
    <div className="row  mb-4">
        <div className="col-md-12">
            <div className="contact">
              <button type="button mb-4"> <Link to="/contact">Contact</Link></button>
              <p><b>Beyond Smart Cities</b> welcomes comments, questions, concerns, or suggestions. You can send us feedback at <a href="mailto:support@beyondsmartcities.in"><b>support@beyondsmartcities.in</b></a></p>
            </div>
            
        </div>
        
    </div>
    </div>
    </div>


            </div>
        )
    }
}

import { SET_SUCCESS} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SUCCESS:
      return { success: payload };

    // case CLEAR_MESSAGE:
    //   return { message: "" };

    default:
      return state;
  }
}
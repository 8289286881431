import React, { Component } from 'react'

export default class GreenBuildingTools extends Component {
    render() {
        return (
         <div>
             
      
                 <div  className="Nature-banner inner-page green">
         
         <div className="green-building">
     
        
               
                   
                     <div className="container">
                           <div className="row mt-4 text-center">
                            <h2 className="section-title text-center heroText1 c1 mt-4 ">Green Building Tools</h2>
                              <p className='heroText2 c1'>Committed to supporting all BSAS projects in their pursuit of <br/>certification, recertif ication and beyond.</p>
  
    </div>
                  
               
          
          
                 
          </div> 
          </div>


    
 <div className="container mb-4">
               
           <div className="row">
       <div className="col-md-12 text-center">
           <h2 className="section-title p-tb-20 text-center heroText1a mt-4 py-4">Value of BSAS Rating System</h2>
          
           
           <p className='heroText2 px-md-5 px-2'>BSAS is the world’s leading green building project and performance management system. It delivers a comprehensive f ramework for green building design, construction, operations and performance.</p>
      </div>
</div>
         <div className="row">
       <div className="col-md-12">   
<h2 className="section-title p-tb-20 text-center heroText1a mt-5">How BSAS Works?</h2>
<h4 style={{fontSize:20,fontWeight:800,marginBottom:20}}>Certification includes a five simple steps: </h4>
<ul className='heroText2'>
    <li>Determine your project type (new or existing space, building, or city or community).</li>
    <li>Review the rating systems.  </li>
    <li>Select priorities for your project based on outcomes outlined in the BSAS rating system (ex. human health, energy eff iciency, carbon reduction, etc.). </li>
    <li>Review priorities in the credit library. </li>
    <li>Review the tools and resources available for BSAS projects.</li>
    <li>Access tools. Document achievement of rating system requirements and credits.</li>
    <li>Go to BSAS Online Process or view forms.  Submit for review in BSAS Online Process</li>

</ul>
 </div>
</div>


     <div className="row text-center">
       
          <h2 className="section-title p-tb-20 text-center heroText1a mt-4"> BSAS performance score</h2>
          <p className='heroText2'><b>Certification is just the beginning—once certified, track your performance data to ensure that you are getting the largest possible benefit and to communicate successes to your family and others.</b></p>
        <h2 className="section-title p-tb-20 text-center heroText1a mt-4">Access project tools</h2>
<div className="col-md-4 ">
          <div className="green-rating">
              <div className="card">
                  <h5 className='heroText1b mt-3'>BSAS credit library</h5>
                  <p className='heroText2a mt-3'>The building blocks of BSAS are the credits, and they’re all housed within our BSAS credit library. Here you’ll f ind credit language, information f rom the reference guides and supplemental resources.</p>
              <div className="text-center">
                       
                     </div>
              </div>
          </div>
           </div>
           <div className="col-md-4 ">
                 <div className="green-rating">
              <div className="card">
                  <h5 className='heroText1b mt-3'>Addenda database</h5>
                  <p className='heroText2a mt-3'>Addenda incorporate changes and improvements to BSAS rating systems and resources to help clarify, correct, interpret or provide alternative language helping project teams better implement BSAS.</p>
                      <div className="text-center">
                       
                     </div>
              </div>
          </div>
           </div>
           <div className="col-md-4 ">
                  <div className="green-rating">
              <div className="card">
                  <h5 className='heroText1b mt-3'>Guides to Certification</h5>
                  <p className='heroText2a mt-3'>Understand the steps to pursuing BSAS certif ication including the current policies, pricing and deadlines for each of the rating systems.</p>
                     <div className="text-center">
                       
                     </div>
                    
              </div>
          </div>
           </div>
</div>
  
           
           
           
           
           
                
                 
               
                 
                 
</div>
</div>
</div>
           
        )
    }
}

import React, { Component } from 'react'

export default class Articles extends Component {
    render() {
        return (
            <div>
                  <div  className="Nature-banner inner-page">
   
          
   <div className="container">
       

   <div className="row articles">
       
  
   <div className="col-md-4 articles-btn">
       
<div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
<button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Examples of Profile Bio and Service Descriptions</button>
<button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">How to Complete smart work Profile</button>
<button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Completing Jobs & working on Beyond Smart Cities</button>
<button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Beyond  Smart  Cities  Profile  verification  and Approval Process </button>

<img src="assets/images/hire-2.jpg" alt="" className="img-fluid" />
</div>
</div>
<div className="col-md-8">
<div className="tab-content" id="v-pills-tabContent">
<div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
  <h2 className="section-title p-tb-20 text-center"> Examples of Profile Bio and Service Descriptions</h2>

  <p>Some really good examples of Profile Bio: Profile Bio is part of Brief Bio in your profile.</p>
  <p>“We deal with Architectural Building Design, Building Performance Engineering and Green Building CertificaDon includes the Indian Green Building Council (IGBC), Green Buildings Rating System (GRIHA), Leadership in Energy and Environmental Design(LEED). We provide innovaDve, ecological effective and economic solutions to all sorts of building performance services."</p>
  <p>" I am expert as a consultant, manger, researcher and academician for 20+ years in environmental sciences and geological projects. I am an impeccable soluDon provider in consultaDon projects related  to  sustainability,  consent  management,  compliance  evaluator  on  environmental  laws  regulaDon,  Ground  Water  quality  and  assessment,  Environmental  Impact  Assessment  (EIA), Environment Management Plan (EMP), Ground water recharge techniques, Land reclamation and remediation, Watershed hydrology models: solute transport, sediment transport, Water quality analysis through chemical and isotopic analysis, proposal writing, Report generation and DPR preparation to developmental and research projects. "</p>
 <h4>Examples of Particular Product and Service Descriptions:</h4>
 <p>You have to write description for each service you provide on Beyond Smart Cities.<br/>Start with a question, Write about your experience for that particular service and menDon how will you approach this work.</p>
<h4>Let's see some examples</h4>
<h4>Green Building Design:</h4>
<p>" We specialize in designing high performance, energy- and resource-efficient buildings, tailored to client objectives and to the needs and well-being of occupants, and with a special focus on minimizing their environmental and ecological impact. "</p>
<h4>Lighting Design:</h4>
<p>“Well-designed lighDng can enhance all aspects of the human experience. Not only does it provide an understanding of form, space and material but it also affects our senses, our emotions, our health and well- being. At WSP, we provide creaDve, energy-efficient lighting solutions for all types of projects. Our well-planned lighting schemes are fully integrated with the architecture, provide visual comfort and are adapted to the needs of the end-user while creating spaces that are inspiring and responsive."</p>
<h4>Intelligent Transportation Systems :</h4>
<p>" Intelligent TransportaDon Systems (ITS) is a combination of leading-edge information and communication technologies used in transportation and traffic management systems to improve the safety, efficiency, and sustainability of transportation networks, to reduce traffic congestion and to enhance drivers’ experiences. The possibilities are endless.</p>
<h4>Environment Consultancy:</h4>
<p>" We provide a full range of due diligence services for vendors and purchasers ranging from pre-divestment reviews, Phase I and II invesDgations, thorough to rapid data room reviews, hazardous materials / asbestos assessments, energy consultancy, specialty technical due diligence, integrated building surveys, post-acquisition / merger integration support, and more.<br/>In addition to our EHS specialists, we offer industrial process and infrastructure expertise and can provide technical evaluations of technologies that can be applied to various industries, including the renewable energy and waste sectors. "</p>

<h4>Strategic Environmental Assessment:</h4>
<p>"  Strategic  Environmental  Assessment  (SEA)  and  Sustainability  Appraisal  (SA)  services  support  effective  and  efficient  decision-making,  ensuring  that  sustainable  development  and  other environmental considerations have been taken into account in policy, program and business planning. Our specialist teams’ technical capabilities also ensure compliance with applicable laws and regulations."</p>


</div>
<div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">

 <h2 className="section-title p-tb-20 text-center">How to Complete smart work Profile</h2>
<p>It’s not enough to just enroll with Beyond Smart Cities or just sign up with it. You need to create a strong, convincing, aaracDve and a rich profile in order to get verified and approved on Beyond Smart Cities. Below are some simple Dps to make your Beyond Smart Cities profile promising:</p>
 <h4>Step 1 - Sign up and enter your Dashboard:</h4>
<p>Click on “Register as an Specialist” on the top right hand corner of the website and fill in your details to sign up. Once you sign up, you will enter your dashboard. You can on click on “Update Profile Buaon” or Click on “Profile” given at right hand side. The moment you click, the below screen appears. This screen contains all profile informaDon fields  you  will  have  to fill  to  get  your  profile  verified  and  approved  to  start  making  business  on  s  Beyond  Smart  Cities.  We  will  now  cover  profile informaDon one by one below.</p>
<h4>Step2 - Basic Details - Fill & Verify</h4>
<p>In the basic details window, you’d see the following things: Profile picture, Name, Email Address, Phone number, Work Address, Languages, PAN number. All these fields are mandatory including the profile picture. Upload a neat and a professional picture of yours, verify your email address and mobile number using otp.</p>
<h4>Step3 - Business Details</h4>
<ul>
   <li>As the name suggests, the business details window consists of everything related to your business.</li>
   <li>Your Profession is pre filled from the profession you selected at the Dme of sign up</li>
   <li>Type your Business Firm Name or Your name if you’re a freelancer</li>
   <li>Select type of enDty - Pvt. Ltd, LLP, Partnership or Freelancing</li>
   <li>Enter Membership or Enrolment ID - Only for green building consultants  & cerDfied energy managers and auditors</li>
    <li>Enter GST number if applicable</li>
   
   </ul>
<h4>Step4 - Work Experience & Education</h4>
<p>In this popup:</p>
<ul>
   <li>you enter your work experience in years.</li>
    <li>College  EducaDon:  If  you  are  graduated,  then  you  add  your  educational  qualificaDon,  this  includes  your  University/College,  Degree/Certification  and  year  of passing out. You can click on the “make public” box for the qualification you want people to see in your public Profile.</li>
   <li>Online Courses/certifications: If you have completed any online course or certificaDon, you can add the details.</li>
 
</ul>
<h4>Step5 - Products & Services</h4>
<p>For green building consultants, energy engineers and Compliance professionals</p>
  <p>ExperDse Areas are the most important part of your profile. Your job feed is automaDcally customised to show jobs according to experDse areas selected by you. If you have not selected a parDcular experDse area, you would not get noDficaDons of any job/leads related to that experDse area.</p>
 <p>Each Service Category contains fixed priced services along with the scope of work needed to complete that service. If you are willing to provide these services at fixed prices, all you have to do is agree on prices menDoned. This way we assign you direct jobs/leads/projects who book fixed price services from smart work’s website or mobile app.</p>
<p>For Building Designers, buiding design  Experts, Developers, Content & markeDng Experts</p>
<p>This is where you can create services. On the lee side you can see different service categories each containing its own services.</p>
<p>You just have to click on the service, fill the one liner starDng with “I will ...”, put brief descripDon of the servcie, set prices and delivery Dme, upload your samples, provide porgolio links.</p>
<h4>Step6 - Brief Biography</h4>
<p>WriDng a convincing Bio is another really important informaDon that affects how your profile is judged by PotenDal Clients and affects your chances of gehng hired on Smartwork. This includes:</p>
<p>CapDon under your name: This capDon will be shown under your name on your public profile. This includes 2-3 words which can represent you. Example, Experienced Green building Designer, Senior sustainability Consultant, energy Consultant etc.</p>
<p>Biography: This is like a linkedin Bio. This is a detailed descripDon of you as a professional. A good biography shall include Your name, your skills, your experience, how many clients have you helped, why people should hire you, how you approach you work, your response Dme and other aspects like your response Dme etc.</p>

<h4>Stepti - Verification Documents</h4>
<p>Verification documents are important to verify your identity, your education, basically, everything you say about yourself. Upload all your personal ID - Driving License, Voter ID, Aadhar or PAN Card. Upload your educational qualificaDon proof. Upload address proof - License, Voter ID or any govt utility bill. Beyond Smart Cities strictly adheres to identity verification and would not approve any profile that looks fake.</p>
<p>So, this was a complete guide on making a promising Beyond Smart Cities Profile. We hope you sign up and complete your profile. Our team takes 24-ti2 hours to verify  and  approve  your  profile.  If  you  have  any  further  doubts,  you  can  ask  us  on  the  support  buaon  given  at  the  boaom  right-hand  corner  or  mail  us  at <a href="mailto:support@beyondsmartcities.in.">support@beyondsmartcities.in.</a></p>











</div>
<div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
   <h2 className="section-title p-tb-20 text-center">Completing Jobs & working on Beyond Smart Cities</h2>
   <p>Beyond Smart Cities scrutinises every Expert profile very carefully. On average it takes about 2-4 weeks to verify and approve an Expert Profile. 
Smartwork follows the below process to verify and approve an Expert profile:</p>
<h4>1. Completion of all Profile Information:</h4>
<p>The first step is to complete your Expert Profile on Beyond Smart Cities. The profile consists of different information as mentioned in the screenshot 
below. All the mandatory information needs to be completed so that profile is eligible to be considered for verification and approval.</p>
<p>You can see this Profile Completion Tutorial or read our article on how to complete your Beyond Smart Cities Profile.</p>
<h4>2. Profile Quality Check up and KYC Check:</h4>
 <ul>
        <li>KYC Documents verification</li>
    <li>Whether Brief Bio is written properly</li>
   <li>Whether samples uploaded for gigs are desired quality</li>
   <li>Whether gig description is written properly</li>
    <li>Enter GST number if applicable</li>
   <li>See best examples of brief bio and services samples to make your profile approval easy, quick and hassle free.</li>
  </ul>
<h4>3. Training, Questionnaire and interview:</h4>
<p>Once the quality check and KYC verification is done, next is training through video tutorials, FAQs, and Articles. Post training there, there is a 
questionnaire and an interview based on training and profile information.</p>
<p>Once the questionnaire is submitted and the interview is completed, Beyond Smart Cities whether to approve or hold the Expert profile.</p>
<p>When your profile is approved, you get an emailer of profile approval. In case the profile could not be approved, we send you an email stating reasons 
due to which we could not approve the profile, once the reasons are resolved the profile is considered for approval again.</p>
<p>For any doubts and queries, you can email us at <a href="mailto:support@beyondsmartcities.in">support@beyondsmartcities.in</a></p> 
</div>
<div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
  <h2 className="section-title p-tb-20 text-center">Beyond Smart Cities Profile verification and Approval Process </h2> 
  <p>Beyond Smart Cities scrutinises every Expert profile very carefully. On average it takes about 2-4 weeks to verify and approve an Expert Profile. Smartwork 
follows the below process to verify and approve an Expert profile:</p>
<h4>1. Completion of all Profile Information:</h4>
<p>The first step is to complete your Expert Profile on S Beyond Smart Cities The profile consists of different information as mentioned in the screenshot below. 
All the mandatory information needs to be completed so that profile is eligible to be considered for verification and approval.</p>
<p>You can see this Profile Completion Tutorial or read our article on how to complete your Beyond Smart Cities Profile.</p>
<h4>2. Profile Quality Checkup and KYC Check:</h4>
<ul>
<li>KYC Documents verification</li>
<li>Whether Brief Bio is written properly</li>
<li>Whether samples uploaded for gigs are desired quality</li>
<li>Whether gig description is written properly</li>
<li>Enter GST number if applicable</li>
<li>See best examples of brief bio and services samples to make your profile approval easy, quick and hassle free.</li>
</ul>
<h4>3. Training, Questionnaire and interview:</h4>  
<p>Once the quality check and KYC verification is done, next is training through video tutorials, FAQs, and Articles. Post training there, there is a questionnaire and 
an interview based on training and profile information.</p>
<p>Once the questionnaire is submitted and the interview is completed, Beyond Smart Cities then decides whether to approve or hold the Expert profile.</p>

<p>When your profile is approved, you get an emailer of profile approval. In case the profile could not be approved, we send you an email stating reasons due to 
which we could not approve the profile, once the reasons are resolved the profile is considered for approval again.</p>
<p>For any doubts and queries, you can email us at <a href="mailto:support@beyondsmartcities.in">support@beyondsmartcities.in</a></p>



</div>
</div>
</div>
 
</div>
</div>
            </div>
       
       </div>
        )
    }
}

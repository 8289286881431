import React, {useEffect, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FormTopbar from '../SubmitProduct/FormTopbar'

export const UploadProductImages = ({
  prevStep,
  nextStep,  
  handleChangeProductImages,
  values,
  addClick,
  removeClick,
  ImageUploading,
  maxNumber,
  onChangeImage,
  step
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  useEffect(() => {
    window.scrollTo(0, 0)   
  }, [])

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
         

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                <Row>
                      <FormTopbar step={step} />
                    </Row>
                  <Container>                 

                    <Form>
                      <Form.Group>
                        <Form.Label>Upload Product Images</Form.Label>
                        <Form.Text className="text-muted">
                        <p>Maximum 4 images</p>
                        </Form.Text>{" "}
                      </Form.Group>

                      <Row className="align-item-center justify-content-center ">
                        <Col>
                          <div className="App">
                            <ImageUploading
                              multiple
                              value={values}
                              onChange={handleChangeProductImages}
                              maxNumber={maxNumber}
                              dataURLKey="data_url"
                              acceptType={['jpg', 'png']}
                            >




                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors,
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">

                            {errors && (
                              <div className="py-2">
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allowed
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution
                                  </span>
                                )}
                              </div>
                            )
                                }   

                                  <Button
                                  className="ImageDropArea position-relative w-100 bg-4 bdr-dotted"
                                    onKeyPress={(e) => {
                                      e.preventDefault();
                                    }}
                                    style={
                                      isDragging ? { color: "red" } : undefined
                                    }
                                    onClick={onImageUpload}
                                    {...dragProps}
                                  >
                                    <div className="text-dark bg-4 my-4">Click or Drop here</div>
                                    <div>
                                    <Row className="bg-4">
                                    {imageList.map((image, index) => (                                     
                                    <div key={index} className="col-md-2 drop-image-item bg-4 mt-3">
                                      <img
                                        src={image["data_url"]}
                                        alt=""
                                        width="100"
                                      />
                                      <div className="image-item__btn-wrapper">
                                        <a
                                        className="delete-button"
                                          onKeyPress={(e) => {
                                            e.preventDefault();
                                          }}
                                          onClick={() => onImageUpdate(index)}
                                        >
                                          Update
                                        </a>
                                        <a
                                         className="delete-button"
                                          onKeyPress={(e) => {
                                            e.preventDefault();
                                          }}
                                          onClick={() => onImageRemove(index)}
                                        >
                                          Remove
                                        </a>
                                      </div>
                                    </div>
                                    
                                  ))}
                                  </Row>
                                    </div>





                                    
                                  </Button>
                                  &nbsp;
                                  <Link
                                  className="mt-3 float-start"
                                    onKeyPress={(e) => {
                                      e.preventDefault();
                                    }}
                                    onClick={onImageRemoveAll}
                                  >
                                    Remove all images
                                  </Link>
                           
                                </div>
                              )}

 

                            </ImageUploading>
                          </div>
                        </Col>
                      </Row>


                      
<Row>
                        <Col>
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            errors,
                          }) =>
                            errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution
                                  </span>
                                )}
                              </div>
                            )
                          }
                        </Col>
                      </Row>

                    

                      <Row className="text-center my-3">
                        <Col>
                          <Button
                            onClick={Previous}
                            type="submit"
                            className="btn btn-primary-trans btn-block me-2"
                          >
                            Previous
                          </Button>

                          <Button
                            onClick={Continue}
                            type="submit"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={false}
                            className="btn btn-primary btn-block c1"
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

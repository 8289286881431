import React from "react";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Confirmation = ({
  prevStep,
  nextStep,
  values,
  submitData,
  handleRegister,
}) => {
  console.log(values);


   let user= JSON.parse(localStorage.getItem('user'))

 

  


  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <section className="lightgrey-bg user-area log-in-area">
      <Container component="main" maxWidth="xs" className="p-2">
        <div className="user-form-content">
          <div className="row">
            <div className="col-md-12">
              <div className="form-card mt-3">
              <Row className="text-center my-3">
              <h2 className="mb-4">Submit Your Information</h2>
              <p>I <span style={{textDecoration:'underline'}}>{user.firstName}</span> hereby declare that the information furnished here is true, complete
and correct to the best of my knowledge and belief.</p>
                  <Col>
                    <Button
                      onClick={Previous}
                      type="submit"
                      className="btn btn-primary-trans btn-block me-2"
                    >
                      Previous
                    </Button>

                    <Button                      
                      type="submit"
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onClick={handleRegister}
                      disabled={false}
                      className="btn btn-primary btn-block c1"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Row className="p-4">
                <h2 className="mb-4">Preview Your Information</h2>
                  <Col>
                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Company Name</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.nameOfCompany}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Company Website</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.companyWebsite}
                      </div>
                    </div>

                    <h3>Address Information</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Street Name</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.address.street}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">City</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.address.city}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">State</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.address.state}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Country</div>
                      <div className="formConfirmValue">
                        {values.applicantInfo.address.country}
                      </div>
                    </div>
                  </Col>
                  <Col>
                  <h3>Contact Person Information</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Title</div>
                      <div className="formConfirmValue">
                        {values.contactPerson.title}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Name</div>
                      <div className="formConfirmValue">
                        {values.contactPerson.name}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Email</div>
                      <div className="formConfirmValue">
                        {values.contactPerson.email}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Mobile</div>
                      <div className="formConfirmValue">
                        {values.contactPerson.mobileNumber}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Designation</div>
                      <div className="formConfirmValue">
                        {values.contactPerson.designation}
                      </div>
                    </div>
                  </Col>

                  <Col>
                    <h3>Alternate Contact Person Information</h3>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Title</div>
                      <div className="formConfirmValue">
                        {values.alternateContactPerson.title}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Name</div>
                      <div className="formConfirmValue">
                        {values.alternateContactPerson.name}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Email</div>
                      <div className="formConfirmValue">
                        {values.alternateContactPerson.email}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Mobile</div>
                      <div className="formConfirmValue">
                        {values.alternateContactPerson.mobileNumber}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Designation</div>
                      <div className="formConfirmValue">
                        {values.alternateContactPerson.designation}
                      </div>
                    </div>
                  </Col>
                </Row>

              
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Confirmation;

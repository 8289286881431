import React, { Component } from "react";
import { Link } from "react-router-dom";
import CourseLinks from "./Components/CourseLinks";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { BiCheck, BiRupee } from "react-icons/bi";
import { connect } from "react-redux";
import { FaFileDownload } from "react-icons/fa";

export default class KnowledgeLab extends Component {

  constructor(props) {
    super(props);
    this.state={     
      courseData:'',     
      errorMessage: "",
      loading: true,
      next:''
       }
   
  }

  componentDidMount() {

   // this.getCourseInformation("https://www.udemy.com/api-2.0/courses/");    
  }

  async getCourseInformation(url) {
    var self = this;
    self.setState({
      loading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(url , {
        headers: {         
          Accept: "application/json, text/plain, */*",          
          Authorization: "Basic NTJ1blpmSDdQTEhWTEpkbndPaGJFVzMyTm9hSE1hY3ZUcUdQVVJvWjpJZVhMSFg1anhDbFh0RzVmVkVERUZHNzVrRW8yMWZSZm9ySVNtY1BaUXhMbDNLb3AxNTVLeElpMTdWQ0U2NVlraEVIMFpoSDRPaUllR1Frbzd1bFRmRDZqekdXMFZVaXJWNUR6dDNOdnlRUkN4VjFlWEp0S010aDV4ZTFxT2RLSg==",
         
        },
      })

      .then(
        function (response) {
          console.log('RRR',response);

          if (response) {
            self.setState({
              courseData:response.data.results,
              loading: false,
              next:response.data.next
            }); 
           
          } else {
            self.setState({
              errorMessage: response.data.error,             
              loading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async goTonextPage(url){
    this.getCourseInformation(url)
  }
  
  render() {
    return (
      <div>
        <div class="Nature-banner inner-page">
          <div class="Knowledge-lab">
            <div class="patner-trs">
              <div class="container">
                <div class="row mt-4 text-center px-md-5">
                  <h2 class="text-center heroText1b c1 px-1 px-md-5">
                    Get Specialized in many areas ranging from Sustainability,
                    Energy, Environment And Commissioning, All the way to
                    upgrade your life and everything in-between.
                  </h2>
                  <div class="solidBtn mt-3 ms-3">
                    <a target="_blank"  class="fw-600 c13" href="https://www.udemy.com/user/krishnaji-7/">
                      Get Specialization Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        {/* <div id="courses">
          <CourseLinks  />
          </div> */}

          <div class="container mb-4  lab-content">
            <h2 className="heroText1a my-4">Latest Courses</h2>
            <div class="row mb-3 course-thumbnail">
               <h2 className="py-4"><span className="c2">Courses on </span>Commissioning Specialist Curriculum </h2>           
              <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/the-commissioning-process/?referralCode=E98305B99E29A92107E0">
                  <img
                    src="https://images.pexels.com/photos/34092/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">Introduction to Commissioning</h3> 
                  </div>
                  </a>
                </div>
              </div>  

                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/the-commissioning-process/?referralCode=E98305B99E29A92107E0">
                  <img
                    src="https://images.pexels.com/photos/34112/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">The Commissioning Process</h3> 
                  </div>
                  </a>
                </div>
              </div>   

              
                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/commissioning-leed-green-buildings/?referralCode=D4F6A17A36DF2D6D5AA9">
                  <img
                    src="https://images.pexels.com/photos/6069368/pexels-photo-6069368.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">Commissioning LEED Green Buildings</h3> 
                  </div>
                  </a>
                </div>
              </div>       


                 <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/certified-commissioning-professional-exam-question-bank/?referralCode=60E1CDD8CB5CB2856F23">
                  <img
                    src="https://images.pexels.com/photos/344328/pexels-photo-344328.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">Certified Commissioning Professional Exam - Question Bank</h3> 
                  </div>
                  </a>
                </div>
              </div>                        


            </div>           
          </div>



          <div class="container mb-4  lab-content">         
            <div class="row mb-3 course-thumbnail">
               <h2 className="py-4"><span className="c2">Courses on </span>Sustainability Specialist Curriculum </h2>           
              <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/gsas-implementation-guide-for-design-practitioners/?referralCode=301C42C24E376F58E9E1">
                  <img
                    src="https://images.pexels.com/photos/5699475/pexels-photo-5699475.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">GSAS Implementation Guide for Design Practitioners</h3> 
                  </div>
                  </a>
                </div>
              </div>  

                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/gsas-implementation-guide-for-construction-practitioners/?referralCode=09B6AEC9C957A6F01F4D">
                  <img
                    src="https://images.pexels.com/photos/691637/pexels-photo-691637.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">GSAS Implementation Guide for Construction Practitioners</h3> 
                  </div>
                  </a>
                </div>
              </div>   

              
                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/gsas-certified-green-professional-cgp-exam-question-bank/?
referralCode=E03D87C70FA209A711B0">
                  <img
                    src="https://images.pexels.com/photos/6069368/pexels-photo-6069368.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">GSAS Certified Green Professional - CGP Exam Question Bank</h3> 
                  </div>
                  </a>
                </div>
              </div> 
                
            </div>           
          </div>




          <div class="container mb-4  lab-content">         
            <div class="row mb-3 course-thumbnail">
               <h2 className="py-4"><span className="c2">Courses on </span>LEED Perspective</h2>           
              <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/leed-implementation-guide-for-construction-professionals/?referralCode=BDA0F1E81C03EE2B049B">
                  <img
                    src="https://images.pexels.com/photos/7412075/pexels-photo-7412075.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">LEED Implementation Guide for Construction Professionals</h3> 
                  </div>
                  </a>
                </div>
              </div>  

                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/gsas-implementation-guide-for-construction-practitioners/?referralCode=09B6AEC9C957A6F01F4D">
                  <img
                    src="https://images.pexels.com/photos/691637/pexels-photo-691637.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">LEED Implementation Guide for Design Professionals(Coming Soon)</h3> 
                  </div>
                  </a>
                </div>
              </div>   

              
                <div class="col-md-3">
                <div class="card-r">
                <a target="_blank"   href="https://www.udemy.com/course/leed-ap-exam-question-bank/?referralCode=02BF3E35EA8414109352">
                  <img
                    src="https://images.pexels.com/photos/1098515/pexels-photo-1098515.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                    alt="smartcity"
                  />
                  <div class="lab-card-content">
                    <h3 className="mb-0 pb-0">LEED AP(BD+C) Exam Question Bank</h3> 
                  </div>
                  </a>
                </div>
              </div> 
                
            </div>           
          </div>


          
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react'
import { Accordion } from 'react-bootstrap'

export default class SpecialistLevelSystem extends Component {
    render() {
        return (
          <div>
            <div className="Nature-banner inner-page">
              <div className="row bsw-layout-banner">
                <div className="col-md-12">
                  <div className="bsw-layout-content">
                    <h1>
                      Specialists level at <br />
                      Beyond Smart Cities
                    </h1>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row mb-4 text-center">
                  <div className="col-md-12">
                    <h2 className="section-title p-tb-20 text-center heroText1a">
                      What are Specialists levels?
                    </h2>

                    <div className="title-divider"></div>

                    <p>
                      Great customer satisfaction, on-time delivery and high
                      quality service will earn you a new Beyond Smart Cities
                      Prime status and benef its. We evaluate your performance,
                      experience and Certif ication month, and determine your
                      rating accordingly.
                    </p>
                  </div>
                </div>

                <div className="row bmd-layout pt-3">
                  <div className="col-md-2">
                    <img
                      src="assets/images/icons/NewSpecialist.png"
                      alt="icon"
                    />
                  </div>
                  <div className="col-md-4">
                    <h4 className="heroText2 pt-3">New Specialist</h4>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Once you join Beyond Smart Cities create a service, you
                      are automatically labeled as a New Specialist . In order
                      to start climbing up the level system, we encourage you to
                      work hard and play fair.
                    </p>
                    <h5 className="heroText2 pt-3">Benefits</h5>
                    <ul>
                      <li>5 Active Services</li>
                      <li>2 Service extras (Rs 8000, Rs 16000, Rs 32000)</li>
                      <li>
                        5 Service Multiples Send Custom Offers, up to Rs 30,000
                      </li>
                      <li>Earning clearance: 14 days</li>
                    </ul>
                  </div>
                </div>

                <div className="row bmd-layout pt-3">
                  <div className="col-md-2">
                    <img
                      src="assets/images/icons/Level-Gold-Specialist (1).png"
                      alt="icon"
                    />
                  </div>
                  <div className="col-md-4">
                    <h4 className="heroText2 pt-3">Level Gold SpecialistPro</h4>
                  </div>
                  <div className="col-md-6">
                    <h5 className="heroText2 pt-3">Requirements</h5>
                    <ul>
                      <li>
                        Complete at least 60 days as an active Seller on beyond
                        smart work
                      </li>
                      <li>
                        Complete at least 8 individual orders (all time) Earn at
                        least Rs 21,000
                      </li>
                      <li>
                        Maintain a 4.1 star rating over the course of 60 days
                      </li>
                      <li>90% Response rate over the course of 60 days</li>
                      <li>90% Order completion over the course of 60 days</li>
                      <li>90% On-time Delivery over the course of 60 days</li>
                      <li>
                        Avoiding receiving warnings over the course of 30 days
                      </li>
                    </ul>

                    <h5 className="heroText2 pt-3">Overview</h5>
                    <p>
                      This elite group of Specialists enjoy a growing number of
                      exclusive benefits, as they continue on providing Buyers
                      with an overall excellent experience. Please note that
                      this is a manual process - once you meet the following
                      requirements, we will look back on your performance. Once
                      our team confirms your eligibility, you’ll be ranked as a
                      platinum Specialist Pro.
                    </p>
                  </div>
                </div>
                <div className="row bmd-layout pt-3">
                  <div className="col-md-2">
                    <img
                      src="assets/images/icons/Level-Platinum-Specialist.png"
                      alt="icon"
                    />
                  </div>
                  <div className="col-md-4">
                    <h4 className="heroText2 pt-3">
                      Level Platinum SpecialistPro
                    </h4>
                  </div>
                  <div className="col-md-6">
                    <h5 className="heroText2 pt-3">Requirements</h5>
                    <ul>
                      <li>
                        {" "}
                        Complete at least 108 days as an active Seller on beyond
                        smart work
                      </li>
                      <li>
                        {" "}
                        Complete at least 21 individual orders (all time) Earn
                        at least Rs 20,000
                      </li>
                      <li>
                        {" "}
                        Maintain a 4.1 star rating over the course of 60 days
                        90% Response rate
                      </li>
                      <li>
                        {" "}
                        Over the course of 60 days 90% Order completion over the
                        course of 60 days 90% On-time delivery
                      </li>
                      <li>
                        {" "}
                        Over the course of 60 days Avoiding receiving warnings
                        over the course of 30 days
                      </li>
                    </ul>

                    <h5 className="heroText2 pt-3">Overview</h5>
                    <p>
                      There are a few requirements you’ll need to meet in order
                      to reach Level Gold status. This is an automatic process -
                      we look back at your performance for the past 60 days, and
                      if you meet the standards, you’ll be ranked as a Level
                      Gold SpecialistPro.
                    </p>
                    <h5 className="heroText2 pt-3">Benefits</h5>
                    <ul>
                      <li>30 Active services 6 service</li>
                      <li>
                        Extras (Rs800, Rs1600, Rs2400, Rs 3200, Rs 8000, Rs
                        80000) 20 service
                      </li>
                      <li>Multiples Custom Offers, up to Rs 8,00,000</li>
                      <li>VIP Customer Support</li>
                      <li>
                        Eligibility to be featured at promotional listings
                      </li>
                      <li>Eligibility for Customer Success program</li>
                      <li>Earning clearance: 8 days</li>
                    </ul>
                  </div>
                </div>

                <div className="row pb-4 pt-2">
                  <h2 className="heroText1a pt-3 text-center">Definitions</h2>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Custom Offer</Accordion.Header>
                      <Accordion.Body>
                        
                            <p>
                              An exclusive proposal that a Seller can create in
                              response to specific requirements from a Buyer.
                            </p>
                        
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header> Priority/VIP support</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          VIP Customer Support tickets are handled first, to
                          enable a faster response.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>
                  
                  <Accordion.Item eventKey="2">
                      <Accordion.Header> Services Extras</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          Additional services offered on top of the Seller's
                          service, for an additional price defined by the
                          Seller.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header> Service Multiples</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          Additional services offered on top of the Seller's
                          service, for an additional price defined by the
                          Seller.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>

               


                  <Accordion.Item eventKey="4">
                      <Accordion.Header> Service Multiples</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>Offer more of the same Rs.8000 Service.</p>
                     
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Earning Clearance</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          The date in which your earning are cleared and
                          released to your account.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="6">
                      <Accordion.Header> Customer Success Program</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          One of Beyond Smart Cities Success Managers will help
                          you grow your business and optimize your account.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Promotional Listings</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          Eligible Seller will be promoted in our emails, ads
                          and other marketing efforts.
                        </p>
                     
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Days Without Warnings</Accordion.Header>
                      <Accordion.Body>
                      
                        <p>
                          A warning is received when violating BSC ’s Terms of
                          Service.
                        </p>
                   
                      </Accordion.Body>
                    </Accordion.Item>

                  </Accordion>   

                  
                </div>
              </div>
            </div>
          </div>
        );
    }
}

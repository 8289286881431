import React, { Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export const CertificatesSplitTwo = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangePersonalInfo,
  handleChangeCertificatesSplitTwo,
  values,
  addClick,
  removeClick,
  ImageUploading,
  maxNumber,
  onChangeImage,
  licensePreviewUrl,
  profilePreviewUrl,
  expCertificatePreviewUrl
  

}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
         

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                     <h2 className="my-4">Please upload the related documents for us to approve you application. Please upload a valid pdf or jpg document with clear visibility</h2>

                      <Row className="align-item-center justify-content-center ">
                        <Col className='col-md-4'>
                        <div className="upload-area">
                        <Form.Group>
                        <Form.Label>Upload License</Form.Label>
                        <p>Upload the License Certificate received from Organization </p>
                        <Form.Text className="text-muted"></Form.Text>{" "}
                      </Form.Group>
                          <div className="previewComponent">
                            <input
                           className="uploadFiletext"
                              type="file"
                              onChange={
                                handleChangeCertificatesSplitTwo("license")
                              }
                            />

                            <div className="uploadimgPreview">
                              {licensePreviewUrl ? (
                                <img className="img-fluid" onerror="this.src='https://img.icons8.com/officel/2x/pdf.png'" src={licensePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                              
                            </div>
                          </div>
                          </div>
                        </Col>

                        <Col className='col-md-4'>
                        <div className="upload-area">
                        <Form.Group>
                        <Form.Label>Upload Experience Certificate</Form.Label>
                        <p>Upload the Experience Certificate received from Organization </p>
                        <Form.Text className="text-muted"></Form.Text>{" "}
                      </Form.Group>
                        <div className="previewComponent">
                            <input
                           className="uploadFiletext"
                              type="file"
                              onChange={
                                handleChangeCertificatesSplitTwo("expCertificate")
                              }
                            />

                            <div className="uploadimgPreview">
                              {expCertificatePreviewUrl ? (
                                <img src={expCertificatePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                            </div>
                          </div>
                          </div>
                        </Col>

                        
                        <Col className='col-md-4'>
                          <div className="upload-area">
                        <Form.Group>
                        <Form.Label>Upload Profile Photo</Form.Label>
                        <p>Upload your profile photo </p>
                        <Form.Text className="text-muted"></Form.Text>{" "}
                      </Form.Group>
                        <div className="previewComponent">
                            <input
                           className="uploadFiletext"
                              type="file"
                              onChange={
                                handleChangeCertificatesSplitTwo("profilePhoto")
                              }
                            />

                            <div className="uploadimgPreview bdr-rad-4">
                              {profilePreviewUrl ? (
                                <img className="bdr-rad-4" src={profilePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                            </div>
                          </div>
                          </div>
                        </Col>
                      </Row>

                      {/* <Row>
                       
                      </Row> */}

                      <Row className="text-center my-3">
                        <Col>
                          <Button
                            onClick={Previous}
                            type="submit"
                            className="btn btn-primary-trans btn-block me-2"
                          >
                            Previous
                          </Button>

                          <Button
                            onClick={Continue}
                            type="submit"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={false}
                            className="btn btn-primary btn-block c1"
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

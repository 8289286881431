import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <div  className="Nature-banner inner-page">
      <div className="container">
           <div className="row">
            <div className="col-md-12">
                <h2 className="section-title p-tb-20 text-center">Privacy Policy</h2>
                <div className="title-divider"></div>
            </div>
         </div>
         <div className="row">
               <div className="col-md-12">
                   <p>This page states the Privacy Notice under which You, the Web Site visitor ("You" or “Your” or “Yourself”) may use or correspond with this Website (“Our Site”), which is owned by the Beyond Smart Cities Private Limited. (“We” or “Us” or “Our”).</p>
                   <p>Beyond Smart Cities is a tech driven interactive platform for Buyers to find, hire and book professional services with Sellers. Buyers can discover Services, discover Sellers based on various filters and buy any service they want. Buyers can also post their projects or products to request proposals from Sellers.</p>
                   <p>Our Privacy Policy explains how we and some of the companies we work with collect, use, share and protect information in relation to our mobile services, web site, and any software provided on or in connection with Beyond Smart Cities services (collectively, the "Service"), and your choices about the collection and use of your information.</p>
                   <p>We at Beyond Smart Cities respect your privacy and appreciate the fact that you have entrusted your valuable information with us. This privacy policy helps in keeping you abreast with the kind of data that we collect and for what purposes. You may also be able to control your privacy as per your convenience once you have created your personal identity account with us. The privacy policy in principle shall remain constant and shall ordinarily not reduce your rights under this privacy policy, however, to cater the current demand of the IT sector or for complying with the ever-changing laws of the land, the privacy policy could be modified. The same shall be brought to your notice and only after your approval of the same, you shall be able to access our services. Our privacy policy is only applicable to the services exclusively rendered by Beyond Smart Cities. This privacy policy doesn’t apply to services offered by other third parties, including products or sites that may be displayed to you as advertisements or other site linked from our services.</p>
                   </div>
                   </div>
                    <div className="row">
            <div className="col-md-12">
                <h2 className="section-title p-tb-20 text-center">Who We Are / Data Controller </h2>
                <div className="title-divider"></div>
                
                <p>The entity controlling the processing of Your Personal Data is Beyond Smart Cities Private Limited. a company incorporated in Bengaluru, Karnataka, India.</p>
             <b>The contact details of the controller are as follows :</b>
             
            <p><b>Address:</b> M/S Beyond Smart Cities.Pvt. Ltd, #55, Chikabanavara, HMR layout, 1st Cross, D.R.Bendre Road, India, Karnataka,Bengaluru-560090.</p>
            <p>If You have any questions or concerns about this Privacy Notice, please contact us using the Contact Us (insert link) section on Our Site. Alternatively, you can contact us using the following information:</p>
             <p><b>Email:</b> <a href="mailto:info@beyondsmartcities.in">info@beyondsmartcities.in</a></p>
             <p><b>Phone:</b> +91 6363032722</p>
              <h2 className="section-title p-tb-20 text-center">Information that we collect</h2>
                <div className="title-divider"></div>
                <p>We collect the following types of information.</p>
                 <div className="row mb-4">
         <div className="col-md-12"> 
         <div className="collapsible-list">
           <button type="button" className="collapsible">1 . Information you provide us directly</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>While signing up, your name, e-mail address and mobile number, when you register for Beyond Smart Cities account or subscribe for our newsletter. This information is used to create and maintain your Beyond Smart Cities account information.</li>
                         <li>Profile information you provide us while creating your profile on Beyond Smart Cities - Name, picture, phone number, gender, age, location, Bio, work address</li>
                        <li>Profile Information for Sellers and Professionals like Green Building Specialists, Designers, Content Writers, Energy Engineers, Environment Consultants, GHG Accounting Team, Product manufactures ,Property Owners and other related professionals shall include Name, mobile number, work address, email, brief bio, education, work experience, membership id, profile picture, expertise areas, area of practice, business organization name, identity proof, educational qualification proof and work address proof.</li>
                        <li>Identity Verification: We might use some information for identity verification such as email verification, Mobile OTP verification and two factor authentication to ensure trust on the platform.</li>
                        <li>Payment Information you provide us such as credit card, PayPal or other bank account information, and billing address to transfer your fees to your respective account.</li>
                        <li>Communications between you and Beyond Smart Cities. For example, we may send you Service related emails (e.g., account verification, changes/updates to features of the Service, technical and security notices). Note that you may not opt out of Service-related emails.</li>
                        <li>Collection of the Third Party Personal Information:</li>
                        <p>We collect the following personal information from you about your contacts or friends: First name, last name, and email address when you provide it to us for the purpose of inviting your contacts or team members.</p>
                       <li>Information collected automatically :</li>
                       <p>Like other online companies, we receive technical information when you use our Services. We use these technologies to analyze how people use our Services, to improve how our Site functions, to save your log-in information for future sessions, and to serve you with advertisements that may interest you. We and our third-party service providers, including analytics and third party content providers, may automatically collect certain information from you whenever you access or interact with the Service. This information may include, among other information, the browser and operating system you are using, the URL or advertisement that referred you to the Service, the search terms you entered into a search engine that led you to the Service, areas within the Service that you visited, and other information commonly shared when browsers communicate with websites. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, and site functionality.</p>
                       <p>The information we collect also includes the Internet Protocol (“IP”) address or other unique device identifier (“Device Identifier”) for any device (computer, mobile phone, tablet, etc.) used to access the Service. A Device Identifier is a number that is automatically assigned or connected to the device you use to access the Service, and our servers identify your device by its Device Identifier. Some mobile service providers may also provide us or our third-party service providers with information regarding the physical location of the device used to access the Service.</p>
                       <p>You do not have a statutory obligation to provide us with any information, but you may have a contractual obligation to do so, and if we do not receive certain information from you, then we will not be able to provide our Service to you. If you have any questions regarding whether provision of information is mandatory and the consequences for withholding such information, please contact us using the contact information below. Beyond Smart Cities</p>
                    </ul>
                  </div>
         </div>
          <button type="button" className="collapsible">2 . Analytics</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools collect information sent by your device or our Service, including the web pages you visit, add-ons, and other information that assists us in improving the Service. We collect and use this analytics information with analytics information from other Users so that it cannot reasonably be used to identify any individual User.</li>
           </ul>
                  </div>
         </div>
         <button type="button" className="collapsible">3 . Cookies and other Similar Information</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
         
                        <li>When you visit the Service, we may use cookies and similar technologies like pixels, web beacons, and local storage to collect information about how you use Beyond Smart Cities and provide features to you. We may ask advertisers or other partners to serve ads or services to your devices, which may use cookies or similar technologies placed by us or the third party.</li>
           </ul>
                  </div>
         </div>
          <button type="button" className="collapsible">4 . Log Information</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>Log file information is automatically reported by your browser each time you make a request to access (i.e., visit) a web page or app. It can also be provided when the content of the web page or app is downloaded to your browser or device.</li>
                            <li>When you use our Service, our servers automatically record certain log file information, including your web request, Internet Protocol ("IP") address, browser type, referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, and other such information. We may also collect similar information from emails sent to our Users which then help us track which emails are opened and which links are clicked by recipients. The information allows for more accurate reporting and improvement of the Service.</li>
          </ul>
                  </div>
         </div>
         <button type="button" className="collapsible">5 . Metadata</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>Metadata is usually technical data that is associated with User Content. For example, Metadata can describe how, when and by whom a piece of User Content was collected and how that content is formatted.</li>
                            <li>Users can add or may have Metadata added to their User Content including a hashtag (e.g., to mark keywords when you post a photo), geotag (e.g., to mark your location to a photo), comments or other data. This makes your User Content more searchable by others and more interactive. If you geotag your photo or tag your photo using other's APIs then, your latitude and longitude will be stored with the photo and searchable (e.g., through a location or map feature) if your photo is made public by you in accordance with your privacy settings.</li>
         </ul>
                  </div>
         </div>
           <button type="button" className="collapsible">6 . Messaging</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>Buyer and Sellers communicate with each other through the Service. If you communicate with an Agency or Client, that Agency or Client will also be a “data controller” with respect to such communications.</li>
                            
                            
         </ul>
                  </div>
         </div>
         <button type="button" className="collapsible">7 . Community</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>We may offer public forums or blogs. If you participate, your comments and questions will be publicly displayed. The Service may provide you the opportunity to participate and post content publicly in forums, on blogs, through interactive features Beyond Smart Cities and through other communication functionality (“Community Forums”). You may choose, through such features or otherwise, to submit or post questions, comments, or other content (collectively, “User Forum Content”). Please note that certain information, such as your name and Profile may be publicly displayed on the Service along with your User Forum Content.</li>
                          <li>Note that anything you post to a Community Forum is public — others will have access to your User Forum Content and may use it or share it with third parties. If you choose to voluntarily disclose Personal Information in your User Forum Content or use Community Forums to link to your Profile, that information will be considered public information and the protections of this Privacy Policy will not apply.</li>  
                            
         </ul>
                  </div>
         </div>
          <button type="button" className="collapsible">8 . Testimonials</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <p>We display personal testimonials of satisfied customers on our Service, in addition to other endorsements. With your consent we may post your testimonial along with your name. In case, you have any issue with your testimonial being displayed please contact us at <a href="mailto:privacy@beyondsmartcities.in"><b>privacy@beyondsmartcities.in</b></a></p>
                            <p>How we use your information :</p>
                            <ul>
                                  <li>We do not sell, rent or transfer your information to anyone </li>
                                   <li>To provide satisfactory service and complete your transaction </li>
                                   <li>We use profile information of Sellers to help our Buyers discover the right Seller with right Service, select and book services with a Seller, facilitate communication between them and simplify work completion, invoicing and billing. </li>
                                  <li> To help you efficiently access your information after you sign in </li>
                                   <li>To remember information so you will not have to re-enter it during your visit or the next time you visit the Service; </li>
                                   <li>To provide personalized content and information to you and others, which could include online ads or other forms of marketing </li>
                                   <li>To Provide, improve, test, and monitor the effectiveness of our Service </li>
                                   <li>To develop and test new products and features </li>
                                   <li>To monitor metrics such as total number of visitors, traffic, and demographic patterns </li>
                                   <li>To diagnose or fix technology problems </li>
                                   <li>To communicate with you through emails or newsletters, provide recent feature updates, new tools, marketing and promotional material. You may any time subscribe out of our emails. </li>
                                   <li>To address your service-related issues </li>
                                   <li>For Compliance with web, legal and other regulatory compliances and internal business purposes </li>

                            </ul>
                          
                            <li>Sharing your information</li>
                            <p>We will not rent or sell your information to third parties outside Beyond Smart Cities without your consent, except as noted in this Policy.</p>
                            </ul>
                  </div>
         </div>
          <button type="button" className="collapsible">9 . Parties with whom we may share your information</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>Legal or Investigative Purposes :</li>
                            <p>We may access, preserve and share your information in response to a legal request or investigations (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the India where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; and to prevent death or imminent bodily harm. Information we receive about you may be accessed, processed and retained for an extended period of time when it is the subject of a legal request or obligation, governmental investigation, or investigations concerning possible violations of our terms or policies, or otherwise to prevent harm.</p>
                                <li>Merger or Acquisition :</li>
                                <p>We may your share your information including personal information in any situation where Beyond Smart Cities gets acquired or merged with other entity or with any current or future subsidiaries or affiliates, primarily for business and operational purposes. We may sell, transfer, or otherwise share some or all of our assets, including your Personal Information, in connection with a merger, acquisition, reorganization or sale of assets (including, in each case, as part of the due-diligence process with any potential acquiring entity) or in the event of bankruptcy.</p>
                                <li>Service Providers :</li>
                                <p>We may appoint third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services (e.g., without limitation, maintenance services, database management, web analytics and online advertising, payment processing, fraud detection and improvement of Beyond Smart cities features) or to assist us in analyzing how our Service is used. These third parties may have access to your Personal Information in order to perform these tasks on our behalf.</p>
                               <li>Sellers on Beyond Smart Cities :</li>
                               <p>We may share Buyer’s Order details with Sellers who have registered on Beyond Smart Cities to fulfill the Services. This Includes information necessary to perform the Service/Gig and complete the Order. The Sellers registered on Beyond Smart Cities are verified and approved professionals.</p>
                               <li>Information about Sellers shared with Buyers :</li>
                               <p>We share information of the Seller with the Buyer for the Order created by the Buyer with the Seller. Note that if a Seller is suspended from the Beyond Smart Cities ’s Service, we may share that information with the Buyer for whom that Seller has worked or submitted proposals for work. We may also share information with Agencies/Firms/Companies to whom Sellers are associated for a particular work project.</p>
                                <li>How we protect your information :</li>
                                   <ul>
                                       <li>Beyond Smart Cities works to protect all your information from loss, misuse or unauthorized alteration by using industry-recognized security safeguards, coupled with carefully developed security procedures and practices. We maintain electronic and procedural safeguards. We use both internal and external resources to review our security procedures. Whenever we prompt you to transmit sensitive information, such as credit card information, we support the encryption of your information as it is transmitted to us. Our employees are trained and required to safeguard your information.</li>
                                        <li>We use commercially reasonable safeguards to help keep the information collected through the Service secure and take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account.</li>
                                        <li>Please do your part to help us. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to emails between you and Beyond Smart Cities, at all times. We are not responsible for the functionality, privacy, or security measures of any other organization.</li>
                                        <li>Beyond Smart Cities takes commercially reasonable steps to help protect and secure the information it collects and stores about Beyond Smart Cities Users. All access to the Site is encrypted using industry-standard transport layer security technology (“TLS”). When you enter sensitive information (such as tax identification number), we encrypt the transmission of that information using secure socket layer technology (“SSL”). We also use HTTP strict transport security to add an additional layer of protection for our Beyond Smart Cities Users. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Thus, while we strive to protect your Personal Information, Beyond Smart Cities  cannot ensure and does not warrant the security of any information you transmit to us.</li>

                                   </ul>
                                   <li>User Choice and control over privacy</li>
                                   <p>We at Bey Beyond Smart Cities, respect your privacy and you have complete freedom to control and maintain your privacy. For instance, you may choose to block all cookies in your browser. However, you may note that such blocking will not enable us to remember your language preference. So, it is expected that you choose your privacy settings wisely so as to maximize your user experience but at same time do not expose your sensitive data. You can Update your account at any time by logging in and changing your profile settings.</p>
                                  <li>Other websites and services</li>
                                 <p>We are not responsible for the practices employed by any websites or services linked to or from our Service, including the information or content contained within them. Please remember that when you use a link to go from our Service to another website or service, our Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party's own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access your User Content. If you are using a third-party website or service and you allow them to access your User Content you do so at your own risk.</p>
                                </ul>
                  </div>
         </div>
         <button type="button" className="collapsible">10 . Changes to our Privacy Policy</button>
              <div className="content"> 

                    <div className="smart-list">
                        <ul>
                            <li>Beyond Smart Cities may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of Beyond Smart Cities or the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.</li>
                            </ul>
                            </div>
                            </div>
      </div>
    </div>
         
            </div>
         </div>
          <div className="row  mb-4">
        <div className="col-md-12"> 
            <div className="contact mb-3">
              <button type="button mb-4"><Link to="/contact">Contact</Link></button>
              <p>In case, You have any questions about this Privacy Policy or the Service, please contact us at  <a href="mailto:privacy@beyondsmartcities.in"><b>privacy@beyondsmartcities.in</b></a></p>
            </div>
            
        </div>
        
    </div>
      </div>
    </div>
    
            </div>
        </div>
        )
    }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../helpers/CardBoxLoading"
import { Link } from "react-router-dom";
import {FaRocket, FaRupeeSign } from "react-icons/fa";
export default class EmergingDesign extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
    //   category:this.props.match.params.cat.replace(/-/g, " "),
    //   subCategory:this.props.match.params.subcat.replace(/-/g, " "),
    //   childCategory:this.props.match.params.childcat.replace(/-/g, " "),
      specialistInfo:[],
      isdataFetched:false,
      serviceId:''
    }
  }

  componentDidMount() {
    this.getCategories();
    console.log(this.props)
    this.getSpecialistsWithQuery();
  }


  componentDidUpdate(prevProps) {
    console.log('ii',prevProps.match.params.childcat)
    console.log('ii2',this.props.match.params.childcat)

    if (this.props.match.params.childcat !== prevProps.match.params.childcat) {
   
      // call the fetch function again
      console.log('yes')

      this.setState({
        category:this.props.match.params.cat.replace(/-/g, " "),
        subCategory:this.props.match.params.subcat.replace(/-/g, " "),
        childCategory:this.props.match.params.childcat.replace(/-/g, " "),
      })
    
       this.getSpecialistsWithQuery();
   
  }
}
 

  goToSpecialistDetail(specialistId){
    console.log('p')
    this.props.history.push({
      pathname: '/specialist-information/'+specialistId           
    })

  }


  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }




  async getSpecialistsWithQuery() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));        
    await axios
      .get(API_URL + "service/search/specialist/level-three/", 

      { params: {
        category: "Sustainability-and-Technology",
        subCategory:"Waste-Design-and-Planning-Services",
        childCategory:"Waste-Design-Services"
      }},
            
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {     
          console.log('SPresponsce',response)    
          if (response.data.success === true) {
            console.log('SPresponsce',response)
            self.setState({
              specialistInfo:response.data.specialistInfo
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async getCategories() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "category/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('CAT',response);

          if (response.data.success === true) {
            self.setState({
              //propertyData:response.data.propertyData
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>                  
                  <Breadcrumb.Item active>Emerging Design</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
                <Col md={6}>
                <div className="beyond-text-section2 pro ps-2 ps-md-5">
                    <h2 className="heroText1 c-3"><span className="fw-300">Explore 
</span> <br />Beyond Smart Cities <br />Top Skills</h2>
                    <p className="heroText2">Great works made by Green
Technology Specialists</p><div class="ghostBtn mt-3 ms-3"><a href="#more">Explore Now</a>
                    </div> 
                    </div>                 
                </Col>
                <Col>
                <img className="img-fluid" src="/assets/images/emergingdesign.png"/>
                </Col>

            </Row>
            <Row  id="more" className="mt-3  pt-3">
            <h2 className="heroText1 c-3 text-center"><span className="fw-600 ">Explore Services</span></h2>
              <Col>
                <div className="top-notice">
                  {this.state.specialistInfo &&
                  !this.state.specialistInfo.length &&
                  this.state.isdataFetched ? (
                    <p>No Specialist Service found for your selection</p>
                  ) : null}
                </div>
              </Col>
            </Row>

           
            <Row class=" Sustainable-inner-design">
              {this.state.specialistInfo.length ? (
                this.state.specialistInfo.map((item) => (
                  <div class="col-md-3 cursor-pointer mb-3">                    
                      <div class="card-r  shd-1">

                      {item.serviceGallery.length ?
                            <img
                            onClick={() => this.goToServiceDetail(item._id,item.createdBy._id)}
                              src={item.serviceGallery[0]?.secureUrl}
                              alt="Person"                             
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                            :
                            <img
                            onClick={() => this.goToServiceDetail(item._id,item.createdBy._id)}
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              height="180"
                              style={{objectFit:'cover'}}
                            />}
                       
                        <div class="card-content">
                          <div class="chip">
                            {item.createdBy?.customerId?.profile?.image?.secureUrl ?
                            <img
                              src={item.createdBy?.customerId?.profile?.image?.secureUrl}
                              alt="Person"
                              width="96"
                              height="96"
                            />
                            :
                            <img
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              width="96"
                              height="96"
                            />}
                            <Link
                              onClick={() =>
                                this.goToSpecialistDetail(item.createdBy?._id)
                              }
                            >
                              {" "}
                              <span className="fw-900">{item.createdBy?.personalInfo?.firstName}</span>
                            </Link>
                          </div>
                          <h3 className="mb-4 c3"
                          onClick={() => this.goToServiceDetail(item._id,item.createdBy?._id)}>
                            {item.overview.gigTitle}                           
                          </h3>
                          <Row>
                            <Col md={4}>
                              <p>⭐4.6(10+)</p>
                            </Col>
                            <Col md={1}>
                              {/* <i class="fas fa-heart"></i> */}
                            </Col>
                            <Col>
                              <h3 className="float-end c4">Starts{ } <FaRupeeSign /> {item.packageDetails[0].price}</h3>
                            </Col>
                          </Row>
                        </div>
                      </div>                   
                  </div>
                ))
              ) : (
                <>
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

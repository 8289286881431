import React, { Component } from 'react'
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
export default class Categories extends Component {

    constructor(props) {
        super(props);
        // this.addNewProperty = this.addNewProperty.bind(this);

        
    }


    componentDidMount(){
      this.getCategories()
    }

    async getCategories() {     
        var self = this;     
        self.setState({
          step: 10,
          submitLoading: true,
          errorMessage: "",
          dataLoading:true
        });
    
        
        const token = await JSON.parse(localStorage.getItem("token"));     
        await axios
          .get(API_URL + "category/list", {
            headers: {
              auth: token,
              Accept: "application/json",
            },
          })
    
          .then(
            function (response) {
              console.log(response);           
    
              if (response.data.success === true) {
                self.setState({
                  //propertyData:response.data.propertyData
                })
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  dataLoading:false
                });  
                
              } else {
                self.setState({
                  errorMessage: response.data.error,
                  submitLoading: false,
                  dataLoading:false
                });
              }
            },
            (error) => {
              const message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              // console.log(message)
              self.setState({
                errorMessage: message,
                submitLoading: false,
                dataLoading:false
              });
            }         
          );
      }    

    render() {
        return (
            <div>
                Hello
            </div>
        )
    }
}

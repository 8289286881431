import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "../../config";
import validator from "validator";
import DashboardSidebar from "../DashboardSidebar";
import {
  validate_Alpha_Length,
  validate_Length_DOB,
  validate_Empty,
} from "../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../helpers/Utilities";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";

export default class SubmitQuery extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.asyncLocalStoragegetItem = this.asyncLocalStoragegetItem.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,

      data: {
        queryTitle: "",
        queryType: "",
        queryDescription: "",
      },

      errorMessage: "",
      queryTitleError: "",
      queryError: "",
      queryDescriptionError: "",
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
      // window.location.reload();
      // setTimeout(() => {
      //   return <Redirect to="/profile" />;
      // }, 3000);
    }

    let self = this;
    this.asyncLocalStoragegetItem("user").then(function (user) {
      console.log("USER", user);
      if (user.profile) {
        self.setState({
          data: {
            profile: user.profile,
          },
        });
      }
    });
  }

  async asyncLocalStoragegetItem(key) {
    return Promise.resolve().then(function () {
      return JSON.parse(localStorage.getItem(key));
    });
  }

  async handleUpdate(e) {
    e.preventDefault();

    if (
      validate_Empty(this.state.data.profile.dob) &&
      validate_Empty(this.state.data.profile.gender) &&
      validate_Empty(this.state.data.profile.profession) &&
      validate_Empty(this.state.data.profile.address.street) &&
      validate_Empty(this.state.data.profile.address.city) &&
      validate_Empty(this.state.data.profile.address.state) &&
      validate_Empty(this.state.data.profile.address.country) &&
      validate_Empty(this.state.data.profile.address.pincode)
    ) {
      this.setState({ spinner: true });

      console.log("DATREAL", this.state.data);
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

      console.log("DATA", self.state.data);
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .put(API_URL + "user/profile/edit", self.state.data, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log(response);
            //handle success

            if (response.data.success === true) {
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.userInfo)
              );
              setTimeout(() => {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                });

                self.props.history.push("/profile");
                toast("Profile Updated Successfully", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }, 1000);
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } else {
      toast("Thers is an Error in the input fields. Please check!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  validateField = (input, type) => (e) => {
    let name = e.target.name;
    let val = e.target.value;

    console.log("yyyp");
    if (type == "address") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          profile: {
            ...prevState.data.profile,
            address: {
              ...prevState.data.profile.address,
              [input]: e.target.value,
            },
          },
        },
      }));
    } else if (type == "profile") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          profile: {
            ...prevState.data.profile,
            [input]: e.target.value,
          },
        },
      }));
    }
    this.validateAll(name, val);
  };

  validateAll(name, val) {
    let errorName = name + "Error";
    validate_Empty(val)
      ? this.setState({ [errorName]: false })
      : this.setState({ [errorName]: true });
    console.log(errorName);
  }

  render() {
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>New Query</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form>
                        <Row>
                          <Col
                            xs
                            lg="2"
                            style={{
                              backgroundColor: "#eee",
                              borderRadius: 6,
                            }}
                          >
                            <DashboardSidebar />
                          </Col>

                          <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                              <Row>
                                <Col>
                                  <div className="dashboard-content-title">
                                    <Link to="/profile">
                                      {" "}
                                      <IoReturnUpBackOutline className="mx-3" />
                                    </Link>
                                    Add New Query
                                  </div>
                                </Col>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.handleUpdate}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Submit
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <Row>
                              <Col>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Query Title</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder="Eg: I need Help"
                                        onChange={this.validateField()}
                                        value={this.state.data.queryTitle}
                                        name="queryTitle"
                                        className={
                                          this.state.queryTitle
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Query Type</Form.Label>

                                      <Form.Select
                                        value={this.state.data.queryType}
                                        onChange={this.validateField()}
                                        name="queryType"
                                        aria-label="Default select"
                                        className={
                                          this.state.queryType
                                            ? "hasError"
                                            : "valid"
                                        }
                                      >
                                        <option value="">
                                          Select Query Type
                                        </option>
                                        <option value="Service Related">
                                          Service Related
                                        </option>
                                        <option value="Products">
                                          Products
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Query Details</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={this.validateField()}
                                    name="queryDescription"
                                    value={this.state.data.queryDescription}
                                    className={
                                      this.state.queryDescription
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { connect } from "react-redux";
import { register } from "../actions/auth";
import { login } from "../actions/auth";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { FaCheck } from "react-icons/fa";
import { FiUser } from "react-icons/fi";


import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import success from "../reducers/success";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vfirstname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The firstname must be between 3 and 20 characters.
      </div>
    );
  }
};

const vlastname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The lastname must be between 3 and 20 characters.
      </div>
    );
  }
};

const vphone = (value) => {
  if (value.length < 10 || value.length > 10) {
    return (
      <div className="alert alert-danger" role="alert">
        The phone number must have 10 digits.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const visagreed = (value) => {
  if (value != true) {
    return (
      <div className="alert alert-danger" role="alert">
        Please Agree to Terms and Conditions
      </div>
    );
  }
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeFirstname = this.onChangeFirstname.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeisAgreed = this.onChangeisAgreed.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      successful: false,
      isAdmin: false,
      isAgreed: false,
      isNLAgreed: false,
      submitProgress: false,
      spinner: false,
      agreeError: "",
      role:'customer'
    };
  }

  onChangeFirstname(e) {
    this.setState({
      firstName: e.target.value,
    });
  }

  onChangeLastname(e) {
    this.setState({
      lastName: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phoneNumber: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeisAgreed(e) {
    console.log(e.target.checked);
    const name = e.target.name;
    this.setState({
      isAgreed: e.target.checked,
      agreeError: "",
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.state.isAgreed == false) {
      this.setState({ agreeError: " Please Agree to Terms and Conditions" });
    } else {
      if (this.checkBtn.context._errors.length === 0) {
        this.setState({
          spinner: true,
          submitProgress: true,
        });

        this.props
          .dispatch(
            register(
              this.state.firstName,
              this.state.lastName,
              this.state.email,
              this.state.phoneNumber,
              this.state.password,
              this.state.role,
            )
          )
          .then(() => {
            this.setState({
              successful: true,
              spinner: false,
              submitProgress: false,
            });
          })
          .catch(() => {
            this.setState({
              successful: false,
              spinner: false,
              submitProgress: false,
            });
          });
      }
    }
  }

  render() {
    // const { message } = this.props;

    const { isLoggedIn, message, success } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/profile" />;
    }

    return (
      <div>
        <section class="lightgrey-bg user-area log-in-area">
          <div class="container">
            <div class="user-form-content">
              <div class="row">
                <div class="col-md-6 align-items-center p-5">            
                 
               
                  <div class="heroText2a pt-3">
                  Beyond Smart Cities is the world’s 1st Green Technology Marketplace, connecting millions of Sustainability, Green Building, Energy, Commissioning, Environment Health & Safety Specialists, Fire Safety Specialists, Climate Change Specialists & Green Products/Technology Manufacturers  with independent talent around the globe.
                  </div>
                  <img
                    src="assets/images/emergingdesign.png"
                    alt="login image"
                    className="img-fluid"
                  />
                </div>

                <div class="col-md-6 mt-4">
                  <div class="sign-layout form-card mt-3 pt-4">
                    <div class="login-form-holder position-relative">
                      <div class="form-group text-center">                        
                        {!this.state.successful ? (
                          <div className="formHeader">
                          <h2 class="mx-2"> <span className="m-2"><FiUser /></span>Sign Up</h2>
                          </div>
                        ) : null}                     
                      </div>

                      <Form
                        onSubmit={this.handleRegister}
                        ref={(c) => {
                          this.form = c;
                        }}
                      >
                        {!this.state.successful &&  (
                          <div>
                           
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label htmlFor="firstname">First Name</label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                    value={this.state.firstName}
                                    onChange={this.onChangeFirstname}
                                    validations={[required, vfirstname]}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label htmlFor="username">Last Name</label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                    value={this.state.lastName}
                                    onChange={this.onChangeLastname}
                                    validations={[required, vlastname]}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="email">Email Address</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={this.state.email}
                                onChange={this.onChangeEmail}
                                validations={[required, email]}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="email">Phone Number</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="phonenumber"
                                value={this.state.phoneNumber}
                                onChange={this.onChangePhone}
                                validations={[required, vphone]}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="password">Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={this.onChangePassword}
                                validations={[required, vpassword]}
                              />
                            </div>

                            <div className="form-group py-3">
                              <Button
                                disabled={this.state.submitProgress}
                                onClick={this.handleRegister}
                                className="btn btn-primary btn-block c1"
                              >
                                {this.state.spinner ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )}
                                <span className="d-inline p-2">Sign Up</span>
                              </Button>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-1 w-35">
                                  <Input
                                    type="checkbox"
                                    name="isAgreed"
                                    checked={this.state.isAgreed}
                                    value={this.state.isAgreed}
                                    onChange={this.onChangeisAgreed}
                                  />
                                </div>
                                <div className="col p-0">
                                  I agree to the{" "}
                                  <Link
                                    to="/terms-conditions.html"
                                    class="text-color-orange fs1"
                                    target="_blank"
                                  >
                                    Terms and Conditions
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    to="/privacy-policy.html"
                                    class="text-color-orange fs1"
                                    target="_blank"
                                  >
                                    Privacy Policy
                                  </Link>
                                </div>
                              </div>
                              {this.state.agreeError != "" ? (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  {this.state.agreeError}
                                </div>
                              ) : null}
                            </div>


                            <div className="form-group">
                              <div className="row">
                                <div className="col-1 w-35">
                                  <Input
                                    type="checkbox"
                                    name="isNLAgreed"
                                    checked={this.state.isNLAgreed}
                                    value={this.state.isNLAgreed}
                                    onChange={this.onChangeisNLAgreed}
                                  />
                                </div>
                                <div className="col p-0">                                  
                                  <Link
                                    to="/terms-conditions.html"
                                    class="text-color-orange fs1"
                                    target="_blank"
                                  >
                                    Signup for Newsletter
                                  </Link>{" "}
                                 
                                  <Link
                                    to="/"
                                    class="text-color-orange fs1"
                                    target="_blank"
                                  >
                                    
                                  </Link>
                                </div>
                              </div>                              
                            </div>

                            <div class="form-group">
                                <div class="col-12">
                                    <p class="create">Already have an account? 
                                    <Link
                                    to="/login"
                                    class="text-color-orange"                                    
                                  >
                                   Login
                                  </Link>
                                 </p>
                                </div>
                            </div>
                            
                          </div>

                        )}

                        {message && (
                          <div className="form-group">
                            <div
                              className={
                                this.state.successful
                                  ? "alert alert-success text-center"
                                  : "alert alert-danger"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        <CheckButton
                          style={{ display: "none" }}
                          ref={(c) => {
                            this.checkBtn = c;
                          }}
                        />

                        {success && this.state.successful ? (
                          <div>
                            <h1 className="text-center c2">
                              <FaCheck />
                            </h1>
                            <h2 className="text-center">Congratulations!</h2>
                            <p class="text-center">
                              Now Explore Green Build Freelance Services,
                              SmartPro Product Rating and Smart Property Rating
                              System at One Location.
                            </p>
                            <div className="row">
                              <div className="col text-center">
                                <Link to="/login" class="btn btn-primary">
                                  Login Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  const { success } = state.success;
  console.log("SUCCESS", success);
  return {
    message,
    success,
  };
}

export default connect(mapStateToProps)(Register);

import {
    SET_SERVICE_CART,  
    CLEAR_SERVICE_CART,  
    GET_SERVICE_CART
  } from "./types";

  
  
 
  export const setServiceCart = (
    cartData
  ) =>(dispatch) => {
   
    dispatch({
        type: SET_SERVICE_CART,
        payload:{ cartItems:cartData },
      });

      return Promise.resolve();

  };



import React, { Component } from 'react'

export default class HelpSupport extends Component {
    render() {
        return (
            <div>
                
                <div  className="Nature-banner inner-page">
   
   <div className="row mb-4">
    <div className="col-md-6 help-layout-1">
        <div className="help-section">
              <h2 className="heroText1 p-tb-20 mt-3 ms-3">Find a solution<br/> fast & smart.</h2>
         <p className="heroText2 p-tb-20 ms-3">Search hundreds of articles on Beyond<br/> Smart Cities Help</p>
         <div class="ghostBtn mt-3 ms-3"><a href="https://knowledgelab.beyondsmartcities.in/index.php/help-support/">Explore Help & Support</a></div>
        </div>
      
        </div>
        
                    <div className="col-md-6  help-layout-2">
                    <img src="assets/images/Customer Support Servicess.png" alt="" className="img-fluid help-section-img" />
        
        </div>
        
        
        
        
        
              </div>
   {/* <div className="container">
             <div className="row mb-4 help-section-s">
                   <h2 className="section-title p-tb-20 text-center heroText1a">Choose an account type for personalized service</h2>
                 <div className="col-md-3">
                     
                       <div className="card specialist">
                          <h5><a href="become-specialist.php">I am a specialist </a></h5> 
                       </div>
                     
                 </div>
                 <div className="col-md-3">
                     
                       <div className="card Client">
                          <h5>I am a Client</h5> 
                       </div>
                     
                 </div>
                 <div className="col-md-3">
                     
                       <div className="card  Manufacturer">
                          <h5><a href="become-manufacturer.php">I am a Manufacturer</a></h5> 
                       </div>
                     
                 </div>
                 <div className="col-md-3">
                     
                       <div className="card owner">
                          <h5>I am a property owner</h5> 
                       </div>
                     
                 </div>
                 
             </div>
             
             <div className="row mb-4 help-section-t">
                 <div className="row">
                      <h2 className="section-title p-tb-20 text-center heroText1a mt-5"> Browse help categories</h2>
                      
                    <div className="col-md-3">
                      <a href="get-started.php">
                       <div className="card">
                         <img src="assets/images/icons/Get-Started.png" alt="icon" />
                          <h5>Get Started</h5> 
                          <p>Getting Started, How It Works, Fees
& Protection</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="build-your-profile.php">
                       <div className="card">
                         <img src="assets/images/icons/Build-Your-Profile.png" alt="icon" />
                          <h5>Build Your Profile </h5> 
                          <p>Profile setting, Edit Profile, Specialist
programs, services </p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="find-a-Job.php">
                       <div className="card">
                         <img src="assets/images/icons/Find a job in (2).png" alt="icon" />
                          <h5>Find a Job </h5> 
                          <p>Search, Send
Proposals, Interview, Accept offers</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="start-working.php">
                       <div className="card">
                         <img src="assets/images/icons/Start Working.png" alt="icon" />
                          <h5> Start Working
</h5> 
                          <p>Messages, Contracts, Feedback</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="get-paid.php">
                       <div className="card">
                         <img src="assets/images/icons/Get-Paid.png" alt="icon" />
                          <h5> Get Paid</h5> 
                          <p>Get Paid, Payment
Options, Reports, Earnings, Taxes</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="payment-Issues.php">
                       <div className="card">
                         <img src="assets/images/icons/Payment-Issues.png
" alt="icon" />
                          <h5>Payment Issues </h5> 
                          <p>Payment
Schedule, Troubleshooting, Disputes</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="account.php">
                       <div className="card">
                         <img src="assets/images/icons/Account.png" alt="icon" />
                          <h5>Account </h5> 
                          <p>Account Settings, Service
Options, identity Verification</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="trust-and-safety.php">
                       <div className="card">
                         <img src="assets/images/icons/Trust-and-Safety.png" alt="icon" />
                          <h5> Trust & Safety</h5> 
                          <p>Terms of Service, Online Safety, Data</p>
                       </div>
                     </a>
                 </div>
                             <div className="col-md-3">
                      <a href="project-category.php">
                       <div className="card">
                         <img src="assets/images/icons/Project Category.png" alt="icon" />
                          <h5>Project Category </h5> 
                          <p>Beyond smart cities project</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="what’s-new.php">
                       <div className="card">
                         <img src="assets/images/icons/Whats New.png" alt="icon" />
                          <h5>Whats New </h5> 
                          <p>Announcement of new
features, Services and more</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="find-specialist.php">
                       <div className="card">
                         <img src="assets/images/icons/Find-a-Specialist.png" alt="icon" />
                          <h5> Find Specialist</h5> 
                          <p>Post a job,  Find Specialist</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="manage-your-project.php">
                       <div className="card">
                         <img src="assets/images/icons/Manage-Your-Project.png" alt="icon" />
                          <h5>Manage Your Project </h5> 
                          <p>Manage
Contracts, Contracts, Feedback</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="pay-for-work.php">
                       <div className="card">
                         <img src="assets/images/icons/Pay-for-Work.png" alt="icon" />
                          <h5>Pay for Work </h5> 
                          <p>Make
Payments, Reports, Billing,  Invoices,
Disputes </p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="service-options.php">
                       <div className="card">
                         <img src="assets/images/icons/Smart-Product-Services.png" alt="icon" />
                          <h5>Service Options </h5> 
                          <p>Basics, Prime membership </p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="Find-Products.php">
                       <div className="card">
                         <img src="assets/images/icons/Find-Product.png" alt="icon" />
                          <h5>Find Products</h5> 
                          <p>Getting Started, rating and critics</p>
                       </div>
                     </a>
                 </div>
                      <div className="col-md-3">
                      <a href="">
                       <div className="card">
                         <img src="assets/images/icons/Property rating.png" alt="icon" />
                          <h5>Property Rating </h5> 
                          <p>Getting Started, How It Works, Fees
& Protection</p>
                       </div>
                     </a>
                 </div>
                 </div>
             </div>
             <div className="row mb-4 help-section-t">
                      <h2 className="section-title p-tb-20 text-center heroText1a">  Access your account</h2>
               
                      <div className="col-md-3">
                     
                       <div className="card">
                         <img src="assets/images/icons/Manage-Contracts.png" alt="icon" />
                          <h5>Manage Contracts </h5> 
                          <p>Update or manage
active contracts
</p>
                       </div>
                     
                 </div>
                      <div className="col-md-3">
                     
                       <div className="card">
                         <img src="assets/images/icons/Update Billing.png" alt="icon" />
                          <h5>Update Billing </h5> 
                          <p>Update or Change your
billing methods 
</p>
                       </div>
                     
                 </div>
                      <div className="col-md-3">
                     
                       <div className="card">
                         <img src="assets/images/icons/Close-Account.png" alt="icon" />
                          <h5>Close Account </h5> 
                          <p>Permanently close
your account</p>
                       </div>
                     
                 </div>
                      <div className="col-md-3">
                     
                       <div className="card">
                         <img src="assets/images/icons/Password-Reset.png" alt="icon" />
                          <h5>Rest Password
</h5> 
                          <p>Change your password </p>
                       </div>
                     
                 </div>
             </div>
             
          
         </div> */}
</div>
    

            </div>
        )
    }
}

import React from "react";
import DashboardSidebar from "./DashboardSidebar";
import ManufacturerDashboardSidebar from "./manufacturer/ManufacturerDashboardSidebar";
import SpecialistDashboardSidebar from "./specialist/SpecialistDashboardSidebar";

export const SidebarCommonMenuItem = ({ currentSelectedDashboard }) => {
  return (
    <div>
      {currentSelectedDashboard == "" ? <DashboardSidebar /> : ""}

      {currentSelectedDashboard == "customer" ? <DashboardSidebar /> : ""}

      {currentSelectedDashboard == "specialist" ? (
        <SpecialistDashboardSidebar />
      ) : (
        ""
      )}

      {currentSelectedDashboard == "manufacturer" ? (
        <ManufacturerDashboardSidebar />
      ) : (
        ""
      )}
    </div>
  );
};

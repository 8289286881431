import axios from "axios";
import { API_URL } from "../config";

//const API_URL = "http://52.66.197.77:5000/";

class AuthService {
    
  login(email, password) {
    return axios
      .post(API_URL + "user/login", { email, password })
      .then((response) => {
        console.log('OO',response.data.token)
        if (response.data.token) {
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem("user", JSON.stringify(response.data.userInfo));
          localStorage.setItem('currentSelectedDashboard', '') 
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("currentSelectedDashboard");
  }

  register(firstName,lastName, email, phoneNumber, password,role ) {
    console.log('Register Clicked2')
    return axios.post(API_URL + "user/signup", {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      role
    })
    
   
  }
}

export default new AuthService();
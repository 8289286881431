import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactSearchBox from "react-search-box";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Breadcrumb,
    Spinner,
  } from "react-bootstrap";
  

class SearchBoxTop extends Component {
  constructor(props) {
    super(props);    
    this.state={
        typedValue:''
    }
  }
  data = [
    {
      key: "Sustainability Specialist Services",
      value: "Sustainability Specialist Services",
    },
    {
      key: "Sustainability Assessments & Reports",
      value: "Sustainability Assessments & Reports",
    },
    {
      key: "Sustainability & ESG Strategy Development",
      value: "Sustainability & ESG Strategy Development",
    },
    {
      key: "Environmental Approvals & Management Systems",
      value: "Environmental Approvals & Management Systems",
    },
    {
      key: "Hydrology & Hydrodynamics",
      value: "Hydrology & Hydrodynamics",
    },
  ];


searchService=(keyword)=>{
console.log(keyword)
this.props.history.push({
  pathname: '/specialists/'+keyword+'/none',
   
})
  }


handleSearchchange(keyword){
    this.setState({
        typedValue:keyword
    })
}

searchServicebyButton(){
    this.props.history.push({
        pathname: '/specialists/'+this.state.typedValue+'/none',
         
      })
}

  render() {
    return (
      <Row>
        <Col className="col-md-9 pe-0">
          <ReactSearchBox
            style={{ width: 500 }}
            placeholder="Start Typing Services"
            onSelect={(record) => this.searchService(record.item.value) }
            onFocus={() => {
              console.log("This function is called when is focussed");
            }}
            onChange={(item)=>this.handleSearchchange(item)}
            value="Sustainability & ESG Strategy Development"
            data={this.data}
            callback={(record) => console.log(record)}
          />
        </Col>
        <Col className="col-md-3">
          <button
            className="btn btn-outline-success"
            type="submit"
            onClick={() => this.searchServicebyButton()}
          >
            Search
          </button>
        </Col>
      </Row>
    );
  }
}

export default withRouter(SearchBoxTop);
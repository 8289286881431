import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./store";
import { ChakraProvider } from '@chakra-ui/react';
import ChatProvider from './components/Chat/Context/ChatProvider';


ReactDOM.render(
  <Provider store={store}>
  {/* <ChakraProvider>  */}
  <ChatProvider>
    <App />
    </ChatProvider>
  {/* </ChakraProvider> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

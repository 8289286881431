import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import {validate_Alpha_Length} from '../../../helpers/Utilities';
import {validate_Alpha_Email_Length} from '../../../helpers/Utilities';
import {validate_MobilePhone_Length} from '../../../helpers/Utilities';




export const ContactInfo = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangeContactInfo,
  values,
}) => {
   // CHECK ERRORS
   const [nameErrorCP, setnameErrorCP] = useState(false);
   const [emailErrorCP, setemailErrorCP] = useState(false);
   const [mobileNumberErrorCP, setmobileNumberErrorCP] = useState(false);
   const [designationErrorCP, setdesignationErrorCP] = useState(false);

   const [nameErrorACP, setnameErrorACP] = useState(false);
   const [emailErrorACP, setemailErrorACP] = useState(false);
   const [mobileNumberErrorACP, setmobileNumberErrorACP] = useState(false);
   const [designationErrorACP, setdesignationErrorACP] = useState(false);
  
   const validateField_CP = (fieldName) => (e) => {
        
     if (fieldName === "name") {
       handleChangeContactInfo("name", "contactPerson", e.target.value); 
       e.target.value
         ? setnameErrorCP(false)
         : setnameErrorCP(true);
     } else if (fieldName === "email") {
       handleChangeContactInfo("email", "contactPerson", e.target.value);
       e.target.value
         ? setemailErrorCP(false)
         : setemailErrorCP(true);

     } else if (fieldName === "mobileNumber") {
       handleChangeContactInfo("mobileNumber", "contactPerson", e.target.value);
       validate_MobilePhone_Length (e.target.value)
      ? setmobileNumberErrorCP(false)
      : setmobileNumberErrorCP(true);

     } else if (fieldName === "designation") {
       handleChangeContactInfo("designation", "contactPerson", e.target.value);
       values.contactPerson.designation.length <= 1
         ? setdesignationErrorCP(true)
         : setdesignationErrorCP(false);
     } 
  
  };

   

   const validateField_ACP = (fieldName) => (e) => {
    if(e.target.value){ 
    if (fieldName === "name") {
      handleChangeContactInfo("name", "alternateContactPerson", e.target.value)
      e.target.value
          ? setnameErrorACP(false)
          : setnameErrorACP(true);       
    } else if (fieldName === "email") {
      handleChangeContactInfo("email", "alternateContactPerson", e.target.value);
      e.target.value
        ? setemailErrorACP(false)
        : setemailErrorACP(true);
    } else if (fieldName === "mobileNumber") {
      handleChangeContactInfo("mobileNumber", "alternateContactPerson", e.target.value);
      validate_MobilePhone_Length (e.target.value)
      ? setmobileNumberErrorACP(false)
      : setmobileNumberErrorACP(true);
    } else if (fieldName === "designation") {
      handleChangeContactInfo("designation", "alternateContactPerson", e.target.value);
      values.alternateContactPerson.designation.length <= 1
        ? setdesignationErrorACP(true)
        : setdesignationErrorACP(false);
    } 
  }
};


  

 
   const Continue = (e) => {
     e.preventDefault();
     console.log('TEST',values.contactPerson.mobileNumber)
     
     if (      
      validate_Alpha_Length(values.contactPerson.name) &&
      validate_Alpha_Email_Length(values.contactPerson.email) &&    
      validate_MobilePhone_Length (values.contactPerson.mobileNumber) &&
      validate_Alpha_Length(values.contactPerson.designation) &&
      validate_Alpha_Length(values.alternateContactPerson.name) &&
      validate_Alpha_Email_Length(values.alternateContactPerson.email) &&    
      validate_MobilePhone_Length (values.alternateContactPerson.mobileNumber) &&
      validate_Alpha_Length(values.alternateContactPerson.designation)
     ) {
       nextStep();
     } else {
       console.log('herer2')      
       
       validate_Alpha_Length(values.contactPerson.name)
       ? setnameErrorCP(false)
       : setnameErrorCP(true);


      validate_Alpha_Email_Length(values.contactPerson.email)
      ? setemailErrorCP(false)
      : setemailErrorCP(true); 

     
      validate_MobilePhone_Length (values.contactPerson.mobileNumber)
      ? setmobileNumberErrorCP(false)
      : setmobileNumberErrorCP(true);


      validate_Alpha_Length(values.contactPerson.designation)
      ? setdesignationErrorCP(false)
      : setdesignationErrorCP(true); 

         
      validate_Alpha_Length(values.alternateContactPerson.name)
      ? setnameErrorACP(false)
      : setnameErrorACP(true);


      validate_Alpha_Email_Length(values.alternateContactPerson.email)
      ? setemailErrorACP(false)
      : setemailErrorACP(true); 

      
      validate_MobilePhone_Length (values.alternateContactPerson.mobileNumber)
      ? setmobileNumberErrorACP(false)
      : setmobileNumberErrorACP(true);


      validate_Alpha_Length(values.alternateContactPerson.designation)
      ? setdesignationErrorACP(false)
      : setdesignationErrorACP(true);      
     }
     
   };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                      <Row>
                        <Col>
                          <h2>Contact Person Details</h2>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: John"
                              onChange={validateField_CP("name")}
                              className={
                                nameErrorCP ? "hasError" : "valid"
                              }
                              //onChange={handleChangeContactInfo("name", "contactPerson")}
                              value={values.contactPerson.name}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"
                              onChange={validateField_CP("email")}
                              // onFocus={validateField_CP("email")}
                              // onPaste={validateField_CP("email")}
                              className={
                                emailErrorCP ? "hasError" : "valid"
                              }
                              //onChange={handleChangeContactInfo("email", "contactPerson")}
                              value={values.contactPerson.email}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"
                              onChange={validateField_CP("mobileNumber")}
                              className={
                                mobileNumberErrorCP ? "hasError" : "valid"
                              }
                             // onChange={handleChangeContactInfo("mobileNumber", "contactPerson")}
                              value={values.contactPerson.mobileNumber}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"
                              onChange={validateField_CP("designation")}
                              className={
                                designationErrorCP ? "hasError" : "valid"
                              }
                             // onChange={handleChangeContactInfo("designation", "contactPerson")}
                              value={values.contactPerson.designation}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <h2>Alternate Contact Person Details</h2>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: John"
                              onChange={validateField_ACP("name")}
                              className={
                                nameErrorACP ? "hasError" : "valid"
                              }
                              //onChange={handleChangeContactInfo("name", "alternateContactPerson")}
                              value={values.alternateContactPerson.name}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"

                              onChange={validateField_ACP("email")}
                              className={
                                emailErrorACP ? "hasError" : "valid"
                              }
                              //onChange={handleChangeContactInfo("email", "alternateContactPerson")}
                              value={values.alternateContactPerson.email}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe" 
                              onChange={validateField_ACP("mobileNumber")}
                              className={
                                mobileNumberErrorACP ? "hasError" : "valid"
                              }
                              //onChange={handleChangeContactInfo("mobileNumber", "alternateContactPerson")}
                              value={values.alternateContactPerson.mobileNumber}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"
                              onChange={validateField_ACP("designation")}
                              className={
                                designationErrorACP ? "hasError" : "valid"
                              }                       
                             
                              value={values.alternateContactPerson.designation}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  FaCompass,
  FaEuroSign,
  FaBox,
  FaPercent,
  FaPaperPlane,
  FaFileVideo,
  FaRupeeSign,
  FaUser,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCircle,
  FaUserNinja,
  FaUsersCog,
} from "react-icons/fa";
import { Accordion } from "react-bootstrap";

export default class SmartProProductsServices extends Component {
  render() {
    return (
      <div>
        <div class="Nature-banner inner-page">
          <div class="row">
            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/ProductsandServices.jpg" alt="img" />
            </div>
            <div class="col-md-6 bg-22 p-0">
              <div class="p-5">
                <h1 className="heroText1">Smart Pro Products & Services</h1>
                <p className="heroText2">
                  The Beyond Smart Cities the SmartPro Products Program in 2020.
                  This program is designed to clarify environmental standards
                  for products and promote environmentally friendly products. A
                  SmartPro Eco-Label is affixed to those products meeting our
                  environmental standards, thus certifying them as SmartPro
                  Eco-Products. Our objective is to provide straightforward
                  information on the environmental considerations incorporated
                  into products to assist customers in the decision-making
                  process when selecting a product. SmartPro encourages the
                  product manufacturer to implement green measures at every
                  stages of product life cycle including product design, product
                  performance during use, raw materials, manufacturing process,
                  recycling/disposal, etc.
                </p>
              </div>
            </div>
          </div>

          <div class="row  bg-22 justify-contents-center align-items-center">
            <div class="col-md-6 bg-22 p-0">
              <div class="p-5">
                <h1 className="heroText1">
                  Green Business Means Good Business!
                </h1>
                <p className="heroText2">
                  With greener products and services on the market, we all stand
                  to benefit: consumers, businesses and the environment.
                </p>
                <div class="solidBtn2 mt-3">
                  <a class="fw-600" href="/dashboard">
                    Become a Manufacturer
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/ProductsandServices2.jpeg" alt="img" />
            </div>
          </div>

          <div class="row bg-22 justify-contents-center align-items-center">
            <div class="col-md-6 hire-img-layout p-0">
              <img src="assets/images/trust-safety.jpg" alt="img" />
            </div>
            <div class="col-md-6 bg-22 p-0">
              <div class="p-5">
                <h1 className="heroText1">
                  Become a Beyond Smart Cities Approved Manufacturer
                </h1>
                <p className="heroText2">
                  As a registered user, you can
                  <ul>
                    <li>Post smart Products or Technology in the database</li>
                    <li>Maintain your profile</li>
                    <li>Track your submissions</li>
                    <li>Contact Potential Partners</li>
                    <li>Use the specialists database</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div
            class="row bg-21 justify-contents-center align-items-center"
            style={{
              marginTop: -2,
              background:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(assets/images/engin-akyurt-3688262.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div class="col-md-8 offset-md-2 p-0 text-center">
              <div class="p-5">
                <h1 className="heroText1 c1">
                  How to Upload Products or Services?
                </h1>
                <p className="heroText2 c1">
                  To upload a Product or information about a green technology in
                  the Beyond Smart Cities SmartPro database, first log in to the
                  BEYOND SMART CITIES portal using your manufacture account
                  credentials. You will then have the option to submit a
                  technology or products Information .<br />
                  If you are not yet a BEYOND SMART CITIES user, first create a
                  BEYOND SMART CITIES account. Anyone can create a manufacture
                  account, it is easy and free of charge
                </p>
                <div class="solidBtn2 mt-3">
                  <a
                    class="fw-600"
                    target="_blank"
                    href="assets/images/documents/SmartPro-Online-Submission.pdf"
                  >
                    SmartPro Database Upload Guide
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row bg-22 justify-contents-center align-items-center">
            <div class="col-md-6 bg-22 p-0">
              <div class="p-5">
                <h1 className="heroText1">About Smart Pro Rating System</h1>
                <p className="heroText2">
                  Our goal at SmartPro Rating system is to highlight products
                  that are healthy for you and healthy for the planet.While the
                  demand for environmentally sustainable products has only grown
                  exponentially in recent years, the need for sustainable
                  solutions is a timeless problem.
                  <br />
                  SmartPro –Product Rating system is developed by Beyond Smart
                  Cities in India. SmartPro assesses ‘How Green is a product’
                  based on a holistic product life cycle framework and
                  highlights the way forward to achieve excellence in
                  environmental performance. The certification system guides the
                  manufacturers to position their products as ‘Green’.
                </p>
              </div>
            </div>

            <div class="col-md-6 hire-img-layout p-0 align-items-center justify-contents-center text-center">
              <img
                style={{
                  borderRadius: 460 / 2,
                  width: 460,
                  height: 460,
                  borderWidth: 50,
                  borderColor: "#ffe5a6",
                  borderStyle: "solid",
                }}
                src="assets/images/8865188.jpg"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { SET_SERVICE_CART, CLEAR_SERVICE_CART, GET_SERVICE_CART } from "../actions/types";

const cartItems = JSON.parse(localStorage.getItem("cartItems"));
  
const initialState = cartItems
  ? { cartHasItem: true, cartItems }
  : { cartHasItem: false, cartItems: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SERVICE_CART:
      return { 
        ...state,
        cartItems: payload.cartItems };

    case CLEAR_SERVICE_CART:
      return { ...state,
        cartItems: "" };

    case GET_SERVICE_CART:
        return { ...state,
            cartItems: payload };    

    default:
      return state;
  }
}
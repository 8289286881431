import React, { Component } from "react";
import { Col } from "react-bootstrap";

export default class LeaderShip extends Component {
  render() {
    return (
      <div>
        <section>
          <div className="Nature-banner inner-page">
            <div className="container">
              <div className="row">
                <Col>
                  <h2 className="heroText1 text-center pb-4">Our Leaders</h2>
                </Col>
              </div>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',}}
                    src="assets/images/Client.png"
                    alt=""
                    className="img-fluid about-img "
                  />
                  <div className="text-center">
                    <h2>
                      KRISHNAJI PAWAR <br />
                    </h2>
                    <p>CEO & FOUNDER</p>
                    <p className='="heroText px-2 px-md-5'>
                      “Be a global citizen. Act with passion and compassion.
                      Help us make this world safer and more sustainable today
                      and for the generations that will follow us. That is our
                      moral responsibility.”{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-tb-20">
                  <p>
                    Krishnaji Pawar is founder and CEO of Beyond Smart Cities.
                    Before being named CEO in January 2020, Krishnaji held
                    leadership roles at Beyond Smart Cities in both
                    Sustainability ,Energy & Environmental Consultancy.{" "}
                  </p>
                  <p>
                    Specialized in developing sustainable design strategies for
                    Green Building Certification Systems (LEED, GSAS, etc.),
                    Energy & Water Conservation, Commissioning, Environmental
                    Impact Assessment & Environmental Management Systems.
                  </p>
                  <p>
                    Currently responsible for 3,767 million square feet Green
                    Building /Energy modeling Consulting since January 2007 in
                    UAE, India and Qatar.Having been at Green Building
                    Technology platform since 2007, he is known for bold
                    leadership and his passion for the company’s mission to
                    create economic opportunities so that people live better
                    lives and he’s committed to building a sustainable,
                    high-performing business that delivers on that mission in
                    ever-bigger ways.Before joining Beyond Smart Cities,
                    Krishnaji led sustainability development for Egis
                    International, Qatar & held corporate sustainability
                    development roles at Arab Engineering Bureau, Qatar. He
                    began his career as an Energy Engineer at Pacific Controls
                    and Company after earning an Engineering Degree in
                    Mechanical Engineering f rom Visvesvaraya Technological
                    University, Belgaum, India
                  </p>
                </div>
              </div>

<hr/>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',borderRadius:250/2 }}
                    src="assets/images/Laxmibai.jpeg"
                    alt=""
                    className="img-fluid about-img mb-2"
                  />
                  <div className="text-center">
                    <h2 className="mb-2">
                    LAXMIBAI PAWAR <br />
                    </h2>
                    <p>FOUNDING DIRECTOR</p>
                   
                  </div>
                </div>
                <div className="col-md-6 p-tb-20">
                  <p>
                  Laxmibai Pawar, Founding Director of Beyond Smart Cities, is also one of the Board Member
at the Beyond Green Building Council(BGBC),India. She is an experienced Director with a
demonstrated history of social entrepreneurship in earth friendly products, environment and
education. 
                  </p>
                  <p>
                  Through Beyond Smart Cities and Beyond Green Building Council, her vision for a clean,
green and safe earth has manifested into popular earth friendly products and technology
used by many rural development and business units.
                  </p>                 
                </div>
              </div>

              <hr/>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',borderRadius:250/2 }}
                    src="assets/images/ShreyankaMallikarjun.jpeg"
                    alt=""
                    className="img-fluid about-img mb-2"
                  />
                  <div className="text-center">
                    <h2 className="mb-2">
                    SHREYANKA MALLIKARJUN <br />
                    </h2>
                    <p>ENVIRONMENTAL SUSTAINABILITY
ADVISORY-BOARD MEMBER</p>
</div>
</div>
                <div className="col-md-6 p-tb-20">
                    <p className='="heroText px-2 px-md-5'>
                    Shreyanka Mallikarjun, Environmental Sustainability Advisory and Board Member of Beyond
Smart Cities, is also Professor & Head of the Department in AGM College of Engineering and
Technology,Hubballi, Karnataka ,India. Working as a professor from past 8 years and presently
pursuing Doctorate of Philosophy in quality of Groundwater Surrounding Municipal Waste
Dumping Yards.Master Degree in Environmental Engineering and Bachelor of Engineering in
Civil Engineering.Guided 14 batches of Civil Engineering students for their project work with
emerging topics of Environmental Engineering.Attended many National level technical
seminars related to Environmental Engineering ,published a technical research paper in
International Journal of Future Research and Development on Characterisation of Dairy
Industrial Wastewater-A case study of KMF,Dharwad Wastewater
                    </p>
                  </div>
                </div>               
            

              <hr/>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',borderRadius:250/2 }}
                    src="assets/images/Sujith-Kumar.jpeg"
                    alt=""
                    className="img-fluid about-img mb-2"
                  />
                  <div className="text-center">
                    <h2 className="mb-2">
                    SUJITH KUMAR

 <br />
                    </h2>
                    <p>CIVIL AND STRUCTURAL ENGINEERING
ADVISORY-BOARD MEMBER
</p>
</div>
</div>
                <div className="col-md-6 p-tb-20">
                    <p className='="heroText px-2 px-md-5'>
                    Sujith Kumar, Civil & Structural Engineering Advisory and Board Member of Beyond Smart
Cities, is also Lead Structural Engineer having more than 20 years of experience in the design of
various complex infrastructures such as Underground Metro Stations, Tunnels, Bridges, Flyovers,
Industrial Structures and Marine Structures. .Master Degree in Structural Engineering, and
Bachelor of Engineering in Civil Engineering.Excellent Design knowledge in Steel and Concrete
structures, which includes technical construction know-how of buildability in value engineering
principles. Mentoring junior team members and developing their technical competency. Worked
with International Codes and Standards such as ISO, BS, CIRIA, British Ports Association and
Eurocodes. Experience in various design report preparation and technical letters.
                    </p>
                  </div>
                           
              </div>

              <hr/>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',borderRadius:250/2 }}
                    src="assets/images/Manoj-joseph.jpg"
                    alt=""
                    className="img-fluid about-img mb-2"
                  />
                  <div className="text-center">
                    <h2 className="mb-2">
                    MANOJ JOSEPH


 <br />
                    </h2>
                    <p>HEALTH SAFETY ENVIRONMENT
ADVISORY-BOARD MEMBER
</p>
</div>
</div>
                <div className="col-md-6 p-tb-20">
                    <p className='="heroText px-2 px-md-5'>
                    Manoj Joseph, Health Safety Environment Advisory Board Member of Beyond Smart Cities, is
also a Masters in Business Administration MBA Specialty in Safety Management and Health and
Safety professional with 3 years Indian and 20 years GCC experience. Worked across a diverse
range of sectors including petrochemical, building, infrastructure oil and gas industries.A highly
motivated team player with good organizational skills, proven track record with the ability to
work in fast paced challenging environment involved in EH&S management. Skilled in ISO 45001
& 14001, Petroleum, Workplace Safety, Safety Management Systems, and Hazard Analysis
                    </p>
                  </div>
                           
              </div>

              <hr/>

              <div className="row mt-4">
                <div className="col-md-6 text-center">
                  {/* <!--<p>Facilitating businesses and specialists reach their full potential</p>--> */}

                  <img
                    style={{ width: 250,height:250,objectFit:'cover',objectPosition:'top',borderRadius:250/2 }}
                    src="assets/images/Rajeswari.jpeg"
                    alt=""
                    className="img-fluid about-img mb-2"
                  />
                  <div className="text-center">
                    <h2 className="mb-2">
                    RAJESHWARI PAWAR
 <br />
                    </h2>
                    <p>SUSTAINABILITY SPECIALIST
</p>
</div>
</div>
                <div className="col-md-6 p-tb-20">
                    <p className='="heroText px-2 px-md-5'>
                    Sustainability Specialist, self-driven, disciplined, highly organized and offering over 4 years of
extensive International Experience and Knowledge in Sustainability and Environmental
Management, across a variety of industrial settings. Specialized in Developing Sustainable Design
Strategies for Gulf Organisation Research & Development (GORD)- GSAS. I am working with
Beyond Smart Cities as an Sustainability Specialist. Here, everyday is a new day and brings an
opportunity to learn and take a step closer towards knowledge and growth.
                    </p>
                  </div>
                            
              </div>


             



            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  FaCompass,
  FaEuroSign,
  FaBox,
  FaPercent,
  FaPaperPlane,
  FaFileVideo,
  FaRupeeSign,
  FaUser,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCircle,
  FaUserNinja,
  FaUsersCog,
} from "react-icons/fa";
import { Accordion } from "react-bootstrap";

export default class SmartProProductsResources extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div
            className="row bg-21 justify-contents-center align-items-center"
            style={{
              marginTop: -2,
              background:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(assets/images/engin-akyurt-3688262.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div class="col-md-8 offset-md-2 p-0 text-center">
              <div class="p-5">
                <h1 className="heroText1 c1">
                  SmartPro Products Tools & Resources
                </h1>
                <div className="row">
                  <div className="col bg-21 py-5 px-2">
                    <span className="heroText2a"> SmartPro</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">Manuals</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#manuals">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-22 py-5 px-2">
                    <span className="heroText2a"> SmartPro</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4"> Standards</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#standards">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-23 py-5 px-2">
                    <span className="heroText2a"> SmartPro</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">Newsletter</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#newsletters">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-24 py-5 px-2">
                    <span className="heroText2a"> SmartPro</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">FAQ</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="/smartpro-faq">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="manuals"
            className="row bg-21 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/manuals.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-21 p-5">
              <h1 className="heroText1">SmartPro Reference Manuals</h1>
              <p>
                Smart Product Certification has been made possible through the
                efforts of many volunteers, Smart Products members and Services
                Council, manufacturing industry representatives, product experts
                and sustainable consultants.
              </p>

              <div className="row p-4">
                <div className="col-md-3">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Smart Pro Reference Manuals
                  </a>
                </div>
                <div className="col-md-3">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Guidelines for using Smart Pro Logo
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            id="standards"
            className="row bg-22 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/standards.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-22 p-5">
              <h1 className="heroText1">SmartPro Standards</h1>
              <p>
                SmartPro Standard helps consumers better understand the growing
                number of green and eco-friendly products in the marketplace.
                Their standards take a comprehensive life-cycle view of every
                product and provide a ranking of degree of 'greenness' explained
                with a simple symbol and a report.
              </p>

              <div className="row p-4">
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Cement{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Energy Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Water Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    LED Lights{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Cleaning Chemicals{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>




          <div
            id="newsletters"
            className="row bg-23 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/newsletters.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-23 p-5">
              <h1 className="heroText1">SmartPro Newsletters</h1>
              <p>
              SmartPro Newsletter is a periodic publication that provides information about certified products with their green features, initiatives
taken by BSC for promoting Smart Products in the market and the latest information on this subject. It aims at creating awareness
amongst all stakeholders about smart products, materials and technologies and their benefits in building and manufacturing sectors.
              </p>

              <div className="row p-4">
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Cement{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Energy Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Water Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    LED Lights{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Cleaning Chemicals{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>





        </div>
      </div>
    );
  }
}

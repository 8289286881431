import React, { Component } from "react";
import { withRouter } from "react-router";
import ReactSearchBox from "react-search-box";

class SearchBox extends Component {
  constructor(props) {
    super(props);    
  }
  data = [
    {
      key: "Sustainability-and-Technology",
      value: "Sustainability and Technology",
    },
    {
      key: "Smart-Cities-and-Infrastructure",
      value: "Smart Cities and Infrastructure",
    },
    {
      key: "Green-Building-Technology",
      value: "Green Building Technology",
    },
    {
      key: "Energy-Efficiency-and-Commissioning",
      value: "Energy Efficiency and Commissioning",
    },
    {
      key: "Health-Safety-Environment-and-Fire-Safety",
      value: "Health Safety Environment and FireSafety",
    },

    {
      key: "Emerging-Design-and-Technology",
      value: "Emerging Design and Technology",
    },
    {
      key: "Building Materials",
      value: "Building Materials",
    },
    {
      key: "Indoor-Environment-Quality",
      value: "Indoor Environment Quality",
    },    
  ];


searchService=(keyword)=>{
console.log(keyword)
this.props.history.push({
  pathname: '/specialists/'+keyword,
   
})
  }

  render() {
    return (
      <ReactSearchBox
      style={{width:500}}
        placeholder="Start Typing Services"             
        onSelect={(record)=> this.searchService(record.item.key) }
        onFocus={() => {
          console.log("This function is called when is focussed");
        }}
        value="Sustainability & ESG Strategy Development"
        data={this.data}
        callback={(record) => console.log(record)}
      />
    );
  }
}

export default withRouter(SearchBox);
import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import CardBoxLoading from "../helpers/CardBoxLoading"
import { Link } from "react-router-dom";
import {FaRocket, FaRupeeSign } from "react-icons/fa";
export default class SmartProducts extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
      category:this.props.match.params.cat.replace(/-/g, " "),
      subCategory:this.props.match.params.subcat.replace(/-/g, " "),
      productInfo:[],
      productList:[],
      isdataFetched:false,
      serviceId:''
    }
  }

  componentDidMount() {
    //this.getProducts();
    console.log(this.props)
    this.getProductsWithQuery();
  }


  componentDidUpdate(prevProps) {
    console.log('ii',prevProps.match.params.childcat)

    console.log('ii2',this.props.match.params.childcat)

    if (this.props.match.params.childcat !== prevProps.match.params.childcat) {
   
      // call the fetch function again
      console.log('yes')

      this.setState({
        category:this.props.match.params.cat.replace(/-/g, " "),
        subCategory:this.props.match.params.subcat.replace(/-/g, " "),
        childCategory:this.props.match.params.childcat.replace(/-/g, " "),
      })
    
       this.getProductsWithQuery();
   
  }
}
 

  goToSpecialistDetail(specialistId){
    console.log('p')
    this.props.history.push({
      pathname: '/specialist-information/'+specialistId           
    })

// this.props.history.push('/specialist-information')

  }


  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }




  async getProductsWithQuery() {
    var self = this;

    console.log('called')

    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,

    });

    const token = await JSON.parse(localStorage.getItem("token"));
    let data={
      category: this.state.category,
      subCategory: this.state.subCategory,
      childCategory:this.state.childCategory
    }
    console.log(data)

    // let api="http://0fa5-2405-201-c02d-210b-8575-a12b-738a-5d3.ngrok.io/"

    await axios
      .get(API_URL + "product/search/", 

      { params: {
        category: self.state.category,
        subCategory:self.state.subCategory,
      }},
      
      
      {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('SPECINFO11',response);

          if (response.data.success === true) {
            self.setState({
                productList:response.data.productsList
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              isdataFetched: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              isdataFetched: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async getProducts() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "product/approved/list", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('CAT',response);

          if (response.data.success === true) {
            self.setState({
            productList:response.data.productsList
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>                  
                  <Breadcrumb.Item active>Services</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="top-notice">
                  {this.state.productList &&
                  !this.state.productList?.length &&
                  this.state.isdataFetched ? (
                    <p>No Smart Products found for your selection</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2>Listing Smart Products</h2>
              </Col>
            </Row>
            <Row class=" Sustainable-inner-design">
              {this.state.productList &&
              this.state.productList?.length ? (
                this.state.productList.map((item) => (
                  <div class="col-md-3 cursor-pointer mb-3">                    
                      <div class="card-r  shd-1">

                      {item.productInfo.image.length ?
                            <img
                            onClick={() => this.goToServiceDetail(item._id,item.createdBy._id)}
                              src={item.productInfo.image[0]?.secureUrl}
                              alt="Person"                             
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                            :
                            <img
                            onClick={() => this.goToServiceDetail(item._id,item.createdBy._id)}
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              height="180"
                              style={{objectFit:'cover'}}
                            />}
                       
                        <div class="card-content">
                          <div class="chip">
                            {item.createdBy?.customerId?.profile?.image?.secureUrl ?
                            <img
                              src={item.createdBy?.customerId?.profile?.image?.secureUrl}
                              alt="Person"
                              width="96"
                              height="96"
                            />
                            :
                            <img
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              width="96"
                              height="96"
                            />}
                            <Link
                              onClick={() =>
                                this.goToSpecialistDetail(item.createdBy?._id)
                              }
                            >
                              {" "}
                              <span className="fw-900">{item?.manufacturerId?.applicantInfo?.nameOfCompany}</span>
                            </Link>
                          </div>
                          <h3 className="mb-0 c3"
                          onClick={() => this.goToServiceDetail(item._id,item.createdBy?._id)}>
                            {item.productInfo.brandName}                           
                          </h3>
                          <p className="fs-12">{item?.manufacturerId?.applicantInfo?.address?.state}</p>
                         
                        </div>
                      </div>                   
                  </div>
                ))
              ) : (
                <>
              
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                  <CardBoxLoading />
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

import React from "react";
import { Container, Row, Col, Form,Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {validate_Alpha_Length} from '../../../helpers/Utilities';
import {validate_Alpha_Email_Length} from '../../../helpers/Utilities';
import {validate_MobilePhone_Length, validate_Desc100_Length} from '../../../helpers/Utilities';

export const Personal = ({ prevStep, nextStep, handleChange, handleChangePersonalInfo, values }) => {
  const Continue = (e) => {
    e.preventDefault();


    if (    
      
      validate_Alpha_Length(values.firstName) &&
      validate_Alpha_Length(values.lastName) &&
     validate_Desc100_Length(values.description)  
     ){       
      nextStep();
     }

     else{
      toast("Please check input fields", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
     }

    
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
          

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                  <div className="row">
            <div class="col mt-3">
              <h2>Get Registered as Green Build Specialist</h2>
              <p>
                Submit your details and get approval in 4 business days.You will
                be notified via email on approval.
              </p>
            </div>
          </div>

                    <Form>
                      <Row className=" mt-4 pb-4">
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>First Name*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: John"
                              onChange={handleChangePersonalInfo("firstName")}
                              value={values.firstName}
                              defaultValue={values.firstName}
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid First Name. This will be used
                              for verification.
                            </Form.Text>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Last Name*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Eg: Doe"
                              onChange={handleChangePersonalInfo("lastName")}
                              value={values.lastName}
                            />

                            <Form.Text className="text-muted">
                              Please Enter Valid Last Name. This will be used
                              for verification.
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                              A Small Description About You* (Min 50 words)
                            </Form.Label>
                            <Form.Control as="textarea" rows={6}
                            onChange={handleChangePersonalInfo("description")}
                            value={values.description}
                            defaultValue={values.description}
                             />
                          </Form.Group>
                        
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center">
                        <Col>                       
                    <Button                
                      onClick={Continue}
                      type="submit"
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      disabled={false}                                
                      className="btn btn-primary btn-block c1"                     
                    >
                      Continue
                    </Button>
                    </Col>
                    </Row>
                  </Container>
                  <Row>
                    <Col>
                    (*) Mandatory Fields.
                    </Col>
                   
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

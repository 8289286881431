import React, { Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {validate_Alpha_Length} from '../../../helpers/Utilities';

export const ProfessionalSplitThree = ({
  prevStep,
  nextStep,
  handleChange,
  handleChangePersonalInfo,
  handleChangeProfessionalSplitThree,
  values,
  addClick,
  removeClick,
  handleRegister
}) => {
  const Continue = (e) => {
    e.preventDefault();
   // if (    
      
      // validate_Alpha_Length(values.education.organizationalMembership.nameOfOrganization) 
    //  validate_Alpha_Length(values.socialPresence) 

   
    // ){       
      nextStep();
    // }

    //  else{
    //   toast("Please check input fields", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //   });
    //  }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container">
         

          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                  <Container>
                    <Form>
                      <Form.Group>
                        <Form.Label>Enter Organization Information</Form.Label>
                        <Form.Text className="text-muted">
                          <p>
                            Enter the higher level of education
                          </p>
                        </Form.Text>{" "}
                      </Form.Group>

                      <Row className="align-item-center justify-content-center ">
                        <Col>
                          {/*  ORGANIZATIONAL MEMBERSHIP */}
                          {/*  NAME OF ORGANIZATION */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Name of Organization*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitThree(
                                "nameOfOrganization"
                              )}
                              value={values.education.organizationalMembership.nameOfOrganization}
                            />
                          </Form.Group>

                          {/*  WORKING SINCE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Year of Post Graduation</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Eg: 31/12/1687"
                              onChange={handleChangeProfessionalSplitThree(
                                "workingSince"
                              )}
                              value={values.education.organizationalMembership.workingSince}
                              defaultValue={values.education.organizationalMembership.workingSince}
                            />
                          </Form.Group>

                          {/*  HONOR and awards */}
                          {/* <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Honors and Awards</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitThree(
                                "honorsAndAwards"
                              )}
                              value={values.education.organizationalMembership.honorsAndAwards.toString()}
                              
                            />
                          </Form.Group> */}
                        </Col>

                        <Col>
                          {/*  PERSONAL WEBSITE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Personal Website(if any)</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="http://"
                              onChange={handleChangeProfessionalSplitThree(
                                "personalWebsite"
                              )}
                              value={values.education.organizationalMembership.personalWebsite}
                              defaultValue={values.education.organizationalMembership.personalWebsite}
                            />
                          </Form.Group>

                          
                        </Col>

                        <Col>
                          {/*  SOCIAL PRESENCE */}
                          {/* <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Social Presence</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitThree(
                                "socialPresence"
                              )}
                              value={values.socialPresence}
                             
                            />
                          </Form.Group> */}

                          {/*  PROFESSIONAL PRESENCE */}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Professional Presence</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              onChange={handleChangeProfessionalSplitThree(
                                "professionalPresence"
                              )}
                              value={values.professionalPresence}
                              defaultValue={values.professionalPresence}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="text-center my-3">
                        <Col>
                          <Button
                            onClick={Previous}
                            type="submit"
                            className="btn btn-primary-trans btn-block  me-2"
                          >
                            Previous
                          </Button>

                          <Button
                            onClick={Continue}
                            type="submit"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={false}
                            className="btn btn-primary btn-block c1"
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
{/* 
                      <div className="form-group py-3">
                              <Button
                             onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                //disabled={this.state.submitProgress}
                                onClick={handleRegister}
                                className="btn btn-primary btn-block c1"
                              >
                              
                                <span className="d-inline p-2">SUBMIT</span>
                              </Button>
                            </div> */}

                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

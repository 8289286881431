import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, Image,Breadcrumb, Spinner } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {BsStarFill, BsStarHalf} from "react-icons/bs";
import {FiAlertTriangle, FiCheck, FiHelpCircle} from "react-icons/fi"
import validator from "validator";
import axios from "axios";
import { API_URL } from "../../../config";
import moment from 'moment';
import DashboardSidebar from '../../DashboardSidebar';
import SpecialistDashboardSidebar from '../SpecialistDashboardSidebar';
import Loader from "react-js-loader";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiBookContent,
  BiUser,  
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiCheck,
  BiCheckDouble,
  BiNotificationOff,
  BiNotification,
  BiBell,
  BiBellOff,
  BiListCheck,
  BiListMinus,
} from "react-icons/bi";
import { HiArrowRight } from 'react-icons/hi';

export default class ServiceLists extends Component {

    constructor(props) {
        super(props);
        this.addNewService = this.addNewService.bind(this);  

             
    
        this.state = {
          notice:false,
          dataLoading:false,
          userData: JSON.parse(localStorage.getItem('user')),
            dobError:'',
            genderError:'',
            professionError:'',
            streetError:'',
            stateError:'',
            cityError:'',
            countryError:'',
            pincodeError:'',
            servicesList:[]
         
        };
}
      componentDidMount() {
    
        if (this.props.isLoggedIn) {
          this.props.history.push("/dashboard");             
        }

        if(this.state.userData?.isEmailVerified){
          this.setState({notice:false})
        }
        else{
          setTimeout(() => {
            this.setState({notice:true})
          }, 3000);
          

        }

        this.getServiceList()
        
      }

      editProfile=()=>{
        this.props.history.push("/profile-edit"); 

      }

dismissNotice=()=>{
  
    this.setState({notice:!this.state.notice})
  }
 
      
    
  addNewService=()=>{
this.props.history.push('/submit-service')
}
   validateField(fieldName)  {
        if (fieldName === "dob") {         

        }
    }


    async getServiceList() {     
      var self = this;     
      self.setState({
        step: 10,
        submitLoading: true,
        errorMessage: "",
        dataLoading:true
      });
  
      
      const token = await JSON.parse(localStorage.getItem("token"));     
      await axios
        .get(API_URL + "service/specialist/me", {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })
  
        .then(
          function (response) {
            console.log("RES",response);           
  
            if (response.data.success === true) {
              self.setState({
                servicesList:response.data.services
              })
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                dataLoading:false
              });  
              
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                dataLoading:false
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              dataLoading:false
            });
          }         
        );
    }    



    render() {   
      
      const userData=this.state.userData
      const createdDate=moment(userData?.createdAt).format('LL');
        return (
          <div>
            <section className="lightgrey-bg user-area log-in-area topadjust specialist-dashboard">
              <div className="container-fluid">
                
                <div className="user-form-content">
                  
                  <div className="row">             

                    <div className="col-md-12">
                      <div className="form-card mt-3">                    
                     
                        <Container fluid>
                        <Breadcrumb>
  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
  <Breadcrumb.Item href="">
    Dashboard
  </Breadcrumb.Item>
  <Breadcrumb.Item active>My Services</Breadcrumb.Item>
</Breadcrumb>

                          {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                          <Row>
                            <Col
                              xs
                              lg="2"
                              className="dashboard-sidebar"
                            >
                              <DashboardSidebar />
                            </Col>

                            <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                                  <Row>
                                    <Col>
                                      <div className="dashboard-content-title">
                                        My Services
                                      </div>
                                    </Col>

                                    {this.state.userData?.role?.includes('specialist')&&
                                    <Col>
                                      <div className="dashboard-content-action float-end">
                                        <Button
                                          onClick={this.addNewService}
                                          type="submit"
                                          onKeyPress={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        > {this.state.spinner ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <span className="d-inline p-2">Add New Service</span>
                                        </Button>
                                      </div>
                                    </Col>
                                 }
                                  </Row>
                                </div>
                              <Row>
                                <Col className="position-relative">
                               

                                {this.state.userData?.role?.includes('specialist')?
                          
                            <ul class="tableList">
                            <li className="listHeader shd-1">
                                <Row className="align-items-center">
                                    <Col md={1}>                                   
                                    </Col>
                                    <Col md={4}>
                                    <div className="property-title">Service Name</div>                                                                 
                                    </Col>
                                    <Col md={5}>
                                    <div className="property-status">Description</div>
                                    </Col>                                    
                                   
                                    <Col>
                                    <div className="property-view">
                                    <Link>Action</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                              </li>  


{this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}

{this.state.servicesList && this.state.servicesList.length?
                                        (  
this.state.servicesList.map((item)=>(
                              <li className="shd-1 py-3">
                                <Row className="align-items-center">
                                    <Col md={1}>
                                    <BiBookContent className="ms-3 fs-2 c11"/>
                                    </Col>
                                    <Col md={4}>
                                    
                                    <div className="table-title fw-700"> <Link to={`/service-details/${item._id}`}>{item.overview.gigTitle}</Link></div>
                                    <div className="service-added-date fs-12">Applied On: {moment(item.createdAt).format('LL')}</div>                                   
                                    </Col>
                                    <Col md={5}>
                                    <div className="table-description fw-700 c3">{item.overview.description}</div>
                                    </Col>                                  
                                   
                                    <Col>
                                    <div className="property-view ">
                                    {/* <Link className="ghost-action-btn" to={`/service-details/${item._id}`}>View</Link> */}
                                    <Link className="ghost-action-btn" to={`/service-details/${item?.overview?.slug ? item?.overview?.slug : item._id }`}>View</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                              </li>  
))
):
                                        (
                                          !this.state.dataLoading?
                                          <li className="text-center p-3 bdr-none">
                                             <BiListMinus className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">Oops! You have not added any services yet. Create one now.</h3>
<p className="text-center">You list of services will be listed here after approvals.</p>

                                            </li>
                                            :''
                                        )
                                      
                                      } 








                              
                           
                            </ul>
    :
    
    <ul>
    <li className="text-center p-3 bdr-none list-inline">
    <BiListMinus className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">Oops! You are not a green build specialist. Become a specialist now.</h3>
<Link
                                      to="/apply-specialist"
                                      class="btn btn-primary-trans fw-700 mt-3"
                                    >
                                     <HiArrowRight />{' '} Submit Application
                                    </Link>
   </li>
   </ul> 
    
    
    
    
    
    }

                            </Col>
                           
                          
                            </Row>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

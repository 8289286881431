import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class WhyGreenBuilding extends Component {
    render() {
        return (
            <div>
                <div  className="Nature-banner inner-page">
         
         <div className="why-green">
     
           <div className="patner-trs">
               
                   
                   
                           <div className="row  text-center">
                            <h2 className="section-title p-tb-20 text-center ">Why GREEN Building</h2>
                            <p>Our buildings can create a healthier, more sustainable future.</p>
         
  
    </div>
                  
               
          
          
                 
          </div> 
          </div>


    
 <div className="container mb-4">
               
           <div className="row">
       <div className="col-md-12 text-center">
           <h2 className="section-title p-tb-20 text-center">Value of BSAS Rating System</h2>
          
           
           <p>BSAS  is  the  leading  tool  for  advancing,  environment,  health  and  well-being  in  buildings  globally.  Register  your  off ice,  school  building  or  other  space  to  leverage  BSAS’s flexible  f ramework  for  improving  environment,  health  and  human  experience  through  design.Available  for  virtually  all  building  types,  BSAS  provides  a  f ramework  for healthy, highly eff icient, and cost-saving green buildings. BSAS certif ication is a globally recognized symbol of sustainability achievement and leadership.</p>
     

           </div>
           
           
           
           
           
           
                 </div>
                 
              
                   
                 
                 
                   <h2 className="section-title p-tb-20 text-center">Health benefits: Stay healthy with BSAS</h2>
                  
<section className="self-icons">
   <div className="row mb-2 ">
       <div className="col-md-2">
           <img src="assets/images/icons/New specialist.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Happier employees, happier occupants</h4>
           <p>Owners in BSAS-certif ied spaces report higher recruitment and retention rates, and increased employee productivity.</p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
          <img src="assets/images/icons/New specialist.png" alt="icon" /> 
       </div>
       <div className="col-md-9">
           <h4>Bringing in the good and keeping out the bad</h4>
           <p>BSAS creates healthier spaces with cleaner air, access to daylight, and f ree f rom harmful chemicals found in paints and f inishings.</p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/GHG and climate change.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Indoor environmental quality </h4>
           <p>Improving indoor air quality can reduce absenteeism and work hours affected by asthma, respiratory allergies, depression and stress, and leads to and self-reported improvements in productivity.</p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/engrgy and Cxa.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Reduced pollution</h4>
           <p>Energy-eff icient buildings help reduce pollution and improve outdoor air quality in major industrialized areas, making BSAS a critical tool in reducing smog.
</p>
       </div>
   </div>
           <h2 className="section-title p-tb-20 text-center">Economic benefits: Save money with BSAS Rating System</h2>
   
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/Reduced pollution.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Gain a competitive edge</h4>
           <p>68% of corporate leaders believe that sustainability leads to market differentiation and improved f inancial performance.</p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/Building Tech.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Attract tenants</h4>
           <p>BSAS-certif ied buildings command the highest rents, while lease-up rates typically range f rom average to 25% above average; vacancy rates for green buildings are an estimated 7% lower than non-green properties. </p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/engrgy and Cxa.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Manage performance </h4>
           <p>BSAS is the world leading green building project and performance management system. It delivers a comprehensive f ramework for green building design, construction, operations and performance.</p>
       </div>
   </div>
     <div className="row mb-4">
       <div className="col-md-2">
           <img src="assets/images/products/Meet ESG goals.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Meet ESG goals  </h4>
           <p>BSAS helps investors meet their ESG goals by providing investors with the robust and globally recognized green building f ramework to measure and manage their real estate performance. BSAS helps investors implement management practices to prioritize building eff iciency, decrease operational costs, increase asset value and ensure productivity, comfort, health and wellbeing for occupants.</p>
       </div>
   </div>
    <h2 className="section-title p-tb-20 text-center">Environmental benefits: Help the environment and reduce carbon with BSAS</h2>
   
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/Meet ESG goals.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Conserving water</h4>
           <p>Expected water savings f rom BSAS commercial and residential buildings is more than 5% of all non-residential water use.</p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/buldingtech.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Reducing waste</h4>
           <p> By 2027, BSAS projects will have diverted more than 170 million tons of waste f rom landf ills. </p>
       </div>
   </div>
     <div className="row mb-2">
       <div className="col-md-2">
           <img src="assets/images/products/Reduced pollution.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Fewer cars, fewer miles driven </h4>
           <p>Nearly 1 billion vehicle miles traveled have been avoided by occupants of BSAS buildings, thanks to eff icient locations and alternative transportation options.</p>
       </div>
   </div>
     <div className="row mb-4">
       <div className="col-md-2">
           <img src="assets/images/products/buldingtech.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Green materials  </h4>
           <p>Certif ied projects are estimated to have specif ied more than Rs 100 billion in green materials, cumulatively.</p>
       </div>
   </div>
     <div className="row mb-4">
       <div className="col-md-2">
           <img src="assets/images/products/Building Tech.png" alt="icon" />
       </div>
       <div className="col-md-9">
           <h4>Implement sustainable strategies  </h4>
           <p>BSAS can help both new and existing buildings to implement sustainable and greener strategies to become high performing buildings.</p>
       </div>
   </div>
      <h2 className="section-title p-tb-20 text-center">  Why Certify Your Property</h2>
      <p>The GSAS plaque OR AWARD stands for leadership and demonstrates that a building has been designed, built and is operating as intended. Being "built to BSAS” or “BSAS equivalency” doesn’t exist—there is no substitute for BSAS certif ication.</p>
     <p><b>Stay engaged: </b>Sign up to receive email about the latest BSAS  updates</p>
               <button><Link to="/signin">Sign UP</Link></button>
</section>       
</div>
</div>
</div> 
           
        )
    }
}

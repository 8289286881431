import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiHelpCircle,
  BiSupport,
  BiMessageDetail,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle, FiX } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";

import moment from "moment";
import DashboardSidebar from "../DashboardSidebar";

export default class QueryDetails extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);

    this.state = {
      propertyId: this.props.match.params.id,
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    console.log(this.state.propertyId);
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    if (this.state.userData.isEmailVerified) {
      this.setState({ notice: false });
    } else {
      setTimeout(() => {
        this.setState({ notice: true });
      }, 3000);
    }
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }

  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Propert Detail</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col
                          xs
                          lg="2"
                          className="dashboard-sidebar"
                        >
                          <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link to="/query-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                  Query
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col className="position-relative">
                              <div className="shd-1 p-3">
                                <Row className="align-items-center">
                                  <Col md={1}>
                                    <BiMessageDetail className="ms-2 fs-2" />
                                  </Col>
                                  <Col md={9}>
                                    <div className="query-title fw-700">
                                      #Q6543
                                    </div>
                                    <div className="query-added-date fs-12">
                                      Created On: 31/12/1933
                                    </div>
                                  </Col>                                  
                                  <Col md={2}>
                                    <div className="query-status fw-700 c9 float-end">
                                      <FiAlertTriangle className="me-1" />{" "}
                                      Pending
                                    </div>
                                  </Col>                                
                                </Row>

                                <Row>
                                  <Col className="mt-3 QueryDetailList">
                                      <Row>
                                          <Col md={1}>
                                          <span className="d-block query-avatar mx-auto"><BiUserCircle /></span>
                                          <p className="queryuser">Sigmato</p>
                                          </Col>
                                          <Col>
                                          <h3> I have signed up for manufacturer last month. But till now i have not received any response from your team. please help</h3>
                                            <div className="queryReplies">
                                                <Row className="mt-3">
                                                    <Col md={1}>
                                                    <span className="d-block query-avatar mx-auto"><BiUserCircle /></span>
                                                    <p className="queryuser">Ramya</p>
                                                    </Col>
                                                    <Col>
                                                    <p>Push notifications are a critical element of almost every mobile application these days.

With so many different tools, approaches, and tech stack combinations out there, it can be hard to figure out the best way to implement push notifications in your next Ionic project.

Join Nicholas Gordon and Matt Irvin from HybridMob (an Ionic Trusted Gold Partner) on October 6 at 10:00 AM PDT / 5:00 PM GMT for a live webinar. Using a case study with H&R Block, they will discuss: </p>
                                                    <div className="replieddate">Replied On: 12/12/2515</div>
                                                    </Col>
                                                </Row>


                                                <Row className="mt-3">
                                                    <Col md={1}>
                                                    <span className="d-block query-avatar mx-auto"><BiUserCircle /></span>
                                                    <p className="queryuser">Ramya</p>
                                                    </Col>
                                                    <Col>
                                                    <p>Push notifications are a critical element of almost every mobile application these days.

With so many different tools, approaches, and tech stack combinations out there, it can be hard to figure out the best way to implement push notifications in your next Ionic project.

Join Nicholas Gordon and Matt Irvin from HybridMob (an Ionic Trusted Gold Partner) on October 6 at 10:00 AM PDT / 5:00 PM GMT for a live webinar. Using a case study with H&R Block, they will discuss: </p>
                                                    <div className="replieddate">Replied On: 12/12/2515</div>
                                                    </Col>
                                                </Row>
                                               
                                            </div>
                                         </Col>
                                      </Row>
                                  
                                  </Col>
                             
                                </Row>                               

                                


                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from 'react'

export default class TrustSafety extends Component {
    render() {
        return (
            <div>
                
     <div  className="Nature-banner inner-page">
         
         <div className="row trust-safety">
             <div className="col-md-12">
                   <div className="trust-safety-banner">
                       <h1 className='heroText1 c1'>Trust Matters Most</h1>
                       <p className='heroText2 c1'>Millions of services. Millions of users.<br/>
                       put your trust & safety is our #1 priority</p>
                   </div>
             </div>
         </div>
         
         
         
         
         
         
      <div className="container">
           <div className="row mb-4 text-center">
            <div className="col-md-12">
                <h2 className="section-title text-center heroText1a pt-4">Getting To Know Your Sellers Is Easy</h2>
                
              
                <p className='heroText2'>Feedback and reputation are essential to the Beyond Smart Work marketplace. With seller ratings, levels and buyer

feedback, we've made it simple to review, compare and purchase the services you need.</p>
                </div>
                
                </div>
              
                <div className="row trust-safety-section-2 mb-4">
                    <div className="col-md-12">
                        
                    </div>
                </div>
                
              
                <div className="row mb-4">
                     <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Seller-Rating.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Seller Ratings</b></h4>
                    <p>After a seller completes a Service,
buyers can rate their experience based
on criteria all made visible to you.</p>
                    
                              </div>
                     </div>
                           
              </div>
                  <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Buyer-Feedback.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Buyer Feedback</b></h4>
                     <p>Full transparency. Buyers can leave
feedback on their interaction with sellers,

and share their order for all to see.</p>
                              </div>
                     </div>
                           
              </div>
                  <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Seller-Levels.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Seller Levels</b></h4>
                 <p>Sellers who deliver high quality work
and maintain strong ratings can earn
new levels of status that provide

additional benefits.</p>
                              </div>
                     </div>
                           
              </div>
                </div>
                
                  <div className="row trust-safety-section-3 mb-4">
                    <div className="col-md-12">
                        
                    </div>
                </div>
                
                  <h2 className="section-title p-tb-20 text-center section-title text-center heroText1a pt-4">  Buy Sell And Rate, Worry Free</h2>
                
                <div className="title-divider"></div>
                
                <p className='heroText1b mb-5 text-center'>With millions of orders taking place, Beyond Smart Work uses the latest high tech anti-f raud and data security

measures to keep your transactions and data safe</p>
                <div className="row">
                     
                     <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Personal-Details.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Personal Details</b></h4>
                    <p>Beyond Smart Cities values your
privacy. Your data is secure at all times
and we'll never share your personal
information with third parties.</p>
                    
                              </div>
                     </div>
                           
              </div>
                   <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Safe-Payments.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Safe Payments</b></h4>
                    <p>All transactions are conducted on the Beyond
Smart Cities platform. Whether a buyer uses a
credit card, PayPal or other form of payment,
we handle everything, and ensure the security
of your personal details. Beyond Smart Cities is
committed to the highest security standards

service provider.</p>
                    
                              </div>
                     </div>
                           
              </div>
                   <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Secure-Communications.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Secure Communications</b></h4>
                    <p>You can safely communicate and
exchange files with any Seller on
Beyond Smart Cities through our
secure messaging system.</p>
                    
                              </div>
                     </div>
                           
              </div>
                 
                </div>
              
              
             
            
            
            
            
                 <div className="service-p text-center">
                  <p><b>We're here to help with any issues you may have, with a friendly customer support team at your service 24/7.</b></p>
                </div>
                
                      </div>
                    
    </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
} from "react-icons/bi";
import {
  HiArrowCircleRight,
  HiArrowRight,
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineUserCircle,
} from "react-icons/hi";

import validator from "validator";
import DashboardSidebar from "../DashboardSidebar";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { GrAlert, GrManual, GrPlan, GrUser, GrUserManager } from "react-icons/gr";
import { FiAlertCircle } from "react-icons/fi";

export default class PrimeMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(localStorage.getItem("user")),      
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
    
  }

  async handleUpload(e) {
    var self = this;
    const formData = new FormData();
    if (e.target.files.length) {
      formData.append("profileImage", e.target.files[0]);
    }

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .put(API_URL + "user/profile/imageEdit", formData, {
        headers: {
          auth: token,
          "Content-Type": "multipart/form-data",
        },
      })

      .then(function (response) {
        console.log("IMAGE UPLOAD", response);
        console.log(response);
        if (response.data.success === true) {
          toast("Profile Photo Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // window.location.reload(false);
          self.getUserDetails();
        } else {
          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }

  async getUserDetails() {
    var self = this;

    const token = await JSON.parse(localStorage.getItem("token"));
    const user = await JSON.parse(localStorage.getItem("user"));
    await axios
      .get(API_URL + "user/" + user?._id, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log("USER", response);

          if (response.data.success === true) {
            self.setState({
              profileImage: response.data?.userData?.profile?.image?.secureUrl,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          toast("Network Error", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  emailVerify = async () => {
    this.setState({
      emailCodeForm: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .post(
        API_URL + "user/emailVerify",
        {},
        {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        }
      )

      .then(function (response) {
        console.log("EV", response);
        console.log(response);
        if (response.data.success === true) {
          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  verifyEmailCode = async () => {
    let self = this;
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .put(
        API_URL + "user/verifyEmailCode",
        { emailVerificationCode: this.state.emailCode },
        {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        }
      )

      .then(function (response) {
        console.log("EV", response);
        console.log(response);
        if (response.data.success === true) {
          this.setState((prevstate) => ({
            userData: {
              ...this.state.userData,
              isEmailVerified: true,
            },
          }));

          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          self.setState({
            emailCodeForm: false,
          });
        } else {
          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData?.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust owner-dashboard">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Row>
                        <Breadcrumb>
                          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                          <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                          <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                        </Breadcrumb>
                      </Row>

                      <Row>
                        {/* MAIN CONTENT */}
                        <Col xs lg="2" className="dashboard-sidebar">
                          <DashboardSidebar />
                        </Col>
                        {/* MIDDEL COLUMN STARTS */}

                        <Col>
                          <Row>
                            {this.state.userData?.role?.includes(
                              "manufacturer"
                            ) ? (
                              <>
                                {this.props.location.search ==
                                  "?membercheck=true" && (
                                  <p className="c3 fs-18 p-2 bdr-rad-4 btn-danger c1">
                                    <FiAlertCircle className="c1" /> You need to
                                    subscribe for a Prime Membership plan to
                                    submit your products.
                                  </p>
                                )}
                                <div className="col-sm-12 col-md-5 text-center position-relative">
                                  <div className="bdr-5 bg-1 p-3 min-h-200 bdr-rad-4 shd-1 mb-2 ">
                                    <div className="box-icons">
                                      <GrPlan />
                                    </div>

                                    <h2>Rs. 9,999(Lifetime)</h2>
                                    <p>
                                      Prime Membership plan allows you to submit
                                      multiple manufacturing products and get
                                      smartpro rating.
                                    </p>
                                    <a >
                                        <Link className="btn btn-primary c1" to="/manufacturer-membership-create-order">
                                      Subscribe Now </Link>
                                    </a>
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-7 text-center position-relative">
                                  <div className="bdr-5 bg-1 p-3 min-h-200 bdr-rad-4 shd-1 mb-2 ">
                                    <h2 className="fw-700 c3 fs-26 py-4">
                                      How does Prime MemberShip Work?
                                    </h2>
                                    <p>
                                      Smart-pro Rating System Our goal at
                                      SmartPro Rating system is to highlight
                                      products that are healthy for you and
                                      healthy for the planet. To that end, we
                                      created the rating system you see outlined
                                      below. We rate all the products in the
                                      smartPro directory with our smartPro
                                      rating system. If a product appears on
                                      this site, it is trustworthy and
                                      sustainably made with as little
                                      environmental impact as possible, however,
                                      some products are more pristine than
                                      others and budget plays a role as well.
                                      Where information is available, we factor
                                      in the carbon emissions and other climate
                                      impacts associated with the production and
                                      operation of a product.
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-sm-12 col-md-12 position-relative">
                                <div className="bdr-5 bg-1 p-3 min-h-200 bdr-rad-4 shd-1 mb-2 py-5">
                                  <Row>                                  
                                  <Col>                                  
                                    <h2 className="btn btn-danger">
                                      You are not allowed to perform this
                                      action!.{" "}
                                    </h2>

                                    <p>Possible Reasons</p>
                                    <ul>
                                      <li>
                                        <h3>
                                          Check if you have applied for becoming
                                          a Manufacturer.{" "}
                                        </h3>
                                      </li>
                                      <li>
                                        <h3>
                                          Check if you have been approved by
                                          Administrator if already applied.
                                        </h3>
                                      </li>
                                      <li>
                                        <h3>
                                          Check if you have been subscribed for
                                          Prime Membership Plan.
                                        </h3>
                                      </li>
                                      <li>
                                        <h3>
                                          Contact Administrator for additional
                                          support.
                                        </h3>
                                      </li>
                                    </ul>
                                  
                                  </Col>
                                  <Col md={3} className="d-flex">
                                    <GrAlert style={{fontSize:90,display:'flex',alignSelf:'center'}} />
                                    </Col>
                                  </Row>
                                </div>  
                                </div>                              
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <div className="row my-5"></div>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from 'react'

export default class Press extends Component {
    render() {
        return (
            <div>
                 <div  className="Nature-banner inner-page">
          <div className="container">

          
           <div className="row mt-4 mb-4">
              
                 <h2 className="about-section-title text-center" >  BSC Press, News, & Media Coverage
                  
                  </h2>
                  <div className="col-md-3">
                      
                 
                        <div className="card-r ">
                             <div className="press-content">
                      <h5>Press inquiries</h5>
                      <p>Get in touch with our press team at</p>
                      <a href="">press@beyondsmartcities.in</a>
                      <h5>Contact Us</h5>
                      <p>Looking for support?</p>
                      <a href="">Visit our Help Center</a>
                       </div>
                  </div>
                    
                
              </div>
              <div className="col-md-3">
                  <div className="card-r ">
                <img src="assets/images/press-1.jpeg" alt="Avatar" />
                   <div className="press-container">
                    <h4><b>08 Jul 2021</b></h4>
                     <p>Green Building Education Is Only for Experts...We are all life-long consumers of buildings—inhabiting them, owning them, building and maintaining them. Given future uncertainties</p>
                              </div>
                     </div>
                           
              </div>
               <div className="col-md-3">
                  <div className="card-r ">
                <img src="assets/images/press-2.jpeg" alt="Avatar" />
                   <div className="press-container">
                    <h4><b>08 Jul 2021</b></h4>
                     <p>Green Building Education Is Only for Experts...We are all life-long consumers of buildings—inhabiting them, owning them, building and maintaining them. Given future uncertainties</p>
                              </div>
                     </div>
                           
              </div>
             <div className="col-md-3">
                  <div className="card-r">
                <img src="assets/images/press-1.jpeg" alt="Avatar" />
                   <div className="press-container">
                    <h4><b>08 Jul 2021</b></h4>
                     <p>Green Building Education Is Only for Experts...We are all life-long consumers of buildings—inhabiting them, owning them, building and maintaining them. Given future uncertainties</p>
                              </div>
                     </div>
                           
              </div>
          </div>
          </div>
          </div>

            </div>
        )
    }
}

import React, { Component } from "react";
import SearchBox from "../helpers/SearchBox";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import CategoryShortLinks from "./Components/CategoryShortLinks";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  componentDidMount() {}

  searchService = (keyword) => {
    console.log(keyword);
    this.props.history.push({
      pathname: "/specialists/" + keyword,
    });
  };


  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
   };
  
   componentDidMount() {
    window.addEventListener("resize", this.handleResize);
   }
  
   componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
   } 


  render() {
    const { windowWidth } = this.state; 
    console.log(windowWidth)
    return (
      <div>
        <div className="banner-section mobile-banner">
          <div className="container-fluid px-5">
            <div className="row">
              <div className="col-md-6 smart">
                <div
                  className="banner-text"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <div
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="800"
                  >
                    <h1>BEYOND SMART CITIES</h1>
                  </div>

                  <p>
                  Beyond Smart Cities is the world’s 1st Green Technology Marketplace, connecting millions of Sustainability Specialists, Green Building Specialists, Energy Specialists, Commissioning Specialists, Environment Specialists, Health & Safety Specialists, Fire Safety Specialists, Climate Change Specialists & Green Products/Technology Manufacturers with independent talent around the globe.
                  </p>

                  <form className="d-flex homesearch">
                    <SearchBox />
                    {/* <input className="form-control " type="search" placeholder="Search" aria-label="Search" />
                                   <button className="btn btn-outline-success" type="submit">Search</button> */}
                  </form>
                  <br />
                  <br />               

                  <CategoryShortLinks />
               

                  <div
                    className="banner btns"                   
                    data-aos="fade-left"
                    data-aos-easing="linear"
                    data-aos-duration="800"
                  >
                    {/* <p className="cursor-pointer">
                      <a
                        onClick={() =>
                          this.searchService(
                            "Sustainability Specialist Services"
                          )
                        }
                      >
                        Smart-Property Rating
                      </a>
                    </p> */}
                    {/* <p className="cursor-pointer">
                      <a
                        onClick={() =>
                          this.searchService(
                            "Sustainability Specialist Services"
                          )
                        }
                      >
                        Knowledge Lab
                      </a>
                    </p> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="banner-img"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="py-2 trustarea">
          <div className="container">
            <div className="row">
             
                <div data-aos="fade-up">
                  <h2 className="heroText1a py-2 py-md-3 text-center">Trusted By</h2>
                </div>

                <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" style={{width:100}} src="assets/images/GSAS-2x2.png" alt="logos" />
                    </div>
                  </div>

                <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid mt-3" src="assets/images/kenjalogo1.png" alt="logos" />
                    </div>
                  </div>
                  
               
                  <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" style={{width:100}} src="assets/images/leed-logo-300-300-2-300x300-2.png" alt="logos" />
                    </div>
                  </div>
                  <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid"  src="assets/images/l2.png" alt="logos" />
                    </div>
                  </div>

                  <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" style={{width:100}} src="assets/images/bsas-01-01-01.png" alt="logos" />
                    </div>
                  </div>

                  <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" style={{width:100}} src="assets/images/l3.png" alt="logos" />
                    </div>
                  </div>
                  {/* <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" src="assets/images/l4.png" alt="logos" />
                    </div>
                  </div> */}
                  <div className="col-4 col-md-2 text-center">
                    <div className="logos">
                      <img className="img-fluid" src="assets/images/l5.png" alt="logos" />
                    </div>
                  </div>                          
            </div>
          </div>
        </section>
        <section className="Services">
          <div className="container">
            <div className="row">
              <div data-aos="fade-up" data-aos-duration="2000">
                <h2 className="heroText1a py-5 py-md-3 text-center">
                  Popular Specialists Services
                </h2>
              </div>

              <div data-aos="fade-up" data-aos-duration="3000">
                <div className="carousel-wrap">
                  <div>
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={10}
                      nav
                      items={windowWidth <=600 ? 2 : 5}
                      autoplay={true}
                    >
                      <div className="item">
                      <Link to="/specialists/Energy-Efficiency-and-Commissioning/Energy-Management/Measurement-&-Verification/">
                        <img
                        className="img-fluid"

                          src="assets/images/products/Measurement & Verification.jpeg"
                          alt="Beyond Smart Cities"
                        />  
                        <div className="tittle">
                          <h5>Measurement & Verification</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Energy-Efficiency-and-Commissioning/Building-Commissioning/LEED-Fundamental-Commissioning/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/LEED Fundamental Commissioning.jpg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>LEED Fundamental Commissioning</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Sustainability-and-Technology/Environmental-Specialist-Services/Construction-Environment-Management-Plan(CEMP)">
                        <img
                        className="img-fluid"
                          src="assets/images/products/Construction Environment Management Plan(CEMP).jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>
                            Construction Environment Management Plan(CEMP)
                          </h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                        <Link to="specialists/Green-Building-Technology/Building-Engineering/Design-Review-&-Technical-Services/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/Design Review & Technical Services.jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>Design Review & Technical Services</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Energy-Efficiency-and-Commissioning/Energy-Management/Energy-Auditing-&-Management/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/Energy Auditing & Management.jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>Energy Auditing & Management</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Sustainability-and-Technology/Environmental-Specialist-Services/Environmental-Impact-Assessments(EIA)/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/Environmental Impact Assessments(EIA).jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>Environmental Impact Assessments(EIA)</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Green-Building-Technology/Green-Building-Certification-Management/GSAS-Certification-Management/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/GSAS Crtification for Light Industrial Units.jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>GSAS Certification for Light Industrial Units</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Energy-Efficiency-and-Commissioning/Building-Commissioning/HVAC-Commissioning/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/HVAC Commissioning.jpg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>Products/HVAC Commissioning</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                      <Link to="/specialists/Smart-Cities-and-Infrastructure/Transportation/Public-transport-infrastructure-and-service-advice/">
                        <img
                        className="img-fluid"
                          src="assets/images/products/Infrastructure and Road Design Review.jpeg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>Infrastructure and Road Design Review</h5>
                        </div>
                        </Link>
                      </div>
                      <div className="item">
                        <img
                          src="assets/images/products/GSAS Feasibity Study for Districts and Infrastrucure.jpg"
                          alt="Beyond Smart Cities"
                        />
                        <div className="tittle">
                          <h5>
                            GSAS Feasibity Study for Districts and Infrastrucure
                          </h5>
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div data-aos="fade-up" data-aos-duration="3000">
                <h2 className="heroText1a py-5 py-md-3 text-center">
                  Popular SmartPro Products and Services{" "}
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
            <div data-aos="fade-up" data-aos-duration="3000">
              <div className="carousel-wrap">
                <div>
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    nav
                    items={windowWidth <=600 ? 2 : 5}
                    autoplay={true}
                    autoplayTimeout={6000}
                  >
                    <div className="item">
                      <img
                        src="assets/images/products/Measurement & Verification.jpeg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Measurement & Verification</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/commercial-air-conditioning-2571306_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Commercial-air-conditioning</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/light-bulb-4514505_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Light-bulb</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/led-823383_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Led</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/carsharing-4382651_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Carsharing</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/architecture-1515491_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Architecture</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/brick-164650_1280.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Brick</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/bricks-1839553_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Bricks</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/alternative-21761_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Alternative</h5>
                      </div>
                    </div>
                    <div className="item">
                      <img
                        src="assets/images/products/sofa-896576_1920.jpg"
                        alt="Beyond Smart Cities"
                      />
                      <div className="tittle">
                        <h5>Sofa</h5>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
        </section>

        <section className="p-0">
          <div className="row align-items-center">
            <div className="col-md-6 talent-col align-items-center">
              <div className="p-5" data-aos="fade-up" data-aos-duration="3000">
                <h3 className="letterspace3">FOR CLIENTS</h3>
                <h2 className="heroText1">
                  Find Talent
                  <br /> Your Way
                </h2>
                <p className="heroText2">
                  Work with the largest network of independent professionals and
                  get things done from quick turnarounds to big transformations.
                </p>
                <div className="ghostBtn mt-5">
                  <a href="/howtohire">Find Talents</a>
                </div>
              </div>              
            </div>
            <div className="col-md-6">
              <img src="assets/images/home-3.jpg " alt="Beyond Smart Cities" />
            </div>
          </div>
        </section>

        <section className="p-0">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <img src="assets/images/home-4.jpg" alt="Beyond Smart Cities" />
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="p-5" data-aos="fade-up" data-aos-duration="3000">
                <h3 className="letterspace3">FOR TALENT</h3>
                <h2 className="heroText1">
                  Find Great
                  <br /> Work
                </h2>
                <p className="heroText2">
                  Meet clients you’re excited to work with and take your career
                  or business to new heights.
                </p>

                <div className="row inner-content">
                  <div className="col-md-4">
                    <div className="card">
                      <p className="heroText2">
                        Opportunities for every stage of your 
                        career
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <p className="heroText2">Control when, where, and how you work</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <p className="heroText2">Explore different ways to earn </p>
                    </div>
                  </div>

                  <br />
                </div>

                <div className="ghostBtn mt-5">
                  <a href="/howtofindwork">Find Opportunities</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Professional py-5 py-md-3">
          <div data-aos="fade-up">
            <h2 className="text-center heroText1a  py-4">Explore The Market Place</h2>
          </div>
          <div className="container">
            <div className="row align-items-center" data-aos="fade-up" data-aos-duration="3000">
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Sustainability-and-Technology/">
                    <img
                    className="img-fluid"
                      src="assets/images/icons/Sustainability _ Environment.png"
                      alt="img"
                    />
                    <h3>Sustainability & Environment </h3>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Smart-Cities-and-Infrastructure/">
                    <img
                    className="img-fluid"
                      src="assets/images/icons/Smart Cities _ Infrastructure.png"
                      alt="img"
                    />
                    <h3>Smart Cities</h3>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Green-Building-Technology/">               
                  
                    <img
                    className="img-fluid"
                      src="assets/images/icons/Building Technology.png"
                      alt="img"
                    />
                    <h3>Building Tech</h3>
                 </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Energy-Efficiency-and-Commissioning/">       
                    <img
                    className="img-fluid"
                      src="assets/images/icons/Energy _ Commissioning.png"
                      alt="img"
                    />
                    <h3>Energy Tech & CxA </h3>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="3000">
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/GreenHouse-Gases-and-Climate-Change/">       
                    <img
                    className="img-fluid"
                      src="assets/images/icons/GHG _ Climate Change.png"
                      alt="img"
                    />
                    <h3>GHG & Climate Change </h3>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Health-Safety-Environment-and-Fire-Safety/">    
                    <img
                    className="img-fluid"
                      src="assets/images/icons/HSE _ Fire Safety.png"
                      alt="img"
                    />
                    <h3>HSE and Fire Safety</h3>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/specialists/Emerging-Design-and-Technology/">
                    <img
                    className="img-fluid"
                      src="assets/images/icons/Emerging Design _ Technology.png
"
                      alt="img"
                    />
                    <h3>Emerging Design & Tech</h3>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="market-place whiteBGwithbdrRadHeight my-2 mx-auto">
                <Link to="/smartproducts/Building-Materials/">
                    <img
                    className="img-fluid"
                      src="assets/images/icons/SmartPro Product Rating Systems.png"
                      alt="img"
                    />
                    <h3>SmartPro Rating System</h3>
                 </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="work-your-way text-center pt-5 pb-0 py-5 py-md-3  mb-5 min-h-600">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
                            <h3 className="letterspace3">WORK YOUR WAY</h3>
                            <h2 className="heroText1 c1">
                  You Bring the Skill<br/> We Make the Earning Easy                 
                </h2>


                <div className="solidBtn mt-5">
                  <a href="/howtofindwork">Become a Specialist</a>
                </div>

            <br />
            <br />
            <h3>Join Our Growing Team If You Are Passionate and Solution Oriented</h3>
           
            <div className="row pt-4 create-service ">
              <div className="col-md-4">
                <div className="content">
                  <h5>1.CREATE SERVICE</h5>
                  <p>
                    Sign up for free , set up your Gig , and offer your work to
                    our global audience
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content">
                  <h5>2 .DELIVER GREAT WORK</h5>
                  <p>
                    Get notified when you get an order and use our system to
                    discuss details with customers
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="content">
                  <h5>3 .GET PAID</h5>
                  <p>
                    Get paid on time , every time . Payment is transferred to
                    you upon order completion.
                  </p>
                </div>
              </div>
            </div>
            <div className="container position-relative">
            <div className="profile-clients position-absolute mt-2 boxshadow1" style={{left:0}}>
              
              <div className="row ">
                <div className="col-md-3">
                  <img
                  className="bdr-rad-30 img-fluid"
                    src="assets/images/Krishnaji-Pawar.jpeg"
                    alt="Beyond Smart Cities"
                  />
                  <h2 className="c3">Krishnaji Pawar </h2>
                  <p>Sr.Sustainability & Environmental Specialist</p>
                </div>
                <div className="col-md-3">
                  <img
                  className="bdr-rad-30 img-fluid"
                    src="assets/images/Sujith-Kumar.jpeg"
                    alt="Beyond Smart Cities"
                  />
                   <h2 className="c3">Sujith Kumar</h2>
                  <p>Sr.Structural Specialist</p>
                </div>
                <div className="col-md-3">
                  <img
                  className="bdr-rad-30 img-fluid"
                    src="assets/images/ShreyankaMallikarjun.jpeg"
                    alt="Beyond Smart Cities"
                  />
                   <h2 className="c3">Shreyanka Mallikarjun</h2>
                  <p>Environmental Sustainability Advisory</p>
                </div>
                <div className="col-md-3">
                  <img
                  className="bdr-rad-30 img-fluid"
                    src="assets/images/Manoj-joseph.jpg"
                    alt="Beyond Smart Cities"
                  />
                   <h2 className="c3">Manoj Joseph </h2>
                  <p>Sr.Health Safety Environment Specialist</p>
                </div>
                <br />
                {/* <h2 className="c3">Learn From Beyond Smart Cities</h2> */}

                {/* <p>
                  On-demand professional courses , led by the world ’ s leading
                  experts .<br />
                  Discover what it takes to be a top-notch seller on Beyond
                  Smart Cities complimentary Learn from BSC course .
                </p> */}
              </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-2" style={{marginTop:200}}>
          <div className="row">
            <div className="col-md-6">
              <div
                className="p-5"
                data-aos="fade-up"
                data-aos-duration="3000"
              >

              <h2 className="heroText1">
                  SmartPro Rating<br/> for Products            
                </h2>

                <h4 className="heroText2">Going Beyond the Regulatory Equations</h4>
                <p>
                  We help clients make better decisions , integrate
                  sustainability into their business and create innovative
                  solutions . Beyond Smart Cities is dedicated to advancing and
                  catalyzing sustainability in India , Qatar & other part of
                  built environment.
                </p>
                <br />
                <div className="ghostBtn">
                  <a href="/smartproproductratingsystems">Explore SmartPro</a>
                </div>
                <br />
                <div className="rate">
                  <input type="radio" id="star5" name="rate" value="5" />
                  <label for="star5" title="text">
                    5 stars
                  </label>
                  <input type="radio" id="star4" name="rate" value="4" />
                  <label for="star4" title="text">
                    4 stars
                  </label>
                  <input type="radio" id="star3" name="rate" value="3" />
                  <label for="star3" title="text">
                    3 stars
                  </label>
                  <input type="radio" id="star2" name="rate" value="2" />
                  <label for="star2" title="text">
                    2 stars
                  </label>
                  <input type="radio" id="star1" name="rate" value="1" />
                  <label for="star1" title="text">
                    1 star
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="assets/images/home-5a.jpg" alt="Beyond Smart Cities" />
            </div>
          </div>
        </section>
        {/* <section className="products">
          <div className="row">
            <div className="col-md-6">
              <img src="assets/images/home-6.png" alt="Beyond Smart Cities" />
            </div>
            <div className="col-md-6">
              <div
                className="product-content "
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2>NEW GREEN BUILDING RATING SYSTEM FOR BUILDING</h2>
                <h4>Going Beyond the Regulatory Equations</h4>
                <br />
                <br />
                <img
                  src="assets/images/logo.png"
                  alt="Beyond Smart Cities"
                  className="product-img"
                />
                <br />
                <br />
                <br />
                <button>
                  <a href="bsasrating">EXPLORE SMART- PRO</a>
                </button>
                <br />
                <div className="rate">
                  <input type="radio" id="star5" name="rate" value="5" />
                  <label for="star5" title="text">
                    5 stars
                  </label>
                  <input type="radio" id="star4" name="rate" value="4" />
                  <label for="star4" title="text">
                    4 stars
                  </label>
                  <input type="radio" id="star3" name="rate" value="3" />
                  <label for="star3" title="text">
                    3 stars
                  </label>
                  <input type="radio" id="star2" name="rate" value="2" />
                  <label for="star2" title="text">
                    2 stars
                  </label>
                  <input type="radio" id="star1" name="rate" value="1" />
                  <label for="star1" title="text">
                    1 star
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

export default withRouter(Home);

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FaFilePdf, FaRocket, FaRupeeSign, FaFileVideo } from "react-icons/fa";

export default function CourseLinks() {
  return (
    <div>
        <section className='courselist'>
          <Container>
          <Row>
          <h2 className='heroText1a my-4'><FaFileVideo />{' '}Available Course Topics</h2>
         <Col md={6} className="mt-3">
        <h3 className="heroText1c mb-3">Sustainability and Environment</h3>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/sustainability-management">Sustainability Management</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/construction-environmental-management-plan" >Construction Environmental Management Plan</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/environmental-impact-assessment">Environmental Impact Assessment</Link></li>
               
         
          </Col>

          <Col md={6} className="mt-3"> 
        <h3 className="heroText1c mb-3">Building Commissioning</h3>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/green-building-commissioning">Green Building Commissioning</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/advanced-green-building-commissioning" >Advanced Green Building Commissioning</Link></li>             
         
          
          </Col>

        <Col md={6} className="mt-3"> 
        <h3 className="heroText1c mb-3">Emerging Design & Technology</h3>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/blockchain-technology">Blockchain Technology</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/digital-transformation">Digital Transformation</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/artificial-intelligence">Artificial Intelligence</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/internet-of-things">Internet of Things</Link></li>
        
                       
          </Col>


        <Col md={6} className="mt-3"> 

        <h3 className="heroText1c mb-3">Green Building Technology</h3> 
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/beyond-smart-cities">Beyond Smart Cities</Link></li>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/beyond-green-building-technology">Beyond Green Building Technology</Link></li>

       
       
          </Col>

          <Col md={6} className="mt-3">
          <h3 className="heroText1c mb-3">Energy Technology</h3>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/advanced-building-energy-modelling" >Advanced Building Energy Modeling</Link></li>
          </Col>

          <Col md={6} className="mt-3">
          <h3 className="heroText1c mb-3">Lifestyle</h3>
          <li className="fw-600 c13"><Link className="c13"  to="/knowledgelab/dynamic-meditation" >Dynamic Meditation</Link></li>
          </Col>
          </Row>
          </Container>
          </section>
    </div>
  )
}

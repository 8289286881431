import React from 'react'
import { Link } from "react-router-dom";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Loader from "react-js-loader";
export default function CardBoxLoading() {
    return (
        
            <div class="col-md-3 ">
              <a >
                <div class="card-r  shd-1">
                  <div className='cardBoxLoader'>
                  <Loader           
              type="spinner-default"
              bgColor={"#eaeaea"}
              title={""}
              color={"#fff"}
              size={40}
            />
                  </div>                 
                 
                  <div class="card-content">
                  <Link><span className="shadeLoader-1"></span></Link>
                    <div class="chip" style={{backgroundColor:'#fff',borderRadius:30}}>
                    
                     <Link><span className="shadeLoader-1"></span></Link>
                    </div>
                    <h3 className="mb-4 c3"><span className="shadeLoader-1"></span></h3>
                    <Row>
                        <Col md={4}>
                        <p><span className="shadeLoader-1"></span></p>
                        </Col>
                        <Col md={1}>
                        {/* <i class="fas fa-heart"></i> */}
                        </Col>
                        <Col>
                       <h3 className="float-end c4"><span className="shadeLoader-1"></span></h3>
                        </Col>
                        </Row>
                  </div>                 
                </div>
              </a>
            </div>
        
    )
}

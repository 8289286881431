import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiBook,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiCheckCircle, FiDownload, FiDownloadCloud, FiHelpCircle, FiX } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";
import {
  withRouter 
} from "react-router-dom";

import axios from "axios";
import { API_URL } from "../../../config";

import moment from "moment";
import DashboardSidebar from "../../DashboardSidebar";
import { FaFirstOrder } from "react-icons/fa";
import Loader from "react-js-loader";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);

    this.state = {
      orderId: this.props.match.params.id,
      orderData:'',
      notice: false,
      dataLoading:false,
      userData: JSON.parse(localStorage.getItem("user")),     

// orderDetails:this.props.location?.state?.orderDetails

     
    };
  }
  componentDidMount() {
   
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
   this.getOrderDetail()
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }



  
  async getOrderDetail() {     
    var self = this;     
    self.setState({    
      submitLoading: true,
      errorMessage: "",
      dataLoading:true
    });

    const token = await JSON.parse(localStorage.getItem("token"));     
    await axios
      .get(API_URL + "order/"+this.state.orderId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('order Details',response.data);           

          if (response.data.success === true) {
            self.setState({
              orderData:response.data.orderData
            })
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading:false
            });  
            
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading:false
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading:false
          });
        }         
      );
  }  


  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData?.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Order Detail</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col
                          xs
                          lg="2"
                          className="dashboard-sidebar"
                        >
                          <DashboardSidebar />
                        </Col>
                      
                        
              

                       


                       

                        <Col className="dashboard-content-area">
                        {this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}

                        {this.state.orderData &&
                        <>
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link to="/order-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                 {this.state.orderData?.orderId}
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col className="position-relative">
                              <div className="shd-1 py-3">
                                <Row className="align-items-center ">
                                  <Col md={1}>
                                    <BiBook className="ms-2 fs-2" />
                                  </Col>
                                  <Col md={8}>
                                    <div className="order-title fw-700">
                                     <h2> {this.state.orderData?.serviceId?.overview?.gigTitle}</h2>
                                    </div>
                                    <div className="property-added-date fs-12">
                                      Specialist: {this.state.orderData?.specialistId?.personalInfo?.firstName}
                                    </div>
                                  </Col>                                
                                  <Col md={2}>
                                    
                                      
                                     {this.state.orderData?.isDelivered ? (
                                       <>
                                       <div className="property-status fw-700 c2">
                                     <FiCheckCircle className='me-1' />
                                     <span>Completed</span>
                                      </div>
                                     </>
                                     ) : 

                                     (
                                       <>
                                       <div className="property-status fw-700 c5">
                                     <FiAlertTriangle className='me-1' />
                                     <span>Pending</span>
                                     </div>
                                     </>
                                     )
                                     }
                                    
                                  </Col>
                                  <Col>
                                    <div className="property-rating">
                                      <BsStarFill className="c2" />
                                      <BsStarFill className="c2" />
                                      <BsStarFill className="c2" />
                                    </div>
                                  </Col>
                                </Row>                           


                           <hr />


                                <Row>  


                                                    

                                  <Col className="mt-3 DetailList">
                                    <h3>Payment Status</h3>
                                    <ul>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                       Payment Id:  {this.state.orderData?.paymentId?.order_id}
                                      </li>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                        Paid On:  { moment(this.state.orderData?.paymentId?.createdAt).format("LL")} 
                                      </li>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                        Amount:Rs. {this.state.orderData?.paymentId?.order_amount}
                                      </li>                                     
                                    </ul>
                                  </Col>
                                  <Col className="mt-3 DetailList">
                                    <h3>Project Delivery Files</h3>
                                    <ul>
                                          {this.state.orderData?.deliveryId?.projectFiles?.length
                                            ? this.state.orderData?.deliveryId?.projectFiles.map(
                                                (item, index) => (
                                                  <li>
                                                    <FiCheck className="me-1 c2" />
                                                    
                                                    <a target="_blank" href={item?.fileLocation?.secureUrl} download="test" ><FiDownload  className="ms-3 cursor-pointer" />{" "}<span className="cursor-pointer">Download File:{index + 1}{"-"}{item?.date}</span> </a>
                                                    
                                                  </li>
                                                )
                                              )
                                            : "No Delivery Yet"}
                                        </ul>
                                  </Col>
                                </Row>


                                <Row>
                                 
                                  </Row>


                              </div>
                            </Col>
                          </Row>
                          </>
                        }
                        </Col>
 
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(OrderDetails);
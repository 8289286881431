import React, { useState } from "react";
import { toast } from "react-toastify";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Confirmation = ({
  prevStep,
  nextStep,
  values,
  submitData,
  handleRegister,
  imageData
}) => {
  console.log(values);


   let user= JSON.parse(localStorage.getItem('user'))

  const [agree, setAgree] = useState(false);


  


  const Continue = (e) => {
    e.preventDefault();
    if(agree){
    handleRegister()
    //nextStep();
    }
    else{
      toast("Please agree to continue", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  const checkboxValidate=(e)=>{
    console.log(e.target.checked)
    setAgree(true)
  }

  return (
    <section className="lightgrey-bg user-area log-in-area">
      <Container component="main" maxWidth="xs" className="p-2">
        <div className="user-form-content">
          <div className="row">
            <div className="col-md-12">
              <div className="form-card mt-3">
              <Row className="text-center my-3">
              <h2 className="mb-4">Submit Your Information</h2>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
              <input style={{display:"inline-block"}} type="checkbox" checked={agree} onChange={e => checkboxValidate(e)}  />
              <p style={{marginBottom:0,marginLeft:10}}>I <span style={{textDecoration:'underline'}}>{user.firstName}</span> hereby declare that the information furnished here is true, complete
and correct to the best of my knowledge and belief.</p>
              </div>
             

            
                  <Col>
                    <Button
                      onClick={Previous}
                      type="submit"
                      className="btn btn-primary-trans btn-block me-2"
                    >
                      Previous
                    </Button>

                    <Button                      
                      type="submit"
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onClick={Continue}
                      disabled={false}
                      className="btn btn-primary btn-block c1"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Row className="p-4">
                <h2 className="mb-4">Preview Your Information</h2>
                  <Col>
                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">First Name</div>
                      <div className="formConfirmValue">
                        {values.personalInfo ?.firstName}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Last Name</div>
                      <div className="formConfirmValue">
                        {values.personalInfo ?.lastName}
                      </div>
                    </div>

                    <h3>Languages Known</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmValue">
                        {values.communication && values.communicaton.length ?
                        values.communication.map((item)=>{
<p>{item}</p>
                        

                        })
                      :
                      ''}

                      </div>
                    </div>

                    <h3>Professional Information</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Occupation</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.occupation}
                      </div>
                    </div>
                    
                  
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Working Since</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.workingSince}
                      </div>
                    </div>
                    </Col>
<Col>
                    <h3>Education</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">College Name</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.collegeName}
                      </div>
                    </div>

                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">University Name</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.universityName}
                      </div>
                    </div>

                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Country OF College</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.countryOfCollege}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Main Stream of Course</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.mainStreamOfCourse}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Year of Completion</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.yearOfCompletion}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">LicenseId</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.licenseId}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">License Name</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.licenseName}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">License Expiry Date</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.licenseExpiryDate}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">License Issued By</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.licenseIssuedBy}
                      </div>
                    </div>

                    
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">License Issued Date</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.licenseIssueDate}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">IS Life Time Certificate</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.isLifeTimeCertificate}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">CredentialId</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.credentialId}
                      </div>
                    </div>

                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Credential URL</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.credentialUrl}
                      </div>
                    </div>
                    
                    
                    </Col>
                 
                  <Col>
                  <h3>Organizational Membership</h3>
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Name of Organization</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.organizationalMembership.nameOfOrganization}
                      </div>
                    </div>

                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Working Since</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.organizationalMembership.workingSince}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Honors and Awards</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.organizationalMembership.honorsAndAwards[0]}
                      </div>
                    </div>


                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Personal Website</div>
                      <div className="formConfirmValue">
                        {values.professionalInfo ?.education.organizationalMembership.personalWebsite}
                      </div>
                    </div>       
         
                   
                  </Col>
                  
                 
                </Row>
                <Row>
                  <Col>
                  <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Profile Photo</div>
                      <div className="formConfirmValue">
                      <div className="uploadimgPreview bdr-rad-4">                    
                              {imageData?.profilePreviewUrl ? (
                                <img className="bdr-rad-4" src={imageData?.profilePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                            </div>
                      </div>
                    </div> </Col>


                    <Col>
                  <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">License Certificate</div>
                      <div className="formConfirmValue">
                      <div className="uploadimgPreview bdr-rad-4">                    
                              {imageData?.licensePreviewUrl ? (
                                <img className="bdr-rad-4" src={imageData?.licensePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                            </div>
                      </div>
                    </div> </Col>


                    <Col>
                  <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Experience Certificate</div>
                      <div className="formConfirmValue">
                      <div className="uploadimgPreview bdr-rad-4">                    
                              {imageData?.expCertificatePreviewUrl ? (
                                <img className="bdr-rad-4" src={imageData?.expCertificatePreviewUrl} />
                              ) : (
                                <div className="previewText">
                                  
                                </div>
                              )}
                            </div>
                      </div>
                    </div> </Col>

                </Row>

              
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Confirmation;
import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="container">
            <div className="row mt-4 pb-4">
              <div className="col-md-6">
                <h2 className="fs-32 pt-3">About us</h2>
                <p>World's 1st Green Technology Marketplace for <br/>
Green Building and Sustainability-Environmental Protection </p>

                <img
                  src="assets/images/Client.jpg"
                  alt="Beyond Smart Cities"
                  className="img-fluid center-block about-img p-2 imageHeight1 "
                 
                />
                <div className="px-5">
                  <h4>
                    KRISHNAJI PAWAR <br />
                  </h4>
                  <p>CEO & FOUNDER</p>
                </div>
              </div>
              <div className="col-md-6 p-tb-20 mt-4">
             
                  <h2>SUSTAINABILITY, IT’S A LIFESTYLE.</h2>
               
                <p>
                  Our mission is to imagine a sustainable future that works for
                  populations and social progress. We contribute
                  differentiating, high-performance and value-added solutions to
                  our clients to meet the challenges of a fast-changing world:
                  restoring value to communities, tackling environmental and
                  sustainability issues, reinventing mobility, harnessing the
                  digital revolution, etc. A wide array of opportunities for us
                  to shape a sustainable world, for the benefit of present and
                  future generations.
                </p>

                <p>
                  {" "}
                  <span>
                    “Any fool can make things bigger, more complex, and more
                    violent. It takes a touch of genius – and a lot of courage –
                    to move in the opposite direction.”
                  </span>
                  <br /> <b style={{ float: "right" }}>- Albert Einstein</b>{" "}
                </p>

                <p>
                  We see people and planet living in harmony without
                  compromising the future of both. Our solution is to give the
                  market exactly what it is required for and more, by pairing
                  Expert Knowledge, economic, environmental, and social vitality
                  throughout the world to achieve more with less.
                </p>
              </div>
            </div>
            
            {/* 
            <p>
              <b>
                Decentralizing your operating and talent models the right way
              </b>
            </p> */}
            {/* <p>
              Beyond Smart Cities’ platform connects businesses with on-demand
              freelance talent offering digital services in more than 800
              categories, across 7 verticals including Green Building Design,
              Energy Engineering, Environmental Consulting, GHG Accounting,
              Climate Change ,Health & Safety & Commissioning Services.
            </p> */}
{/* 
            <div className="text-center mb-4">
              <button>
                <a href="leadership">Leader ship</a>
              </button>
            </div> */}
          </div>
          <section className="section-about">
            <div className="row pb-4">
              <div className="col-md-6">
                <img src="assets/images/about-3.jpg" />
              </div>
              <div className="col-md-6 text-center">
                <div className="core-section">
                  <h2 className="section-title  text-center pt-3"> Core Values</h2>
                  <p>
                    Our Mission is to advance the Green Building Technology
                    movement through best-in-class Education and Encouragement
                  </p>
                  <div className="boxes">
                    <div className="box-content hover-noaction">
                      <img
                        src="assets/images/icons/Quality of life.png"
                        class="img-fluid"
                      />

                      <h4>Quality of Life</h4>
                      <p>
                        Proactively create a healthy worklife balance and
                        consistent enjoyment in day-to-day life for our family
                        (TEAM) and our community.
                      </p>
                    </div>
                    <div className="box-content">
                      <img
                        src="assets/images/icons/best in class.png"
                        className="img-fluid"
                      />
                      <h4>Best-in-class</h4>
                      <p>
                        Global notoriety as a trendsetter and influencer in all
                        that we do education, TECHNOLOGY, and customer service.
                      </p>
                    </div>
                  </div>
                  <div className="boxes">
                    <div className="box-content hover-noaction">
                      <img
                        src="assets/images/icons/Positivity (2).png"
                        className="img-fluid"
                      />
                      <h4>Positivity</h4>
                      <p>
                        Passionate practice of optimism and environmental
                        stewardship in the present about the future.
                      </p>
                    </div>
                    <div className="box-content hover-noaction">
                      <img
                        src="assets/images/icons/Trust (2).png"
                        className="img-fluid"
                      />
                      <h4>Trust</h4>
                      <p>
                        Faith in each other, goodwill towards our customers &
                        clients, and consistent integrity have yielded longevity
                        in business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="stories text-center">
            <div className="container">
              <h2 className="about-section-title text-center">Our Services</h2>

              <p>
                Beyond Smart Cities is dedicated to advancing and catalyzing
                sustainability in India and other part of built
                environment.Beyond Smart Cities is a major international company
                in the construction engineering & mobility services sectors
                whose unique global service range encompasses Environmental &
                sustainability services, energy modeling, engineering and
                operation
              </p>
              <div className="row pt-4">
                <div className="col-md-4">
                  <div className="card-r">
                    <img
                      src="assets/images/about-1.jpg"
                      alt="Beyond Smart Cities"
                      className="img-fluid p-4"
                    />
                    <div className="services-box">
                      <h2>Specialist Prime Services</h2>

                      <p>
                        Beyond Smart Cities is the world’s 1st Green Technology
                        Marketplace, connecting millions of Sustainability
                        Specialists, Green Building Specialists, Energy
                        Specialists, Commissioning Specialists, Environment
                        Specialists Health & Safety Specialists, Fire Safety
                        Specialists, Climate Change Specialists & Green
                        Products/Technology Manufacturers with independent
                        talent around the globe.
                      </p>
                      <div className="ghostBtn">
                        <a href="/beyondbusiness">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-r">
                    <img
                      src="assets/images/about-2.jpg"
                      alt="Beyond Smart Cities"
                      className="img-fluid p-4"

                    />
                    <div className="services-box">
                      <h2>SmartPro Product Rating</h2>
                      <p>
                        The SmartPro Products Certification adopts a holistic
                        approach based on the life cycle of the product. The
                        certification system encourages the product
                        manufacturers to implement Green measures in all phases
                        of the product e.g .Product Design, Raw Materials ,
                        Manufacturing Process, Product Performance during use,
                        Recycling/Disposal.
                      </p>
                      <div className="ghostBtn">
                        <a href="/smartproproductratingsystems">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card-r">
                    <img
                      src="assets/images/about-4.jpg"
                      alt="Beyond Smart Cities"
                      className="img-fluid p-4"

                    />
                    <div className="services-box">
                      <h2>BSAS Property Rating System</h2>
                      <p>
                        Green building uses an integrative project approach
                        which brings people together at the front end to
                        collaborate and share ideas that can enhance building
                        performance and save money  during construction and
                        building operation.The green construction industry is
                        moving fast with a growing trend for Green Building
                        Rating Tools.
                      </p>
                      <div className="ghostBtn">
                        <a href="/selfcertification">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
{/* <div className="container">
          <div className="row mt-4 mb-5 pb-5">
          <h2 className="fs-32 pt-3 text-center mb-5">Our Leaders</h2>

              <div className="col-md-6">               

                <img
                  src="assets/images/Client.jpg"
                  alt="Beyond Smart Cities"
                  className="img-fluid p-5 imageHeight1 "
                  
                  
                />
                <div className="text-center">
                  <h5>
                    KRISHNAJI PAWAR <br />
                  </h5>
                  <p>CEO & FOUNDER</p>
                  <quote>
                  “Be a global citizen. Act with passion
and compassion. Help us make this
world safer and more sustainable
today and for the generations that
will follow us. That is our moral
responsibility.”
                  </quote>
                </div>
              </div>
              <div className="col-md-6 p-tb-20">
              
                  <h2> KRISHNAJI PAWAR </h2>
                
               
   <p>              
Krishnaji Pawar is founder and CEO of Beyond Smart Cities. Before being named CEO in January
2020, Krishnaji held leadership roles at Beyond Smart Cities in both Sustainability ,Energy &
Environmental Consultancy.</p>
<p>
Specialized in developing sustainable design strategies for Green Building Certification Systems
(LEED, GSAS, etc.), Energy & Water Conservation, Commissioning, Environmental Impact
Assessment & Environmental Management Systems.</p>
<p>
Currently responsible for 3,767 million square feet Green Building /Energy modeling Consulting
since January 2007 in UAE, India and Qatar.Having been at Green Building Technology platform
since 2007, he is known for bold leadership and his passion for the company’s mission to create
economic opportunities so that people live better lives and he’s committed to building a
sustainable, high-performing business that delivers on that mission in ever-bigger ways.Before
joining Beyond Smart Cities, Krishnaji led sustainability development for Egis International, Qatar &
held corporate sustainability development roles at Arab Engineering Bureau, Qatar. He began his
career as an Energy Engineer at Pacific Controls and Company after earning an Engineering
Degree in Mechanical Engineering f rom Visvesvaraya Technological University, Belgaum, India.
                </p>
            

             
              </div>
            </div>
            </div>   */}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { ToastContainer, toast } from "react-toastify";
import { BiAbacus, BiX } from "react-icons/bi";


export default class PaymentFailed extends Component {
  constructor(props) {
    super(props); 
    this.state={
        paymentRole:this.props.match.params.role, 
      }
  }

  componentDidMount() {
    //this.getCategories();   
  }

 

  goToRetry(){
      this.props.history.goBack()
  }

  
  
  render() {
    return (
      <div className="page-container lightgrey-bg">
        <div className="page-inner content-card">
          <Container fluid>
          <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Confirm Payment</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
            </Row>

         

            <Row class="mb-4">
                      
            <BiX className="fs-48 c5 text-center mt-5"/>
<h3 className="text-center mx-4 mt-4 px-2">We are Sorry! We couldt process your Payment</h3>
<p className="text-center">Please check your card details, or balance amount in your account. Make sure you are online before retrying.</p>   
<button type="submit" onClick={()=>this.goToRetry()} class="btn btn-primary text-center mx-auto w-300 bdr-rad-30 mb-5"> <span class="d-block p-2">Try Again</span></button> 
                        
                         
            </Row> 
          </Container>
        </div>
      </div>
    );
  }
}

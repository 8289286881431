import React from "react";

import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Confirmation = ({
  prevStep,
  nextStep,
  values,
  submitData,
  handleRegister,
}) => {
  console.log(values);


   let user= JSON.parse(localStorage.getItem('user'))

 

  


  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <section className="lightgrey-bg user-area log-in-area">
      <Container component="main" maxWidth="xs" className="p-2">
        <div className="user-form-content">
          <div className="row">
            <div className="col-md-12">
              <div className="form-card mt-3">
              <Row className="text-center my-3">
              <h2 className="mb-4">Submit Your Information</h2>
              <p>I <span style={{textDecoration:'underline'}}>{user.firstName}</span> hereby declare that the information furnished here is true, complete
and correct to the best of my knowledge and belief.</p>
                  <Col>
                    <Button
                      onClick={Previous}
                      type="submit"
                      className="btn btn-primary-trans btn-block me-2"
                    >
                      Previous
                    </Button>

                    <Button                      
                      type="submit"
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onClick={handleRegister}
                      disabled={false}
                      className="btn btn-primary btn-block c1"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Row className="p-4">
                <h2 className="mb-4">Preview Your Information</h2>
                 
                    <div className="formConfirmHolder">
                    {/* <div className="formConfirmLabel">Selected Information</div> */}
                    <Row>

                    <div className="col-md-4">
                  <ul>
                    <h2>General Information</h2>
                    { values?.projectName &&
                     <>
                  <li>Project Type: {values?.projectType} </li>
                  <li>Start Date: {values?.startDate} </li>
                  <li>End Date: {values?.endtDate} </li>
                  <li>Address: {values?.address} </li>
                  <li>Latitude: {values?.latitude} </li>
                  <li>Longitude: {values?.longitude} </li>
                  <li>Description: {values?.description} </li>
                     </>  
                    }
                    </ul>
                    </div>


                  <div className="col-md-4">
                  <ul>
                    <h2>Smart Transportation</h2>
                    { values.smartTransportation &&
                      values.smartTransportation.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>

                    <div className="col-md-4">
                    <ul>
                    <h2>Green City</h2>
                      {values.greenSites &&
                      values.greenSites.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>


                    <div className="col-md-4">
                    <ul>
                    <h2>Energy Efficiency</h2>
                      {values.energyEfficiency &&
                      values.energyEfficiency.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>


                    <div className="col-md-4">
                    <ul>
                    <h2>Water Efficiency</h2>
                      {values.waterEfficiency &&
                      values.waterEfficiency.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>


                    <div className="col-md-4">
                    <ul>
                    <h2>Material Resources</h2>
                      {values.materialResources &&
                      values.materialResources.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>


                    <div className="col-md-4">
                    <ul>
                    <h2>Indoor Environment</h2>
                      {values.indoorEnvironment &&
                      values.indoorEnvironment.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>


                    <div className="col-md-4">
                    <ul>
                    <h2>Emerging Technology</h2>
                      {values.emergingTechnology &&
                      values.emergingTechnology.map((item)=>
                  <li> {item} </li>
                      )
                    }
                    </ul>
                    </div>

                    </Row>

                      <div className="formConfirmValue">
                        {/* {values.applicantInfo.nameOfCompany} */}
                      </div>
                    </div>

                 
                </Row>

              
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Confirmation;

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiRupee,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "./../../../config";
import validator from "validator";
import {
  validate_Alpha_Length,
  validate_Length_DOB,
  validate_Empty,
} from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import SpecialistDashboardSidebar from "../SpecialistDashboardSidebar";
import Categories from "../../../services/Categories";
import SubCategories from "../../../services/SubCategories";
import ChildCategories from "../../../services/ChildCategories";

import Select from "react-select";

const specialistId = JSON.parse(localStorage.getItem("user"));

export default class SubmitService extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    const initialState = {
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,
      categories: [],
      subcategories: [],
      childcategories: [],
      gigTitle: "",
      searchTagsError: false,
      selectedOptionCat: null,
      selectedOptionsubCat: null,
      selectedOptionchildCat: null,
      formErrors: {
        gigTitle: "",
        description: "",
        gigMegaData: "",
        searchTags: "",
        declaration: "",
        priceOne: "",
        priceTwo: "",
        priceThree: "",
      },
      data: {
        specialistId: specialistId?.specialistId?._id,
        overview: {
          gigTitle: "",
          description: "",
          gigMegaData: "",
          searchTags: [],
          declaration: false,
          categoryId: "",
          subCategoryId: "",
          childCategoryId: "",
        },

        packageDetails: [
          {
            price: "",
            name: "Basic",
            featuresList: [],
          },
          {
            price: "",
            name: "Standard",
            featuresList: [],
          },

          {
            price: "",
            name: "Advanced",
            featuresList: [],
          },
        ],
        pricing: {
          // selectedPackage : "Nne",
          addOnServices: ["ff", "sdf"],
          extraFastDelivery: true,
          additionalRevision: "No",
        },
        faq: [
          {
            question: "",
            answer: "",
          },
          {
            question: "",
            answer: "",
          },

          {
            question: "",
            answer: "",
          },         
        ],
      },

      errorMessage: "",
    };
    this.state = initialState;
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }
    Categories.getCategories().then((responseCat) => {
      let cat = [];
      for (let i = 0; i < responseCat.categoriesList.length; i++) {
       // console.log(responseCat.categoriesList[i].name);
        cat.push({
          value: responseCat.categoriesList[i].name,
          label: responseCat.categoriesList[i].name,
          categoryId: responseCat.categoriesList[i]._id,
        });
      }
      this.setState({ categories: cat });
     // console.log("cc", cat);

      console.log("CAT", responseCat.categoriesList);
    });

    // SubCategories.getSubCategories().then((responseSubcat) => {
    //   let subcat = [];
    //   for (let i = 0; i < responseSubcat.subCategoriesList.length; i++) {
    //    // console.log(responseSubcat.subCategoriesList[i].name);
    //     subcat.push({
    //       value: responseSubcat.subCategoriesList[i].name,
    //       label: responseSubcat.subCategoriesList[i].name,
    //       subCategoryId: responseSubcat.subCategoriesList[i]._id,
    //     });
    //   }
    //   this.setState({ subcategories: subcat });
    //  // console.log("sub", subcat);     
    // });



    // ChildCategories.getCategories().then((responseCat) => {
    //   console.log('ff',responseCat)
    //   let cat = [];
    //   for (let i = 0; i < responseCat.subSubCategoriesList.length; i++) {
    //     console.log(responseCat.subSubCategoriesList[i].name);
    //     cat.push({
    //       value: responseCat.subSubCategoriesList[i].name,
    //       label: responseCat.subSubCategoriesList[i].name,
    //       categoryId: responseCat.subSubCategoriesList[i]._id,
    //     });
    //   }
    //   this.setState({ childcategories: cat });
    //   console.log("cc2", cat);
    //   console.log("CAT", responseCat.subSubCategoriesList);
    // });
  }


  fetchSubCategories(id){
    SubCategories.getSubCategoriesbyId(id).then((responseSubcat) => {
      let subcat = [];
      for (let i = 0; i < responseSubcat.subCategoryData.length; i++) {
        console.log(responseSubcat.subCategoryData[i].name);
        subcat.push({
          value: responseSubcat.subCategoryData[i].name,
          label: responseSubcat.subCategoryData[i].name,
          subCategoryId: responseSubcat.subCategoryData[i]._id,
        });
      }
      this.setState({ subcategories: subcat });
      console.log("cc", subcat);
      // this.setState({subcategories:responseSubcat.subCategoriesList})
      // console.log(responseSubcat)
    });
  }




  fetchChildCategories(id){
    ChildCategories.getCategoriesbyId(id).then((response) => {
      console.log('ff',response)
      let cat = [];
      for (let i = 0; i < response.childCategoryData.length; i++) {
        console.log(response.childCategoryData[i].name);
        cat.push({
          value: response.childCategoryData[i].name,
          label: response.childCategoryData[i].name,
          childCategoryId: response.childCategoryData[i]._id,
        });
      }
      this.setState({ childcategories: cat });
      console.log("cc2", cat);
      console.log("CAT", response.childCategoryData);
      
    });
  }




  



  async handleSubmit() {
    // e.preventDefault();

    if (
      validate_Empty(this.state.data.overview.gigTitle) &&
      validate_Empty(this.state.data.overview.gigMegaData) &&
      validate_Empty(this.state.data.overview.description) &&
      validate_Empty(this.state.data.overview.categoryId) &&
      validate_Empty(this.state.data.overview.subCategoryId)&&
      validate_Empty(this.state.data.overview.childCategoryId)
    ) {
      this.setState({ spinner: true });

      console.log("DATREAL", this.state.data);
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

      console.log("DATA", self.state.data);
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .post(API_URL + "service/new", self.state.data, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log("X", response);
            //handle success
            console.log(response);
            if (response.data.success === true) {
              // localStorage.setItem(
              //   "user",
              //   JSON.stringify(response.data.userInfo)
              // );
              setTimeout(() => {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                });

                self.props.history.push("/service-lists");
                toast("Service Created Successfully", {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }, 1000);
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast(message, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        );
    } else {
      toast("Thers is an Error in the input fields. Please check!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  handleCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          categoryId: selectedOption.categoryId,
        },
      },
    });
    this.setState({
      selectedOptionCat: selectedOption,
      selectedOptionsubCat: '',
      selectedOptionchildCat: '',
    });

    this.fetchSubCategories(selectedOption.categoryId)
  };

  handleSubCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          subCategoryId: selectedOption.subCategoryId,
        },
      },
    });
    this.setState({
      selectedOptionsubCat: selectedOption,
      selectedOptionchildCat: '',
    });

    this.fetchChildCategories(selectedOption.subCategoryId)
  };


  handleChildCategory = (selectedOption) => {
    console.log("e", selectedOption);
    // let name = e.label;
    // let val = e.value;
    this.setState({
      data: {
        ...this.state.data,
        overview: {
          ...this.state.data.overview,
          childCategoryId: selectedOption.childCategoryId,
        },
      },
    });
    this.setState({
      selectedOptionchildCat: selectedOption,
    });

  };


  validateOverviewField = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    console.log(e);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        overview: {
          ...prevState.data.overview,
          [name]: e.target.value,
        },
      },
    }));

    this.validateAll(name, val);
  };

  validateOverviewSearchField = (e) => {
    let name = e.target.name;
    let val = e.target.value.split(",");
    console.log(e);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        overview: {
          ...prevState.data.overview,
          [name]: val,
        },
      },
    }));

    this.validateAll(name, val);
  };

  validateDeclarationField = (e) => {
    let name = e.target.name;
    let val = e.target.checked;
    console.log(e.target.name);

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        overview: {
          ...prevState.data.overview,
          [name]: val.toString(),
        },
      },
    }));

    this.validateAll(name, val);
  };

  validatePackageField = (input, i) => (e) => {
    // console.log('II',e.target.value)
    let name = e.target.name;
    let val = e.target.value;

    let values = [...this.state.data.packageDetails];
    if (e.target.name === "name") {
      values[i].name = e.target.value;
    } else {
      values[i].price = e.target.value;
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        overview: {
          ...prevState.data.overview,
        },
        packageDetails: values,
      },
    }));

    this.validateAll(name, val);
  };

  validatePackageFeatureField = (i, j) => (e) => {
    let name = e.target.name;
    let val = e.target.value;

    let values = [...this.state.data.packageDetails];
    // console.log('VVALL', values)
    if (e.target.name === "feature") {
      values[i].featuresList[j] = e.target.value;
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        overview: {
          ...prevState.data.overview,
        },
        packageDetails: values,
      },
    }));
    this.validateAll(name, val);
  };

  validatePackageFAQField = (i) => (e) => {
    let name = e.target.name;
    let val = e.target.value;
    // console.log('HERE')

    let values = [...this.state.data.faq];
    if (e.target.name === "question") {
      values[i].question = e.target.value;
    } else {
      values[i].answer = e.target.value;
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        // overview: {
        //   ...prevState.data.overview
        // },
        // packageDetails:{
        //   ...prevState.data.packageDetails,
        // },
        faq: values,
      },
    }));
    console.log("faq", this.state.data.faq);
    this.validateAll(name, val);
  };

  validateAll(name, value) {
    console.log(name);
    let formErrors = this.state.formErrors;
    switch (name) {
      case "gigTitle":
        formErrors.gigTitle =
          value.length < 20 ? "Title must be atleast 20 characters long!" : "";
        this.setState({
          formErrors: { ...this.state.formErrors, [name]: formErrors.gigTitle },
        });
        break;
      case "description":
        formErrors.description =
          value.length < 50
            ? "Description must be atleast 50 characters long!"
            : "";
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [name]: formErrors.description,
          },
        });
        break;

      case "declaration":
        formErrors.declaration =
          value == false ? "You must accept the declaration!" : "";
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [name]: formErrors.declaration,
          },
        });
        break;

      case "gigMegaData":
        formErrors.gigMegaData =
          value.length < 5 ? "Meta data can be same as your title!" : "";
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [name]: formErrors.gigMegaData,
          },
        });
        break;

      case "searchTags":
        console.log(value);
        formErrors.searchTags =
          value.length < 1
            ? "Enter atleast search Word separated by comma"
            : "";
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            [name]: formErrors.searchTags,
          },
        });
        break;

      default:
        break;
    }

    //this.setState(Object.assign(this.state, { formErrors,[name]: value }));
    console.log(this.state.formErrors);
  }

  // THIS VALIDATION WORKS WHEN SUBMIT BUTTON IS CLICKED
  validateOnSubmit = (e) => {
    e.preventDefault();
    console.log("here");
    let formErrors = this.state.formErrors;

    if (!this.state.data.overview.gigTitle) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          gigTitle: "Title must be atleast 20 characters long!",
        },
      });
      toast("Title must be atleast 20 characters long!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!this.state.data.overview.description) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          description: "Description must be atleast 50 characters long!",
        },
      });
      toast("Description must be atleast 50 characters long!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!this.state.data.overview.gigMegaData) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          gigMegaData: "Meta data can be same as your title!",
        },
      });
      toast("Meta data can be same as your title!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (this.state.data.overview.searchTags.length < 1) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          searchTags: "Enter atleast search Word separated by comma",
        },
      });
      toast("Enter atleast search Word separated by comma!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!this.state.data.packageDetails[0].price) {
      // this.setState({
      //   formErrors: { ...this.state.formErrors, priceOne:  "Enter Amount" },
      //   });
      toast("Enter Price Amount!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!this.state.data.packageDetails[1].price) {
      // this.setState({
      //   formErrors: { ...this.state.formErrors, priceTwo:  "Enter Amount" },
      //   });
      toast("Enter Price Amount!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (!this.state.data.packageDetails[2].price) {
      // this.setState({
      //   formErrors: { ...this.state.formErrors, priceThree:  "Enter Amount" },
      //   });
      toast("Enter Price Amount!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (
      !this.state.data.packageDetails[0].featuresList[0] ||
      !this.state.data.packageDetails[1].featuresList[0] ||
      !this.state.data.packageDetails[2].featuresList[0]
    ) {
      toast("Enter Atleast One Feature on Each Package!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (
      !this.state.data.faq[0].question ||
      !this.state.data.faq[0].answer ||
      !this.state.data.faq[1].question ||
      !this.state.data.faq[1].answer ||
      !this.state.data.faq[2].question ||
      !this.state.data.faq[2].answer
    ) {
      toast("Enter Atleast 3 FAQ's!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (
      !this.state.data.overview.categoryId ||
      !this.state.data.overview.subCategoryId
    ) {
      toast("You must choose Category and Subcategory for your Service!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (this.state.data.overview.declaration == false) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          declaration: "You must accept the declaration!",
        },
      });
      toast("You must accept the declaration!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      this.handleSubmit();
    }
  };

  render() {
    const { formErrors } = this.state;
    if (this.state.categories === null) {
      return null; //Or some other replacement component or markup
    }
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust specialist-dashboard">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>My Services</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form>
                        <Row>
                          <Col
                            xs
                            lg="2"
                            style={{
                              backgroundColor: "#eee",
                              borderRadius: 6,
                            }}
                            className="d-none"
                          ></Col>

                          <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                              <Row>
                                <Col>
                                  <div className="dashboard-content-title">
                                    <Link
                                      onClick={() =>
                                        this.props.history.goBack()
                                      }
                                    >
                                      {" "}
                                      <IoReturnUpBackOutline className="mx-3" />
                                    </Link>
                                    Add New Service
                                  </div>
                                </Col>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.validateOnSubmit}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Submit
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <Row className="form-bg-color">
                              <Col>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Enter the Service Title*
                                      </Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={this.validateOverviewField}
                                        value={
                                          this.state.data.overview.gigTitle
                                        }
                                        name="gigTitle"
                                        className={
                                          this.state.gigTitle
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      <Form.Text>
                                        This title defines your service. Please
                                        be accurate while writing the service
                                        name.
                                      </Form.Text>
                                      <p>
                                        {formErrors.gigTitle.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.gigTitle}
                                          </span>
                                        )}
                                      </p>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlTextarea1"
                                    >
                                      <Form.Label>
                                        Write a Small Description on your
                                        Service*
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={this.validateOverviewField}
                                        name="description"
                                        value={
                                          this.state.data.overview.description
                                        }
                                        className={
                                          this.state.descriptionError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      <p>
                                        {formErrors.description.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.description}
                                          </span>
                                        )}
                                      </p>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Search Tags*</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={
                                          this.validateOverviewSearchField
                                        }
                                        value={
                                          this.state.data.overview.searchTags
                                        }
                                        name="searchTags"
                                        className={
                                          this.state.searchTagsError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      <p>
                                        {formErrors.searchTags.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.searchTags}
                                          </span>
                                        )}
                                      </p>
                                    </Form.Group>

                                    {/* <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Choose Category</Form.Label>

                                      <Form.Select
                                        value={
                                          this.state.data.overview.categoryId ||
                                          ""
                                        }
                                        onChange={this.handleCategory}
                                        name="categoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.categoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      >
                                        <option>Select Category Type</option>
                                        {this.state.categories &&
                                        this.state.categories.length
                                          ? this.state.categories.map(
                                              (item) => (
                                                <option value={item._id}>
                                                  {item.name}
                                                </option>
                                              )
                                            )
                                          : ""}
                                      </Form.Select>
                                      <Form.Text>
                                        Customer can find you if you have
                                        proprly chosen the category that belongs
                                        to your service.
                                      </Form.Text>
                                    </Form.Group> */}

                                    {/* <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Choose Sub Category
                                      </Form.Label>

                                      <Form.Select
                                        value={
                                          this.state.data.overview.subCategoryId
                                        }
                                        onChange={this.validateOverviewField}
                                        name="subCategoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.subCategoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      >
                                        <option value="">
                                          Select Sub Category Type
                                        </option>
                                        {this.state.subcategories &&
                                        this.state.subcategories.length
                                          ? this.state.subcategories.map(
                                              (item) => (
                                                <option value={item._id}>
                                                  {item.name}
                                                </option>
                                              )
                                            )
                                          : ""}
                                      </Form.Select>
                                      <Form.Text>
                                        Customer can find you if you have
                                        proprly chosen the sub category that
                                        belongs to your service.
                                      </Form.Text>
                                    </Form.Group> */}

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Meta Data*</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        onChange={this.validateOverviewField}
                                        value={
                                          this.state.data.overview.gigMegaData
                                        }
                                        name="gigMegaData"
                                        className={
                                          this.state.gigMegaDataError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      <p>
                                        {formErrors.gigMegaData.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.gigMegaData}
                                          </span>
                                        )}
                                      </p>
                                    </Form.Group>
                                  </Col>

                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Choose Category*</Form.Label>

                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionCat || ""
                                        }
                                        onChange={this.handleCategory}
                                        name="categoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.categoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                        options={
                                          this.state.categories
                                            ? this.state.categories
                                            : ""
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Choose Sub Category*
                                      </Form.Label>

                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionsubCat || ""
                                        }
                                        onChange={this.handleSubCategory}
                                        name="subCategoryId"
                                        aria-label="Default select"
                                        className={
                                          this.state.categoryIdError
                                            ? "hasError"
                                            : "valid"
                                        }
                                        options={
                                          this.state.subcategories
                                            ? this.state.subcategories
                                            : ""
                                        }
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Choose Category Service*
                                      </Form.Label>

                                      <Select
                                        //value={this.state.data.overview.categoryId ||""}
                                        value={
                                          this.state.selectedOptionchildCat || ""
                                        }
                                        onChange={this.handleChildCategory}
                                        name="childCategoryId"
                                        aria-label="Default select"                                        
                                        options={
                                          this.state.childcategories
                                            ? this.state.childcategories
                                            : ""
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            {/* package */}

                            <Row className="mt-2">
                              <h2>Package Details</h2>
                              <p>
                                Define your package name and price with the
                                below 3 options for the customers to choose
                                from. The information mentioned here will be
                                shown to the customer while ordering the service
                                for you.
                              </p>
                              <Col className="create-package-box">
                                <div className="package-number-float">1</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder="Eg: Basic"
                                    onChange={this.validatePackageField(
                                      "name",
                                      0
                                    )}
                                    // value={
                                    //   this.state.data.packageDetails[0].name
                                    // }

                                    value={"Basic"}
                                    disabled
                                    name="name"
                                    // className={
                                    //   this.state.packageDetailsNameError
                                    //     ? "hasError"
                                    //     : "valid"
                                    // }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Price*</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1}>
                                      <BiRupee className="fs-32  c4" />
                                    </Col>
                                    <Col md={5}>
                                      <Form.Control
                                        type="number"
                                        onInput={(e) => {
                                          e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                          )
                                            .toString()
                                            .slice(0, 8);
                                        }}
                                        placeholder="Eg:3200"
                                        onChange={this.validatePackageField(
                                          "price",
                                          0
                                        )}
                                        value={
                                          this.state.data.packageDetails[0]
                                            .price
                                        }
                                        name="price"
                                        className={
                                          this.state.packageDetailsError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />

                                      <p>
                                        {formErrors.priceOne.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.priceOne}
                                          </span>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One*
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      0
                                    )}
                                    value={
                                      this.state.data.packageDetails[0]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      1
                                    )}
                                    value={
                                      this.state.data.packageDetails[0]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      2
                                    )}
                                    value={
                                      this.state.data.packageDetails[0]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      3
                                    )}
                                    value={
                                      this.state.data.packageDetails[0]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      0,
                                      4
                                    )}
                                    value={
                                      this.state.data.packageDetails[0]
                                        .featuresList[4]
                                    }
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col className="create-package-box">
                                <div className="package-number-float">2</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageField(
                                      "name",
                                      1
                                    )}
                                    // value={
                                    //   this.state.data.packageDetails[1].name
                                    // }
                                    value={"Standard"}
                                    disabled
                                    name="name"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Price* </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1}>
                                      <BiRupee className="fs-32  c4" />
                                    </Col>
                                    <Col md={5}>
                                      <Form.Control
                                        type="number"
                                        onInput={(e) => {
                                          e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                          )
                                            .toString()
                                            .slice(0, 8);
                                        }}
                                        placeholder="Eg: 5400"
                                        onChange={this.validatePackageField(
                                          "price",
                                          1
                                        )}
                                        value={
                                          this.state.data.packageDetails[1]
                                            .price
                                        }
                                        name="price"
                                        className={
                                          this.state.packageDetails
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />

                                      <p>
                                        {formErrors.priceTwo.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.priceTwo}
                                          </span>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One*
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      0
                                    )}
                                    value={
                                      this.state.data.packageDetails[1]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      1
                                    )}
                                    value={
                                      this.state.data.packageDetails[1]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      2
                                    )}
                                    value={
                                      this.state.data.packageDetails[1]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      3
                                    )}
                                    value={
                                      this.state.data.packageDetails[1]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      1,
                                      4
                                    )}
                                    value={
                                      this.state.data.packageDetails[1]
                                        .featuresList[4]
                                    }
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col className="create-package-box">
                                <div className="package-number-float">3</div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Name</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageField(
                                      "name",
                                      2
                                    )}
                                    // value={
                                    //   this.state.data.packageDetails[2].name
                                    // }
                                    value={"Advanced"}
                                    disabled
                                    name="packageDetails"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Package Price*</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Row>
                                    <Col md={1}>
                                      <BiRupee className="fs-32  c4" />
                                    </Col>
                                    <Col md={5}>
                                      <Form.Control
                                        type="number"
                                        onInput={(e) => {
                                          e.target.value = Math.max(
                                            0,
                                            parseInt(e.target.value)
                                          )
                                            .toString()
                                            .slice(0, 8);
                                        }}
                                        placeholder="Eg: 6500"
                                        onChange={this.validatePackageField(
                                          "price",
                                          2
                                        )}
                                        value={
                                          this.state.data.packageDetails[2]
                                            .price
                                        }
                                        name="packageDetails"
                                        className={
                                          this.state.packageDetails
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />
                                      <p>
                                        {formErrors.priceThree.length > 0 && (
                                          <span style={{ color: "red" }}>
                                            {formErrors.priceThree}
                                          </span>
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="mt-4">
                                    Feature One*
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      0
                                    )}
                                    value={
                                      this.state.data.packageDetails[2]
                                        .featuresList[0]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Feature Two(Optional)</Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      1
                                    )}
                                    value={
                                      this.state.data.packageDetails[2]
                                        .featuresList[1]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Three(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      2
                                    )}
                                    value={
                                      this.state.data.packageDetails[2]
                                        .featuresList[2]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Four(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      3
                                    )}
                                    value={
                                      this.state.data.packageDetails[2]
                                        .featuresList[3]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Feature Five(Optional)
                                  </Form.Label>
                                  {/* <DatePicker   /> */}

                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={this.validatePackageFeatureField(
                                      2,
                                      4
                                    )}
                                    value={
                                      this.state.data.packageDetails[2]
                                        .featuresList[4]
                                    }
                                    name="feature"
                                    className={
                                      this.state.packageDetails
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <h2>Describe Frequently Asked Questions</h2>

                              <Col>
                                <div className="qa-section">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Question</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      type="text"
                                      placeholder="Eg: How do you deliver?"
                                      onChange={this.validatePackageFAQField(0)}
                                      value={this.state.data?.faq[0]?.question}
                                      name="question"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Answer</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      type="text"
                                      placeholder="Eg: Via PDF Reports"
                                      onChange={this.validatePackageFAQField(0)}
                                      value={this.state.data?.faq[0]?.answer}
                                      name="answer"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="qa-section">
                                  <Form.Group
                                    className="mt-2"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Question</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      type="text"
                                      placeholder="Eg:What is the average time to deliver the certificate?"
                                      onChange={this.validatePackageFAQField(1)}
                                      value={this.state.data?.faq[1]?.question}
                                      name="question"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Answer</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      type="text"
                                      placeholder="Eg: 10 to 15 days"
                                      onChange={this.validatePackageFAQField(1)}
                                      value={this.state.data?.faq[1]?.answer}
                                      name="answer"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="qa-section">
                                  <Form.Group
                                    className="mt-2"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Question</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      type="text"
                                      placeholder="Eg: What happens if i did not received the Report?"
                                      onChange={this.validatePackageFAQField(2)}
                                      value={this.state.data?.faq[2]?.question}
                                      name="question"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>Answer</Form.Label>
                                    {/* <DatePicker   /> */}

                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      type="text"
                                      placeholder=""
                                      onChange={this.validatePackageFAQField(2)}
                                      value={this.state.data?.faq[2]?.answer}
                                      name="answer"
                                      className={
                                        this.state.faq ? "hasError" : "valid"
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <Form.Group
                                  className="my-5"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Check
                                    onChange={this.validateDeclarationField}
                                    value={this.state.data.overview.declaration}
                                    name="declaration"
                                    type="checkbox"
                                    label="Declaration : I hereby confirm that I am registered as Self Employed and take full responsibility for all Income Tax and National Insurance payments on my gross earnings from Beyond Smart Cities Pvt.Ltd."
                                  />
                                  <p>
                                    {formErrors.declaration.length > 0 && (
                                      <span style={{ color: "red" }}>
                                        {formErrors.declaration}
                                      </span>
                                    )}
                                  </p>
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="dashboard-content-actions mb-5">
                              <Row>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.validateOnSubmit}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Submit
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiCheck,
  BiCheckDouble,
  BiNotificationOff,
  BiNotification,
  BiBell,
  BiBellOff,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle, FiX } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";

import moment from "moment";
import DashboardSidebar from "../DashboardSidebar";
import { SidebarCommonMenuItem } from "../SidebarCommonMenuItem";
import axios from "axios";
import { API_URL } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-js-loader";


const notifications=[{
    _id: "kjhkjh87",
    userId: "7867868HJGHGGH", 
    notificationText: "This is a basic notification string, To keep your account safe, we need to be sure it was you who just tried to sign in. We sent a text with a one-time code to:",
    type: "Alert",
    read: false,
    createdAt: "31/2121/35656", // date string
    updatedAt: "31/2121/35656", // date string
},
{
  _id: "kjhkjh8799",
  userId: "7867868HJGHGG656H", 
  notificationText: "This is a second notification string, To keep your account safe, we need to be sure it was you who just tried to sign in. We sent a text with a one-time code to:",
  type: "Offer",
  read: false,
  createdAt: "31/2121/35656", // date string
  updatedAt: "31/2121/35656", // date string
}]

export default class Notifications extends Component {
  constructor(props) {
    super(props);  

    this.state = {
      dataLoading:false,      
      notificationList:'',
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      currentSelectedDashboard:localStorage.getItem('currentSelectedDashboard'),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",
    };
  }
  componentDidMount() {

   
this.getNotificationsList()
   
  } 




  async getNotificationsList() {     
    var self = this;     
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading:true
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));     
    await axios
      .get(API_URL + "notification/me", {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log(response);           

          if (response.data.success === true) {
            self.setState({
              notificationList:response.data.notificationList
            })
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading:false
            });  
            
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading:false
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading:false
          });
        }         
      );
  }   





  async makeRead(id) {   

    this.setState({ spinner: true });
     var self = this;
     self.setState({
       submitLoading: true,
       errorMessage: "",
     });

     let data={        
           read : true,             
     }
     const token = await JSON.parse(localStorage.getItem("token"));
     // Post the form, just make sure to set the 'Content-Type' header
     await axios
       .post(API_URL + "notification/read/update/"+id,  {
         headers: {
           auth: token,
           Accept: "application/json",
         },
       })

       .then(
         function (response) {
           console.log('Noti',response);
           //handle success
console.log(response)
           if (response.data.success === true) {
               
             // localStorage.setItem(
             //   "user",
             //   JSON.stringify(response.data.userInfo)
             // );
             setTimeout(() => {
               self.setState({
                 submitLoading: false,
                 isdataSubmitted: true,
                 spinner: false,
               });
               
               toast("Notification Read Successfully", {
                 position: toast.POSITION.BOTTOM_RIGHT,
               });
             }, 1000);
           } else {
             self.setState({
               errorMessage: response.data.error,
               submitLoading: false,
               spinner: false,
             });
             toast("Thers is an Error in the input fields. Please check!", {
               position: toast.POSITION.BOTTOM_RIGHT,
             });
           }
         },
         (error) => {
           const message =
             (error.response &&
               error.response.data &&
               error.response.data.message) ||
             error.message ||
             error.toString();
           // console.log(message)
           self.setState({
             errorMessage: message,
             submitLoading: false,
             spinner: false,
           });
           toast("Thers is an Error in the input fields. Please check!", {
             position: toast.POSITION.BOTTOM_RIGHT,
           });
         }
       );
   } 
 

  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col
                          xs
                          lg="2"
                          className="dashboard-sidebar"
                        >
                        <SidebarCommonMenuItem
currentSelectedDashboard={this.state.currentSelectedDashboard}
/>
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link to="/property-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                  Notifications
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col className="position-relative">
                              <div className="shd-1 p-3"> 
                              {/* MAIL INBOX STARTS */}
                              <Row className="align-items-center">
                                  <Col md={12}>

                                  {this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}
                                      <ul className="notificationList p-0">

                                        {this.state.notificationList && this.state.notificationList.length?
                                        (                                        
                                          this.state.notificationList.map((item)=>(
                                          <li className="cursor-pointer"> 
                                          <Row>
                                          <Col md={1} className="align-self-center">                                             
                                            <BiBell onClick={()=>this.makeRead(item._id)} className="ms-2 fs-2 c2"/>                                           
                                            </Col>   
                                            <Col md={9}>                                         
                                             
                                                   <h3 className={item.read?"d-inline-block me-4 c13" : "d-inline-block me-4" }>{item.type}</h3>
                                                   <p className={item.read? "d-block fs-12 me-4 c13" : "d-block fs-12 me-4"}>{item.notificationText}</p> 
                                                  <p className="d-block fs-10">{item.createdAt}</p>
                                            </Col>
                                            <Col md={2} className="align-self-center">  
                                            {item.read?
                                            <BiCheck onClick={()=>this.makeRead(item._id)} className="ms-2 fs-2 c2"/>
                                             :
                                             <BiCheckDouble className="ms-2 fs-2 c13"/>
                                              }
                                            </Col>   
                                            </Row> 
                                             </li>
                                        ))
                                        ):
                                        (
                                          !this.state.dataLoading?
                                          <li className="text-center p-3 bdr-none">
                                             <BiBellOff className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2">No Recent Notifications found!</h3>
<p className="text-center">You receive notifications on new alerts and offers from smartcity platform on regular basis.</p>

                                            </li>
                                            :''
                                        )
                                      
                                      } 
                                                       
                                      </ul>
                                  </Col> 
                                  
                              </Row> 
                                {/* MAIL INBOX ENDS */}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

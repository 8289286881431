import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiAccessibility,
} from "react-icons/bi";

import validator from "validator";
import DashboardSidebar from "../DashboardSidebar";
import moment from "moment";
import SpecialistDashboardSidebar from "../specialist/SpecialistDashboardSidebar";
import ManufacturerDashboardSidebar from "../manufacturer/ManufacturerDashboardSidebar";
import { SidebarCommonMenuItem } from "../SidebarCommonMenuItem";
import axios from "axios";
import { API_URL } from "../../config";
import { ToastContainer, toast } from "react-toastify";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.state = {
      emailCode:'',
      emailCodeForm:false,
      profileImage:'',
      rawImage:'',
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      currentSelectedDashboard: localStorage.getItem(
        "currentSelectedDashboard"
      ),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    if (this.state.userData?.isEmailVerified) {
      this.setState({ notice: false });
    } else {
      setTimeout(() => {
        this.setState({ notice: true });
      }, 3000);
    }
    this.getUserDetails()
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  handleUpdate() {}
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }




 handleProfileImageChange = e => {
   console.log(e)
    if (e.target.files.length) {
      this.setState({       
        rawImage: e.target.files[0]
      });
      this.handleUpload()
    }
  };



  async handleUpload(e)  {
    var self = this; 
    const formData = new FormData();
    if (e.target.files.length) {   
    formData.append("profileImage", e.target.files[0]);
    
    }

    const token = await JSON.parse(localStorage.getItem("token"));   
    await axios
      .put(API_URL + "user/profile/imageEdit", formData, {
        headers: {
          auth: token,         
          "Content-Type": "multipart/form-data"
        },
      })

      .then(
        function (response) {
          console.log('IMAGE UPLOAD',response);         
console.log(response)
          if (response.data.success === true) {
            toast("Profile Photo Updated Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // window.location.reload(false);
            self.getUserDetails()
  }
  else{
    toast(response.data.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
}
      )
}





 async getUserDetails() {     
  var self = this; 

  
  const token = await JSON.parse(localStorage.getItem("token"));     
  const user = await JSON.parse(localStorage.getItem("user")); 
  await axios
    .get(API_URL + "user/"+ user?._id , {
      headers: {
        auth: token,
        Accept: "application/json",
      },
    })

    .then(
      function (response) {
        console.log("USER",response);           

        if (response.data.success === true) {
          self.setState({
            profileImage:response.data?.userData?.profile?.image?.secureUrl
          })
          
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        // console.log(message)
        toast("Network Error", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        self.setState({
          errorMessage: message,
          submitLoading: false,
          dataLoading:false
        });
      }         
    );
}    


 emailVerify=async()=>{  

  this.setState({
    emailCodeForm:true
  })

  const token = await JSON.parse(localStorage.getItem("token"));   
  await axios
    .post(API_URL + "user/emailVerify",{ }, {
      headers: {
        auth: token,
        Accept: "application/json",
      },
    })

    .then(
      function (response) {
        console.log('EV',response);         
console.log(response)
        if (response.data.success === true) {
          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
}
else{
  toast(response.data.message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}
}
    )
}




verifyEmailCode=async()=>{
  let self=this
  const token = await JSON.parse(localStorage.getItem("token"));   
  await axios
    .put(API_URL + "user/verifyEmailCode",{"emailVerificationCode":this.state.emailCode }, {
      headers: {
        auth: token,
        Accept: "application/json",
      },
    })

    .then(
      function (response) {
        console.log('EV',response);         
console.log(response)
        if (response.data.success === true) {        

          this.setState((prevstate)=>({
          userData:{
            ...this.state.userData,
            isEmailVerified:true
          }

          }))


          toast(response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          self.setState({
            emailCodeForm:false
          })
}
else{
  toast(response.data.message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });

 
}

}
    )
}


handleEmailCode=(e)=>{
  e.preventDefault();
  console.log(e.target.value)
  this.setState({
    emailCode:e.target.value
  })
}


  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData?.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    {/* <div className="top-notice">
                      {!userData?.isEmailVerified && this.state.notice ? (
                        <p>
                          We have sent an email confirmation to your email
                          address. Please confirm it before 14 days.{" "}
                          <span
                            onClick={this.dismissNotice}
                            className="c5 fw-700 cursor-pointer"
                          >
                            Dismiss Now
                          </span>
                        </p>
                      ) : null}
                    </div> */}
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                          {/* <SidebarCommonMenuItem
                            currentSelectedDashboard={
                              this.state.currentSelectedDashboard
                            }
                          /> */}

                        <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  My Profile Details
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end"></div>
                              </Col>
                            </Row>
                          </div>

                          <Row>
                            <Col className="position-relative">
                              <div className="floating-button-topright">
                                <BiPencil
                                  onClick={this.editProfile}
                                  className="floating-icon"
                                />
                              </div>

                              <ul class="tableList shd-1 p-3">
                                <li>
                                  <BiUser className="me-2" />
                                  {userData?.firstName} {userData?.lastName}
                                </li>
                                <li>
                                  <BiEnvelope className="me-2" />
                                  {userData?.email}
                                  {userData?.isEmailVerified ? (
                                    <span className="bg-green ms-2 bdr-rad-4 c1 px-3 py-1 fs-12">
                                      Verified
                                    </span>
                                  ) : (
                                    <span onClick={this.emailVerify} className="bg-red ms-2 bdr-rad-4 c1 px-3 py-1 fs-12 cursor-pointer">
                                      Verify Now
                                    </span>
                                  )}
                                </li>
                                {this.state.emailCodeForm ?
                                <li className="mt-3 mb-3 bg-4 p-3 bdr-rad-4">
                                <label>Enter the validation code received to your email</label>
                                  <div className="row">
                                  <div className="col-md-3">
                                  
                                  <input type="text"  
                                  
                                    onChange={(e)=>this.handleEmailCode(e)}                                    
                                    className="form-control mt-2" />
                                  </div>
                                  <div className="col-md-2">
                                  <a onClick={this.verifyEmailCode} className="btn btn-primary my-2 c1">Verify</a>
                                  </div>
                                  </div>
                                  </li>
                                :''}

                                <li>
                                  <BiPhone className="me-2" />
                                  {userData?.phoneNumber}
                                </li>
                                {this.state.userData?.profile ? (
                                  <>
                                  {userData?.profile?.dob?
                                    <li>
                                      <BiCalendarStar className="me-2" />
                                      {userData?.profile?.dob}
                                    </li>
                                    :''}
                                     {userData?.profile?.gender?
                                    <li>
                                      <BiUserX className="me-2" />
                                      {userData?.profile?.gender}
                                    </li>
                                    :''}
                                     {userData?.profile?.profession?
                                    <li>
                                      <BiChair className="me-2" />
                                      {userData?.profile?.profession}
                                    </li>
                                    :''}
                                    {userData?.profile?.address?.street ?
                                    <li>
                                      <BiChair className="me-2" />
                                      {userData?.profile?.address?.street}
                                    </li>
                                    : ''}
                                    {userData?.profile?.address?.country?
                                    <li>
                                      <BiLocationPlus className="me-2" />
                                      {userData?.profile?.address?.city}{" "}
                                      {userData?.profile?.address?.state}{" "}
                                      {userData?.profile?.address?.country}
                                    </li>
                                    :''}
                                  </>
                                ) :
                                ''}
                                
                                
                                  
                                  {!userData?.profile?.gender?
                                  <p className="profileIncompleteAlert">
                                    Your Profile is incomplete. Please{" "}
                                    <span
                                      className="cursor-pointer c5"
                                      onClick={this.editProfile}
                                    >
                                      {" "}
                                      update now.
                                    </span>
                                  </p>
                                  :''}
                                
                                

                                {/* <li>
                                  <BiBadge className="me-2" />
                                  {userData?.isActive ? (
                                    <span className="bdr-rad-4 c4 py-1">
                                      Active Since {createdDate}
                                    </span>
                                  ) : (
                                    <span className="bg-red ms-2 bdr-rad-4 c1 px-3 py-1 fs-12">
                                      Account is Suspended
                                    </span>
                                  )}
                                </li> */}
                                <li>
                               
                                <BiAccessibility className="me-2" />
                                Assigned Roles:
                                  {userData?.role?.map((item)=>
                                  <span className="bg-bluemain ms-2 bdr-rad-4 c1 px-3 py-1 fs-12 me-1"> {item}</span>
                                  )}
                                </li>

                              </ul>
                            </Col>

                            <Col
                              xs
                              lg="2"
                              className="dashboard-content-area-right"
                            >
                              <div className="floating-button-topright">
                              <label htmlFor="upload-button">
                                <BiPencil className="floating-icon" />
                                </label>
                              </div>
                              <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={this.handleUpload}
                              />

                              {this.state.profileImage?
                            
                                <Image
                                  className="img-fluid"
                                  src={this.state.profileImage}
                                  rounded
                                />
                                :
                                <Image
                                className="img-fluid"
                                src="assets/images/blank-user.png"
                                rounded
                              />
  }

                             
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';
// import { connect } from "react-redux";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import { HiOutlineMail,HiOutlinePhone,HiOutlineUserCircle } from "react-icons/hi";

// class Dashboard extends Component {

//   render() {
//     const { user: currentUser } = this.props;
//     console.log('USER in Dashboard',currentUser)

//     if (!currentUser) {
//       return <Redirect to="/login" />;
//     }

//     return (

// <div>
//       <div className="content-area topadjust px-4 bg-4">
//       <div className="container-fluid">
//           <nav aria-label="breadcrumb my-2">
//           <ol class="breadcrumb">
//             <li class="breadcrumb-item">
//             <Link to="/"> Home </Link>
//             </li>
//             <li class="breadcrumb-item">
//             <Link to="/"> Dashboard</Link>
//             </li>
//           </ol>
//           </nav>

//           <div className="row align-items-center">
//           <div className="col-md-12">
//           <h2>Welcome Back! {currentUser.firstName}</h2>
//             </div>
//             </div>

//         <div className="row align-items-center mt-3">

//         <div className="col-sm-12 col-md-4">
//             <div className="bdr-4 p-3 min-h-200 align-items-center bg-1 bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">My Dashboard</h2>
//                <p className="c4 m-1"><HiOutlineUserCircle/>&nbsp;{currentUser.firstName}&nbsp;{currentUser.lastName}</p>
//               <p className="c4 m-1"><HiOutlineMail/>&nbsp;{currentUser.email}</p>
//               <p className="c4 m-1"><HiOutlinePhone/>&nbsp;{currentUser.phoneNumber}</p>
//               <Link to="/login" class="btn btn-primary">
//               Edit Dashboard
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-4 align-self-center">
//             <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">Find Green Build Services</h2>
//               <p className="c4">
//                 Work with the largest network of independent professionals and
//                 get things done from quick turnarounds to big transformations.
//               </p>
//               <Link to="/login" class="btn btn-primary-trans">
//                View Professionals
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-4">
//             <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">Get Green Build Ratings</h2>
//               <p className="c4">
//               Going Beyond the Regulatory Equations. Submit your property and get certifications and ratings.
//               </p>
//               <Link to="/login" class="btn btn-primary-trans">
//               Submit your Property Now
//               </Link>
//             </div>
//           </div>

//         </div>

//         <div className="row my-5">

//           <div className="col-sm-12 col-md-6">
//             <div className="bdr-2 p-3 min-h-200 align-items-center bg-2 bdr-rad-4 m-2">
//               <h2 className="fw-700 c1 fs-26">Are you a Service Provider?</h2>
//               <p className="c1">
//                 Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
//               </p>
//               <Link to="/apply-specialist" class="btn btn-primary-trans">
//                Apply Now
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-6">
//             <div className="bdr-3 p-3 min-h-200 align-items-center bg-3 bdr-rad-4 m-2">
//               <h2 className="fw-700 c1 fs-26">Are you a Product Manufacturer?</h2>
//               <p className="c1">
//                 Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
//               </p>
//               <Link to="/apply-manufacturer" class="btn btn-primary-trans">
//                Get SmartPro Rating and Showcase your Products
//               </Link>
//             </div>
//           </div>

//         </div>

//         <header className="jumbotron">
//           <h3>
//             {/* <strong>{currentUser.username}</strong> Dashboard */}

//           </h3>
//         </header>
//         {/* <p>
//           <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
//           {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
//         </p>
//         <p>
//           <strong>Id:</strong> {currentUser.id}
//         </p>
//         <p>
//           <strong>Email:</strong> {currentUser.email}
//         </p>
//         <strong>Authorities:</strong>
//         <ul>
//           {currentUser.roles &&
//             currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
//         </ul> */}
//       </div>
//       </div>
//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const { user } = state.auth;
//   return {
//     user,
//   };
// }

// export default connect(mapStateToProps)(Dashboard);

import React, { Component } from 'react'

export default class Careers extends Component {
    render() {
        return (
            <div>
                <div  className="Nature-banner inner-page">
         
         <div className="row careers">
         <div className="col-md-12">
               <div className="careers-banner">
                   <h1 className='heroText1 c1'>Independent talent at the heart of every business</h1>
                   <p className='heroText2a c1'>Be part of the team that's bringing this vision to life</p>                  
               </div>
         </div>
     </div>
     
     
     
     
  <div className="container mobile-careers">
       <div className="row mb-4">
        <div className="col-md-12">
            <h2 className="heroText1a py-4">Working at Beyond Smart Cities</h2>
             <p>At Beyond Smart Cities, we're solving the world's most important problems with talented individuals who share our passion to change the world. Our culture is fast-paced, energetic and innovative. It is our mission to grow and cultivate the world’s largest digital marketplace, a place where people can f ind and purchase any professional service they need and build any business they dream</p>
            
          
     </div>
      </div>
      <div className="row  mb-4">
          <div className="col-md-6">
              <div className="careers-join">
                    <h2 className="heroText2">JOIN US.LETS BUILD A SUSTAINABLE PLANET</h2>
              <p>Beyond Smart Cities is India's Green Technology Marketplace that connects businesses with independent  talent.  We serve everyone from  one-person startups to MNC with a powerful,  trust-driven  platform  that  enables  companies, product manufactures, property owners and freelancers to work together in new ways that unlock their potential. We are making the future cause of our present. And you could be part of it.</p>
              <div>
             <a href="">Follow us @beyondsmartcities</a>
             
              <br/>
             <a href="">Follow us @beyondsmartcities</a>
             </div>
              </div>
            
          </div>
           <div className="col-md-6">
               <div className="img-fluid">
                   <img src="assets/images/careers-2.png" className="img-fluid" />
               </div>
          </div>
          
      </div>
      
      <div className="row mb-4">
            <h2 className="heroText1a py-3"> Teams</h2>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Sustainability</h2>
                   
                    
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Energy</h2>
                   
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Environment</h2>
                    
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>GHG</h2>
                   
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Customer support</h2>
                    
                </div>
            </div>
            <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Marketing</h2>
                  
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Data</h2>
                  
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>General &Admin</h2>
                   
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Technical Support</h2>
                  
                </div>
            </div>
             <div className="col-md-3">
                <div className="card teams-content">
                    <h2>Design</h2>
                   
                </div>
            </div>
         
      </div>

      <div className="row mb-4">
          <a href="mailto:careers@beyondsmartcities.in" className='heroText1a py-4 bdr-3 bdr-rad-10 text-center'>To Apply for a Job, Please email us to careers@beyondsmartcities.in</a>
      </div>
      
      {/* <div className="row mb-4">
           <h2 className="section-title p-tb-20 text-center">Welcome to Beyond Smart CitiesSustainability</h2>  
           
            <div className="col-md-6">
               <div className="img-fluid">
                   <img src="assets/images/about-2.jpg" className="img-fluid careers-img" />
               </div>
          </div>
                <div className="col-md-6">
                 <h2 className="section-title p-tb-20 text-center">Get know Sustainability</h2>
                         <p>We provide Strategic Sustainability Assessment services and a range of services to support with the identif ication and implementation of sustainability and climate change solutions for master plans and projects. Sustainable services refer to an intention to minimize the negative impact of construction on the environment. Energy eff iciency plays a large role in this effort, and is Building commissioning’ primary focus within the process.</p>
          </div>
          
          
      </div> */}
      
      {/* <div className="row mb-4">
             <h2 className="section-title p-tb-20 text-center"> Bengaluru Sustainability Development Team Specialist (Bengaluru)</h2>  
      
             <div className="collapsible-list">
                    <button type="button" className="collapsible">The Position</button>
          <div className="content"> 

                <div className="smart-list">
                    <p>BSC is looking for a Consultant to join our Built Energy and Sustainability Team based at our office in Bengaluru.Our Energy and Sustainability Team provide a range of assessments (Energy Reports, Overheating Assessments, Daylight Analysis, BREEAM Assessments, Sustainability Statements) on new, existing and operational buildings to a multitude of clients. RPS specialises in all areas of the built and natural environment, shaping the future of our environmental, social and economic landscapes.</p> 
              </div>
             </div>
              <button type="button" className="collapsible">Responsibilities</button>
          <div className="content"> 

                <div className="smart-list">
                   <p>You’ll be working with our award-winning team of Energy and BREEAM assessors and have responsibility for: the completion of energy calculations and sustainability assessments (e.g., BREEAM, Home Quality Mark, Sustainability reports etc) , data interpretation and analysis, site visits and surveys, regulatory consultations and report writing; delivery of Energy and Sustainability assessments on new, existing and operational buildings, site visits and surveys, report writing and reviewing both on a technical and commercial level.</p>
             </div>
             </div>
                <button type="button" className="collapsible">Requirements</button>
          <div className="content"> 

                <div className="smart-list">
             <p>You’ll have worked in a similar role and have experience of how the BREEAM process works and what the BRE require is essential.Knowledge of Code for Sustainable Homes, Life Cycle Assessments, Circular Economy, Home Quality Mark, WELL, Fitwel, LEED, CEEQUAL is beneficial.</p>
             </div>
             </div>
                <button type="button" className="collapsible">Benefits </button>
          <div className="content"> 

                <div className="smart-list">
                  <p>When you join our team, you're helping create opportunities for anyone in the world to build their business, brand, or dream. Is there a more meaningful benefit than that? To top it off we also have a tight-knit BSC culture, team gatherings, you will be able to work from a pretty amazing office-space</p>
             </div>
             </div>
             
             
             
             
             </div>
      </div> */}
     
</div>
</div>
            </div>
        )
    }
}

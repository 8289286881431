import React, { Component } from 'react'

export default class BSASCertifiedProject extends Component {
    render() {
        return (
            <div>
                   <div  class="Nature-banner inner-page">
      <div class="container">
           <div class="row mb-4">
            <div class="col-md-12">
                <h2 class="section-title p-tb-20 text-center">BEYOND SMART CITIES PVT.LTD</h2>
                <div class="title-divider"></div>
                
                </div>
                
                
                
                
                
                
                
                      </div>
                      <div class="Project-layout mb-4">
                       <div class="row  ">
                           <div class="col-md-6 Project-Description-BSAS">
                               <img src="assets/images/LEED Consulting for Manufacturing Facility.jpeg" alt="BEYOND SMART CITIES"/>
                               
                               <h3>BSAS Certificates</h3>
                           </div>
                           <div class="col-md-6">
                               <div class=" Project-Description">
                               <h3>Project Description</h3>
                               <p>The Wajbah Healthcare Center is for primary ambulatory care. In addition to the
Clinic, other complementary miscellaneous buildings shall occupy the Wajbah site
such as a Mosque, an Ancillary building, a Guard House, 3 Watchman rooms and,
Ambulance Garage, and waste holding Room. </p>
<h3>BSAS PROJECT DETAILS
</h3>
<ul>
    <li><b>GSAS Scheme: </b>Warehouse and factory</li>
     <li><b>GSAS Certificate:</b>BSAS Commercial </li>
      <li><b>Rating :</b>(2) STAR </li>
       <li><b>Owner :</b>TLM Interior</li>
        <li><b>Address :</b>Wajbah, Umm al dome street,south Doha -
Qatar</li>
</ul>
<h3>Benefits</h3>
                                       <p>21% Savings on Energy Use</p>
  <p>47.5% Savings on Potable Water Use</p>
  <p>28.72% Materials Used with Recycled Content</p>
</div>
                           </div>
                            </div>
                           
                           
                         
                             
                     </div>         
                              
    </div>
    


            </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, Link,withRouter } from "react-router-dom";
import { BiUserCircle, BiAperture, BiCart, BiCertification, BiChat, BiCog, BiHomeAlt, BiEnvelope, BiSupport, BiArrowBack, BiArrowToRight, BiRightArrowCircle, BiRightArrow } from "react-icons/bi";
import {HiAcademicCap, HiGlobeAlt, HiOutlineBriefcase, HiOutlineCog, HiOutlineDocumentText, HiOutlineSwitchHorizontal, HiShieldCheck} from "react-icons/hi"
import { Container, Row, Col, Form, Button, Image,Breadcrumb, Spinner } from "react-bootstrap";

import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
class DashboardSidebar extends Component {

  constructor(props) {
    super(props);

    this.switchDashboard = this.switchDashboard.bind(this);

    this.state = {
      showModal: false,
      userData: JSON.parse(localStorage.getItem("user")),
    };
    
  }

 handleOpen = () =>{
this.setState({showModal:true})
 } 


 countArray(arr){
   return arr.length > 1 ? true: false
    
   
 }

 handleClose = () =>{
  this.setState({showModal:false})
   } 

   switchDashboard(dashboard){

    // const { history } = this.props;
    //  console.log(this.props.history)

    localStorage.setItem('currentSelectedDashboard', dashboard) 
   
if(dashboard=="customer"){ 
 this.props.history.push('dashboard')  
}
else if(dashboard=="specialist"){
  this.props.history.push('specialist-dashboard')  
}
else{
  this.props.history.push('manufacturer-dashboard')
}
   }   

  render() {
   
    return (
      <>
        <ul className="sidebarList">
        
          <li>
            {" "}
            <BiHomeAlt className="me-2" />
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            {" "}
            <BiUserCircle className="me-2" />
            <Link to="/profile"> Profile</Link>
          </li>

          <li>
            <BiAperture className="me-2" />
            <Link to="/service-lists">Services</Link>
          </li>          


          <li>
            <BiAperture className="me-2" />
            <Link to="/property-lists"> Properties</Link>
          </li>
          
          <li>
            <BiCart className="me-2" />
            <Link to="#">Orders</Link>
            <ul className="sublist" style={{marginLeft:5,paddingLeft:5}}>
            {this.state.userData.role.includes("customer") &&
<li><BiRightArrow className="me-2" /><span style={{fontSize:14,margin:0}}><Link to="/order-lists">Property Owner</Link></span></li>
}
{this.state.userData.role.includes("specialist") &&
<li><BiRightArrow className="me-2" /><span style={{fontSize:14,margin:0}}><Link to="/specialist-order-lists">Specialists</Link></span></li>
 }
            </ul>           
            
          </li>
  
  

          <li>
            <BiAperture className="me-2" />
            <Link to="/product-lists"> Products</Link>
          </li>

          <li>
            <BiEnvelope className="me-2" />
            <Link to="/inbox">Inbox</Link>
          </li>

          <li>
            <BiEnvelope className="me-2" />
            <Link to="/notifications">Notifications</Link>
          </li>

          <li>
            <BiChat className="me-2" />
            <a href="/chats">Chats</a>
          </li>

          {/* <li>
            <BiSupport className="me-2" />
            <Link to="/query-lists">Query(Coming Soon)</Link>
          </li> */}

         {/* {this.state.userData?.role && this.countArray(this.state.userData.role) ?

          <li className="mt-3 shd-1 bdr-rad-30 fs-14 text-center cursor-pointer" onClick={this.handleOpen}>
            <HiOutlineSwitchHorizontal className="fw-700 me-2"  />
             Switch Dashboard
          </li>
          :''} */}

        </ul>

        <Modal  show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Switch Dashboard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
<Row>
  <Col className="text-center m-2 bdr-7 bdr-rad-4 p-3 cursor-pointer" onClick={()=>this.switchDashboard('customer')}>
  <div><HiOutlineBriefcase className="fs-48 c3" /></div>
  User/Owner Dashboard</Col>

  <Col className="text-center m-2 bdr-7 bdr-rad-4 p-3 cursor-pointer" onClick={()=>this.switchDashboard('specialist')}>
  <div><HiOutlineDocumentText className="fs-48 c3" /></div>
  Specialist Dashboard</Col>

  <Col className="text-center m-2 bdr-7 bdr-rad-4 p-3 cursor-pointer" onClick={()=>this.switchDashboard('manufacturer')}>
  <div><HiOutlineCog className="fs-48 c3" /></div>
  Manufacturer Dashboard</Col>
</Row>




          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}



export default withRouter(DashboardSidebar)



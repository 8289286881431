import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
            <div>
                <section>
  <div  className="Nature-banner inner-page">
     <div className="container">

          
          <div className="row mt-4 ">
              
                 <h2 className="about-section-title text-center" >  Contact us
                  
                  </h2>
              <div className="col-md-4 ">
                  <div className="card contact-layout-card">
                <img src="assets/images/icons/Customer Support .png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Customer Support</b></h4>
                     <a href="/help-support">Visit Help Center</a>
                              </div>
                     </div>
                           
              </div>
               <div className="col-md-4">
                    <div className="card contact-layout-card">
                <img src="assets/images/icons/Specialist Solutions .png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Specialist Solutions</b></h4>
                     <a href="">specialists@beyondsmartcities.in</a>
                              </div>
                     </div>
              </div>
               <div className="col-md-4">
                    <div className="card contact-layout-card">
                <img src="assets/images/icons/Manufacturers Solutions .png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Manufacturers Solutions</b></h4>
                      <a href="">manufacturers@beyondsmartcities.in</a>
                              </div>
                     </div>
              </div>
          </div>
            <div className="row mt-4">
              <div className="col-md-4">
                    <div className="card contact-layout-card">
                <img src="assets/images/icons/Property Owners.png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Property Owners Solutions</b></h4>
                      <a href="">property@beyondsmartcities.in</a>
                              </div>
                     </div>
              </div>
               <div className="col-md-4">
                     <div className="card contact-layout-card">
                <img src="assets/images/icons/Partnerships .png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Partnerships</b></h4>
                      <a href="">partnerships@beyondsmartcities.in</a>
                              </div>
                     </div>
              </div>
               <div className="col-md-4">
                   <div className="card contact-layout-card">
                <img src="assets/images/icons/Press .png" alt="Avatar" />
                   <div className="container">
                    <h4><b>Press and News</b></h4>
                     <a href="">press@beyondsmartcities.in</a>
                              </div>
                     </div>
              </div>
          </div>
            <div className="row mt-4 ">
              
                 <h2 className="about-section-title">Our Offices
                  
                  </h2>
                    <div className="col-md-6">
                         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5909926486556!2d77.49669701413603!3d13.061686316430832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae2330d4d2f437%3A0x60671e54a981b6e7!2sBeyond%20Smart%20Cities.Pvt.Ltd%2C!5e0!3m2!1sen!2sin!4v1627032376256!5m2!1sen!2sin" width="100%" height="200" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                    <div className="col-md-6">
                        
                        <ul className="contact-add">
                            
                            <li><h4>Bengaluru Office:</h4>
                  
                           </li>
                       <li><p>M/S Beyond Smart Cities.Pvt.Ltd,<br/>
#55,Chikabanavara, HMR layout,1st<br/>
Cross,D.R.Bendre Road,India,<br/>
Karnataka,Bengaluru-560090</p></li>
                          <li><b>Email:</b>  info@beyondsmartcities.in</li>
                          <li><b>Mobile :</b> +91 6363032722 </li>

                        </ul>
                          
                     
                        
                    </div>
                  </div>
     </div>
    </div>
</section>

            </div>
        )
    }
}

import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "./config";
import Loader from "react-js-loader";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation
} from 'react-router-dom'

const TokenVerification = () => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [messageok, setMessageok] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const handleSubmit = async () => {
    if (password.length  && confirmpassword.length) {
      console.log(token);

      //var decoded = jwt_decode(token);
     // console.log(decoded)
      let data={
         password:password,
         confirmPassword:confirmpassword,
         token:token
      }

      setLoading(true);

      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .put(API_URL + "user/passwordVerification", data, {
          headers: {
            auth:token,
            Accept: "application/json"
          }
        })

        .then(function (response) {
          //handle success
          setLoading(false);
          console.log(response);
          setMessage(response?.data?.notice);
          setMessageok(response?.data?.message);
        },(error) => {
          const m =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.notice ||
            error.toString();
            setMessage(m)
            setLoading(false);
        });
    } else {
      setMessage('Please enter input fields')
       setLoading(false);
      //alert("Please enter input fields");
    }
  };

  return (
    <div className="row" style={{ paddingTop: 200, paddingBottom: 200 }}>
      <div className="col-md-6 offset-md-3">
        <h2>Please enter your new password</h2>


        <div>

        {/* <div className="form-group">
                              <label htmlFor="password">Your Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}                            
                              />
                            </div> */}

        <div className="form-group">
                              <label htmlFor="password">New Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}                            
                              />
                            </div>
                           
<br/>
                            <div className="form-group">
                              <label htmlFor="password">Confirm Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="confirmpassword"
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}                                                     
                              />
                            </div>
                            </div>
                            <br/>

                            <input
          onClick={() => handleSubmit()}
          className="btn btn-outline-success"
          type="submit"
          value="Submit"
        />

        {message && <div className="alert alert-warning mt-3">{message}</div>}
        {messageok && <div className="salert alert-success mt-3 p-3">{messageok}</div>}

        {loading ? (
          <Loader
            type="spinner-default"
            bgColor={"#0A989C"}
            title={"Please Wait!"}
            color={"#0A989C"}
            size={60}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TokenVerification;

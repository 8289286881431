import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import {FaRocket, FaRupeeSign } from "react-icons/fa";
import {
  HiAcademicCap,
  HiArrowRight,
  HiBadgeCheck,
  HiCheck,
  HiClock,
  HiDotsCircleHorizontal,
  HiGlobe,
  HiHashtag,
  HiLightBulb,
  HiLocationMarker,
  HiOutlineAcademicCap,
  HiOutlineArrowsExpand,
  HiOutlineBadgeCheck,
  HiOutlineBriefcase,
  HiOutlineCreditCard,
  HiOutlineDocumentReport,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
  HiOutlinePaperClip,
  HiOutlinePhone,
  HiOutlineTag,
  HiOutlineUser,
  HiOutlineUserCircle,
  HiShieldCheck,
  HiStar,
  HiUser,
  HiViewBoards,
} from "react-icons/hi";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export default class SpecialistInformation extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state = {
      specialistId: this.props.match.params.id,
      specialistData: "",
      serviceList:'',
      specializationList:''
    };
  }

  componentDidMount() {
    this.getSpecialistDetails();
  }

  async getSpecialistDetails() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    console.log('SDD',this.state.specialistId)
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "specialist/" + this.state.specialistId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log("SPECLL", response);

          if (response.data.success === true) {
            self.setState({
              specialistData: response.data.specialistData,
              serviceList: response.data.serviceList
            });

            //Specilizations
            let specFromServiceArray= response.data.serviceList  

           
  //           specFromServiceArray.map(item => item.overview.subCategoryId.name)
  // .filter((value, index, self) => self.indexOf(value) === index)

  let unique = [...new Set(specFromServiceArray.map(item =>  item?.overview?.subCategoryId.name))];

  self.setState({  
    specializationList: unique
  });

  console.log('U',unique)

            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }


  goToChat(email){
    this.props.history.push('/chats?chatUserId='+email)

  }


  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
            <Row>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Specialists</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <div className="card-r p-4">
            
              <Row class="mb-4">
                <div class="col-md-3 mb-4">
                  {this.state.specialistData?.personalInfo?.profilePic?
                  <img
                  className="img-fluid bdr-rad-4"
                  src={this.state.specialistData?.personalInfo?.profilePic.secureUrl}
                />
                :

                  <img
                    className="img-fluid bdr-rad-4"
                    src="/assets/images/greatwork.png"
                  />
  }

          <h2 className="pb-2 position-relative mt-3">
                    {this.state.specialistData?.personalInfo?.firstName}{" "}
                    {this.state.specialistData?.personalInfo?.lastName} 
                    {this.state.specialistData?.isAdminApproved ? <span className="approvedbadge"><HiCheck /></span> : null}
                  </h2>
                  
                  <p className="mb-1"><HiHashtag className="me-1" />{this.state.specialistData?.userName}{" "}</p>
                  <p className="mb-1"><HiLocationMarker className="me-1" />India</p>
                  <p className="mb-1"><HiClock className="me-1" />Joined Since 2021</p>
<div className="pt-3">
                  {this.state.specialistData?.personalInfo?.communication.length ?
                  this.state.specialistData?.personalInfo?.communication.map(
                    (item) => (
                      <p className="mb-1">{item.language} 
                      {item.proficiencyLevel == "Poor" ?
                      <>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      </>
                      :
                      item.proficiencyLevel == "Good" ?
                      <>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      </>
                      :
                      <>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      <HiStar className="c16"/>
                      </>

                    }
                      </p>
                    )
                  )
                : <p>........ </p>
                }

                <h2 className="mt-3 headerwithBG">Specializations</h2>
                {this.state.specializationList?.length ?
                <ul className="ms-0 ps-0">
                    { this.state.specializationList.map((item)=>
                     
                       <li style={{listStyleType:'none'}} className="ms-0 fs-14 mb-1"><HiCheck /> {item}</li>
                      
                      )
                    }
                      </ul>
                       : null}
            </div>     
                </div>

                <div class="col-md-6">
                  <h2 className="headerwithBG"><HiOutlineUser className="c4 me-2" />About me</h2>
                  <p>{this.state.specialistData?.personalInfo?.description}</p>

                     <hr/>     
                  <h2 className="headerwithBG"><HiOutlineBriefcase className="c4 me-2" />Professional Details</h2>

                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />}Occupation:
                    <strong>
                      {" "}
                      {this.state.specialistData?.professionalInfo?.occupation}
                    </strong>
                  </p>

                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />}Working Since:
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo
                          ?.workingSince
                      }
                    </strong>
                  </p>
                  <hr/>  
                  <h2 className="headerwithBG"><HiOutlineOfficeBuilding className="c4 me-2" />Education</h2>

                  <p className="mb-1">
                  {<HiOutlineBadgeCheck className="me-1" />}College Name:{" "}
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo?.education
                          .collegeName
                      }
                    </strong>
                  </p>
               
                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />}Country Of College:
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo?.education
                          .countryOfCollege.collegeName
                      }
                    </strong>
                  </p>
                 
                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />}University Name:
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo?.education
                          .universityName
                      }
                    </strong>
                  </p>

                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />}Year of Completion:
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo?.education
                          .yearOfCompletion
                      }
                    </strong>
                  </p>

                  <p className="mb-1">
                  {<HiOutlineBadgeCheck className="me-1" />}Main Stream of Course:
                    <strong>                    
                      { this.state.specialistData?.professionalInfo?.education
                          .mainStreamOfCourse
                      }
                    </strong>
                  </p>
                  <hr/>  
                  <h2 className="headerwithBG"><HiOutlineOfficeBuilding className="c4 me-2" />Organizational Membership</h2>
                  <p className="mb-1">
                    {" "}
                    {<HiOutlineBadgeCheck className="me-1" />} Name Of Organization:
                    <strong>
                      {" "}
                      {
                        this.state.specialistData?.professionalInfo?.education
                          .organizationalMembership.nameOfOrganization
                      }
                    </strong>
                  </p>
                  <hr/>
               
                
                  
              
                <hr/>
                <h2 className="headerwithBG"><HiOutlineAcademicCap className="c4 me-2" />Honors and Awards</h2>
                {this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.honorsAndAwards.length?
                        this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.honorsAndAwards.map((val)=>
                        <p className="mb-1">{<HiOutlineBadgeCheck className="me-1" />}{val}</p>
                        
                        ):
                        <p>No honors and awards are added by specialist! </p> }
                <hr/>

                
                
                </div>

                <div class="col-md-3 bg-4 p-3">
                <p className="mb-1 c16 fs-18"><HiShieldCheck className="me-1 fs-20" />Rising Talent</p>
                <p className="mb-1 c16 fs-18"><HiBadgeCheck className="me-1 fs-20" />Verified Specialist</p>
                <p className="mb-1 c16 fs-18"><HiViewBoards className="me-1 fs-20" />25+ Orders Completed</p>


                {this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.personalWebsite?
                                <p className="mb-1 c16 fs-18 ellipsis"><HiGlobe className="me-1 fs-20" /><a href={'http://' + this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.personalWebsite} target="_blank"> {this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.personalWebsite}</a></p>
:null}
                {this.state.serviceList?.length ?
                <p className="mb-1 c16 fs-18 mb-3"><HiLightBulb className="me-1 fs-20" />{this.state.serviceList.length} Services Published</p>
             
                :null}
               
                <div>                 <HiDotsCircleHorizontal className="c2"/> Online
                </div>
                <div className="fs-14 ps-3">
                  Available 30+ Hours a Week
                  </div>
                  <p onClick={()=>this.goToChat(this.state.specialistData?.customerId?._id)} className="mb-1 fs-14 mb-3 bdr bdr-rad-4 bg-2 text-center p-2 my-2 cursor-pointer"><HiArrowRight className="me-1 fs-20" />Connect Specialist</p>
               <p className="fs-12">Chat Facility is currently unavailable</p>
                  </div>
              </Row>







{/* Certificates */}
{/* <Row className="mt-3">
  <Col>
            <h2 className="headerwithBG"><HiOutlinePaperClip className="c4 me-2" />Certificates Achieved</h2>
            <Row>

                  {this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.certificates.length?
                   this.state.specialistData?.professionalInfo?.education?.organizationalMembership?.certificates.map((val)=>
                   <>
                  
                        <Col className="col-md-4">
                        <img src={val.secureUrl} className="img-fluid  p-3" />
                        </Col>
                       
                        </>                     
                        )
                      
                    :
                    <p>No certificates uploaded by specialist! </p> }
            </Row>
  </Col>
  

</Row> */}




{/* Certificates */}
{/* <Row className="mt-3">
  
  <Col>
  <h2 className="headerwithBG"><HiOutlinePaperClip className="c4 me-2" />License Certificates</h2>
            <Row>

                  {this.state.specialistData?.professionalInfo?.education?.license?
                  
                   <>                  
                        <Col className="col-md-12">
                        <img src={this.state.specialistData?.professionalInfo?.education?.license.secureUrl} className="img-fluid  p-3" />
                        </Col>
                       
                        </>                    
                       
                      
                    :
                    <p>No License uploaded by specialist! </p> }
            </Row>                  

  </Col>


  <Col>
  <h2 className="headerwithBG"><HiOutlinePaperClip className="c4 me-2" />Experience Certificates</h2>
            <Row>

                  {this.state.specialistData?.professionalInfo?.education?.experienceCertificate?
                  
                   <>                  
                        <Col className="col-md-12">
                        <img src={this.state.specialistData?.professionalInfo?.education?.experienceCertificate.secureUrl} className="img-fluid  p-3" />
                        </Col>
                       
                        </>                    
                       
                      
                    :
                    <p>No Certificates uploaded by specialist! </p> }
            </Row>
  


                   

  </Col>

</Row> */}









</div>



              <Row class=" Sustainable-inner-design">
             
               <h2 className="headerwithBG">Services Published by {this.state.specialistData?.personalInfo?.firstName}{' '}{this.state.specialistData?.personalInfo?.lastName}</h2>

                      {this.state.serviceList?.length ?
                      this.state.serviceList.map((item)=>
                    <>
                       <div class="col-md-3 cursor-pointer mb-3">                    
                      <div class="card-r  shd-1">

                    { item.serviceGallery?.length ?
                      
                            <img
                            onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}
                              src={item?.serviceGallery[0]?.secureUrl}
                              alt="Person"                             
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                     
                            :
                            
                            <img
                            onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}
                              src="/assets/images/beyond-business-4.jpeg"
                              alt="Person"
                              height="180"
                              style={{objectFit:'cover'}}
                            />
                      
                    }

                        <div class="card-content">
                        
                          <h3 className="mb-4 c3"
                          onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}>
                            {item?.overview?.gigTitle}                           
                          </h3>
                          <Row>
                            <Col md={4}>
                              <p>⭐4.9(1)</p>
                            </Col>
                            <Col md={1}>
                              {/* <i class="fas fa-heart"></i> */}
                            </Col>
                            <Col>
                              <h3 className="float-end c4">Starts{ } <FaRupeeSign /> {item?.packageDetails[0].price}</h3>
                            </Col>
                           
                          </Row>
                        </div>
                        </div>
                        </div>
                        </>
                      )
                      : null
                  }
                                  
                         
            </Row>
           
          </Container>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
  BiUpload,
  BiUpArrow,
} from "react-icons/bi";
import { MdFileDownload, MdFileUpload } from "react-icons/md";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle, FiX } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";

import moment from "moment";
import DashboardSidebar from "../../DashboardSidebar";
import axios from "axios";
import { API_URL } from "../../../config";
import Loader from "react-js-loader";
import { toast } from "react-toastify";


export default class PropertySingle extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);

    this.state = {
      dataLoading: false,
      imageProgressWidth: 0,
      sitePlanProgressWidth:0,
      floorPlanProgressWidth:0,
      drawingsProgressWidth:0,
      elevationProgressWidth:0,
      mechanicalPlanProgressWidth:0,
      propertyId: this.props.match.params.id,
      docName: "",
      sitePlandocName:'',
      drawingsdocName:'',
      floorPlandocName:'',
      elevationdocName:'',
      mechanicalPlandocName:'',
      currentProgress: "",
      progessHTML: "",
      propertyData: {
        smartTransportation: [],
        greenSites: [],
        energyEfficiency: [],
        waterEfficiency: [],
        materialResources: [],
        indoorEnvironment: [],
        emergingTechnology: [],
        healthAndWellness: [],
      },

      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    console.log(this.state.propertyId);
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    if (this.state.userData.isEmailVerified) {
      this.setState({ notice: false });
    } else {
      setTimeout(() => {
        this.setState({ notice: true });
      }, 3000);
    }

    this.getPropertyDetails();
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }

  handleDocUpload = (e, category, checklistValue) => {

    let chelstVal=e.target.name
    console.log("first",category, chelstVal);
    var self = this;
    e.preventDefault();
    console.log(e);
    self.setState({
      currentProgress: chelstVal,
      progessHTML:
        "<div style={{height:2,width:this.state.imageProgressWidth,backgroundColor:'#342343'}}></div>",
    });

    //console.log(document.body.classList.contains(category+index))

    var loaded = 0;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      self.setState({
        docName: file,
      });

      self.handleUpdate(category, chelstVal);
    };

    reader.onprogress = function (data) {
      if (data.lengthComputable) {
        var progress = parseInt((data.loaded / data.total) * 100, 10);
        self.setState({
          imageProgressWidth: progress + "%",
        });
        console.log(progress);
      }
    };

    reader.readAsDataURL(file);
  };





  handleGeneralDocUpload = (e, category) => {
    var self = this;
    e.preventDefault();
    console.log(e);
    // self.setState({
    //   currentProgress: checklistValue,
    //   progessHTML:
    //     "<div style={{height:2,width:this.state.imageProgressWidth,backgroundColor:'#342343'}}></div>",
    // });


    var loaded = 0;

    let reader = new FileReader();
    let file = e.target.files[0];
console.log(category+'docName')
    let docName=category+'docName'
    reader.onloadend = () => {
      self.setState({
        [docName]: file,
      });

      self.handlegeneralDocUpdate(category);
    };

    // reader.onprogress = function (data) {
    //   if (data.lengthComputable) {
    //     var progress = parseInt((data.loaded / data.total) * 100, 10);
    //     self.setState({
    //       sitePlanProgressWidth: progress + "%",
    //     });
    //     console.log(progress);
    //   }
    // };

    reader.readAsDataURL(file);
  };






  async handleUpdate(cat, checklistValue) {
    console.log("d",cat, checklistValue);
    var self = this;
    self.setState({ spinner: true });
    self.setState({
      submitLoading: true,
      errorMessage: "",
    });

    let formData = new FormData();
    formData.append("document", this.state.docName);
    formData.append("category", cat);
    formData.append("checklistValue", checklistValue);

    // for (let i = 0; i < this.state.data.serviceGallery.length; i++) {

    //   formData.append("serviceGallery", this.state.data.serviceGallery[i].file);
    // }
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .put(API_URL + `property/docs/` + this.state.propertyId, formData, {
        headers: {
          auth: token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      .then(
        function (response) {
          console.log("R", response);
          //handle success
          console.log(response);
          if (response.data.success === true) {
            setTimeout(() => {
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                spinner: false,
              });

              //self.props.history.goBack();
              self.getPropertyDetails();
              toast("Image Uploaded Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }, 1000);
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            spinner: false,
          });
          toast("Thers is an Error in the input fields. Please check!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
  }

  async getPropertyDetails() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "property/" + this.state.propertyId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log(response.data.propertyData);

          if (response.data.success === true) {
            self.setState({
              propertyData: response.data.propertyData,
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }







  async handlegeneralDocUpdate(cat) {   
    
    var self = this;
    self.setState({ spinner: true });
    self.setState({
      submitLoading: true,
      errorMessage: "",
    });

    let formData = new FormData();   

    if(cat==="sitePlan"){
      formData.append('sitePlan', this.state.sitePlandocName);  
    }   

    if(cat==="floorPlan"){
      formData.append('floorPlan', this.state.floorPlandocName);  
    }   
    
    if(cat==="drawings"){
      formData.append('drawings', this.state.drawingsdocName);  
    }  

    if(cat==="elevation"){
      formData.append('elevation', this.state.elevationdocName);  
    }  

    if(cat==="mechanicalPlan"){
      formData.append('mechanicalPlan', this.state.mechanicalPlandocName);  
    }  


   
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .put(API_URL + "property/general/projectDocs/" + this.state.propertyId, formData, {
        headers: {
          auth: token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          if (cat === "sitePlan") {
            this.setState({ sitePlanProgressWidth: progress + "%" });
          }

          if (cat === "floorPlan") {
            this.setState({ floorPlanProgressWidth: progress + "%" });
          }

          if (cat === "elevation") {
            this.setState({ elevationProgressWidth: progress + "%" });
          }

          if (cat === "drawings") {
            this.setState({ drawingsProgressWidth: progress + "%" });
          }

          if (cat === "mechanicalPlan") {
            this.setState({ mechanicalPlanProgressWidth: progress + "%" });
          }
        },

        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress);
          if (cat === "sitePlan") {
            this.setState({sitePlanProgressWidth:progress + "%"})
          }
          if (cat === "floorPlan") {
            this.setState({ floorPlanProgressWidth: progress + "%" });
          }

          if (cat === "elevation") {
            this.setState({ elevationProgressWidth: progress + "%" });
          }

          if (cat === "drawings") {
            this.setState({ drawingsProgressWidth: progress + "%" });
          }

          if (cat === "mechanicalPlan") {
            this.setState({ mechanicalPlanProgressWidth: progress + "%" });
          }          
        },

      })

      .then(
        function (response) {
          console.log("Ri", response);
          //handle success
          console.log(response);
          if (response.data.success === true) {
            setTimeout(() => {
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                spinner: false,
              });

              //self.props.history.goBack();
              self.getPropertyDetails();
              toast("Document Uploaded Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }, 1000);


            setTimeout(() => {

              self.setState({
                floorPlanProgressWidth:0,
                sitePlanProgressWidth:0,
                drawingsProgressWidth:0,
                elevationProgressWidth:0,
                mechanicalPlanProgressWidth:0
              })
              
            }, 2000);

           
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            spinner: false,
          });
          toast("Thers is an Error in the input fields. Please check!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
  }



  render() {
    const userData = this.state.userData;
    const propertyD = this.state.p;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust prop-single">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          Property Detail
                        </Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                          <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link to="/property-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                  My Properties
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          {!this.state.dataLoading ? (
                            <Row>

{/* TABS */}

<Tabs defaultActiveKey="propertryInformation" id="uncontrolled-tab-example" className="mb-3 fw-600">


<Tab eventKey="propertryInformation" title="Property Information">

<Col className="position-relative">
                                <div className="shd-1 py-3">
                                <ul class="tableList p-3">
                                  <Row>
                                    <Col md={6}>
                                    <li>
                                  <BiBuilding className="me-2" />
                                  Project ID: <span className="fw-600"> {this.state.propertyData?.projectID}</span>
                                </li>

                                <li>
                                  <BiBadge className="me-2" />
                                  Project Name: <span className="fw-600">{this.state.propertyData?.projectName}</span>
                                </li>

                                <li>
                                  <BiCalendarStar className="me-2" />
                                  Created On: <span className="fw-600">{moment(this.state.propertyData?.createdAt).format('LL')}</span>
                                </li>

                                <li>
                                  <BiLocationPlus className="me-2" />
                                  Address:<span className="fw-600"> {this.state.propertyData?.address}</span>
                                </li>

                                <li>
                                  <BiLocationPlus className="me-2" />
                                  Latitude: <span className="fw-600">{this.state.propertyData?.geoLocation?.longitude}</span>
                                </li>

                                <li>
                                <BiLocationPlus className="me-2" />
                                  Longitude: <span className="fw-600">{this.state.propertyData?.geoLocation?.latitude}</span>
                                </li>


                                <li>
                                  <BiCalendar className="me-2" />
                                  Start Date:<span className="fw-600"> {this.state.propertyData?.startDate}</span>
                                </li>

                                <li>
                                <BiCalendar className="me-2" />
                                  End Date: <span className="fw-600">{this.state.propertyData?.endDate}</span>
                                </li>

                                <li>
                                  <BiPencil className="me-2" />
                                  Description: <span className="fw-600">{this.state.propertyData?.description}</span>
                                </li>
                                    </Col>

                                    <Col >
                                    <h2 className="ms-1 mt-5">Application Status</h2>
                                    <p>Last Reviewed: {moment(this.state.propertyData?.updatedAt).format('LL')}</p>
                                    {this.state.propertyData?.status  === "Pending" ?
                                    <>
                                    <div class="circle small mt-3 circlebdrPending">
                                  <h3>BSAS/{this.state.propertyData?.projectID}</h3>
                                  <h1>{this.state.propertyData?.status}</h1>
                                  </div>
                                  </>
                                  :
                                  this.state.propertyData?.status  === "Rejected" ?
                                  <>
                                    <div class="circle small mt-3 circlebdrRejected">
                                  <h3>BSAS/{this.state.propertyData?.projectID}</h3>
                                  <h1>{this.state.propertyData?.status}</h1>
                                  </div>
                                  </>
                                  :
                                  <>
                                  <div class="circle small mt-3 circlebdrApproved">
                                <h3>BSAS/{this.state.propertyData?.projectID}</h3>
                                <h1>{this.state.propertyData?.status}</h1>
                                </div>
                                </>
                                }
                                
                               
                                    </Col>


                                    <Col >    
                                    <h2 className="ms-1 mt-5">Property Rating</h2>
                                    <p>Last Reviewed: {moment(this.state.propertyData?.updatedAt).format('LL')}</p>                             
                                    {this.state.propertyData?.status  === "Pending" ?
                                    <>
                                    <div class="circle small mt-3 circlebdrPending">  
                                    <h3>Points</h3>                                
                                  <h1>{this.state.propertyData?.points}</h1>
                                  </div>
                                  </>
                                  :
                                  this.state.propertyData?.status  === "Rejected" ?
                                  <>
                                    <div class="circle small mt-3 circlebdrRejected">  
                                    <h3>Points</h3>                                
                                  <h1>{this.state.propertyData?.points}</h1>
                                  </div>
                                  </>
                                  :
                                  <>
                                  <div class="circle small mt-3 circlebdrApproved">  
                                  <h3>Points</h3>                              
                                <h1>{this.state.propertyData?.points}</h1>
                                </div>
                                </>
                                }
                                
                               
                                    </Col>


                                    

                                  </Row> 
                                </ul>                                 
                                  
                                  
                                  </div>
   </Col>
   
   </Tab>


  <Tab eventKey="PropertyUploads" title="Property Uploads">  

                              <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>
                                    <Row className="align-items-center">
                                      <Col md={1}>
                                        <BiBuildings className="ms-2 fs-2" />
                                      </Col>
                                      <Col md={6}>
                                        <div className="property-title fw-700">
                                          {this.state.propertyData?.projectName}
                                        </div>
                                        <div className="property-added-date fs-12">
                                          Applied On:{moment(this.state.propertyData?.createdAt).format("LL")}
                                        </div>
                                      </Col>
                                      <Col md={2}>
                                        Points{" "}
                                        <span className="d-inline-block property-points fw-700 c3 ">
                                          {this.state.propertyData?.points}
                                        </span>
                                      </Col>
                                      <Col md={2}>
                                     {this.state.propertyData?.status == "Approved" ?
                                        <div className="property-status fw-700 c2">
                                          <FiAlertTriangle className="me-1" />{" "}
                                          {this.state.propertyData?.status}
                                        </div>
                                        :
                                        <div className="property-status fw-700 c9">
                                        <FiAlertTriangle className="me-1" />{" "}
                                        {this.state.propertyData?.status}
                                      </div>
                              }
                                      </Col>
                                      <Col>
                                        <div className="property-rating">
                                          <BsStarFill className="c2" />
                                          <BsStarFill className="c2" />
                                          <BsStarFill className="c2" />
                                        </div>
                                      </Col>
                                    </Row>


                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                    
                                    <div className="property-maindoc-upload ps-5 fw-600">                                   
                                                        <label className="cursor-pointer fs-18 fw-600" htmlFor="sitePlan">
                                                          <MdFileUpload />
                                                          Upload Site Plan
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="sitePlan"
                                                          type="file"
                                                          name="sitePlan"
                                                          onChange={(e) =>
                                                            this.handleGeneralDocUpload(
                                                              e,
                                                              "sitePlan"                                                             
                                                            )
                                                          }
                                                        />                                                        

                                                      </div>

                                                      <div
                                                            style={{
                                                              height: 4,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sitePlanProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

                                                      </Col>
                                                      <Col className="mt-3 DetailPropertyList">
                                                     <h2>Site Plans</h2>
                                                     {this.state.propertyData?.sitePlanFile?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.propertyData?.sitePlanFile?.secureUrl}><MdFileDownload  /><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:1,borderColor:'#abc7ac',display:'inline-block'}}>Download/View</span></a>
                                                     : 'Not Uploaded'}
                                                     </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-3 DetailPropertyList pt-4">
                                  
                                    <div className="property-maindoc-upload ps-5 fw-600  pb-3">                                  
                                                        <label className="cursor-pointer fs-18 fw-600" htmlFor="floorPlan">
                                                          <MdFileUpload />
                                                          Upload Floor Plan
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="floorPlan"
                                                          type="file"
                                                          name="floorPlan"
                                                          onChange={(e) =>
                                                            this.handleGeneralDocUpload(
                                                              e,
                                                              "floorPlan"                                                             
                                                            )
                                                          }
                                                        />                                                       
                                                      </div>

                                                      <div
                                                            style={{
                                                              height: 4,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .floorPlanProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

                                                      </Col>
                                                      <Col className="mt-3 DetailPropertyList">
                                                     <h2>Floor Plans</h2>
                                                     {this.state.propertyData?.floorPlanFile?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.propertyData?.floorPlanFile?.secureUrl}><MdFileDownload  /><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:1,borderColor:'#abc7ac',display:'inline-block'}}>Download/View</span></a>
                                                      : 'Not Uploaded'}
                                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-3 DetailPropertyList pt-4">


                                                    
                                    <div className="property-maindoc-upload ps-5 fw-600 pb-3">                                    
                                                        <label className="cursor-pointer fs-18 fw-600" htmlFor="elevation">
                                                          <MdFileUpload />
                                                          Upload Elevation
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="elevation"
                                                          type="file"
                                                          name="elevation"
                                                          onChange={(e) =>
                                                            this.handleGeneralDocUpload(
                                                              e,
                                                              "elevation"                                                             
                                                            )
                                                          }
                                                        />                                                       
                                                      </div>

                                                      <div
                                                            style={{
                                                              height: 4,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .elevationProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,
                                                            }}
                                                          ></div>

                                                      </Col>
                                                      <Col className="mt-3 DetailPropertyList">
                                                     <h2>Elevation</h2>
                                                     {this.state.propertyData?.elevationFile?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.propertyData?.elevationFile?.secureUrl}><MdFileDownload  /><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:1,borderColor:'#abc7ac',display:'inline-block'}}>Download/View</span></a>
                                                        :'Not Uploaded'}
                                                      </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">                                                      
                                    <div className="property-maindoc-upload ps-5 fw-600 pb-3">                                   
                                                        <label className="cursor-pointer fs-18 fw-600" htmlFor="drawings">
                                                          <MdFileUpload />
                                                          Upload Drawings
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="drawings"
                                                          type="file"
                                                          name="drawings"
                                                          onChange={(e) =>
                                                            this.handleGeneralDocUpload(
                                                              e,
                                                              "drawings"                                                             
                                                            )
                                                          }
                                                        />                                                       
                                                      </div>
                                                      <div
                                                            style={{
                                                              height: 4,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .drawingsProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>
                                                      </Col>
                                                      <Col className="mt-3 DetailPropertyList">
                                                      <h2>Drawings</h2>
                                                      {this.state.propertyData?.drawingsOfProject?
                                                      <a className="cursor-pointer" target="_blank" href={ this.state.propertyData?.drawingsOfProject?.secureUrl}><MdFileDownload  /><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:1,borderColor:'#abc7ac',display:'inline-block'}}>Download/View</span></a>
                                                    : 'Not Uploaded'
                                                      }
                                                      </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">                                                      
                                    <div className="property-maindoc-upload ps-5 fw-600 pb-3">                                   
                                                        <label className="cursor-pointer fs-18 fw-600" htmlFor="mechanicalPlan">
                                                          <MdFileUpload />
                                                          Upload Mechanical Plan
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mechanicalPlan"
                                                          type="file"
                                                          name="mechanicalPlan"
                                                          onChange={(e) =>
                                                            this.handleGeneralDocUpload(
                                                              e,
                                                              "mechanicalPlan"                                                             
                                                            )
                                                          }
                                                        />                                                       
                                                      </div>
                                                      <div
                                                            style={{
                                                              height: 4,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .mechanicalPlanProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>
                                                      </Col>
                                                      <Col className="mt-3 DetailPropertyList">
                                                     <h2>MechanicalPlan</h2>
                                                    {this.state.propertyData?.mechanicalPlanFile?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.propertyData?.mechanicalPlanFile?.secureUrl}><MdFileDownload  /><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:1,borderColor:'#abc7ac',display:'inline-block'}}>Download/View</span></a>
                                                        :
                                                        'Not Uploaded'}
                                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>
</Tab>
{/* TAB UPLOADS COMPLETE */}
 
  <Tab eventKey="GreenBuildDocuments" title="Green Build Uploads">
  <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>
  <Row>
                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Smart Transportation(ST)</h3>
                                        <ul>
                                          {this.state.propertyData
                                            .smartTransportation ? (
                                            this.state.propertyData.smartTransportation.map(
                                              (item, index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col  md={2}>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`smartTransportation-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id={`smartTransportation-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "smartTransportation",
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {this.state
                                                          .currentProgress ==
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col md={2} >
                                                    <span className="hightlightUpload">
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="smartTransportation" && i.checklistValue==item ? <FiCheck className="me-1 c2" />  :''                                                   
                                                    
                                                    )}
                                                    </span>
                                                   
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>

                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Green Sites</h3>
                                        <ul>
                                          {this.state.propertyData.greenSites &&
                                          this.state.propertyData.greenSites
                                            .length ? (
                                            this.state.propertyData.greenSites.map(
                                              (item, index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`greenSites-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="fileInput"
                                                          id={`greenSites-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "greenSites",
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {this.state
                                                          .currentProgress ==
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="greenSites" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Energy Efficiency(EF)</h3>
                                        <ul>
                                          {this.state.propertyData
                                            .energyEfficiency &&
                                          this.state.propertyData
                                            .energyEfficiency.length ? (
                                            this.state.propertyData.energyEfficiency.map(
                                              (item, index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`energyEfficiency-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="fileInput"
                                                          id={`energyEfficiency-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "energyEfficiency",
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {this.state
                                                          .currentProgress ==
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="energyEfficiency" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>

                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Water Efficiency</h3>
                                        <ul>
                                          {this.state.propertyData
                                            .waterEfficiency &&
                                          this.state.propertyData
                                            .waterEfficiency.length ? (
                                            this.state.propertyData.waterEfficiency.map(
                                              (item,index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`waterEfficiency-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="fileInput"
                                                          id={`waterEfficiency-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "waterEfficiency",
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {this.state
                                                          .currentProgress ==
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="waterEfficiency" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Material Resources(MR)</h3>
                                        <ul>
                                          {this.state.propertyData
                                            .materialResources &&
                                          this.state.propertyData
                                            .materialResources.length ? (
                                            this.state.propertyData.materialResources.map(
                                              (item, index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`materialResources-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="fileInput"
                                                          id={`materialResources-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "materialResources",
                                                              item,
                                                              index
                                                            )
                                                          }
                                                        />

                                                        {this.state
                                                          .currentProgress ===
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="materialResources" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>

                                      <Col className="mt-3 DetailPropertyList">
                                        <h3>Indoor Environment</h3>
                                        <ul>
                                          {this.state.propertyData
                                            .indoorEnvironment &&
                                          this.state.propertyData
                                            .indoorEnvironment.length ? (
                                            this.state.propertyData.indoorEnvironment.map(
                                              (item,index) => (
                                                <li>
                                                  <Row>
                                                    <Col
                                                      md={8}
                                                      className="align-self-center"
                                                    >
                                                      <FiCheck className="me-1 c2" />
                                                      {item}{" "}
                                                    </Col>
                                                    <Col>
                                                      <div className="property-doc-upload">
                                                        <label htmlFor={`indoorEnvironment-${index}`}>
                                                          <MdFileUpload />
                                                        </label>

                                                        <input
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          className="fileInput"
                                                          id={`indoorEnvironment-${index}`}
                                                          type="file"
                                                          name={item}
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "indoorEnvironment",
                                                              item
                                                            )
                                                          }
                                                        />
                                                        {this.state
                                                          .currentProgress ==
                                                        item ? (
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              width:
                                                                this.state
                                                                  .imageProgressWidth,
                                                              backgroundColor:
                                                                "#342343",
                                                            }}
                                                          ></div>
                                                        ) : null}
                                                      </div>
                                                    </Col>
                                                    <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="indoorEnvironment" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                  </Row>
                                                </li>
                                              )
                                            )
                                          ) : (
                                            <>
                                              <li>No Data Submitted</li>
                                            </>
                                          )}
                                        </ul>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Row>
                                    <Col className="mt-3 DetailPropertyList">
                                      <h3>Health and Wellness</h3>
                                      <ul>
                                        {this.state.propertyData
                                          .healthAndWellness &&
                                        this.state.propertyData
                                          .healthAndWellness.length ? (
                                          this.state.propertyData.healthAndWellness.map(
                                            (item,index) => (
                                              <li>
                                                <Row>
                                                  <Col
                                                    md={8}
                                                    className="align-self-center"
                                                  >
                                                    <FiCheck className="me-1 c2" />
                                                    {item}{" "}
                                                  </Col>
                                                  <Col>
                                                    <div className="property-doc-upload">
                                                      <label htmlFor={`healthAndWellness-${index}`}>
                                                        <MdFileUpload />
                                                      </label>

                                                      <input
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        className="fileInput"
                                                        id={`healthAndWellness-${index}`}
                                                        type="file"
                                                        name={item}
                                                        onChange={(e) =>
                                                          this.handleDocUpload(
                                                            e,
                                                            "healthAndWellness",
                                                            item
                                                          )
                                                        }
                                                      />
                                                      {this.state
                                                        .currentProgress ==
                                                      item ? (
                                                        <div
                                                          style={{
                                                            height: 2,
                                                            width:
                                                              this.state
                                                                .imageProgressWidth,
                                                            backgroundColor:
                                                              "#342343",
                                                          }}
                                                        ></div>
                                                      ) : null}
                                                    </div>
                                                  </Col>
                                                  <Col >
                                                    {
                                                    this.state.propertyData.documents.map((i)=>                                                    
                                                    i.category==="healthAndWellness" && i.checklistValue==item ? <FiCheck className="me-1 c2" /> :''                                                   
                                                    
                                                    )}
                                                   
                                                    </Col>
                                                </Row>
                                              </li>
                                            )
                                          )
                                        ) : (
                                          <>
                                            <li>No Data Submitted</li>
                                          </>
                                        )}
                                      </ul>
                                    </Col>
                                  </Row>
                                  </div>
                                  </Col>
  </Tab>

  <Tab eventKey="comments" title="Comments">
  <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>
                                  {this.state.propertyData.adminComments ?
                                     this.state.propertyData.adminComments.map((item)=>
                                  <p className="fw-600 p-3"><span className="fw-900 c16">BSAS Admin:</span> <br/>{item}<br/><span className="fw-900 c2"> Reviewed On:</span> <span className="fw-600">{moment(this.state.propertyData?.updateddAt).format('LL')}</span></p>
                                  ) 
                                 :
                                 <>
                                 <BiCog className="text-center fw-600 fs-22" />
                                 <p className="text-center fw-600 fs-22">No Comments Available</p>
                                  </>
                                 
                                  }
                                  </Col>



                                  </div>
                                  </Col>
 
   
   </Tab>

  <Tab eventKey="certificates" title="Certificates">
  <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>
                                  <h2 className="p-5">Please download the certificate by clicking the below link. </h2>
                                  {this.state.propertyData.certificateUrl ?

                                  <><a className="cursor-pointer ghost-action-btn d-inline-block ms-5" target="_blank" href={this.state.propertyData.certificateUrl.secureUrl}>Download Certificate</a></>
                                 :
                                 <>
                                 <BiCog className="text-center fw-600 fs-22" />
                                 <p className="text-center fw-600 fs-22">No Certificates Available</p>
                                  </>
                                 
                                  }
                                  </Col>



                                  </div>
                                  </Col>
  </Tab>
</Tabs>



                            </Row>
                          ) : (
                            <div className="content-spinner">
                              <Loader
                                type="spinner-default"
                                bgColor={"#0A989C"}
                                title={""}
                                color={"#0A989C"}
                                size={60}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "../../config";
import validator from "validator";

import {
  validate_Alpha_Length,
  validate_Length_DOB,
  validate_Empty,
} from "./../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "./../../helpers/Utilities";
import { validate_MobilePhone_Length } from "./../../helpers/Utilities";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { SidebarCommonMenuItem } from "../SidebarCommonMenuItem";

export default class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.asyncLocalStoragegetItem = this.asyncLocalStoragegetItem.bind(this);

    this.state = {
      currentSelectedDashboard: localStorage.getItem(
        "currentSelectedDashboard"
      ),
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,
      errorMessage: "",
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        lastName: "",
        firstName:'',
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
      // window.location.reload();
      // setTimeout(() => {
      //   return <Redirect to="/profile" />;
      // }, 3000);
    }

    let self = this;
    this.asyncLocalStoragegetItem("user").then(function (user) {
      console.log("USER", user);
      if (user.profile) {
        self.setState({          
          data: {
            firstName:user.firstName,
          lastName:user.lastName,
            profile: user.profile,
          },
        });
      }
    });
  }

  async asyncLocalStoragegetItem(key) {
    return Promise.resolve().then(function () {
      return JSON.parse(localStorage.getItem(key));
    });
  }

  async handleUpdate(e) {
    e.preventDefault();
    if (
      validate_Empty(this.state.data.firstName) &&
      validate_Empty(this.state.data.lastName) &&
      validate_Empty(this.state.data.profile.dob) &&
      validate_Empty(this.state.data.profile.gender) &&
      validate_Empty(this.state.data.profile.profession) &&
      validate_Empty(this.state.data.profile.address?.street) &&
      validate_Empty(this.state.data.profile.address?.city) &&
      validate_Empty(this.state.data.profile.address?.state) &&
      validate_Empty(this.state.data.profile.address?.country) &&
      validate_Empty(this.state.data.profile.address?.pincode)
    ) {
      this.setState({ spinner: true });
      console.log("DATREAL", this.state.data);
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

      console.log("DATA", self.state.data);
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .put(API_URL + "user/profile/edit", self.state.data, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log(response);
            //handle success

            if (response.data.success === true) {
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.userInfo)
              );
              setTimeout(() => {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                });

                self.props.history.push("/profile");
                toast("Profile Updated Successfully", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }, 1000);
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } else {

      if(!validate_Empty(this.state.data.profile.dob)){
        toast("Enter Date of Birth!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      else if(!validate_Empty(this.state.data.profile.gender))
      {
        toast("Enter Gender!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.profession))
      {
        toast("Enter Profession!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.address?.street))
      {
        toast("Enter Street Address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.address?.city))
      {
        toast("Enter City Address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.address?.state))
      {
        toast("Enter State!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.address?.pincode))
      {
        toast("Enter Pincode!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      else if(!validate_Empty(this.state.data.profile.address?.country))
      {
        toast("Enter Country!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      
    }
  }
  validateField = (input, type) => (e) => {
    let name = e.target.name;
    let val = e.target.value;
    
    if (type == "address") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          profile: {
            ...prevState.data.profile,
            address: {
              ...prevState.data.profile.address,
              [input]: e.target.value,
            },
          },
        },
      }));
    } else if (type == "profile") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          profile: {
            ...prevState.data.profile,
            [input]: e.target.value,
          },
        },
      }));
    }

    else if (type == "userdata") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          [input]:e.target.value         
        },
      }));
    }

    this.validateAll(name, val);
  };

  validateAll(name, val) {
    let errorName = name + "Error";
    validate_Empty(val)
      ? this.setState({ [errorName]: false })
      : this.setState({ [errorName]: true });
    console.log(errorName);
  }

  render() {
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form>
                        <Row>
                          <Col xs lg="2" className="dashboard-sidebar">
                            <SidebarCommonMenuItem
                              currentSelectedDashboard={
                                this.state.currentSelectedDashboard
                              }
                            />
                          </Col>

                          <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                              <Row>
                                <Col>
                                  <div className="dashboard-content-title">
                                    <Link onClick={this.props.history.goBack}>
                                      {" "}
                                      <IoReturnUpBackOutline className="mx-3" />
                                    </Link>
                                    My Profile Details
                                  </div>
                                </Col>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.handleUpdate}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Update
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <Row className="p-3 shd-1">
                              <Col>
                                <Row>
                                  <Col>
                                  <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    onChange={this.validateField(
                                      "firstName", 
                                      'userdata'                                   
                                    )}
                                    value={this.state.data.firstName}
                                    name="firstName"
                                    // className={
                                    //   this.state.professionError
                                    //     ? "hasError"
                                    //     : "valid"
                                    // }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    onChange={this.validateField(
                                      "lastName",  
                                      'userdata'                                  
                                    )}
                                    value={this.state.data.lastName}
                                    name="lastName"
                                    // className={
                                    //   this.state.professionError
                                    //     ? "hasError"
                                    //     : "valid"
                                    // }
                                  />
                                </Form.Group>

                                   
                                  </Col>

                                  <Col>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Gender</Form.Label>

                                      <Form.Select
                                        value={this.state.data.profile.gender}
                                        onChange={this.validateField(
                                          "gender",
                                          "profile"
                                        )}
                                        name="gender"
                                        aria-label="Default select"
                                        className={
                                          this.state.genderError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </Form.Select>
                                    </Form.Group>

                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Date of Birth</Form.Label>
                                      {/* <DatePicker   /> */}

                                      <Form.Control                                       
                                        placeholder="DD-MM-YYYY"
                                        onChange={this.validateField(
                                          "dob",
                                          "profile"
                                        )}                                       
                                        value={this.state.data.profile.dob}                                       
                                        name="dob"
                                        maxLength={10}
                                        className={
                                          this.state.dobError
                                            ? "hasError"
                                            : "valid"
                                        }
                                      />

                                   
                                    </Form.Group>                                  



                                  </Col>
                                </Row>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Profession</Form.Label>
                                  <Form.Control
                                    onChange={this.validateField(
                                      "profession",
                                      "profile"
                                    )}
                                    value={this.state.data.profile.profession}
                                    name="profession"
                                    className={
                                      this.state.professionError
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                  {/* <option value="" selected="selected" disabled="disabled">-- select one --</option>
    <optgroup label="Healthcare Practitioners and Technical Occupations:">
      <option value="1">-  Chiropractor</option>
      <option value="2">-  Dentist</option>
      <option value="3">-  Dietitian or Nutritionist</option>
      <option value="4">-  Optometrist</option>
      <option value="5">-  Pharmacist</option>
      <option value="6">-  Physician</option>
      <option value="7">-  Physician Assistant</option>
      <option value="8">-  Podiatrist</option>
      <option value="9">-  Registered Nurse</option>
      <option value="10">-  Therapist</option>
      <option value="11">-  Veterinarian</option>
      <option value="12">-  Health Technologist or Technician</option>
      <option value="13">-  Other Healthcare Practitioners and Technical Occupation</option>
    </optgroup>
    <optgroup label="Healthcare Support Occupations:">
      <option value="14">-  Nursing, Psychiatric, or Home Health Aide</option>
      <option value="15">-  Occupational and Physical Therapist Assistant or Aide</option>
      <option value="16">-  Other Healthcare Support Occupation</option>
    </optgroup>
    <optgroup label="Business, Executive, Management, and Financial Occupations:">
      <option value="17">-  Chief Executive</option>
      <option value="18">-  General and Operations Manager</option>
      <option value="19">-  Advertising, Marketing, Promotions, Public Relations, and Sales Manager</option>
      <option value="20">-  Operations Specialties Manager (e.g., IT or HR Manager)</option>
      <option value="21">-  Construction Manager</option>
      <option value="22">-  Engineering Manager</option>
      <option value="23">-  Accountant, Auditor</option>
      <option value="24">-  Business Operations or Financial Specialist</option>
      <option value="25">-  Business Owner</option>
      <option value="26">-  Other Business, Executive, Management, Financial Occupation</option>
    </optgroup>
    <optgroup label="Architecture and Engineering Occupations:">
      <option value="27">-  Architect, Surveyor, or Cartographer</option>
      <option value="28">-  Engineer</option>
      <option value="29">-  Other Architecture and Engineering Occupation</option>
    </optgroup>
    <optgroup label="Education, Training, and Library Occupations:">
      <option value="30">-  Postsecondary Teacher (e.g., College Professor)</option>
      <option value="31">-  Primary, Secondary, or Special Education School Teacher</option>
      <option value="32">-  Other Teacher or Instructor</option>
      <option value="33">-  Other Education, Training, and Library Occupation</option>
    </optgroup>
    <optgroup label="Other Professional Occupations:">
      <option value="34">-  Arts, Design, Entertainment, Sports, and Media Occupations</option>
      <option value="35">-  Computer Specialist, Mathematical Science</option>
      <option value="36">-  Counselor, Social Worker, or Other Community and Social Service Specialist</option>
      <option value="37">-  Lawyer, Judge</option>
      <option value="38">-  Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)</option>
      <option value="39">-  Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)</option>
      <option value="40">-  Religious Worker (e.g., Clergy, Director of Religious Activities or Education)</option>
      <option value="41">-  Social Scientist and Related Worker</option>
      <option value="42">-  Other Professional Occupation</option>
    </optgroup>
    <optgroup label="Office and Administrative Support Occupations:">
      <option value="43">-  Supervisor of Administrative Support Workers</option>
      <option value="44">-  Financial Clerk</option>
      <option value="45">-  Secretary or Administrative Assistant</option>
      <option value="46">-  Material Recording, Scheduling, and Dispatching Worker</option>
      <option value="47">-  Other Office and Administrative Support Occupation</option>
    </optgroup>
    <optgroup label="Services Occupations:">
      <option value="48">-  Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)</option>
      <option value="49">-  Chef or Head Cook</option>
      <option value="50">-  Cook or Food Preparation Worker</option>
      <option value="51">-  Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)</option>
      <option value="52">-  Building and Grounds Cleaning and Maintenance</option>
      <option value="53">-  Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)</option>
      <option value="54">-  Sales Supervisor, Retail Sales</option>
      <option value="55">-  Retail Sales Worker</option>
      <option value="56">-  Insurance Sales Agent</option>
      <option value="57">-  Sales Representative</option>
      <option value="58">-  Real Estate Sales Agent</option>
      <option value="59">-  Other Services Occupation</option>
    </optgroup>
    <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
      <option value="60">-  Construction and Extraction (e.g., Construction Laborer, Electrician)</option>
      <option value="61">-  Farming, Fishing, and Forestry</option>
      <option value="62">-  Installation, Maintenance, and Repair</option>
      <option value="63">-  Production Occupations</option>
      <option value="64">-  Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation</option>
    </optgroup>
    <optgroup label="Transportation Occupations:">
      <option value="65">-  Aircraft Pilot or Flight Engineer</option>
      <option value="66">-  Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)</option>
      <option value="67">-  Other Transportation Occupation</option>
    </optgroup>
    <optgroup label="Other Occupations:">
      <option value="68">-  Military</option>
      <option value="69">-  Homemaker</option>
      <option value="70">-  Other Occupation</option>
      <option value="71">-  Don't Know</option>
      <option value="72">-  Not Applicable</option>
    </optgroup> */}
                                  {/* </Form.Select> */}
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Street</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    onChange={this.validateField(
                                      "street",
                                      "address"
                                    )}
                                    name="street"
                                    value={
                                      this.state.data.profile?.address?.street
                                    }
                                    className={
                                      this.state.streetError
                                        ? "hasError"
                                        : "valid"
                                    }
                                  />
                                  <Form.Text className="text-muted">
                                    Please Enter Valid Address. This will be
                                    used for verification(Min 30 Characters).
                                  </Form.Text>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>State</Form.Label>
                                  <Form.Select
                                    onChange={this.validateField(
                                      "state",
                                      "address"
                                    )}
                                    name="state"
                                    aria-label="Default select"
                                    className={
                                      this.state.stateError
                                        ? "hasError"
                                        : "valid"
                                    }
                                    value={
                                      this.state.data.profile?.address?.state
                                    }
                                  >
                                    <option value="">Select State</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="TamilNadu">TamilNadu</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="AndraPradesh">
                                      AndraPradesh
                                    </option>
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>City</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.validateField(
                                      "city",
                                      "address"
                                    )}
                                    name="city"
                                    className={
                                      this.state.cityError
                                        ? "hasError"
                                        : "valid"
                                    }
                                    value={
                                      this.state.data.profile?.address?.city
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Pincode</Form.Label>
                                  <Form.Control
                                    type="number"
                                    onChange={this.validateField(
                                      "pincode",
                                      "address"
                                    )}
                                    name="pincode"
                                    className={
                                      this.state.pincodeError
                                        ? "hasError"
                                        : "valid"
                                    }
                                    value={
                                      this.state.data.profile?.address?.pincode
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Country</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.validateField(
                                      "country",
                                      "address"
                                    )}
                                    name="country"
                                    className={
                                      this.state.countryError
                                        ? "hasError"
                                        : "valid"
                                    }
                                    value={
                                      this.state.data.profile?.address?.country
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';
// import { connect } from "react-redux";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import { HiOutlineMail,HiOutlinePhone,HiOutlineUserCircle } from "react-icons/hi";

// class Dashboard extends Component {

//   render() {
//     const { user: currentUser } = this.props;
//     console.log('USER in Dashboard',currentUser)

//     if (!currentUser) {
//       return <Redirect to="/login" />;
//     }

//     return (

// <div>
//       <div className="content-area topadjust px-4 bg-4">
//       <div className="container-fluid">
//           <nav aria-label="breadcrumb my-2">
//           <ol class="breadcrumb">
//             <li class="breadcrumb-item">
//             <Link to="/"> Home </Link>
//             </li>
//             <li class="breadcrumb-item">
//             <Link to="/"> Dashboard</Link>
//             </li>
//           </ol>
//           </nav>

//           <div className="row align-items-center">
//           <div className="col-md-12">
//           <h2>Welcome Back! {currentUser.firstName}</h2>
//             </div>
//             </div>

//         <div className="row align-items-center mt-3">

//         <div className="col-sm-12 col-md-4">
//             <div className="bdr-4 p-3 min-h-200 align-items-center bg-1 bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">My Dashboard</h2>
//                <p className="c4 m-1"><HiOutlineUserCircle/>&nbsp;{currentUser.firstName}&nbsp;{currentUser.lastName}</p>
//               <p className="c4 m-1"><HiOutlineMail/>&nbsp;{currentUser.email}</p>
//               <p className="c4 m-1"><HiOutlinePhone/>&nbsp;{currentUser.phoneNumber}</p>
//               <Link to="/login" class="btn btn-primary">
//               Edit Dashboard
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-4 align-self-center">
//             <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">Find Green Build Services</h2>
//               <p className="c4">
//                 Work with the largest network of independent professionals and
//                 get things done from quick turnarounds to big transformations.
//               </p>
//               <Link to="/login" class="btn btn-primary-trans">
//                View Professionals
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-4">
//             <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
//               <h2 className="fw-700 c3 fs-26">Get Green Build Ratings</h2>
//               <p className="c4">
//               Going Beyond the Regulatory Equations. Submit your property and get certifications and ratings.
//               </p>
//               <Link to="/login" class="btn btn-primary-trans">
//               Submit your Property Now
//               </Link>
//             </div>
//           </div>

//         </div>

//         <div className="row my-5">

//           <div className="col-sm-12 col-md-6">
//             <div className="bdr-2 p-3 min-h-200 align-items-center bg-2 bdr-rad-4 m-2">
//               <h2 className="fw-700 c1 fs-26">Are you a Service Provider?</h2>
//               <p className="c1">
//                 Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
//               </p>
//               <Link to="/apply-specialist" class="btn btn-primary-trans">
//                Apply Now
//               </Link>
//             </div>
//           </div>

//           <div className="col-sm-12 col-md-6">
//             <div className="bdr-3 p-3 min-h-200 align-items-center bg-3 bdr-rad-4 m-2">
//               <h2 className="fw-700 c1 fs-26">Are you a Product Manufacturer?</h2>
//               <p className="c1">
//                 Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
//               </p>
//               <Link to="/apply-manufacturer" class="btn btn-primary-trans">
//                Get SmartPro Rating and Showcase your Products
//               </Link>
//             </div>
//           </div>

//         </div>

//         <header className="jumbotron">
//           <h3>
//             {/* <strong>{currentUser.username}</strong> Dashboard */}

//           </h3>
//         </header>
//         {/* <p>
//           <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
//           {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
//         </p>
//         <p>
//           <strong>Id:</strong> {currentUser.id}
//         </p>
//         <p>
//           <strong>Email:</strong> {currentUser.email}
//         </p>
//         <strong>Authorities:</strong>
//         <ul>
//           {currentUser.roles &&
//             currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
//         </ul> */}
//       </div>
//       </div>
//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const { user } = state.auth;
//   return {
//     user,
//   };
// }

// export default connect(mapStateToProps)(Dashboard);

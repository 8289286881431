import React from 'react'

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiArchive, FiFile, FiUser } from "react-icons/fi";
import FormTopbar from '../SubmitProduct/FormTopbar'

const Confirmation = ({ prevStep, nextStep, values, submitData,handleRegister,documents,step }) => {
  console.log(values);
  let user= JSON.parse(localStorage.getItem('user'))

  const { firstName, lastName, email, username, country, levelOfEducation } = values
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <section className="lightgrey-bg user-area log-in-area">
      <Container fluid component="main" maxWidth="xs" className="p-2">                   
        <div className="user-form-content">
          <div className="row">
            <div className="col-md-12">
              <div className="form-card mt-3">
              <Row>
                      <FormTopbar step={step} />
                    </Row>
              <Row className="text-center my-3">
              <h2 className="mb-4">Submit Your Information</h2>
              <p>I <span style={{textDecoration:'underline'}}>{user.firstName}</span> hereby declare that the information furnished here is true, complete
and correct to the best of my knowledge and belief.</p>
                  <Col>
                    <Button
                      onClick={Previous}
                      type="submit"
                      className="btn btn-primary-trans btn-block me-2"
                    >
                      Previous
                    </Button>

                    <Button                      
                      type="submit"
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      onClick={handleRegister}
                      disabled={false}
                      className="btn btn-primary btn-block c1"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Row className="p-4">
                <h2 className="mb-4">Preview Your Information</h2>
                  <Col>
                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Model Name</div>
                      <div className="formConfirmValue">
                        {values?.productInfo?.model}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Brand Name</div>
                      <div className="formConfirmValue">
                        {values?.productInfo ?
                        values?.productInfo?.brandName
                      :''}
                      </div>
                    </div>                   
                  </Col>
                  <Col>                 
                    <div className="formConfirmHolder">                      
                      <div className="formConfirmLabel">Description</div>
                      <div className="formConfirmValue">
                        {values?.productInfo?
                        values?.productInfo?.description
                      :''}
                      </div>
                    </div>

                    <div className="formConfirmHolder">
                      <div className="formConfirmLabel">Keyword</div>
                      <div className="formConfirmValue">
                        { values?.productInfo ?
                        values?.productInfo?.keyword
                      :''}
                      </div>
                    </div>                 
                  </Col>
                           
                </Row>



                <Row className="p-4">
                <h3>Product Images</h3>
                {values?.productImages ?
                values?.productImages.map((item)=>     
                  <Col md={4}>                  
                  <div className="formConfirmHolder">                      
                      <div className="formConfirmValue">
                          <img className="img-fluid" src={ URL.createObjectURL(item.file)} />
                      </div>
                    </div> 
                  </Col>
                   )
                   :''}
                </Row>


                  
                <Row className="p-4">
                <h3>Uploaded Documents</h3>
                {values?.documents ?
                values?.documents.map((item)=>     
                  <Col md={4}>                  
                  <div className="formConfirmHolder">                      
                      <div className="formConfirmValue">
                      <FiFile className="img-fluid mb-2 bdr-rad-4" style={{height:60,width:60}} />  
                      <p>{item?. name}</p>
                          {/* <img className="img-fluid" src={ URL.createObjectURL(item.file)} /> */}
                      </div>
                    </div> 
                  </Col>
                   )
                   :''}
                </Row>


              
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Confirmation
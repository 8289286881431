import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class WhatsNew extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="why-green">
            <div className="patner-trs">
              <div className="row  text-center">
                <h2 className="section-title p-tb-20 text-center ">
                  Beyond Smart Cities, Software Updates and Releases
                </h2>
              </div>
            </div>
          </div>

          <div className="container my-4">         

          
           
           
             <h2>Future Updates Appears Here</h2>
           
          
          </div>
        </div>
      </div>
    );
  }
}

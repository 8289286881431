import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Breadcrumb,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiBuilding,
  BiBuildings,
  BiStar,
} from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { FiAlertTriangle, FiCheck, FiHelpCircle, FiX } from "react-icons/fi";
import { MdFileDownload, MdFileUpload } from "react-icons/md";

import { IoReturnUpBackOutline } from "react-icons/io5";
import validator from "validator";
import axios from "axios";
import { API_URL } from "../../../config";
import moment from "moment";
import DashboardSidebar from "../../DashboardSidebar";
import { FaFile, FaFileDownload } from "react-icons/fa";
import Loader from "react-js-loader";
import { toast } from "react-toastify";

export default class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.addNewProduct = this.addNewProduct.bind(this);

    this.state = {
      dataLoading: false,

    // PDP SUSTAIN
      ProdPerSVSProgressWidth:0,
      ProdPerSPResProgressWidth:0,
      ProdPerSVS:'',
      ProdPerSPRes:'',

      // PDP ENV
      ProdPerEVSProgressWidth:0,
      ProdPerEPResProgressWidth:0,
      ProdPerEVS:'',
      ProdPerEPRes:'',


      // PDP HEALTHSAFETY
      ProdPerHSProgressWidth:0,         
      ProdPerHS:'',

      // PDP ENERGYPOLICY
      ProdPerEPProgressWidth:0,         
      ProdPerEP:'',  

      // PDP PRODUCT PERFORMANCE
      ProdPerPPProgressWidth:0,         
      ProdPerPP:'', 



      // EMERGING TECH
     emTechESACRProgressWidth:0,
     emTechIMHProgressWidth:0,
     emTechELAProgressWidth:0,
     emTechESACR:'',
     emTechIMH:'',
     emTechELA:'',

    //  PRODUCT STREWARDSHIP

    proStewSAGPProgressWidth:0,
    proStewQMDProgressWidth:0,
    proStewPTBProgressWidth:0,

    proStewSAGP:'',
    proStewQMD:'',
    proStewPTB:'',



      // PDP ENV
      ProdPerEVSProgressWidth:0,
      ProdPerEPResProgressWidth:0,
      ProdPerEVS:'',
      ProdPerEPRes:'',


// MR
mrReuse:0,
mrReuseProgressWidth:0,

mrLocalmat:0,
mrLocalmatProgressWidth:0,

mrLocmap:0,
mrLocmapProgressWidth:0,

mrNonhazar:0,
mrNonhazarProgressWidth:0,

mrMatwaste:0,
mrMatwasteProgressWidth:0,

mrUtiWaste:0,
mrUtiWasteProgressWidth:0,

mrCommhazar:0,
mrCommhazarProgressWidth:0,


// MT

mtAnnualprod:0,
mtAnnualprodProgressWidth:0,

energyEff:0,
energyEffProgressWidth:0,

mtWaterEff:0,
mtWaterEffProgressWidth:0,

mtRainwater:0,
mtRainwaterProgressWidth:0,

onAndoff:0,
onAndoffProgressWidth:0,

mtTotalPower:0,
mtTotalPowerProgressWidth:0,

mtGreenEner:0,
mtGreenEnerProgressWidth:0,


// LCA
impactAnalysis:0,
impactAnalysisProgressWidth:0,

lcaStudy:0,
lcaStudyProgressWidth:0,

// RCF

rcfCDM:0,
rcfCDMProgressWidth:0,

rcfGHG:0,
rcfGHGProgressWidth:0,

// PS

psProductsafety:0,
psProductsafetyProgressWidth:0,

psQualityManage:0,
psQualityManageProgressWidth:0,

psEPR:0,
psEPRProgressWidth:0,


// ET

etPromoteEnv:0,
etPromoteEnvProgressWidth:0,

etInnovation:0,
etInnovationProgressWidth:0,

etCertificate:0,
etCertificateProgressWidth:0,
     

     

      productData: "",
      productId: this.props.match.params.id,
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      dobError: "",
      genderError: "",
      professionError: "",
      streetError: "",
      stateError: "",
      cityError: "",
      countryError: "",
      pincodeError: "",

      data: {
        profile: {
          dob: "",
          gender: "",
          profession: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          },
        },
      },
    };
  }
  componentDidMount() {
    console.log(this.state.productId);
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");
    }

    if (this.state.userData.isEmailVerified) {
      this.setState({ notice: false });
    } else {
      setTimeout(() => {
        this.setState({ notice: true });
      }, 3000);
    }
    this.getProductDetails();
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProduct = () => {
    this.props.history.push("/submit-product");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }

  async getProductDetails() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "product/" + this.state.productId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log(response.data.productData);

          if (response.data.success === true) {
            self.setState({
              productData: response.data.productData,
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }






  handleDocUpload = (e, cat) => {
    
    var self = this;
    e.preventDefault();
    console.log(e);   


    var loaded = 0;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      self.setState({
        [cat]: file,
      });
      self.handleUpdate(cat);
    };   

    reader.readAsDataURL(file);
  };



  async handleUpdate(cat) {   
    
    var self = this;
    self.setState({ spinner: true });
    self.setState({
      submitLoading: true,
      errorMessage: "",
    });

    let formData = new FormData();
     
      // for (let i=0;i < this.state.sustainabilityPDP.resources.length;i++){
      //   formData.append('resources', this.state.sustainabilityPDP.resources[i]); 
      // }     
   
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header

    let url_extend;
    if (cat === "ProdPerSVS") {
      url_extend = "product/upload/pdp/sustainability/docs/";
      formData.append('vision', this.state.ProdPerSVS); 
    }


    if (cat === "ProdPerSPRes") {
      url_extend = "product/upload/pdp/sustainability/docs/";
      formData.append('resources', this.state.ProdPerSPRes); 
    }

    if (cat === "ProdPerEVS") {
      url_extend = "product/upload/pdp/env/docs/";
      formData.append('envVision', this.state.ProdPerEVS); 
    }

    if (cat === "ProdPerEPRes") {
      url_extend = "product/upload/pdp/env/docs/";
      formData.append('envResources', this.state.ProdPerEPRes); 
    }

    if (cat === "ProdPerHS") {
      url_extend = "product/upload/pdp/health/docs/";
      formData.append('healthAndSafety', this.state.ProdPerHS); 
    }

    if (cat === "ProdPerEP") {
      url_extend = "product/upload/pdp/energy/docs/";
      formData.append('energyPolicy', this.state.ProdPerEP);
    }

    if (cat === "ProdPerPP") {
      url_extend = "product/upload/pdp/productPerformance/docs/";
      formData.append('productPerformance', this.state.ProdPerPP);
    }

    // MAT

    if (cat === "matResue") {
      url_extend = "product/upload/mr/reuse/docs/";
      formData.append('recycleMaterialCalSheet', this.state.matResue);
    }


    if (cat === "mrLocalmat") {
      url_extend = "product/upload/mr/local/docs/";
      formData.append('materialCalSheet', this.state.mrLocalmat); 
    }

    if (cat === "mrLocmap") {
      url_extend = "product/upload/mr/local/docs/";
      formData.append('materialSupplyLocation', this.state.mrLocmap); 
    }

    if (cat === "mrNonhazar") {
      url_extend = "product/upload/mr/nonhazard/docs/";
      formData.append('testCertificates', this.state.mrNonhazar); 
    }

    if (cat === "mrMatwaste") {
      url_extend = "product/upload/mr/wasteMgt/docs/";
      formData.append('wasteGeneratedAndQuantity', this.state.mrMatwaste); 
    }

    if (cat === "mrUtiWaste") {
      url_extend = "product/upload/mr/wasteMgt/docs/";
      formData.append('wasteUtilizationAndProcess', this.state.mrUtiWaste); 
    }

    if (cat === "mrCommhazar") {
      url_extend = "product/upload/mr/wasteMgt/docs/";
      formData.append('wastecommonhazardous', this.state.mrCommhazar); 
    }


    // MT 



  if (cat === "mtAnnualprod") {
      url_extend = "product/upload/manufacturingTech/energy/docs/";
      formData.append('annualProductionAndEnergyConsumption', this.state.mtAnnualprod); 
    }


    if (cat === "energyEff") {
      url_extend = "product/upload/manufacturingTech/energy/docs/";
      formData.append('energyEfficencyImprovement', this.state.energyEff); 
    }

    if (cat === "mtWaterEff") {
      url_extend = "product/upload/manufacturingTech/water/docs/";
      formData.append('annualWaterConsumption', this.state.mtWaterEff); 
    }

    if (cat === "mtRainwater") {
      url_extend = "product/upload/manufacturingTech/water/docs/";
      formData.append('rainwaterHarvesting', this.state.mtRainwater); 
    }

    if (cat === "onAndoff") {
      url_extend = "product/upload/manufacturingTech/greenPower/docs/";
      formData.append('installation', this.state.onAndoff); 
    }

    if (cat === "mtTotalPower") {
      url_extend = "product/upload/manufacturingTech/greenPower/docs/";
      formData.append('totalPowerConsumption', this.state.mtTotalPower); 
    }

    if (cat === "mtGreenEner") {
      url_extend = "product/upload/manufacturingTech/greenEnergy/docs/";
      formData.append('energyUtilization', this.state.mtGreenEner); 
    }

// LCA


if (cat === "lcaStudy") {
  console.log('lca')
  url_extend = "product/upload/lifeCycleAppr/assesment/docs/";
  formData.append('studyReport', this.state.lcaStudy); 
}

if (cat === "impactAnalysis") {
  url_extend = "product/upload/lifeCycleAppr/assesment/docs/";
  formData.append('analysisBenefits', this.state.impactAnalysis); 
}


// RCF

if (cat === "rcfCDM") {
  url_extend = "product/upload/reduceCarbon/cleanDev/docs/";
  formData.append('cdmStudyReport', this.state.rcfCDM); 
}

if (cat === "rcfGHG") {
  url_extend = "product/upload/reduceCarbon/ghg/docs/";
  formData.append('accountingReport', this.state.rcfGHG); 
}


// PS

if (cat === "psProductsafety") {
  console.log('ps')
  url_extend = "product/upload/prSteward/productSafety/docs/";
  formData.append('stakeHolders', this.state.psProductsafety); 
}

if (cat === "psQualityManage") {
  url_extend = "product/upload/prSteward/qualityMgmt/docs/";
  formData.append('productQuality', this.state.psQualityManage); 
}

if (cat === "psEPR") {
  url_extend = "product/upload/prSteward/extendedProducer/docs/";
  formData.append('productTakeBack', this.state.psEPR); 
}





// ET
if (cat === "etPromoteEnv") {
  url_extend = "product/upload/emergingTech/environmentalAwareness/docs/";
  formData.append('report', this.state.etPromoteEnv); 
}

if (cat === "etInnovation") {  
  url_extend = "product/upload/emergingTech/innovation/docs/";
  formData.append('innovativeMeasures', this.state.etInnovation); 
}

if (cat === "etCertificate") {
  url_extend = "product/upload/emergingTech/innovation/docs/";
  formData.append('certificate', this.state.etCertificate); 
}






console.log(cat)
    
    await axios
      .put(API_URL + url_extend + this.state.productId, formData, {
        headers: {
          auth: token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;         
            this.setState({ [cat+"ProgressWidth"]: progress + "%" });
        },

        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress);         
            this.setState({[cat+"ProgressWidth"]:progress + "%"})              
        },
      })

      .then(
        function (response) {
          console.log("Ri", response);
          //handle success
          console.log(response);
          if (response.data.success === true) {
            setTimeout(() => {
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                spinner: false,
              });

              //self.props.history.goBack();
              self.getProductDetails();
              toast("Document Uploaded Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }, 1000);

            setTimeout(() => {
              self.setState({
                [cat+"ProgressWidth"]:0,              
              })
              
            }, 2000);

           
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            spinner: false,
          });
          toast("Thers is an Error in the input fields. Please check!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
  }



  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust prod-detail">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Product Detail</Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                          <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col>
                                <div className="dashboard-content-title">
                                  <Link onClick={this.props.history.goBack}>
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                 {this.state.productData?.productInfo?.brandName}
                                </div>
                              </Col>
                              <Col>
                                <div className="dashboard-content-action float-end">
                                  {/* ACTION BUTTON */}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          {!this.state.dataLoading ? (

                          <Row>


<Tabs defaultActiveKey="productInformation" id="uncontrolled-tab-example" className="mb-3 fw-600">
<Tab eventKey="productInformation" title="Product Information">


                            <Col className="position-relative">
                              <div className="shd-1 py-3">
                                <Row className="align-items-center">
                                  <Col md={1}>
                                    <BiBuildings className="ms-2 fs-2" />
                                  </Col>
                                  <Col md={8}>
                                    <div className="product-title fw-700">
                                      {
                                        this.state.productData?.productInfo
                                          ?.brandName
                                      }{" "}
                                    </div>
                                    <div className="product-added-date fs-12">
                                      Applied On:{" "}
                                      {this.state.productData?.createdAt}
                                    </div>
                                  </Col>

                                  <Col md={2}>
                                    <div className="product-status fw-700 c9">
                                      <FiAlertTriangle className="me-1" />{" "}
                                      {this.state.productData?.adminVerified
                                        ? " Approved"
                                        : "Pending"}
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="product-rating">
                                      <BsStarFill className="c2" />
                                      <BsStarFill className="c2" />
                                      <BsStarFill className="c2" />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="mt-3 DetailList">
                                    <ul>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                        Product Name: {
                                          this.state.productData?.productInfo
                                            ?.brandName
                                        }
                                      </li>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                        Model Name:{" "}
                                        {
                                          this.state.productData?.productInfo
                                            ?.model
                                        }
                                      </li>
                                      <li>
                                        <FiCheck className="me-1 c2" />
                                        Keywords:{" "}
                                        {
                                          this.state.productData?.productInfo
                                            ?.keyWord
                                        }
                                      </li>
                                    </ul>
                                  </Col>

                                  <Col className="mt-3 DetailList">
                                    <ul>
                                      <li>
                                        <FiCheck className="me-1 c5" />
                                        Category:{" "}
                                        {
                                          this.state.productData?.productInfo
                                            ?.categoryId?.name
                                        }
                                      </li>

                                      <li>
                                        <FiCheck className="me-1 c5" />
                                        Sub Category:{" "}
                                        {
                                          this.state.productData?.productInfo
                                            ?.subCategoryId?.name
                                        }
                                      </li>
                                    </ul>
                                  </Col>
                                </Row>


                                <Row className="px-4 py-4">
                                  <Col>
                                  <h3>Description</h3>
                                  {this.state.productData?.productInfo?.description }    
                                  </Col>                             
                                </Row>

                                <Row className="px-4">
                                  <h3>Product Images</h3>
                                  {this.state.productData?.productInfo?.image &&
                                  this.state.productData?.productInfo?.image?.map(
                                    (item) => (
                                      <Col md={3}>
                                        <div className="bdr-rad-1">
                                          <img
                                            className="img-fluid"
                                            src={item?.secureUrl}
                                          />
                                        </div>
                                      </Col>
                                    )
                                  )}
                                </Row>

                                <Row className="px-4 mt-4">
                                  <h3>Documents</h3>

                                
                                  {this.state.productData?.productInfo?.supportDocuments &&
                                  this.state.productData?.productInfo?.supportDocuments?.map(
                                    (item) => (
                                      <Col md={3}>
                                        <div className="bdr-rad-1 p-3">
                                          <Link to={item.secureUrl}>
                                         <FaFileDownload style={{fontSize:48}} />
                                          </Link>
                                        </div>
                                      </Col>
                                    )
                                  )}  
                               
                                </Row>
                              </div>
                            </Col>


 </Tab>

 <Tab eventKey="productDesignPerformance" title="Product Design & Performance(PDP)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >PRODUCT DESIGN & PERFORMANCE[PDP]</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> PDP.1 Sustainability Policy Statement</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Upload documents related to "Sustainability Vision Statement of the company"</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerSVS">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="ProdPerSVS"
                                                          type="file"
                                                          name="ProdPerSVS"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'ProdPerSVS'                                                                                                                       
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : ' Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .ProdPerSVSProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.List of documents with respect to Strategies /Resources
allocation for sustainability ( Allowed more than one) </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerSPRes">
                                                          <MdFileUpload />
                                                          { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.resourcesAndStrategies[0]?.secureUrl? 'Upload New' : 'Upload'}

                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="ProdPerSPRes"
                                                          type="file"
                                                          name="ProdPerSPRes"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'ProdPerSPRes'                                                                                                                       
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.resourcesAndStrategies?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.resourcesAndStrategies[0]?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : ' Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .ProdPerSPResProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >PRODUCT DESIGN & PERFORMANCE[PDP]</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> PDP.2 Environmental Policy Statement</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Upload documents related to "Environmental Vision Statement of the company"</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerEVS">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envVisionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="ProdPerEVS"
                                                          type="file"
                                                          name="ProdPerEVS"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'ProdPerEVS'                                                                                                                       
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envVisionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envVisionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : ' Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .ProdPerEVSProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.List of documents with respect to Strategies /Resources
allocation for Environmental activities. ( Allowed more than one) </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerEPRes">
                                                          <MdFileUpload />
                                                          { this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envResourcesAndStrategies[0]?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="ProdPerEPRes"
                                                          type="file"
                                                          name="ProdPerEPRes"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "ProdPerEPRes"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envResourcesAndStrategies?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envResourcesAndStrategies[0]?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : ' Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .ProdPerEPResProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >PRODUCT DESIGN & PERFORMANCE[PDP]</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> PDP.3 Health & Safety Policy</span> <span className={ this.state.productData?.productDesignAndPerformance?.environmentalPolicyStatement?.envResourcesAndStrategies?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Upload documents related to "Health and Safety policy with measurable objectives"</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerHS">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="ProdPerHS"
                                                            type="file"
                                                            name="ProdPerHS"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,
                                                                "ProdPerHS"                                                             
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .ProdPerHSProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >PRODUCT DESIGN & PERFORMANCE[PDP]</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> PDP.4 Energy Policy Statement</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Upload documents related to "Energy saving policy with measurable objectives"</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerEP">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="ProdPerEP"
                                                            type="file"
                                                            name="ProdPerEP"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,
                                                                "ProdPerEP"                                                             
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                     </Col>
                                      </Row>
                                       <Row>
                                        <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >PRODUCT DESIGN & PERFORMANCE[PDP]</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> PDP.5 Product Performance Testing</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Upload documents related to "Provide the testing reports for products performance
improvement"</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="ProdPerPP">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="ProdPerPP"
                                                            type="file"
                                                            name="ProdPerPP"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,
                                                                "ProdPerPP"                                                             
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                        </Col>                                                        
                                    </Row>
                                   
                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>


{/* MATERIAL */}

<Tab eventKey="materialResources" title="Material & Resources(MR)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >MATERIAL AND RESOURCES(MR)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> MR.1 Recycled Materials Reuse</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Provide the Recycled materials calculation sheets with evidence</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrReuse">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mrReuse"
                                                          type="file"
                                                          name="mrReuse"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'mrReuse'

                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                 

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >MATERIAL AND RESOURCES(MR)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> MR.2 Local Materials</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Provide the Local materials calculation with evidence</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrLocalmat">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mrLocalmat"
                                                          type="file"
                                                          name="mrLocalmat"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              'mrLocalmat'                                                                                                                        
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.Provide the location map for local material Supply </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrLocmap">
                                                          <MdFileUpload />
                                                          Upload
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mrLocmap"
                                                          type="file"
                                                          name="mrLocmap"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "mrLocmap"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >MATERIAL AND RESOURCES(MR)</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> MR.3  Use of Non-Hazardous Materials</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Test certif icates /Materials Safety Data Sheets per the specif ied
confirming levels of heavy metals or substances in the products </p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrNonhazar">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mrNonhazar"
                                                            type="file"
                                                            name="mrNonhazar"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e, 
                                                                'mrNonhazar'                                                                                                                       
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >MATERIAL AND RESOURCES(MR)</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> MR.4 Material Waste Management Plan</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Details of waste generated and their quantity by weight or volume</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrMatwaste">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mrMatwaste"
                                                            type="file"
                                                            name="mrMatwaste"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e, 
                                                                'mrMatwaste'                                                                                                                       
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 


<p>2: Details of utilization of the wastes and the process of utilization</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrUtiWaste">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mrUtiWaste"
                                                            type="file"
                                                            name="mrUtiWaste"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,    
                                                                'mrUtiWaste'                                                                                                                    
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 


<p>3: Details of the wastes handed over to Approved Common Hazardous
Wastes Treatment Storage and Disposal Facility (TSDF) for past 2
years</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mrCommhazar">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mrCommhazar"
                                                            type="file"
                                                            name="mrCommhazar"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,  
                                                                'mrCommhazar'                                                                                                                      
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 



                                                        </div> 
  
                                     </Col>
                                      </Row>
                                       
                                   
                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>



{/* Manufacturing */}


<Tab eventKey="manufacturingTechnology" title="Manufacturing Technology(MT)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >MANUFACTURING TECHNOLOGY(MT)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> MT.1 Energy Efficiency</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Details of annual production, energy consumption & specific energy consumption for the preceding 2 years</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mtAnnualprod">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mtAnnualprod"
                                                          type="file"
                                                          name="mtAnnualprod"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,   
                                                              'mtAnnualprod'                                                                                                                     
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.Details of implementation of energy efficiency improvement
measures with actual benefits achieved </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="energyEff">
                                                          <MdFileUpload />
                                                          Upload
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="energyEff"
                                                          type="file"
                                                          name="energyEff"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "energyEff"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >MANUFACTURING TECHNOLOGY(MT)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> MT.2 Water Efficiency</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Details of annual water consumption & Specific water consumption for 2 years</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mtWaterEff">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mtWaterEff"
                                                          type="file"
                                                          name="mtWaterEff"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,  
                                                              'mtWaterEff'                                                                                                                      
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.Rain water harvesting system installed and quantity of water
harvested annually </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mtRainwater">
                                                          <MdFileUpload />
                                                          Upload
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="mtRainwater"
                                                          type="file"
                                                          name="mtRainwater"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "mtRainwater"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >MANUFACTURING TECHNOLOGY(MT)</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> MT.3 Green Power</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Details of installation of onsite and offsite renewable power
generating sources including the technology, installed capacity
and location with photographs of installations.</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="onAndoff">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="onAndoff"
                                                            type="file"
                                                            name="onAndoff"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,  
                                                                'onAndoff'                                                                                                                      
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                       



                                                        <p>1: Details of installation of onsite and offsite renewable power
generating sources including the technology, installed capacity
and location with photographs of installations.</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mtTotalPower">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mtTotalPower"
                                                            type="file"
                                                            name="mtTotalPower"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e, 
                                                                'mtTotalPower'                                                                                                                       
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                       </div> 
  
                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >MANUFACTURING TECHNOLOGY(MT)</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> MT.4 Green Energy</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Provide the Green Energy Utilization report for
manufacturing.</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="mtGreenEner">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="mtGreenEner"
                                                            type="file"
                                                            name="mtGreenEner"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,   
                                                                'mtGreenEner'                                                                                                                     
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                     </Col>
                                      </Row>
                                     
                                   
                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>



{/* LIFE CYCLE */}


<Tab eventKey="lifeCycleApproach" title="Life Cycle Approach(LCA)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >LIFE CYCLE APPROACH(LCA)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> LCA.1 Life Cycle Assessment</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: LCA study report with the details of the study conducted and impact analysis</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="lcaStudy">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="lcaStudy"
                                                          type="file"
                                                          name="lcaStudy"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'lcaStudy'                                                                                                                       
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.Details of the measures implemented based on the impact analysis
of LCA study and the benefits achieved. </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="impactAnalysis">
                                                          <MdFileUpload />
                                                          Upload
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="impactAnalysis"
                                                          type="file"
                                                          name="impactAnalysis"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "impactAnalysis"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>                                    
                                    </Row>                                  
                                   
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>



{/* REDUCE CARBON */}

<Tab eventKey="reduceCarbonFootprint" title="Reduce Carbon Footprint(RCF)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >REDUCE CARBON FOOTPRINT(RCF)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> RCF.1 Clean Development Mechanism (CDM)</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: CDM study report with the details of the study conducted and impact analysis</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="rcfCDM">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="rcfCDM"
                                                          type="file"
                                                          name="rcfCDM"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              'rcfCDM'                                                                                                                        
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                         

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >REDUCE CARBON FOOTPRINT(RCF)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> RCF.2 GHG Accounting</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Provide the GHG Accounting report with carbon credits data</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="rcfGHG">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="rcfGHG"
                                                          type="file"
                                                          name="rcfGHG"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,  
                                                              'rcfGHG'                                                                                                                      
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                   

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                  
                                     
                                   
                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>



{/* PRODUCT STEWARDSHIP */}

<Tab eventKey="productStewardships" title="Product Stewardship(PS)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >PRODUCT STEWARDSHIP(PS)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> PS.1 Product Safety Awareness</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Details of the stake holders' specific awareness programmes about
the Green Products, its features and their roles to reap the intended
benefits</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="psProductsafety">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="psProductsafety"
                                                          type="file"
                                                          name="psProductsafety"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              'psProductsafety'                                                                                                                      
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>
                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >PRODUCT STEWARDSHIP(PS)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> PS.2 Quality Management System</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Details of in place to over see the quality of the product during
distribution up to the user end.
</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="psQualityManage">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="psQualityManage"
                                                          type="file"
                                                          name="psQualityManage"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,   
                                                              'psQualityManage'                                                                                                                     
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                          

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                    <Row>
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                      <h2 >PRODUCT STEWARDSHIP(PS)</h2>     
                                      <div className="property-maindoc-upload p-3 fw-600">                                   
                                      <h3 className="ps-0"><span className="c5"> PS.3 Extended Producer Responsibility(EPR)</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                      <p>1: Details of the mechanism in place for product take back and
Quantity of reduction in product take back</p>     
                                                          <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="psEPR">
                                                            <MdFileUpload />
                                                           { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                          </label>
                                                          <input
                                                            className="fileInput"
                                                            style={{
                                                              display: "none",
                                                            }}
                                                            id="psEPR"
                                                            type="file"
                                                            name="psEPR"
                                                            onChange={(e) =>
                                                              this.handleDocUpload(
                                                                e,  
                                                                "psEPR"                                                                                                                      
                                                              )
                                                            }
                                                          /> 
  
  {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                       <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                       : 'Not Uploaded'}  
  
  <div
                                                              style={{
                                                                height: 2,
                                                                marginTop:10,                                                             
                                                                width:
                                                                  this.state
                                                                    .sustainabilityPDPVisionProgressWidth,
                                                                backgroundColor:
                                                                  "rgb(141 233 146)",
                                                                  borderRadius:30,
  
                                                              }}
                                                            ></div> 
                                                        </div> 
  
                                     </Col>                                    
                                      </Row>                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>



{/* EMERGING TECH */}


<Tab eventKey="emergingTechnology" title="Emerging Technology(ET)">
 <Col className="position-relative">
                                <div className="shd-1 py-3">
                                  <Col>                                   


                                    <Row> 
                                    <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >EMERGING TECHNOLOGY(ET)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> ET.1 Promote Environmental Awareness & Sustainability</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Submit the Environmental Sustainability awareness activities and reports</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="etPromoteEnv">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="etPromoteEnv"
                                                          type="file"
                                                          name="etPromoteEnv"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              "etPromoteEnv"                                                                                                                       
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                                                               

                                                      </div>

                                                    

                                     </Col>
                                     <Col className="mt-3 DetailPropertyList pt-4">
                                      
                                    <h2 >EMERGING TECHNOLOGY(ET)</h2>     
                                    <div className="property-maindoc-upload p-3 fw-600">                                   
                                    <h3 className="ps-0"><span className="c5"> ET.2 Innovation in Product Design & Technology</span> <span className={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'uploadStatusAlertYes' : 'uploadStatusAlertNo'}></span> { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? <FiCheck className="fs-22 fw-600 c2" /> : <FiAlertTriangle  className="fs-22 fw-600 c9" />} </h3> 
                                    <p>1: Details of the innovative measures highlighting the Intent and the measured Impacts</p>     
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="etInnovation">
                                                          <MdFileUpload />
                                                         { this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl? 'Upload New' : 'Upload'}
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="etInnovation"
                                                          type="file"
                                                          name="etInnovation"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e, 
                                                              "etInnovation"                                                                                                                       
                                                            )
                                                          }
                                                        /> 

{this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  

<div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPVisionProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>

<hr/>
                                                <p>2.Copy of the certificates for the details of Eco-labels & Awards
obtained </p>   
                                                        <label className="cursor-pointer fs-18 fw-600 uploadlabel" htmlFor="etCertificate">
                                                          <MdFileUpload />
                                                          Upload
                                                        </label>
                                                        <input
                                                          className="fileInput"
                                                          style={{
                                                            display: "none",
                                                          }}
                                                          id="etCertificate"
                                                          type="file"
                                                          name="etCertificate"
                                                          onChange={(e) =>
                                                            this.handleDocUpload(
                                                              e,
                                                              "etCertificate"                                                             
                                                            )
                                                          }
                                                        /> 

                                                         {this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?
                                                     <a className="cursor-pointer" target="_blank" href={ this.state.productData?.productDesignAndPerformance?.sustainabilityPolicyStatement?.visionStatementDoc?.secureUrl}><span style={{fontSize:14,borderStyle:'solid',paddingLeft:20,paddingRight:20, fontWeight:600,marginLeft:10,borderRadius:30,borderWidth:0,borderColor:'#abc7ac',display:'inline-block'}}><MdFileDownload  />Download/View</span></a>
                                                     : 'Not Uploaded'}  


                                                          <div
                                                            style={{
                                                              height: 2,
                                                              marginTop:10,                                                             
                                                              width:
                                                                this.state
                                                                  .sustainabilityPDPResourceProgressWidth,
                                                              backgroundColor:
                                                                "rgb(141 233 146)",
                                                                borderRadius:30,

                                                            }}
                                                          ></div>                                                                                                                    

                                                      </div>

                                                    

                                     </Col>
                                    </Row>
                                   
                                     
                                   
                                   
                                  
                                    <Row>
                                      <Col>


                                    </Col>
                                    </Row> 
                                  
                                  </Col>
                                  
                                </div>
                              </Col>


</Tab>






</Tabs>
                           
                          </Row>
                           ) : (
                            <div className="content-spinner">
                              <Loader
                                type="spinner-default"
                                bgColor={"#0A989C"}
                                title={""}
                                color={"#0A989C"}
                                size={60}
                              />
                            </div>
                          )}

                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { BiCart } from "react-icons/bi";

class PrimeMembershipCreateOrder extends Component {
  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
        serviceId:this.props.match.params.serviceid,
        specialistId:this.props.match.params.specialistid,
        // paymentId:this.props.match.params.paymentid,
        paymentId:'',

        orderResponseData:'',
        serviceInCart:''
      }
  }

  componentDidMount() {


    //this.getCategories();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
document.body.appendChild(script);
  }



  loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}


async displayRazorpay() {
    var self=this
    const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    await this.createOrder().then((res)=>
    {
    
   

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }


    console.log('RESULT', this.state.orderResponseData)

    // const { amount, id: order_id, currency } = res;

    var orderData={
        amount:this.state.orderResponseData.order.amount ,
        id:this.state.orderResponseData.order.id,
        currency:this.state.orderResponseData.order.currency
    }

    const options = {
        key: "rzp_test_QgTBZ5pT5Z8OFl", // Enter the Key ID generated from the Dashboard
        amount: orderData.amount.toString(),
        currency: orderData.currency,
        name: "Beyond smart Cities",
        description: "Book Order for a Specialist",
        // image: { logo },
        order_id: orderData.id,
        modal: {
            "ondismiss": function(){
                self.props.history.push('/manufacturer-membership-payment-failed?role=manufacturer')  
            }
        },
        handler: async function (response) {
          console.log('pay',response)
            const data = {
                order_id: orderData.id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            } 

            const token = await JSON.parse(localStorage.getItem("token"));          
            await axios
              .put(API_URL + "payment/bookService/confirmPayment", data, {
                headers: {
                  auth: token,
                  Accept: "application/json",
                },
              }).then(
                function (response) {
                  console.log('PAYMENT',response);
                  //handle success
      console.log(response)
                  if (response.data.success === true) {                 
                
                   
                      self.setState({
                        submitLoading: false,
                        isdataSubmitted: true,
                        spinner: false,    
                        paymentId:response.data.paymentInfo._id                  
                      });                      

                      toast(response.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });

self.createOrderforSpecialist()
                   
                  } else {
                    self.setState({
                      errorMessage: response.data.error,
                      submitLoading: false,
                      spinner: false,
                    });
                    toast("Thers is an Error in the input fields. Please check!", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    self.props.history.push('/payment-failed?role=specialist-customer')

                  }
                },
                (error) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  // console.log(message)
                  self.setState({
                    errorMessage: message,
                    submitLoading: false,
                    spinner: false,
                  });
                  toast("Thers is an Error in the input fields. Please check!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              );
      
           

            
        },
        prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
})
}



  async createOrder() {   

     this.setState({ spinner: true });

      console.log("DATREAL", this.state.data);
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

    //   "amount" : "",
    //   "receipt" : "",
    //   "serviceId" : "",
    //   "specialistId" : "",
    //   "packageDetails" : {
    //       "name" : "",
    //       "featuresList" : ["",""],
    //       "price" : ""

      let data={     
            amount:  9999 + '00', 
            productId : this.state.specialistId,
            serviceId : this.state.serviceId,          
            receipt: this.state.paymentId,           
        packageDetails:await JSON.parse(localStorage.getItem("packageDetails")), 
      }
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .post(API_URL + "payment/product/regFee/createOrder", data, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log('CREATE ORDER',response);
            //handle success
console.log(response)
            if (response.data.success === true) {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                  orderResponseData:response.data
                });
              
             
             
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } 






    async createOrderforSpecialist() {   

      this.setState({ spinner: true });
  
       var self = this;
       self.setState({
         submitLoading: true,
         errorMessage: "",
       });
  
   
  
       let data={ 
             specialistId : this.state.specialistId,
             serviceId : this.state.serviceId,
             paymentId : this.state.paymentId,                
       }
       const token = await JSON.parse(localStorage.getItem("token"));
       // Post the form, just make sure to set the 'Content-Type' header

       console.log('SENDING DATA', data)
       await axios
         .post(API_URL + "order/create", data, {
           headers: {
             auth: token,
             Accept: "application/json",
           },
         })
  
         .then(
           function (response) {
             console.log('SPEC CREATE ORDER',response);
             //handle success
  console.log(response)
             if (response.data.success === true) {
             

              
                 self.setState({
                   submitLoading: false,
                   isdataSubmitted: true,
                   spinner: false,
                   orderResponseData:response.data
                 });
                 toast("Order Placed...", {
                   position: toast.POSITION.BOTTOM_RIGHT,
                 });

                 setTimeout(() => {
                  self.props.history.push('/payment-confirmation?role=specialist-customer')

                 }, 2000);

              
              
             } else {
               self.setState({
                 errorMessage: response.data.error,
                 submitLoading: false,
                 spinner: false,
               });
               toast("Problem Confirming an Order!", {
                 position: toast.POSITION.BOTTOM_RIGHT,
               });
             }
           },
           (error) => {
             const message =
               (error.response &&
                 error.response.data &&
                 error.response.data.message) ||
               error.message ||
               error.toString();
             // console.log(message)
             self.setState({
               errorMessage: message,
               submitLoading: false,
               spinner: false,
             });
             toast("Problem Confirming an Order!", {
               position: toast.POSITION.BOTTOM_RIGHT,
             });
           }
         );
     } 
   
  
  
  render() {
    const { cartItems } = this.props;
    return (
      <div className="page-container create-order-bg">
        <div className="page-inner create-order-bg">
          <Container fluid>
          <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Order</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
            </Row>

            

            <Row class="mb-4 justify-content-center">
            <h2 className="text-center">Confirm your Order</h2>
                         <div class="col-md-6 offset-md-3">                            
                                 <div className="package-outer shd-1 bdr-rad-4">
                                     <ul className="tableList packList">
                                       <h2 className="text-center fs-32"><BiCart /></h2>
                                       <h3 className="mt-3 bg-none bdr-none fw-900">Lifetime Manufacturer Subscription</h3>  
                                       <hr />
                                         <h3 className="bg-none bdr-none">Package selected: <span className="fw-900">Premium</span></h3>
                                         <h3 className="mt-1 bg-none bdr-none fw-900">Rs. 9,999</h3>
                                         <h3 onClick={()=>this.displayRazorpay()} className="basic mt-3 cursor-pointer">Confirm and Proceed to Payment</h3>
                                    
                                     </ul>
                                     <div className="text-center">
                                     <img src="/assets/images/payment-banner.png" className="payment-banner mx-auto" />
                                       </div>
                                       <div className="text-center">
                                         <h3 onClick={()=>this.props.history.goBack()} style={{fontStyle:'italic',fontSize:13,marginTop:20,cursor:'pointer',color:'red'}}>Cancel Order</h3>
                                         </div>
                                 </div> 
                         </div>
                        
                         
               </Row>              
               <Row>
               <div class="slider-content">
                               <h2 class="section-title p-tb-20 ">Payment Terms and Conditions</h2>
                               <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                              
                             
                             </div>

               </Row>

          </Container>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  const { cartItems } = state.cart;
  console.log(cartItems, isLoggedIn,user)
  return {
    user,
    isLoggedIn,
    cartItems
  };
}

export default connect(mapStateToProps)(PrimeMembershipCreateOrder);
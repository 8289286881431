import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, Link,withRouter } from "react-router-dom";
import { BiUserCircle, BiAperture, BiCart, BiCertification, BiChat, BiCog, BiHomeAlt, BiEnvelope, BiSupport } from "react-icons/bi";
import {HiAcademicCap, HiGlobeAlt, HiOutlineBriefcase, HiOutlineCog, HiOutlineDocumentText, HiOutlineSwitchHorizontal, HiShieldCheck} from "react-icons/hi"
import { Container, Row, Col, Form, Button, Image,Breadcrumb, Spinner } from "react-bootstrap";

import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
class FormTopbar extends Component {

  constructor(props) {
    super(props);


    this.state = {
      showModal: false,
      userData: JSON.parse(localStorage.getItem("user")),
    };
    
  }

 handleOpen = () =>{
this.setState({showModal:true})
 } 


 countArray(arr){
   return arr.length > 1 ? true: false
       
 }

 handleClose = () =>{
  this.setState({showModal:false})
   } 

  
  render() {
   
    return (
      <>
        <ul className="topbarList">
        
          <li className={this.props.step==1? "activeTab pulse" : 'nonactiveTab'}>
            <h2 className="step-number-holder">1</h2>
            <Link to="/dashboard">Basic Details</Link>
          </li>
          <li className={this.props.step==2? "activeTab pulse" : 'nonactiveTab'}>
          <h2 className="step-number-holder">2</h2>
            <Link to="/profile">Product Images</Link>
          </li>

          <li className={this.props.step==3? "activeTab pulse" : 'nonactiveTab'}>
          <h2 className="step-number-holder">3</h2>
            <Link to="/service-lists">Documents</Link>
          </li>          


          <li className={this.props.step==4? "activeTab pulse" : 'nonactiveTab'}>
          <h2 className="step-number-holder">4</h2>
            <Link to="/property-lists">Confirmation</Link>
          </li>        
         

        </ul>

       
      </>
    );
  }
}



export default withRouter(FormTopbar)



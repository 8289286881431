import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { HiOutlineMail,HiOutlinePhone,HiOutlineUserCircle } from "react-icons/hi";

class Onboarding extends Component {

  render() {
    const { user: currentUser } = this.props;
    console.log('USER in Profile',currentUser)

    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (

<div>
      <div className="content-area topadjust px-4 bg-4">
      <div className="container-fluid">
          <nav aria-label="breadcrumb my-2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
            <Link to="/"> Home </Link>
            </li>
            <li class="breadcrumb-item">
            <Link to="/"> Dashboard</Link>
            </li>                    
          </ol>
          </nav>    
     
          <div className="row align-items-center">
          <div className="col-md-12">
          <h2>Welcome Back! {currentUser.firstName}</h2>
            </div>
            </div>
     
        <div className="row align-items-center mt-3">

        <div className="col-sm-12 col-md-4">
            <div className="bdr-4 p-3 min-h-200 align-items-center bg-1 bdr-rad-4 shd-1 mb-2">
              <h2 className="fw-700 c3 fs-26">My Profile</h2>
               <p className="c4 m-1"><HiOutlineUserCircle/>&nbsp;{currentUser.firstName}&nbsp;{currentUser.lastName}</p>
              <p className="c4 m-1"><HiOutlineMail/>&nbsp;{currentUser.email}</p> 
              <p className="c4 m-1"><HiOutlinePhone/>&nbsp;{currentUser.phoneNumber}</p>              
              <Link to="/dashboard" class="btn btn-primary">
              Edit Profile
              </Link>
            </div>
          </div>


          <div className="col-sm-12 col-md-4 align-self-center">
            <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
              <h2 className="fw-700 c3 fs-26">Find Green Build Services</h2>
              <p className="c4">
                Work with the largest network of independent professionals and
                get things done from quick turnarounds to big transformations.
              </p>
              <Link to="/login" class="btn btn-primary-trans">
               View Professionals
              </Link>
            </div>
          </div>

          <div className="col-sm-12 col-md-4">
            <div className="bdr-5 bg-5 p-3 min-h-200 align-items-center bdr-rad-4 shd-1 mb-2">
              <h2 className="fw-700 c3 fs-26">Get Green Build Ratings</h2>
              <p className="c4">
              Going Beyond the Regulatory Equations. Submit your property and get certifications and ratings. 
              </p>
              <Link to="/submit-property" class="btn btn-primary-trans">
              Submit your Property Now
              </Link>
            </div>
          </div>
         
        </div>




        <div className="row my-5">

 

          <div className="col-sm-12 col-md-6">
            <div className="bdr-2 p-3 min-h-200 align-items-center bg-2 bdr-rad-4 m-2">
              <h2 className="fw-700 c1 fs-26">Are you a Service Provider?</h2>
              <p className="c1">
                Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
              </p>
              <Link to="/apply-specialist" class="btn btn-primary-trans">
               Apply Now
              </Link>
            </div>
          </div>


          <div className="col-sm-12 col-md-6">
            <div className="bdr-3 p-3 min-h-200 align-items-center bg-3 bdr-rad-4 m-2">
              <h2 className="fw-700 c1 fs-26">Are you a Product Manufacturer?</h2>
              <p className="c1">
                Sign up and Meet clients you’re excited to work with and take your career or business to new heights.
              </p>
              <Link to="/apply-manufacturer" class="btn btn-primary-trans">
               Get SmartPro Rating and Showcase your Products
              </Link>
            </div>
          </div>      

        
        </div>


        <header className="jumbotron">
          <h3>
            {/* <strong>{currentUser.username}</strong> Profile */}
           
          </h3>
        </header>
        {/* <p>
          <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
          {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
        </p>
        <p>
          <strong>Id:</strong> {currentUser.id}
        </p>
        <p>
          <strong>Email:</strong> {currentUser.email}
        </p>
        <strong>Authorities:</strong>
        <ul>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
        </ul> */}
      </div>
      </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Onboarding);
export const countries= [ 
    {name: 'India', code: 'India'}, 
    {name: 'United Kingdom', code: 'United Kingdom'},   
    {name: 'United States of America', code: 'United States of America'},  
    {name: 'United Arab Emirates', code: 'United Arab Emirates'}, 
    {name: 'Canada', code: 'Canada'},   
    {name: 'Australia', code: 'Australia'},  
    {name: 'China', code: 'China'},   
    {name: 'Denmark', code: 'Denmark'}, 
   
  ]
import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, Image,Breadcrumb, Spinner } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { BiUserCircle, BiAperture, BiCart, BiCertification, BiChat, BiCog, BiUser, BiEnvelope, BiPhone, BiBadge, BiCalendar, BiPencil, BiCalendarStar, BiUserX, BiChair, BiLocationPlus, BiBuilding, BiBuildings, BiStar, BiListMinus, BiComment } from "react-icons/bi";
import {BsStarFill, BsStarHalf} from "react-icons/bs";
import {FiAlertTriangle, FiCheck, FiCheckCircle, FiHelpCircle} from "react-icons/fi"
import validator from "validator";
import axios from "axios";
import { API_URL } from "../../../config";
import Loader from "react-js-loader";

import moment from 'moment';
import DashboardSidebar from '../../DashboardSidebar';

export default class PropertyLists extends Component {

    constructor(props) {
        super(props);
        this.addNewProperty = this.addNewProperty.bind(this);  

             
    
        this.state = {

          propertiesList:[],
          dataLoading:false,
          notice:false,
          userData: JSON.parse(localStorage.getItem('user')),
            dobError:'',
            genderError:'',
            professionError:'',
            streetError:'',
            stateError:'',
            cityError:'',
            countryError:'',
            pincodeError:'',

          data: {
            profile: {
              dob: "",
              gender: "",
              profession: "",
              address: {
                street: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
              },
            },
          },
        };
}
      componentDidMount() {
    
        if (this.props.isLoggedIn) {
          this.props.history.push("/dashboard");             
        }

        if(this.state.userData.isEmailVerified){
          this.setState({notice:false})
        }
        else{
          setTimeout(() => {
            this.setState({notice:true})
          }, 3000);
          

        }

        this.getPropertyList()
        
      }

      editProfile=()=>{
        this.props.history.push("/profile-edit"); 

      }

dismissNotice=()=>{
  
    this.setState({notice:!this.state.notice})
  }
 
      
    
  addNewProperty=()=>{
this.props.history.push('/submit-property')
}
   validateField(fieldName)  {
        if (fieldName === "dob") {         

        }
    }


async getPropertyList() {     
      var self = this;     
      self.setState({
        step: 10,
        submitLoading: true,
        errorMessage: "",
        dataLoading:true
      });
  
      console.log("DATA", self.state.data);
      const token = await JSON.parse(localStorage.getItem("token"));     
      await axios
        .get(API_URL + "property/list", {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })
  
        .then(
          function (response) {
            console.log(response);           
  
            if (response.data.success === true) {
              self.setState({
                propertiesList:response.data.propertiesList
              })
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                dataLoading:false
              });  
              
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                dataLoading:false
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              dataLoading:false
            });
          }         
        );
    }    



    render() {   
      
      const userData=this.state.userData
      const createdDate=moment(userData.createdAt).format('LL');
        return (
          <div>
            <section className="lightgrey-bg user-area log-in-area topadjust">
              <div className="container-fluid">
                
                <div className="user-form-content">
                  
                  <div className="row">             

                    <div className="col-md-12">
                      <div className="form-card mt-3">                    
                     
                        <Container fluid>
                        <Breadcrumb>
  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
  <Breadcrumb.Item href="">
    Dashboard
  </Breadcrumb.Item>
  <Breadcrumb.Item active>Property</Breadcrumb.Item>
</Breadcrumb>

                          {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                          <Row>
                            <Col
                              xs
                              lg="2"
                              className="dashboard-sidebar"
                            >
                              <DashboardSidebar />
                            </Col>

                            <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                                  <Row>
                                    <Col>
                                      <div className="dashboard-content-title">
                                        My Properties
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="dashboard-content-action float-end">
                                        <Button
                                          onClick={this.addNewProperty}
                                          type="submit"
                                          onKeyPress={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        > {this.state.spinner ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <span className="d-inline p-2">Add New Property</span>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              <Row>
                                <Col className="position-relative">
                               

                          
                          
                            <ul class="tableList">
                            <li className="listHeader shd-1">
                                <Row className="align-items-center">
                                    <Col md={1}>                                   
                                    </Col>
                                    <Col md={4}>
                                    <div className="property-title">Property Name</div>                                                                 
                                    </Col>
                                    <Col md={2}>
                                    <div className="property-status">Documents</div>
                                    </Col>
                                    <Col md={2}>
                                    <div className="property-status">Application Status</div>
                                    </Col>
                                    <Col>
                                    <div className="property-rating">
                                   Rating
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className="property-view">
                                    <Link>Action</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                              </li>  


{this.state.dataLoading ? (
  <div className="content-spinner">
            <Loader           
              type="spinner-default"
              bgColor={"#0A989C"}
              title={""}
              color={"#0A989C"}
              size={60}
            />
            </div>
          ) : null}


{this.state.propertiesList && this.state.propertiesList.length?
(
this.state.propertiesList.map((item)=>(
                              <li className="shd-1 py-3">
                                <Row className="align-items-center">
                                    <Col md={1}>
                                    <BiBuildings className="ms-2 fs-2"/>
                                    </Col>
                                    <Col md={4}>
                                    <div className="property-title fw-700 "> <Link className='c16' to={`/property-detail/${item._id}`}>{item.projectID? item.projectID : ''}</Link></div>
                                    <div className="property-title fw-700"> <Link to={`/property-detail/${item._id}`}>{item.projectName? item.projectName : moment(item.createdAt).format('LL')}</Link></div>
                                    <div className="property-added-date fs-12">Applied On: {moment(item.createdAt).format('LL')}</div>                                   
                                    </Col>
                                    <Col md={2}>
                                    {!item?.sitePlanFile || !item?.floorPlanFile || !item?.drawingsOfProject || !item?.elevationFile || !item.mechanicalPlanFile ?
                                    <div class="bg-green ms-2 bdr-rad-4 c1 px-3 py-1 fs-12 d-inline-block">Upload</div>
                                    :
                                    <div className="fw-700 c3"><FiCheck className="me-1" />Submitted</div> 
                                     }
                                    </Col>
                                    <Col md={2}>
                                    <div className= {item?.status==="Approved" ? "property-status fw-700 c2" : "property-status fw-700 c9"}>
                                    {item?.status==="Approved" ?
                                    <>
                                    <FiCheckCircle className="me-1" />{item?.status}
                                    </>
                                    :
                                    <>
                                    <FiAlertTriangle className="me-1" />{item?.status}
                                    </>
                                    }
                                    </div>
                                   

                                    </Col>
                                    <Col>
                                    <div className="property-rating">
                                    <BsStarFill className="c2" />
                                   <BsStarFill className="c2" />
                                   <BsStarFill className="c2" />
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className="property-view">
                                    <Link to={`/property-detail/${item._id}`}>View</Link>
                                    </div>
                                    </Col>
                                    </Row> 

                                    {item?.adminComments?
                                    <Row >
                                    <Col md={1}>
                                    <p className="ms-2 fs-12 mt-2">Admin Comments</p>
                                    </Col>
                                      <Col >    
                                      <div className='bg-7 mt-2 bdr-rad-4'>                               
                                      <p className='p-2 c19'>{item.adminComments}</p>    
                                      </div>                                     
                                      </Col>
                                    </Row>
                                    : ''}

                              </li>  
))
):


(
  !this.state.dataLoading?
  <li className="text-center p-3 bdr-none">
     <BiListMinus className="ms-2 fs-48 c5 text-center"/>
<h3 className="text-center mx-4 mt-4 px-2 bg-none bdr-none">Oops! You have not added any properties yet. If you are looking to get a green build certification, Submit your property now.</h3>
<p className="text-center">You submitted properties will be listed here after approvals.</p>

    </li>
    :''
)
}




                              
                           
                            </ul>

                            </Col>
                           
                          
                            </Row>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
    }
}

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const MaterialAndResources = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                    <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>MATERIALS AND RESOURCES(MR)</h2>
                          <Row>
                    <p className="mt-2">
                          The Materials category is concerned with
the conservation of natural resources and
the use or reuse of materials and structure
to have the least environmental impact. 
                          </p>
                      </Row>
                          <img
                            className="img-fluid pt-3"
                            src="assets/images/materialresources.png"
                          ></img>
                        
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                15 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-2 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                                
                                label="Site Waste Management Plan"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Site Waste Management Plan"
                                id={`inline-checkbox-1`}
                              checked= {values?.materialResources?.includes('Site Waste Management Plan') ? true : false}
                              />

                              <Form.Check                                
                                label="Recycled Materials"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Recycled Materials"
                                id={`inline-checkbox-25`}
                              checked= {values?.materialResources?.includes('Recycled Materials') ? true : false}
                              />

                              <Form.Check                                
                                label="Local Materials"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Local Materials"
                                id={`inline-checkbox-38`}
                              checked= {values?.materialResources?.includes('Local Materials') ? true : false}
                              />

                             
                            </Col>
                            <Col>
                             
                            <Form.Check
                               
                                label="Recycling Collection, Processing & Storage"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Recycling Collection, Processing & Storage"
                                id={`inline-checkbox-44`}
              checked= {values?.materialResources?.includes('Recycling Collection, Processing & Storage') ? true : false}
              />

                              <Form.Check                                
                                label="Reused Building Materials"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Reused Building Materials"
                                id={`inline-checkbox-45`}
                              checked= {values?.materialResources?.includes('Reused Building Materials') ? true : false}
                              />
                              
                            


                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>

                   

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/materialresources-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

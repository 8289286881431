import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class BeyondBusiness extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="row">
            <div className="col-md-6 p-0 ">
              <div className="beyond-text-section2 pro ps-2 ps-md-5">
                <h2 className="heroText1 c-3">
                <span className="fw-300">Boost your</span> <br />
                  Business with <br />
                  Specialist Pro
                </h2>
                <p className="heroText2">
                  Set your team up for success with freelance talent and
                  advanced tools for everyday projects of any scope.
                </p>

                <div class="ghostBtn mt-3 ms-3">
                  <a href="/dashboard">Create Account</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 hire-img-layout">
              <img src="assets/images/beyond-bussines.jpg" alt="img" />
            </div>
          </div>



          <div className="row">            
            <div className="col-md-6 p-0 hire-img-layout">
              <img src="assets/images/business.png" alt="img" />
            </div>
            <div className="col-md-6 p-0 ">
              <div className="beyond-text-section2 pro ps-2 ps-md-5">
                <h2 className="heroText1 c-3">
                <span className="fw-300"> Promoting <br />
                    Sustainability by </span><br />
                    Marketing <br />
                    Green Products
                </h2>
                <p className="heroText2">
                Ready to join the sustainable movement?
                </p>

                <div class="ghostBtn mt-3 ms-3">
                  <a href="/dashboard">Create Smart Pro Account</a>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-6 p-0 ">
              <div className="beyond-text-section2 pro ps-2 ps-md-5">
                <h2 className="heroText1 c-3">
                <span className="fw-300"> Boost your business
                    <br /> with</span> BSAS Property
                    <br /> Rating System
                </h2>
                <p className="heroText2">
                By promoting your brand with eco-f riendly promotional
                  products, your company is taking part in a revolutionary
                  movement that defines this generation. Decrease your company's
                  ecological footprint and increase brand awareness with
                  sustainable promotional products you can feel good about
                  giving away.Sustainability may have started out as a consumer
                  trend. “Going green” was once the cool thing to do. Now - it’s
                  the expected thing to do.
                </p>

                <div class="ghostBtn mt-3 ms-3 pb-4">
                  <a href="/dashboard">Create Account</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 hire-img-layout position-relative">
                <div className="image-overlay-content-right heroText2">
                BSAS is the next generation standard for green building design, construction, operations and performance
                </div>
              <img src="assets/images/beyond-business-4.jpeg" alt="img" />
            </div>
          </div>   
            
           
           
        </div>
      </div>
    );
  }
}

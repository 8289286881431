import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class HowtoHire extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-md-6 hire px-0">
                <div className="hire-text">
                  <h1 className="heroText1 pt-2">Assess the Skills Needed for Your Ideal Job</h1>
                  <p className="heroText2">
                    Find Specialists and run projects your way at the world’s
                    work green technology marketplace
                  </p>
                  <div class="solidBtnRed mt-3 mb-3"><a class="fw-600" href="category/sustainability-technology">Find Specialists</a></div>
                 
                  <p>
                    {" "}
                    Looking to get hired?.
                    <Link to="/dashboard" className="text-color-orange">
                      Sign up here
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-md-6 px-0 hire-img-layout">
                <img src="assets/images/hire-1.jpg" alt="img" />
              </div>
            </div>

            <div className="row py-5">
              <div className="col-md-12 text-center">
                <h2 className="heroText1 pt-2">
                  Let’s get to work
                </h2>
                <p className="heroText2 pb-4 ">
                  Build relationships and create your own Virtual Talent Base
                  for quick project turnarounds or big transformations
                </p>
              </div>
            </div>
            {/* <div className="row mb-4">
                     <div className="col-md-6 hire-img-layout">
                    
                         <img src="assets/images/hire-2.jpg" alt="img"/>
                    
                   
               </div>
                     
               <div className="col-md-6 hire">
                         <div className="hire-text-section2">
                              <h2>Post a job and hire a Specialist</h2>
                            <p>Connect with specialist that gets you with Talent MarketplaceFind f reelancers and run projects your way at the world’s work marketplace</p>
                            <p>Post your work on the world’s work marketplace and wait for the proposals to flood in f rom talented people around the world.</p>
                            <p>Our advanced algorithms help you shortlist candidates who are the best f it. And you can check prof iles, portfolios, and reviews before you give someone the green light.</p>
                         
                         <button><a href="">Find Specialist </a></button>
                        
                         </div>
                        
                  
               </div>
                 
           </div> */}
            <div className="row">
            <div className="col-md-6 hire-img-layout px-0">
                <img src="assets/images/hire3.jpg" alt="img" />
              </div>
              <div className="col-md-6 hire px-0 py-4">
                <div className="ps-5 pt-4" >
                  <h2 className="heroText1 pt-2">SmartPro Rated<br/> Products</h2>
                  <p className="heroText2 pt-1">
                    SmartPro is a product certification which helps an
                    environmentally conscious customer to make an informed
                    choice to buy eco-f riendly products.
                  </p>
                  <p className="heroText2 pt-1">
                    SmartPro is a mark of guarantee that the product which bears
                    the SmartPro label is environment f riendly throughout its
                    life cycle.
                  </p>
                  <p className="heroText2 pt-1">
                    SmartPro ultimately empowers a customer with the knowledge
                    of the product and steer towards sustainable products.
                  </p>
                  <div class="solidBtnRed mt-3 mb-3"><a class="fw-600" href="/smartpro-products">Find Smart Pro Products</a></div>

                 
                </div>
              </div>
             
            </div>
            <div className="row">
             

              <div className="col-md-6 hire">
                <div className="hire-text">
                  <h2 className="heroText1 pt-2 pt-4 pb-4">
                    Our buildings can create <br/>  a healthier, more sustainable
                    future.
                  </h2>
                  <p className="heroText2 pt-2 pb-4">
                    BSAS(Beyond Sustainability Assessment System)world leading
                    green building project and performance management system. It
                    delivers a comprehensive framework for green building
                    design, construction, operations and performance.
                  </p>
                  <div class="solidBtnRed mt-3 mb-3"><a class="fw-600" href="/dashboard">Register your Property (Its Free!)</a></div>

                </div>
              </div>
              <div className="col-md-6 px-0 hire-img-layout">
                <img src="assets/images/hire4.jpeg" alt="img" />
              </div>
            </div>
            {/* <div className="row mb-4">
                   
                      <div className="col-md-6 hire">
                         <div className="hire-text-section2">
                              <h2>All in one place </h2>
                            <p>Once you sign in you’ll get your own online space to manage your project. You’re safe with usYou get what you pay for. And we can prove it.</p>
                         <p>Use it to securely send and receive files, give real-time feedback and make payments.</p>
                         
                         <button><a href="">Find SmartPro Products </a></button>
                         <br/>
                         <br/>
                         
                        <p> Looking to get hired?.<Link to="/signin" className="text-color-orange" >Sign up here</Link>
                                </p> 
                         </div>
                        
                  
               </div>
                     <div className="col-md-6 hire-img-layout">
                    
                         <img src="assets/images/hire3.jpeg" alt="img" />
                    
                   
               </div>
                     
            
                 
           </div> */}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row,Modal, Button } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { BiCart } from "react-icons/bi";
import { cashfreeSandbox, cashfreeProd } from 'cashfree-dropjs';

const dropinComponents = [
  {
    name: 'Order Details',
    id: 'order-details'
  },
  {
    name: 'Card',
    id: 'card'
  },
  {
    name: 'UPI',
    id: 'upi'
  },
  {
    name: 'Wallets',
    id: 'app'
  },
  {
    name: 'Netbanking',
    id: 'netbanking'
  },
  {
    name: 'Paylater',
    id: 'paylater'
  },
  {
    name: 'Credit Card EMI',
    id: 'credicardemi'
  }
];

const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

  
class CreateOrder extends Component { 

  constructor(props) {
    super(props);
    // this.addNewProperty = this.addNewProperty.bind(this);
    this.state={
        serviceId:this.props.match.params.serviceid,
        specialistId:this.props.match.params.specialistid,
        // paymentId:this.props.match.params.paymentid,
        orderToken:'',
        paymentId:'',

        orderResponseData:'',
        serviceInCart:'',
        checkedState : new Array(dropinComponents.length).fill(false),
        style:'',
        isProd:true,
        components:[],
        displayConfirmBox:true,
        show:false,
        userData: JSON.parse(localStorage.getItem("user")),

        
      }
  }


  handleClose=()=> {
    this.setState({show:false})
  };
  handleShow=()=>{
    this.setState({show:true})
  };

  componentDidMount() {


    //this.getCategories();
    const script = document.createElement("script");
    script.src = "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js";
    script.async = true;
document.body.appendChild(script);
  }



  loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}




 cbs = async(data)=> { 
 
  if (data.order && data.order.status === 'PAID') {
    var self=this   

    // this.setState({
    //   paymentId:data.paymentInfo._id 
    // })
    
    console.log('HERE',data)
    //alert('order is paid. Call api to verify');

    self.createOrderforSpecialist()

    // const data2 = {
    //   orderPayObj: {
    //     order_token: this.state.orderToken,
        // paymentMethod: {
        //   card: {
        //     channel: "link",
        //     card_number: "4111111111111111",
        //     card_holder_name: "Tushar Gupta",
        //     card_expiry_mm: "06",
        //     card_expiry_yy: "22",
        //     card_cvv: "900",
        //   },
        // },
      //},
      // order_id: orderData.id,
      // razorpay_payment_id: response.razorpay_payment_id,
      // razorpayOrderId: response.razorpay_order_id,
      // razorpaySignature: response.razorpay_signature,
   // }; 

//     const token = await JSON.parse(localStorage.getItem("token"));          
//     await axios
//       .put(API_URL + "payment/bookService/confirmPayment", data2, {
//         headers: {
//           auth: token,
//           Accept: "application/json",
//         },
//       }).then(
//         function (response) {
//           console.log('PAYMENT',response);
//           //handle success
// console.log(response)
//           if (response.data.success === true) {                 
        
           
//               self.setState({
//                 submitLoading: false,
//                 isdataSubmitted: true,
//                 spinner: false,    
//                 paymentId:response.data.paymentInfo._id                  
//               });                      

//               toast(response.data.message, {
//                 position: toast.POSITION.BOTTOM_RIGHT,
//               });

//self.createOrderforSpecialist()
           
      //     } else {
      //       self.setState({
      //         errorMessage: response.data.error,
      //         submitLoading: false,
      //         spinner: false,
      //       });
      //       toast("Thers is an Error in the input fields. Please check!", {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //       });
      //       self.props.history.push('/payment-failed?role=specialist-customer')

      //     }
      //   },
      //   (error) => {
      //     const message =
      //       (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //       error.message ||
      //       error.toString();
      //     // console.log(message)
      //     self.setState({
      //       errorMessage: message,
      //       submitLoading: false,
      //       spinner: false,
      //     });
      //     toast("Thers is an Error in the input fields. Please check!", {
      //       position: toast.POSITION.BOTTOM_RIGHT,
      //     });
      //   }
      // );















    ///////////////////////////
    //this.createOrderforSpecialist()

//     await axios
//     .put("https://api.cashfree.com/pg/orders/"+ data.order.orderId, {
//       headers: {
//        // auth: token,
//         Accept: "application/json",
//         'x-client-id':"199300535081f47e43a6e633d3003991",
//         'x-client-secret':"fea530c520e4e342966eb4c49defb1e4d7fcb197",
//         "x-api-version": "2021-05-21",

//       },
//     }).then(
//       function (response) {
//         self.createOrderforSpecialist()
// console.log(response)

     // })

    // self.props.history.push('/payment-confirmation?role=specialist-customer')


  }
}

cbf (data){
  alert(data.order.errorText);
}


async renderDropin(){
  this.setState({
    displayConfirmBox:false,
  })
  var self=this
  await self.createOrder().then((res)=>
    {
      console.log(res)

  if (this.state.orderToken === '') {
   // alert('Order Token is empty');
    return;
  }
  // if (!this.state.components.length) {
  //   alert('No drop in specified');
  //   return;
  // }
  let parent = document.getElementById('drop_in_container');
  parent.innerHTML = '';
  let cashfree;
  if (this.state.isProd) {
    cashfree = new cashfreeProd.Cashfree();
  } else {
    cashfree = new cashfreeSandbox.Cashfree();
  }
  console.log('before Initialisation');
  cashfree.initialiseDropin(parent, {
    orderToken:this.state.orderToken,
    onSuccess: this.cbs,
    onFailure: this.cbf,
    components:['order-details','card','upi'],
    style:this.state.style
  });
  console.log('after Initialisation');
})
}





handleOnChange(position){
  const updatedCheckedState = this.state.checkedState.map((item, index) =>
    index === position ? !item : item
  );
  this.setState({checkedState:updatedCheckedState})
 
  let comp = [];
  updatedCheckedState.forEach((item, index) => {
    if (item) {
      comp.push(dropinComponents[index].id);
    }
  });
  this.setState({
    components:comp
  })  
};


// handleStyleChange(e){

//   setStyle({
//     ...style,
//     [e.target.id]: e.target.value
//   });
// };





async displayRazorpay() {
    var self=this
    const res = await this.loadScript(
        // "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js"
        "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js"        
    );

    if (!res) {
        alert("Cashfree SDK failed to load. Are you online?");
        return;
    }

    await this.createOrder().then((res)=>
    {
    
  

    console.log('RESULT', this.state.orderResponseData)

    // const { amount, id: order_id, currency } = res;

    var orderData={
        amount:this.state.orderResponseData.order.amount ,
        id:this.state.orderResponseData.order.id,
        currency:this.state.orderResponseData.order.currency
    }

    const options = {
        key: "rzp_test_QgTBZ5pT5Z8OFl", // Enter the Key ID generated from the Dashboard
        amount: orderData.amount.toString(),
        currency: orderData.currency,
        name: "Beyond smart Cities",
        description: "Book Order for a Specialist",
        // image: { logo },
        order_id: orderData.id,
        modal: {
            "ondismiss": function(){
                self.props.history.push('/payment-failed?role=specialist-customer')  
            }
        },
        handler: async function (response) {
          console.log('pay',response)
            const data = {
              orderPayObj: {
                order_token: this.state.orderToken,
                paymentMethod: {
                  card: {
                    channel: "link",
                    card_number: "4111111111111111",
                    card_holder_name: "Tushar Gupta",
                    card_expiry_mm: "06",
                    card_expiry_yy: "22",
                    card_cvv: "900",
                  },
                },
              },
              // order_id: orderData.id,
              // razorpay_payment_id: response.razorpay_payment_id,
              // razorpayOrderId: response.razorpay_order_id,
              // razorpaySignature: response.razorpay_signature,
            }; 

            const token = await JSON.parse(localStorage.getItem("token"));          
            await axios
              .put(API_URL + "payment/bookService/confirmPayment", data, {
                headers: {
                  auth: token,
                  Accept: "application/json",
                },
              }).then(
                function (response) {
                  console.log('PAYMENT',response);
                  //handle success
      console.log(response)
                  if (response.data.success === true) {                 
                
                   
                      self.setState({
                        submitLoading: false,
                        isdataSubmitted: true,
                        spinner: false,    
                        paymentId:response.data.paymentInfo._id                  
                      });                      

                      toast(response.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });

self.createOrderforSpecialist()
                   
                  } else {
                    self.setState({
                      errorMessage: response.data.error,
                      submitLoading: false,
                      spinner: false,
                    });
                    toast("Thers is an Error in the input fields. Please check!", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    self.props.history.push('/payment-failed?role=specialist-customer')

                  }
                },
                (error) => {
                  const message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  // console.log(message)
                  self.setState({
                    errorMessage: message,
                    submitLoading: false,
                    spinner: false,
                  });
                  toast("Thers is an Error in the input fields. Please check!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              );
      
           

            
        },
        prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
})
}



  async createOrder() {   

     this.setState({ spinner: true });

      console.log("DATREAL", this.state.data);
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

    //   "amount" : "",
    //   "receipt" : "",
    //   "serviceId" : "",
    //   "specialistId" : "",
    //   "packageDetails" : {
    //       "name" : "",
    //       "featuresList" : ["",""],
    //       "price" : ""

      let data={   
        
        orderObj : {
          orderId : 'ORDER' + Math.floor(Math.random() * 99999),
          orderAmount : this.props.cartItems.cartAmount + ".00",
          orderNote : "",
          customerDetails : {
              customer_id : this.state.specialistId,
              customer_email : this.state.userData?.email,
              customer_phone : this.state.userData?.phoneNumber
              // customer_bank_account_number : "",
              // customer_bank_ifsc : "",
              // customer_bank_code : ""
          },
            //amount:  this.props.cartItems.cartAmount + '00', 
            specialistId : this.state.specialistId,
            serviceId : this.state.serviceId,          
           // receipt: this.state.paymentId,           
        packageDetails:await JSON.parse(localStorage.getItem("packageDetails")), 
      }
    }

    console.log(data)
      const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
        .post(API_URL + "payment/bookService/createOrder", data, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log('CREATE ORDER222',response);
            //handle success
console.log('HERE',response.data.order.order_token)
            if (response.data.success === true) {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                  orderResponseData:response.data,
                  orderToken:response.data.order.order_token
                });

                self.setState({
                  paymentId:response.data.paymentInfo._id 
                })
              
             
             
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } 






    async createOrderforSpecialist() {   

      this.setState({ spinner: true });
  
       var self = this;
       self.setState({
         submitLoading: true,
         errorMessage: "",
       });
  
   
  
       let data={ 
             specialistId : this.state.specialistId,
             serviceId : this.state.serviceId,
             paymentId : this.state.paymentId,                
       }
       const token = await JSON.parse(localStorage.getItem("token"));
       // Post the form, just make sure to set the 'Content-Type' header

       console.log('SENDING DATA', data)
       await axios
         .post(API_URL + "order/create", data, {
           headers: {
             auth: token,
             Accept: "application/json",
           },
         })
  
         .then(
           function (response) {
             console.log('SPEC CREATE ORDER',response);
             

             //handle success
  console.log(response)
             if (response.data.success === true) {
              self.props.history.push('/payment-confirmation?role=specialist-customer')
                 self.setState({
                   submitLoading: false,
                   isdataSubmitted: true,
                   spinner: false,
                   orderResponseData:response.data
                 });
                 toast("Order Placed...", {
                   position: toast.POSITION.BOTTOM_RIGHT,
                 });

                 setTimeout(() => {
                  self.props.history.push('/payment-confirmation?role=specialist-customer')

                 }, 2000);

              
              
             } else {
               self.setState({
                 errorMessage: response.data.error,
                 submitLoading: false,
                 spinner: false,
               });
               toast("Problem Confirming an Order!", {
                 position: toast.POSITION.BOTTOM_RIGHT,
               });
             }
           },
           (error) => {
             const message =
               (error.response &&
                 error.response.data &&
                 error.response.data.message) ||
               error.message ||
               error.toString();
             // console.log(message)
             self.setState({
               errorMessage: message,
               submitLoading: false,
               spinner: false,
             });
             toast("Problem Confirming an Order!", {
               position: toast.POSITION.BOTTOM_RIGHT,
             });
           }
         );
     } 
   
  
  
  render() {
    const { cartItems } = this.props;
    return (
      <div className="page-container create-order-bg">
        <div className="page-inner create-order-bg">
          <Container fluid>
          <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>Order</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
            </Row>

            

            <Row class="mb-4 justify-content-center">
            <h2 className="text-center">Confirm your Order</h2>
                         <div class="col-md-6 offset-md-3">   

         {!this.state.displayConfirmBox &&
         <div
        className="dropin-parent"
        id="drop_in_container"
        style={{ height: '600px' }}
      >       
      </div>
      }



{this.state.displayConfirmBox &&
                                 <div className="package-outer shd-1 bdr-rad-4">                                   
                                     <ul className="tableList packList">
                                       <h2 className="text-center fs-32"><BiCart /></h2>
                                       <h3 className="mt-3 bg-none bdr-none fw-900">{cartItems?.gigTitle}</h3>  
                                       <hr />
                                         <h3 className="bg-none bdr-none">Package selected: <span className="fw-900">{cartItems?.packagename}</span></h3>                                      
                                         <h3 className="mt-1 bg-none bdr-none">Author: {cartItems?.authorName}</h3>                               
                                         <h3 className="mt-1 bg-none bdr-none fw-900">Rs. {cartItems?.cartAmount}</h3>
                                         <h3 onClick={()=>this.renderDropin()} className="basic mt-3 cursor-pointer">Confirm and Proceed to Payment</h3>
                                    
                                     </ul>
                                     <div className="text-center">
                                     <img src="/assets/images/payment-banner.png" className="payment-banner mx-auto" />
                                       </div>
                                       <div className="text-center">
                                         <h3 onClick={()=>this.props.history.goBack()} style={{fontStyle:'italic',fontSize:13,marginTop:20,cursor:'pointer',color:'red'}}>Cancel Order</h3>
                                         </div>
                                 </div> 
 }
                         </div>

                        
                         
               </Row>              
               <Row>

                <Col md={8} className="offset-md-5">

      <Button style={{width:200,marginTop:50,textAlign:'center'}} variant="primary" onClick={this.handleShow}>
        Payment Terms
      </Button>
      </Col>

      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h2 class="section-title p-tb-20 ">Payment Terms and Conditions</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>    <p>Beyond Smart Cities’s Payment Terms Last Updated: April 2022<br/><br/>
The following terms (these “Payment Terms”) govern payments you make or receive through
www.beyondsmartcities.in (the “Site”), operated by Beyond Smart Cities Private Limited (#55,HMR
Layout,Chikabanawar,Bengaluru,Karnataka,India).<br/><br/>
Beyond Smart Cities Private Limited processes card payments from the India.Beyond Smart Cities Private Limited
and Beyond Smart Cities - GreenTechnology Marketplace, are collectively referred hereto as “Beyond Smart
Cities” “we” or “us”.<br/><br/>
Please read these Payment Terms carefully before making or receiving payments through the Site. By making or
receiving payments through the Site, you accept and agree, on behalf of yourself or on behalf of your employer or
any other entity (if applicable), to be bound and abide by these Payment Terms. These Terms are supplemental to
Beyond Smart Cities’s General Terms of Service and Beyond Smart Cities Business Terms of Service, as
applicable (collectively, the “Terms of Service”).<br/><br/>
<b>Key Terms</b><br/><br/>
Beyond Smart Cities Balance is the aggregated amount of your Revenue as a Seller and/or returned payments
from canceled orders as a Buyer and/or your Beyond Smart Cities Business Balance (as defined in the Beyond
Smart Cities Terms of Service).<br/>
Payment Services Provider(s) are service providers that provide payment services to Buyers and Sellers in
connection with the Beyond Smart Cities platform, including with respect to collection of funds from Buyers in
connection with purchases, remittance and withdrawal of funds to Sellers, currency exchange services in
connection with payments and withdrawals in local currencies, and the holding of funds in connection with Beyond
Smart Cities Balances. Revenue is the money that Sellers earn from completed orders and can either withdraw
or use to purchase on Beyond Smart Cities, subject to these Terms.<br/><br/>
<b>Receiving Payments</b><br/>
General<br/><br/>
• Each Service you sell and successfully complete, accredits your account with revenue equal to 80% of
the purchase amount.<br/>
• Beyond Smart Cities accredits Sellers once an order is completed. See the "Orders” section in the Terms
of Service for a definition of a completed order.<br/>
• If an order is canceled (for any reason), the funds paid will be returned to the Buyer’s Beyond Smart
Cities Balance.<br/>
• Sellers are responsible for paying any direct or indirect taxes, including any GST, VAT or income tax,
which may apply to them depending on residency, location or otherwise, under provisions of their
jurisdiction. Sellers represent and warrant that they comply, and will comply at all times, with their
obligations under income tax provisions in their jurisdiction. The price shown on the Service page is
inclusive of all such taxes and charges that may apply to the Sellers.<br/>
• Appointment as Limited Payment Collection Agent: Seller hereby appoints Beyond Smart Cities as
Seller’s limited authorized payment collection agent solely for the purpose of accepting payments (via its
Payment Services Provider, if applicable) from Buyer, and remitting those payments to Seller. Seller
agrees that payment from Buyer to Beyond Smart Cities shall be considered the same as a made directly
to Seller. Buyer’s payment obligation to Seller will be satisfied upon receipt of payment by Beyond Smart
Cities (or its Payment Services Provider, as applicable), and Beyond Smart Cities (via its Payment
Services Provider, as applicable) is responsible for remitting the funds to the Seller in the manner
described in these Payment Terms. In the event that Beyond Smart Cities (via Payment Services
Provider) does not remit any such amounts to Seller, the Seller will have recourse only against Beyond
Smart Cities and not the Buyer directly. Seller agrees that Beyond Smart Cities may describe or otherwise
reflect the terms contained herein in any terms of service, receipts, disclosures, or notices including, but
not limited to, receipts provided to Buyers that Beyond Smart Cities may deem necessary or prudent.
• Beyond Smart Cities partners with Payment Services Providers for purposes of collecting payments from
Buyers, transferring such payments from Buyers to Sellers, and holding funds in connection with Beyond
Smart Cities Balances. All payments services in connection with the withdrawal of funds on the Beyond
Smart Cities platform are performed by Beyond Smart Cities’s Payment Services Providers.<br/><br/>
<b>Withdrawing Revenues</b><br/>
• To withdraw your revenue, you must have an account with at least one of Beyond Smart Cities's Payment
Service Providers for the withdrawal methods listed below. All funds eligible for Withdrawal will be held on
your behalf at an account with Beyond Smart Cities’s Payment Services Provider. All payment services,
including withdrawal services, will be provided by Beyond Smart Cities’s Payment Services Provider.
• Your Beyond Smart Cities profile can be associated with only one account from each Beyond Smart Cities
withdrawal method. A Payment Service Provider withdrawal account can be associated with only one
Beyond Smart Cities profile.<br/>
• Revenues are only made available for withdrawal from the Revenue page following a safety clearance
period of 15 days after the order is marked as complete.<br/>
• Sellers may withdraw their revenues using one of Beyond Smart Cities’s withdrawal options. To withdraw
your available revenue, you must click on the designated withdrawal provider to initiate the withdrawal
process.<br/>
• For security concerns, Beyond Smart Cities may temporarily disable a Seller’s ability to withdraw revenue
to prevent fraudulent or illicit activity. This may come as a result of security issues, improper behavior
reported by other users, or associating multiple Beyond Smart Cities accounts to a single withdrawal
provider.<br/>
• Withdrawals can only be made in the amount available to you.<br/>
• Withdrawal fees vary depending on the withdrawal method.<br/>
• Withdrawals are final and cannot be undone. We will not be able to reverse this process once it has
begun.<br/>
• In certain cases, Sellers may withdraw Revenues in several different currencies. All currency exchange
services in connection with such withdrawals are performed by Beyond Smart Cities’s Payment Services
Providers. Your Beyond Smart Cities Balance is always derived from its INR ₹ value and, therefore, the
local currency amount of your Beyond Smart Cities Balance may change daily in accordance with
exchange rate fluctuations and also includes conversion fees. Sellers always have the option to withdraw
Revenues in INR ₹.<br/>
• Sellers will be able to withdraw their revenues from disabled accounts after a safety period of 90 days
following full verification of ownership of the account in question, from the day of the last cleared payment
received in their account and subject to Beyond Smart Cities's approval.<br/><br/>
<b>Withdrawal Methods</b>
• Withdraw to your UPI account - 0 Fee *<br/>
• Local Bank Transfer (LBT) - 2.5 % of the total amount per transfer<br/>
* Additional fees may apply based on your location and currency.<br/><br/>
<b>Purchasing</b><br/>
General<br/>
• Buyers pay Beyond Smart Cities to create an order from a Seller’s Service page using the Order Now button.
Beyond Smart Cities partners with Payment Services Providers for purposes of collecting all payments from
Buyers, transferring such payments from Buyers to Sellers, and holding funds in connection with Beyond Smart
Cities Balances. All payments services in connection with the collection of funds on the Beyond Smart Cities
platform are performed by Beyond Smart Cities’s Payment Services Providers.<br/>
• Beyond Smart Cities serves as Seller’s limited authorized payment collection agent solely for the purpose of
accepting payments (via its Payment Services Provider, if applicable) from Buyer, and remitting those payments
to Seller. Buyer’s payment obligation to Seller will be satisfied upon receipt of payment by Beyond Smart Cities
(or its Payment Services Provider, as applicable), and Beyond Smart Cities (via its Payment Service Provider,
as applicable) is responsible for remitting the funds to the Seller in the manner described in these Payment
Terms. In the event that Beyond Smart Cities (via Payment Services Provider) does not remit any such amounts
to Seller, the Seller will have recourse only against Beyond Smart Cities and not the Buyer directly.
• In most locations, purchases on Beyond Smart Cities can be made by using one of the following payment
methods: UPI Payment ,Debit card, Credit Card, Beyond Smart Cities Credits or existing Beyond Smart Cities
Balance. Additional payment methods may apply in certain locations.<br/>
• Service fees are added at the time of purchase where you can review and accept the total amount requested to
pay. These fees cover administrative fees. As of March 2022, the service fees are 10% of the purchase amount.
• Your existing Beyond Smart Cities Balance will be automatically applied to your next purchase.<br/>
• You may not offer Sellers to pay, or make payment using any method other than through the Beyond Smart
Cities.com site. In case you have been asked to use an alternative payment method, please report it
immediately to Customer Support here.<br/>
• You agree to receive invoices and/or payment receipts from Beyond Smart Cities in electronic form as PDF
documents, by email or through the Site.<br/>
• To protect against fraud, unauthorized transactions (such as money laundering), claims, or other liabilities,
payment information in connection with withdrawals is collected by either Beyond Smart Cities or Beyond Smart
Cities’s Payment Services Providers. Payment Services Providers may also collect such other information as
necessary for the purpose of processing withdrawal payments.Beyond Smart Cities is not exposed to the
payment information provided to Payment Services Providers, and this information is subject to the privacy
policy applicable to the Payment Service Provider.<br/>
• By using any payment method and/or providing payment details for making purchases on Beyond Smart Cities,
you represent and warrant that: (a) you are legally authorized to provide such information; (b) you are legally
authorized or have permission to make payments using the payment method(s); (c) if you are an employee or
agent of a company or person that owns the payment method, you are authorized by that company or person to
use the payment method to make payments on Beyond Smart Cities; and (d) such actions do not violate any
applicable law.<br/><br/>
<b>Local Currencies</b>
• You can pay on Beyond Smart Cities in several different currencies. Unless stated otherwise, you will be
charged in the currency displayed on the payment page. All currency exchange services in connection with
payments in local currencies are performed by Beyond Smart Cities’s Payment Services Providers. In certain
cases, however, when certain currencies are not supported by certain payment methods, you will be charged in
INR ₹ even if the price is displayed in another currency. In any event, the actual charged amount (in the actual
payment currency) will be clearly disclosed to you before you complete the payment.<br/>
• Note that all prices on Beyond Smart Cities are derived always from their original Indian Rupee(INR ₹) price
and, therefore, non-Indian Rupees currency prices may change daily in accordance with exchange rate
fluctuations and may also include conversion fees. Buyers always have the option to pay in INR ₹ by changing
their currency.<br/>
• If you paid for an Order in a currency other than INR ₹ and your Order was later canceled for any reason, the
amount returned to your Beyond Smart Cities Balance would be based on the exchange rate as of the date of
cancellation. Therefore, the returned amount may vary from the paid amount in local currency terms, while
always maintaining the same INR ₹ value.<br/>
• Your Beyond Smart Cities Balance is always valued in INR ₹, even if it is displayed in a non-INR ₹ currency.
Therefore, should you choose to view your Beyond Smart Cities Balance in any currency other than INR ₹, it
may change daily in accordance with exchange rate fluctuations, while always maintaining the same INR ₹
value. Users always have the option to view their Beyond Smart Cities Balance in INR ₹ by changing their
selected displayed currency.<br/><br/>
<b>Taxes</b>
• Beyond Smart Cities may be required by applicable laws to charge users with indirect taxes (such as
Sales Tax, VAT or GST) or to withhold taxes. Any amount Beyond Smart Cities will be required to
collect will be in addition to the purchase amount and any other fees payable by the Buyer, and any
amount Beyond Smart Cities will be required to withhold will be deducted from the Seller’s revenue, as
required by applicable laws.<br/>
• Indirect taxes are in addition to the price shown on the site, and in any event, any such taxes will always be
displayed to the Buyer before payment.<br/>
• Users are responsible for paying any direct or indirect taxes, including any GST, VAT, or income tax, which may
apply to them depending on residency, location or otherwise, under provisions of their jurisdiction.<br/><br/>
<b>Disputes and Cancellations</b>
We encourage our Buyers and Sellers to try and settle conflicts amongst themselves. If for any reason this fails
after using the Resolution Center or if you encounter non-permitted usage on the Site, users can contact Beyond
Smart Cities's Customer Support department for assistance.<br/><br/>
<b>Fundamentals</b>
• Order cancellations can be performed on Beyond Smart Cities, when eligible, by Customer Support or through
the Resolution Center per order.<br/>
• Filing a transaction dispute or reversing a payment through your payment provider or your bank is a violation of
these Payment Terms. Doing so may get your account temporarily or permanently disabled. Note: Once you
have filed a dispute with your payment provider, the funds will be ineligible for a refund due to our obligations
towards the payment provider.<br/>
• In the event that you encounter an issue related to the service provided in an order, you are encouraged to use
the Site's dispute resolution tools to attempt to resolve the matter.<br/>
• Beyond Smart Cities, through its Payment Services Provider, reserves the right to cancel orders or place funds
on hold for any suspected fraudulent transactions made on the Site.<br/>
• All transfer and assignment of intellectual property to the Buyer shall be subject to full payment and the delivery
may not be used if payment is canceled for any reason.<br/>
• If an order is canceled (for any reason), the funds paid will be returned to the Buyer’s Beyond Smart Cities
Balance.<br/>
• Revisions to deliveries can be performed by Sellers based on the Seller’s Service and customer care. Sellers
may determine the amount of revisions they wish to offer, including no revisions.<br/>
• Requests for revisions can be performed through the Order Page while the order is marked as Delivered.
• Requesting to gain more services from Sellers beyond the agreed requirements by using the Request Revisions
button is not allowed.<br/><br/>
<b>Order Cancellations</b>
• Beyond Smart Cities encourages Buyers and Sellers to resolve service disputes mutually using the Resolution
Center.<br/>
• Eligibility for requests to Beyond Smart Cities to cancel an order will be assessed by our Customer Support
team based on a number of factors, including violations of our Terms of Service and/or our Community
Standards, general misconduct, and improper usage of the Beyond Smart Cities delivery system. See below for
Order specific eligibility.<br/>
• Completed orders (or, where applicable, completed milestones) may be canceled, upon review of our Customer
Support team, up to 21 days after the order (or Milestone) is marked as complete. Please be advised that orders
cannot be partially canceled (i.e. we can only cancel the entire order when it is justified).<br/>
• In rare circumstances where we find it appropriate, our Customer Support team may cancel a completed order
even after 21 days have passed from its completion. In such cases, the amounts paid for the canceled order will
be returned to the Buyer’s Beyond Smart Cities Balance and will be deducted from the Seller’s Beyond Smart
Cities Balance or, if there are no sufficient amounts in the Seller’s Beyond Smart Cities Balance, from future
revenues of such Seller.<br/>
• Orders are not eligible to be canceled based on the quality of service/materials delivered by the Seller if the
service was rendered as described in the Service Page. You may rate your experience with the Seller on the
Order Page, including the overall level of service quality received.<br/>
• You must use Beyond Smart Cities Resolution Center to address your concerns and desired resolution related
to the service provided by their Seller prior to contacting Customer Support. Customer Support will not take any
action against Orders where the applicable has failed to inform their Seller of issues related to the Seller’s
service and will allow Sellers to provide a resolution first. This does not include non-permitted usage of Beyond
Smart Cities.<br/>
• Any non-permitted usage of Beyond Smart Cities encountered during an Order, after being reviewed by our
Customer Support team, may result in the order being canceled. This includes, but not limited to; harassment,
unlawful behavior, or other violations of Beyond Smart Cities’s Terms of Service. Beyond Smart Cities Customer
Support will cancel orders based on, but not limited to, the following reasons:<br/>
Active orders (after requirements were submitted to the Seller and before the Seller delivers on Beyond Smart
Cities):<br/>
A. The Seller is late and unresponsive for more than 48 hours while the order is marked as Late.<br/>
B. Users are abusive towards the other party through threats of low ratings or leveraging order materials (such as
logins, personal information) against each other.<br/>
C. Users supplied or included copyright/trademark infringing materials as part of their requirements or the Seller’s
delivery.<br/>
D. The user is no longer an active Beyond Smart Cities user due to Terms of Service violations or closure of their
account.<br/>
Delivered Orders (after the Seller clicks Deliver Now and before the order is marked as complete)
A. The Seller uses the Delivery system to extend the delivery due date to complete the requested service without
providing the final delivered service. Note: Multiple reported offenses will result in permanent suspension of
your account.<br/>
B. The Seller delivers no files and/or proof of work related to the agreed upon order requirements. Note:
Subjectivity of the materials in question will be reviewed by our Customer Support team.<br/>
C. The Seller requests additional payments, on or off the Beyond Smart Cities platform, by withholding the final
delivery of services directly related to the agreed requirements.<br/>
D. The Seller is withholding the final delivery of services for improved ratings.<br/>
E. Users who abuse the Request Revisions button to gain more services from Sellers beyond the agreed
requirements.<br/>
F. Users who threaten to leave a damaging review to gain more services from the Seller not related to the agreed
requirements.<br/>
Completed Orders (after the order is marked as complete and before the 15-day limitation)<br/>
A. Users who have been reported to use copyright/trademark infringing materials after verification and with proof.<br/>
B. Users who did not purchase commercial use rights and are reported to have used the materials commercially.<br/>
Note: Terms of Commercial use are found on the Seller’s Service Page and cannot be retroactively included
once the order is completed for over 15 days.<br/>
C. Beyond Smart Cities Customer Support will review cases of Order delivery manipulation that prevents users
from fully utilizing our Resolution Center that enabled the order to be marked as complete.<br/><br/>
<b>Chargebacks</b><br/>
Beyond Smart Cities reviews cases of payment provider chargebacks and disputes on behalf of Sellers. Although
results vary per case due to each chargeback reason, we work hard on resolving disputes in the Seller’s favor. If
the chargeback case allows, Beyond Smart Cities (or one of Beyond Smart Cities’s Payment Services Providers)
will return parts or full revenue back to Sellers, otherwise the chargeback amount will be borne by the Seller.
<b>Refunds</b><br/>
• Beyond Smart Cities does not automatically refund payments made for canceled orders back to your payment
provider. Funds from order cancellations are returned to the Buyer's Beyond Smart Cities Balance and are
available for future purchases on Beyond Smart Cities. In any event, if the service fees were not returned to
your Beyond Smart Cities Balance following an order cancelation, such service fees’ amount will be exempted
from your next purchase on Beyond Smart Cities.<br/>
• Deposit refunds (i.e. refunds directly to your payment provider) can be performed by our Customer Support
team, based on the Order’s original payment amount and currency. To prevent fraud and abuse, we limit the
total amount of times users can request a payment provider refund, which is subject to review by our Customer
Support team. Such refunds may be subject to an additional fee.<br/><br/>
<b>General Terms</b><br/>
Modifications<br/>
Beyond Smart Cities may make changes to these Payment Terms from time to time. When these changes are
made, Beyond Smart Cities will make a new copy of the terms available on this page. You understand and agree
that if you use Beyond Smart Cities after the date on which the Payment Terms have changed, Beyond Smart
Cities will treat your use as acceptance of the updated Payment Terms.<br/><br/>
<b>Disclaimer of Warranties</b><br/>
YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
WEBSITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER BEYOND SMART CITIES NOR ANY
PERSON ASSOCIATED WITH BEYOND SMART CITIES MAKES ANY WARRANTY OR REPRESENTATION
WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
AVAILABILITY OF THE SITE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT
BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW<br/><br/>
<b>Limitation on Liability</b><br/>
IN NO EVENT WILL BEYOND SMART CITIES, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY
KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH
OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER
WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE
IN ACCEPTING LIABILITY AS THE LIMITED AUTHORIZED PAYMENT COLLECTION AGENT OF SELLER,
BEYOND SMART CITIES AND ITS AFFILIATES ASSUME NO LIABILITY FOR ANY ACTS OR OMISSIONS OF
SELLER.<br/><br/>
THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
APPLICABLE LAW.<br/><br/>
The term “Affiliate” referred to herein, is an entity that, directly or indirectly, controls, or is under the control of, or is
under common control with Beyond Smart Cities, where control means having more than fifty percent (50%)
voting stock or other ownership interest or the majority of voting rights of such entity.</p></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleClose}>
           Confirm
          </Button>
        </Modal.Footer>
      </Modal>

             




                             {/* <p className="order-token">Choose components</p> */}
      {/* <ul className="toppings-list">
        {dropinComponents.map(({ name, id }, index) => {
          return (
            <>
              <input
                type="checkbox"
                id={`custom-checkbox-${index}`}
                name={name}
                value={id}
                checked={this.state.checkedState[index]}
                onChange={() => this.handleOnChange(index)}
                key={id}
              />
              <label className="mr-8" htmlFor={`custom-checkbox-${index}`}>
                {name}
              </label>
            </>
          );
        })}
      </ul> */}

      {/* <button className="btn-render mt-2" onClick={()=>this.renderDropin()}>
        Render
      </button> */}

    

               </Row>

          </Container>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  const { cartItems } = state.cart;
  console.log(cartItems, isLoggedIn,user)
  return {
    user,
    isLoggedIn,
    cartItems
  };
}

export default connect(mapStateToProps)(CreateOrder);
import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const GreenSite = ({ prevStep, nextStep, handleChange, values }) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>GREEN SITE(GS)</h2>
                          <Row>
                    <p className="mt-2">
                            The Green Site Category is concerned with the design
                            of the proposed development having a direct impact
                            on both the site of the development itself as well
                            as any adjacent sites.
                          </p>
                      </Row>

                          <img
                            className="img-fluid pt-3"
                            src="assets/images/greencity.jpg"
                          ></img>
                         
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                36 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-5 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col >
                              <Form.Check                                
                                label="Sustainable Site Selection"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Sustainable Site Selection"
                                id={`inline-checkbox-1`}
                                checked= {values?.greenSites?.includes('Sustainable Site Selection') ? true : false}

                              />

                              <Form.Check                                
                                label="Limit Development on Agricultural Land"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Limit Development on Agricultural Land"
                                id={`inline-checkbox-2`}
                                checked= {values?.greenSites?.includes('Limit Development on Agricultural Land') ? true : false}

                              />

                              <Form.Check                                
                                label="Environmental Site Assessment"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Environmental Site Assessment"
                                id={`inline-checkbox-3`}
                                checked= {values?.greenSites?.includes('Environmental Site Assessment') ? true : false}

                              />

                              <Form.Check                                
                                label="Pollution Prevention"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Pollution Prevention"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Pollution Prevention') ? true : false}

                              />

                              <Form.Check                                
                                label="Site Master Plan"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Site Master Plan"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Site Master Plan') ? true : false}

                              />

                              <Form.Check                                
                                label="Light Pollution Prevention"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Light Pollution Prevention"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Light Pollution Prevention') ? true : false}

                              />
                            </Col>
                            <Col>
                              <Form.Check                                
                                label="Protect Habitat"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Protect Habitat"
                                id={`inline-checkbox-1`}
                                checked= {values?.greenSites?.includes('Protect Habitat') ? true : false}

                              />

                              <Form.Check                                
                                label="Heat Island Reduction"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Heat Island Reduction"
                                id={`inline-checkbox-2`}
                                checked= {values?.greenSites?.includes('Heat Island Reduction') ? true : false}

                              />

                              <Form.Check                                
                                label="Responsive Shading"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Responsive Shading"
                                id={`inline-checkbox-3`}
                                checked= {values?.greenSites?.includes('Responsive Shading') ? true : false}

                              />

                              <Form.Check                                
                                label="Rain Water Harvesting "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Rain Water Harvesting"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Rain Water Harvesting') ? true : false}

                              />

                              <Form.Check                                
                                label="Shared Use of Facilities "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Shared Use of Facilities"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Shared Use of Facilities') ? true : false}

                              />

                              <Form.Check                                
                                label="Sustainable Landscapes "
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Sustainable Landscapes"
                                id={`inline-checkbox-4`}
                                checked= {values?.greenSites?.includes('Sustainable Landscapes') ? true : false}

                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/green-city-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

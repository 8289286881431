import React, { Component, Fragment } from "react";
import authHeader from "../../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../../config";

import { Redirect } from "react-router-dom";
import { SmartTransportation } from "./SmartTransportation";
import { GreenSite } from "./GreenSite";
import { EnergyEfficiency } from "./EnergyEfficiency";
import { WaterEfficiency } from "./WaterEfficiency";
import { MaterialAndResources } from "./MaterialAndResources";
import { IndoorEnvironment } from "./IndoorEnvironment";
import { HealthAndWellness } from "./HealthAndWellness";
import { EmergingTechnology } from "./EmergingTechnology";
import Confirmation from "./Confirmation";
import Success from "./Success";
import { BasicInformation } from "./BasicInformation";
import { InfoTip } from "./InfoTip";
import { ToastContainer, toast } from "react-toastify";

export default class submitProperty extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      errorMessage: "",
      selectedProjectType:'',
      step: 1,
      isSTchecked:false,
      data: {
        projectName:'',
        projectType:'',
        address:'',
        geoLocation:{
          latitude:'',
          longitude:'',
        },
        startDate:'',
        endDate:'',
        grossFloorArea:'',
        description:'',
        // propertyTitle:'',
        // proprtyAddress:'',
        smartTransportation: [],
        greenSites: [],
        energyEfficiency: [],
        waterEfficiency: [],
        materialResources: [],
        indoorEnvironment: [],
        emergingTechnology: [],
        healthAndWellness:[],        
      },
    };
  }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  firstStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 3 });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // Handle fields change
  handleChangeCompanyInfo = (input, type, value) => {
    console.log("val", value, type, input);
    if (type === "address") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          applicantInfo: {
            ...prevState.data.applicantInfo,
            address: {
              ...prevState.data.applicantInfo.address,
              [input]: value,
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          applicantInfo: {
            ...prevState.data.applicantInfo,
            [input]: value,
          },
        },
      }));
    }
  };



  handleChangeBI = () => (e) => {

   
console.log(e.target.value.length)
   
// PROJECTTYPE
    if(e.target.name=="projectType"){
      this.setState({
        selectedProjectType:e.target.value
      })
    }

// STARTDATE
    if(e.target.name=="startDate" || e.target.name=="endDate"){    

    // If date is more than 30
    if(e.target.value.length === 2 && e.target.value > 31 ){
      e.target.value=''
    }

    // If date is more than 30  
    if(e.target.value.charAt(3) > 1 ){         
      e.target.value=e.target.value.slice(0, -1)
    }

    // If date is more than 30  
    if(e.target.value.charAt(6) > 2 ){       
      e.target.value=e.target.value.slice(0, -1)
    }


    let val=e.target.value
    if(e.target.name=="startDate"){     
      if(e.target.value.length === 2 || e.target.value.length === 5){
        val= e.target.value + "-"
      }
    }


    if(e.target.name=="endDate"){      
      if(e.target.value.length === 2 || e.target.value.length === 5){
        val= e.target.value + "-"
      }
    } 
    
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [e.target.name]:val,        
      },
    }));    
  }

  else if(e.target.name==="latitude" || e.target.name==="longitude"){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        geoLocation:{
          ...prevState.data.geoLocation,
          [e.target.name]:e.target.value, 
         }                
      },
    }));  
  }


  else{

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [e.target.name]:e.target.value,        
      },
    }));    

  }



  
    console.log("FINAL2", this.state.data);
  };


  handleChangeST = () => (e) => {
    console.log(e.target.checked)
    if(e.target.checked){
      // this.setState({
      //   isSTchecked:e.target.checked
      // })
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        smartTransportation: [
          ...this.state.data.smartTransportation,
          e.target.name,
        ],
      },
    }));
    }
    else{
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          smartTransportation: this.state.data.smartTransportation.filter(n => n !== e.target.name),
        },
      }));
  
    }
    console.log("FINAL2", this.state.data);
  };

  handleChangeGC = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        greenSites: [...this.state.data.greenSites, e.target.name],
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        greenSites: this.state.data.greenSites.filter(n => n !== e.target.name),
      },
    }));

  }
    console.log("FINAL2", this.state.data);
  };

  handleChangeEE = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        energyEfficiency: [...this.state.data.energyEfficiency, e.target.name],
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        energyEfficiency: this.state.data.energyEfficiency.filter(n => n !== e.target.name),
      },
    }));

  }
    console.log("FINAL2", this.state.data);
  };

  handleChangeWE = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        waterEfficiency: [...this.state.data.waterEfficiency, e.target.name],
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        waterEfficiency: this.state.data.waterEfficiency.filter(n => n !== e.target.name),
      },
    }));

  }
    console.log("FINAL2", this.state.data);
  };

  handleChangeMR = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        materialResources: [...this.state.data.materialResources, e.target.name],
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        materialResources: this.state.data.materialResources.filter(n => n !== e.target.name),
      },
    }));

  }
    console.log("FINAL2", this.state.data);
  };

  handleChangeIE = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        indoorEnvironment: [...this.state.data.indoorEnvironment, e.target.name],
      },
    }));
  }
    else{
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          indoorEnvironment: this.state.data.indoorEnvironment.filter(n => n !== e.target.name),
        },
      }));
  
    }
    console.log("FINAL2", this.state.data);
  };


  handleChangeHW = () => (e) => {
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        healthAndWellness: [...this.state.data.healthAndWellness, e.target.name],
      },
    }));
  }
    else{
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          healthAndWellness: this.state.data.healthAndWellness.filter(n => n !== e.target.name),
        },
      }));
  
    }
    console.log("FINAL2", this.state.data);
  };

  handleChangeET = () => (e) => {   
    if(e.target.checked){
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        emergingTechnology: [...this.state.data.emergingTechnology, e.target.name],
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        emergingTechnology: this.state.data.emergingTechnology.filter(n => n !== e.target.name),
      },
    }));

  }
    console.log("FINAL2", this.state.data);
  };

  async handleRegister(e) {
    console.log("DATREAL", this.state.data);
    var self = this;
    e.preventDefault();
    self.setState({
      step: 12,
      submitLoading: true,
      errorMessage: "",
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .post(API_URL + "property/submit", self.state.data, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log(response);
          //handle success

          if (response.data.success === true) {
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
            });

            // setTimeout(function(){
            //   self.props.history.push("/profile");

            //  }, 4000);
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
          });
        }
        // .catch(function (response) {

        //   //handle error
        //   console.log(response);
        //   // this.setState({
        //   //     submitLoading:false
        //   // })
        // });
      );
  }

  render() {
    const { step } = this.state;

    switch (step) {

      case 1:
        return (
          <BasicInformation
            nextStep={this.nextStep}
            handleChange={this.handleChangeBI}
            selectedProjectType={this.state.selectedProjectType}
            values={this.state.data}
          />
        );


        case 2:
        return (
          <InfoTip
            nextStep={this.nextStep}
            prevStep={this.prevStep}            
          />
        );


      case 3:
        return (
          <SmartTransportation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeST}
            values={this.state.data}
          />
        );

      case 4:
        return (
          <GreenSite
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeGC}
            values={this.state.data}
          />
        );
      case 5:
        return (
          <EnergyEfficiency
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeEE}
            values={this.state.data}
          />
        );

      case 6:
        return (
          <WaterEfficiency
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeWE}
            values={this.state.data}
          />
        );

      case 7:
        return (
          <MaterialAndResources
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeMR}
            values={this.state.data}
          />
        );

      case 8:
        return (
          <IndoorEnvironment
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeIE}
            values={this.state.data}
          />
        );

      case 9:
        return (
          <HealthAndWellness
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeHW}
            values={this.state.data}
          />
        );

      case 10:
        return (
          <EmergingTechnology
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChangeET}
            values={this.state.data}
          />
        );

        case 11:
            return (
              <Confirmation
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                handleRegister={this.handleRegister}
                values={this.state.data}
              />
            ); 
            case 12:
                return (
                  <Success
                  submitLoading={this.state.submitLoading}
                  isdataSubmitted={this.state.isdataSubmitted}
                  errorMessage={this.state.errorMessage}
                  handleRegister={this.handleRegister}
                  firstStep={this.firstStep}
                  />
                );       

      default:
      // do nothing
    }
  }
}

import React, { component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import { connect } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { history } from "../helpers/history";
import CategoryLinks from "./navbar/categorylinks";
import ProfileDropdown from "./navbar/profile-dropdown";
import SearchBoxTop from "../helpers/SearchBoxTop"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showServiceProviderBoard: false,
      currentUser: undefined,
      isTop: true,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;
    const head = document.getElementById('header');
    const cat= document.getElementById('CategoriesMenu')

    document.addEventListener('scroll', () => {
      
     
      if (window.scrollY > 250) {
        head.classList.add("nofixedheader");
      }
      else{
        head.classList.remove("nofixedheader");
      }

      if (window.scrollY > 255) {
        cat.classList.add("nofixedcat");
      }
      else{
        cat.classList.remove("nofixedcat");
      }
    });

    
   

    if (user) {
      this.setState({
        currentUser: user,
        showManufacturerBoard: user.role.includes("customer"),
        showServiceProvidernBoard: user.role.includes("serviceprovider"),
      });
    }
  }

  logOut() {
    this.props.dispatch(logout());
  }

 

  render() {
    const { currentUser, showManufacturerBoard, showServiceProviderBoard } =
      this.state;
    const { isLoggedIn } = this.props;

    return (
      <div className="headMain" id="headerMain">
        <nav
          className="navbar navbar-expand-md navbar-light fixed-top sticky topnav"
          id="header"
        >
          <div className="container-fluid">
            {/* <!-- Toggler/collapsibe Button --> */}
            <Link to="/" className="navbar-brand">
              <img
                src="/assets/images/logo.png"
                alt="logo"
                className="img-fluid logo"
              />
            </Link>

            <div id="such-bar-home">   
            <SearchBoxTop />
            {/* <Row>
              <Col className="col-md-9 pe-0">
              <SearchBox />
              </Col>
              <Col  className="col-md-3">
              <button className="btn btn-outline-success" type="submit"
              onClick={()=> this.searchService() }
              >
                  Search
                </button>
              </Col>
              </Row>            */}
           
           
              {/* <form className="d-flex">
                <input
                  className="form-control "
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>               */}
            </div>

            {/* <!-- <img src="#" className="logo"> --> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* <!-- Navbar links --> */}
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="nav navbar-nav explore ms-auto">
                <li className="nav-item ">
                  <Link
                    to="/beyond-business"
                    className="nav-link"
                    // className="nav-link active"
                    aria-current="page"
                  >
                    Beyond Business
                  </Link>
                </li>


                <li className="nav-item explore-menu ">
                  <Link
                    to="#"
                    className="nav-link"
                    aria-current="page"
                  >
                    Explore
                  </Link>
              

                {/* EXPLORE MENU */}

                {/* <NavDropdown title="Explore" id="explore-nav-dropdown" className="explore-drop dropdown-menu-end"> */}
                <Container fluid className="explore-dropdown">
                  <div className="row px-3">
                    <div className="col-md-6 list-items bdr-r-1">
                     


                      <Link to="/EmergingDesign">Emerging Design
                        <p className="fs-12 wrap c4">
                          {" "}
                          Emerging Project Management Trends
                        </p>
                        </Link>

                     
                    

                      <a target="_blank" href="http://knowledgelab.beyondsmartcities.in/index.php/category/guides/">Specialist Guides
                        <p className="fs-12 wrap c4">
                          {" "}
                          In-depth Specialist guides covering green technology  - Work Marketplace
                        </p>
                        </a>

                        <a href="/smartpro-product-rating-systems">SmartPro Rating System
                        <p className="fs-12 wrap c4">
                          {" "}
                          Your trusted partner for green certification in your business journey towards sustainability
                        </p>
                        </a>


                        <a href="/learning-lab">Learning Lab
                        <p className="fs-12 wrap c4">
                          {" "}
                          To advance the green technology movement through best-in-class education and encouragement
                        </p>
                        </a>
                     

                    
                    </div>

                    <div className="col-md-6 list-items">                   

                      <a href="/self-certification">Certify Your Property
                        <p className="fs-12 wrap c4">
                          {" "}
                          Building green is not a choice but commmitment for future generations
                        </p>
                        </a>
                   

                    

                      <a target="_blank" href="http://beyondsmartcities.in/forum">Press & News
                        <p className="fs-12 wrap c4">
                          {" "}
                          In-depth technical guides covering green technology &
                          business topics
                        </p>
                        </a>

                      
                      <a target="_blank" href="http://beyondsmartcities.in/forum">Community
                        <p className="fs-12 wrap c4">
                          {" "}
                          News, Information and specialist community stories
                        </p>
                        </a>                     
                 

                      <a href="/dashboard">Beyond Smart Workspace
                        <p className="fs-12 wrap c4">
                          {" "}
                          Automate your specialist's back office
                        </p>
                        </a>


                    </div>
                  </div>
                  </Container>
                  </li>
                {/* </NavDropdown> */}

                {/* <li className="nav-item dropdown explore">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Explore
                  </a>

                  <ul className="dropdown-menu Explore">
                    <div className="triangle-up"></div>
                    <div className="row">
                      <div className="col-md-6 list-items">
                        <ul className="navlist-items">
                          <li>
                            <Link to="/careers">
                              <h6>Careers</h6>
                              Inspiring projects made on Beyond Smart Cities
                            </Link>
                          </li>
                          <li>
                            <Link to="press">
                              <h6>Press </h6>
                              In-depth technical guides covering green
                              technology & business topics
                            </Link>
                          </li>
                          <li>
                            <Link to="/partnering">
                              <h6>Partner Ships</h6>
                              Inspiring projects made on Beyond Smart Cities
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="navlist-items">
                          <li>
                            <Link to="/faqs">
                              <h6>FAQ</h6>
                              Professional online courses, led by specialists
                            </Link>
                          </li>
                          <li>
                            <a href="blog">
                              <h6> Blog</h6>
                              News, information and community stories
                            </a>
                          </li>
                          <li>
                            <Link to="/community">
                              <h6>Community Standards</h6>
                              Connect with Beyond Smart Cities’s team and
                              community
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ul>
                </li> */}
                <li className="nav-item ">
                  <Link
                    to="/bsas-rating"
                    className="nav-link "
                    aria-current="page"
                  >
                    BSAS Rating
                  </Link>
                </li>

                {!isLoggedIn ? (
                  <>
                    <li className="nav-item">
                      <Link to="/login" className="nav-link">
                        Sign In
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to="/register" className="nav-link" id="join">
                        Join Now
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {this.props.user && (
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                      {this.props.user.firstName}
                    </Link>
                  </li>
                )}
              </ul>

              <ProfileDropdown />
            </div>
          </div>
        
        </nav>
        <CategoryLinks />       
      </div>
      
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  return {
    user,
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(Header);

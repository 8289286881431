
import React, { Component } from 'react';
import './App.css';
import "react-image-gallery/styles/css/image-gallery.css";
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import BeyondBusiness from './pages/BeyondBusiness';
import BsasRating  from './pages/BsasRating';
import HowtoHire  from './pages/HowtoHire';
import TalentMarketplace from './pages/TalentMarketplace';
import LeaderShip from './pages/LeaderShip';
import About from './pages/About';
import InvestorRelations from './pages/InvestorRelations';
import Login from './components/login.component';
import TrustSafety from './pages/TrustSafety';
import Contact from './pages/Contact';
import Articles from './pages/Articles';
import BswsLevelSystem from './pages/BswsLevelSystem';
import BSASCertifiedProject from './pages/BSASCertifiedProject';
import HowToFindWork from './pages/HowToFindWork';
import FreelanceWorldWide from './pages/FreelanceWorldWide';
import FreelanceGcc from './pages/FreelanceGcc';
import SelfCertification from './pages/SelfCertification';
import WhyGreenBuilding from './pages/WhyGreenBuilding';
import PropertyResources from './pages/PropertyResources';
import HireInWorld from './pages/HireInWorld';
import HireMiddleEast from './pages/HireMiddleEast';
import GreenBuildingTools from './pages/GreenBuildingTools';
import SiteMap from './pages/SiteMap';
import SmartProProductRatingSystems from './pages/SmartProProductRatingSystems';
import AboutSmartPro from './pages/AboutSmartPro';
import TermsService from './pages/TermsService';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import KnowledgeLab from './pages/KnowledgeLab';
import Careers from './pages/Careers';
import Press from './pages/Press';
import Partnering from './pages/Partnering';
import Faqs from './pages/Faqs';
import Community from './pages/Community';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
// import {BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import { Router, Switch, Route, Link,useLocation,Redirect } from "react-router-dom";
import Sustainability from './pages/Sustainability';
import SmartCities from './pages/SmartCities';
import BuildingTech from './pages/BuildingTech';
import EnergyCXA from './pages/EnergyCXA';
import GHGClimateChange from './pages/GHGClimateChange';
import HSEFireSafety from './pages/HSEFireSafety';
import EmergingTech from './pages/EmergingTech';
import Form from './components/form/Form';
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/register.component";
// import Home from "./components/home.component";
import Profile from "./components/Profile/Profile";
import BoardUser from "./components/board-user.component";
import BoardManufacturer from "./components/board-manufacturer.component";
import BoardServiceProvider from "./components/board-serviceprovider.component";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';
import Dashboard from './components/dashboard.component';
import ApplySpecialist from './components/specialist/Apply/ApplySpecialist';
import ApplyManufacturer from './components/manufacturer/Apply/ApplyManufacturer';
import Onboarding from './components/Onboarding';
import ProfileEdit from './components/Profile/ProfileEdit';
import { ToastContainer, toast,Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import submitProperty from './components/customer/SubmitProperty/SubmitProperty';
import SpecialistDashboard from './components/specialist/SpecialistDashboard';
import ManufacturerDashboard from './components/manufacturer/ManufacturerDashboard';
import SubmitProduct from './components/manufacturer/SubmitProduct/SubmitProduct';
import PropertyLists from './components/customer/PropertyLists/PropertyLists';
import PropertySingle from './components/customer/PropertyLists/PropertySingle';
import Inbox from './components/Inbox/Inbox';
import Notifications from './components/Notifications/Notifications';
import Query from './components/Query/QueryLists';
import QueryDetails from './components/Query/QueryDetails';
import SubmitQuery from './components/Query/SubmitQuery';
import OrderLists from './components/customer/Orders/OrderLists';
import OrderDetails from './components/customer/Orders/OrderDetails';

import ServiceLists from './components/specialist/ServiceLists/ServiceLists';
import ServiceDetails from './components/specialist/ServiceLists/ServiceDetails';
import ProductLists from './components/manufacturer/ProductList/ProductLists';
import ProductDetail from './components/manufacturer/ProductList/ProductDetail';
import Categories from './components/Categories';
import Specialists from './components/specialist/Specialists';
import SpecialistDetails from './components/specialist/SpecialistInformation';
import SubmitService from './components/specialist/SubmitService/SubmitService';
import ServiceGallery from './components/specialist/ServiceGallery';
import ServiceInformation from './components/Services/ServiceInformation';
import SpecialistInformation from './components/specialist/SpecialistInformation';
import CreateOrder from './components/specialist/Orders/CreateOrder';
import {ProtectedRoute} from './Routes/ProtectedRoute'
import PaymentConfirmation from './components/PaymentConfirmation';
import PaymentFailed from './components/PaymentFailed';


// import io from "socket.io-client";
import { API_URL } from './config';
import SpecialistOrdersLists from './components/specialist/Orders/SpecialistOrdersLists';
import SpecialistOrderDetails from './components/specialist/Orders/SpecialistOrderDetails';
import ServiceEdit from './components/specialist/ServiceLists/ServiceEdit';
import WhatsNew from './pages/Whatsnew';
import PrimeMembership from './components/manufacturer/PrimeMembership';
import PrimeMembershipCreateOrder from './components/manufacturer/PrimeMembershipCreateOrder';
import PrimeMembershipPaymentFailed from './components/manufacturer/PrimeMembershipPaymentFailed';
import SmartProducts from './components/SmartProducts';
import GreenBuildingCommissioning from './KnowledgeLab/GreenBuilding/GreenBuildingCommissioning';
import CatSustainability from './components/CategoryPages/Sustainability';
import CatSmartCities from './components/CategoryPages/SmartCities';
import KnowledgeLab from './KnowledgeLab/KnowledgeLab';
import EmergingDesign from './pages/EmergingDesigns';
import SmartProProducts from './components/SmartProProducts';
import ProductInformation from './components/ProductInformation';
import CatGreenBuilding from './components/CategoryPages/GreenBuilding';
import CatEnergyEfficiency from './components/CategoryPages/EnergyEfficiency';
import CatHSCFireSafety from './components/CategoryPages/HSEFireSafety';
import CatGreenHouseClimateChange from './components/CategoryPages/GHGClimateChange';
import CatEmergingDesign from './components/CategoryPages/EmergingDesign';
import HelpSupport from './pages/HelpSupport';
import SpecialistLevelSystem from './pages/SpecialistLevelSystem';
import SpecialistPrimeMembership from './pages/SpecialistPrimeMembership';
import SellingOnBSC from './pages/SellingOnBSC';
import HowtoPromoteProducts from './pages/HowtoPromoteProducts';
import SmartProProductsServices from './pages/SmartProProductsServices';
import SmartProProductsResources from './pages/SmartProProductResources';
import SmartProFaq from './pages/SmartProFaq';
import SustainabilityManagement from './KnowledgeLab/SustainabilityEnvironment/SustainabilityManagement';
import ConstructionEnvironmentalManagementPlan from './KnowledgeLab/SustainabilityEnvironment/ConstructionEnvironmentalManagementPlan';
import EnvironmentalImpactAssessment from './KnowledgeLab/SustainabilityEnvironment/EnvironmentalImpactAssessment';
import AdvancedGreenBuildingCommissioning from './KnowledgeLab/GreenBuilding/AdvancedGreenBuildingCommissioning';
import ArtificialIntelligence from './KnowledgeLab/EmergingDesignTechnology/ArtificialIntelligence';
import BlockchainTechnology from './KnowledgeLab/EmergingDesignTechnology/BlockchainTechnology';
import DigitalTransformation from './KnowledgeLab/EmergingDesignTechnology/DigitalTransformation';
import InternetOfThings from './KnowledgeLab/EmergingDesignTechnology/InternetOfThings';
import AdvancedBuildingEnergyModelling from './KnowledgeLab/EnergyTechnology/AdvancedBuildingEnergyModeling';
import BeyondGreenBuildingTechnology from './KnowledgeLab/GreenBuildingTechnology/BeyondGreenBuildingTechnology';
import BeyondSmartCities from './KnowledgeLab/GreenBuildingTechnology/BeyondSmartCities';
import DynamicMeditation from './KnowledgeLab/Lifestyle/DynamicMeditation';
import BSASApprovedProperties from './components/BSASApprovedProperties';
import BSASPropertyInformation from './components/BSASPropertyInformation';
import Loginn from './components/Chat/Login';
import RoomList from './components/Chat/RoomList';
import AddRoom from './components/Chat/AddRoom';
import ChatRoom from './components/Chat/ChatRoom';
import Chat from './components/Chat/Chat';
import Chatpage from './components/Chat/Pages/Chatpage';
import Homepage from './components/Chat/Pages/Homepage';
import ForgetPassword from './ForgetPassword';
import TokenVerification from './TokenVerification';
import SustainabilityToolsResources from './pages/SustainabilityToolsResources';



class App extends Component {

  
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showServiceProviderBoard: false,
      currentUser: undefined,
      isSpecialist:false,
      isManufacturer:false,
      isCustomer:false,
      loggedIn:false, 
      socket:null,
        
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;

    
    
// this.setupSocket()

    if (user) {
      this.setState({
        loggedIn:true,
        currentUser: user,
        isCustomer:true,
        showManufacturerBoard: user.role.includes("customer"),
        showServiceProvidernBoard: user.role.includes("serviceprovider"),
      });
    }
    if(user && user.role.includes("specialist")){
      this.setState({isSpecialist:true})
    }

    if(user && user.role.includes("manufacturer")){
      this.setState({isManufacturer:true})
    }
  }

  logOut() {
    this.props.dispatch(logout());
  }


 

  // setupSocket = () => {
    
  //   const token = JSON.parse(localStorage.getItem("token"));     

  //   if (token && !this.state.socket) {
  //     const newSocket = io(API_URL, {
  //       query: {
  //         token: token,
  //       },
  //     });

  //     newSocket.on("disconnect", () => {
  //       this.setState({socket:null})
  //       setTimeout(this.setupSocket, 2000);
  //       console.log("error", "Socket Disconnected!");
  //     });

  //     newSocket.on("connect", () => {
  //       console.log("success", "Socket Connected!");
  //     });
      
  //     this.setState({socket:newSocket})
      
  //   }
  // };


  render() {
   
    const { currentUser } = this.state;
console.log('USER IN APPJS',currentUser)
const { isLoggedIn } = this.props;


    return (
      <React.Fragment>
        <Router history={history}>
          <Header />
          <div>
            <ScrollToTop>

              <Switch>
                <Route exact path={["/", "/home"]} component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <ProtectedRoute
                  path="/profile"
                  loggedIn={isLoggedIn}
                  component={Profile}
                />
                {/* AUTH */}
                {/* <ProtectedRoute path="/apply-specialist" loggedIn={this.state.loggedIn} component={ApplySpecialist}/> */}
                <ProtectedRoute
                  path="/payment-confirmation"
                  loggedIn={this.state.loggedIn}
                  component={PaymentConfirmation}
                />
                <ProtectedRoute
                  path="/payment-failed"
                  loggedIn={this.state.loggedIn}
                  component={PaymentFailed}
                />
                <ProtectedRoute
                  exact
                  path="/dashboard"
                  loggedIn={isLoggedIn}
                  component={Dashboard}
                />
                <ProtectedRoute
                  exact
                  path="/apply-specialist"
                  loggedIn={isLoggedIn}
                  component={ApplySpecialist}
                />
                <ProtectedRoute
                  exact
                  path="/apply-manufacturer"
                  loggedIn={isLoggedIn}
                  component={ApplyManufacturer}
                />
                <Route exact path="/onboarding" component={Onboarding} />
                <ProtectedRoute
                  exact
                  path="/profile-edit"
                  loggedIn={isLoggedIn}
                  component={ProfileEdit}
                />
                <Route
                  exact
                  path="/submit-property"
                  component={submitProperty}
                />
                <ProtectedRoute
                  exact
                  path="/submit-product"
                  loggedIn={isLoggedIn}
                  component={SubmitProduct}
                />
                <Route exact path="/inbox" component={Inbox} />
                <Route exact path="/notifications" component={Notifications} />
               
                {/* <Route
                  exact
                  path="/chat"
                  render={() => <Chat socket={this.state.socket} />}
                /> */}
              


                <Route exact path="/query-lists" component={Query} />
                <Route exact path="/submit-query" component={SubmitQuery} />
                <Route
                  exact
                  path="/query-details/:id"
                  component={QueryDetails}
                />
                <Route exact path="/category-lists" component={Categories} />
                <ProtectedRoute
                  exact
                  path="/service-lists"
                  loggedIn={isLoggedIn}
                  component={ServiceLists}
                />
                <ProtectedRoute
                  exact
                  path="/service-edit/:id"
                  loggedIn={isLoggedIn}
                  component={ServiceEdit}
                />
                <Route
                  exact
                  path="/service-information/:serviceid/:specialistid/"
                  component={ServiceInformation}
                />
                <Route
                  exact
                  path="/service-gallery"
                  component={ServiceGallery}
                />
                <ProtectedRoute
                  exact
                  path="/submit-service"
                  loggedIn={isLoggedIn}
                  component={SubmitService}
                />
                <Route
                  exact
                  path="/service-details/:id"
                  component={ServiceDetails}
                />
                
                <Route
                  exact={false}
                  path="/specialists/:cat/:subcat/:childcat/"
                  component={Specialists}
                />

                <Route
                  exact={false}
                  path="/specialists/:cat/"
                  component={Specialists}
                />

<Route
                  exact={true}
                  path="/specialists/:cat/:subcat/"
                  component={Specialists}
                />


<Route
                  exact={false}
                  path="/smartproducts/:cat/:subcat/"
                  component={SmartProducts}
                />


                <Route
                  exact
                  path="/specialist-details"
                  component={SpecialistDetails}
                />
                <Route
                  exact
                  path="/specialist-information/:id"
                  component={SpecialistInformation}
                />
                <ProtectedRoute
                  exact
                  path="/create-order/:serviceid/:specialistid/:paymentid"
                  loggedIn={isLoggedIn}
                  component={CreateOrder}
                />
                <ProtectedRoute
                  exact
                  path="/product-lists"
                  loggedIn={isLoggedIn}
                  component={ProductLists}
                />
                <ProtectedRoute
                  exact
                  path="/product-details/:id"
                  loggedIn={isLoggedIn}
                  component={ProductDetail}
                />
                <Route exact path="/order-lists" component={OrderLists} />
                <Route
                  exact
                  path="/order-details/:id"
                  component={OrderDetails}
                />
                <Route
                  exact
                  path="/specialist-order-lists"
                  component={SpecialistOrdersLists}
                />
                <Route
                  exact
                  path="/specialist-order-details/:id"
                  component={SpecialistOrderDetails}
                />
                <Route exact path="/property-lists" component={PropertyLists} />
                <Route
                  exact
                  path="/property-detail/:id"
                  component={PropertySingle}
                />
                <Route
                  exact
                  path="/specialist-dashboard"
                  component={SpecialistDashboard}
                />
                <Route
                  exact
                  path="/manufacturer-dashboard"
                  component={ManufacturerDashboard}
                />
                <Route path="/user" component={BoardUser} />
                <Route path="/manufacturer" component={BoardManufacturer} />
                <Route
                  path="/serviceprovider"
                  component={BoardServiceProvider}
                />
                <Route path="/about" component={About} />
                <Route path="/signin" component={SignIn} />
                <Route path="/beyond-business" component={BeyondBusiness} />
                <Route path="/bsas-rating" component={BsasRating} />
                <Route
                  path="/talentmarketplace"
                  component={TalentMarketplace}
                />
                <Route path="/leadership" component={LeaderShip} />
                <Route path="/howtohire" component={HowtoHire} />
                <Route
                  path="/Investorrelations"
                  component={InvestorRelations}
                />
                <Route path="/login" component={Login} />
                <Route path="/trustsafety" component={TrustSafety} />
                <Route path="/contact" component={Contact} />
                <Route path="/articles" component={Articles} />
                <Route path="/bswslevelsystem" component={BswsLevelSystem} />
                <Route
                  path="/BSAScertifiedproject"
                  component={BSASCertifiedProject}
                />
                <Route path="/howtofindwork" component={HowToFindWork} />
                <Route
                  path="/freelanceworldwide"
                  component={FreelanceWorldWide}
                />
                <Route path="/freelancegcc" component={FreelanceGcc} />
                <Route
                  path="/self-certification"
                  component={SelfCertification}
                />
                <Route path="/whygreenbuilding" component={WhyGreenBuilding} />
                <Route
                  path="/propertyresources"
                  component={PropertyResources}
                />
                <Route path="/hireinworld" component={HireInWorld} />
                <Route path="/hiremiddleeast" component={HireMiddleEast} />
                <Route
                  path="/greenbuildingtools"
                  component={GreenBuildingTools}
                />
                <Route path="/sitemap" component={SiteMap} />
                <Route
                  path="/smartpro-product-rating-systems"
                  component={SmartProProductRatingSystems}
                />
                <Route path="/aboutsmartpro" component={AboutSmartPro} />
                {/* <Route path="/termsservice" component={TermsService} /> */}
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/learning-lab" exact={true} component={KnowledgeLab} />
                <Route path="/careers" component={Careers} />
                <Route path="/press" component={Press} />
                <Route path="/partnering" component={Partnering} />
                <Route path="/faqs" component={Faqs} />
                <Route path="/community" component={Community} />
                <Route path="/sustainability" component={Sustainability} exact={true} />
                <Route path="/smartcities" component={SmartCities} />
                <Route path="/buildingtech" component={BuildingTech} />
                <Route path="/energycxa" component={EnergyCXA} />
                <Route path="/ghgclimatechange" component={GHGClimateChange} />
                <Route path="/hsefiresafety" component={HSEFireSafety} />
                <Route path="/Emergingtech" component={EmergingTech} />
                <Route path="/whats-new" component={WhatsNew} />
                <Route path="/manufacturer-membership" component={PrimeMembership} />   
                <Route path="/manufacturer-membership-create-order" component={PrimeMembershipCreateOrder} />                   
                <Route path="/manufacturer-membership-payment-failed" component={PrimeMembershipPaymentFailed} />  
              
                <Route path="/smartpro-products" component={SmartProProducts} />
                <Route path="/product-information/:id" component={ProductInformation} />

                <Route path="/help-support" component={HelpSupport} />
                <Route path="/specialist-level-system" component={SpecialistLevelSystem} />
                <Route path="/specialist-prime-membership" component={SpecialistPrimeMembership} />
                <Route path="/selling-on-beyond-smart-cities" component={SellingOnBSC} />
                <Route path="/how-to-promote-products" component={HowtoPromoteProducts} />
                <Route path="/smart-pro-products-services" component={SmartProProductsServices} />
                <Route path="/smart-pro-product-resources" component={SmartProProductsResources} />
                <Route path="/smartpro-faq" component={SmartProFaq} />               
                <Route path="/BSAS-approved-properties" component={BSASApprovedProperties} />
                <Route path="/BSAS-property-information/:id" component={BSASPropertyInformation} />
                <Route path="/sustainability-tools-resources" component={SustainabilityToolsResources} />




                <Route
                  exact
                  path="/forgetPassword"
                  component={ForgetPassword}
                />


<Route
                  exact
                  path="/forgetPassword/tokenVerification"
                  component={TokenVerification}
                />






                {/* CAT PAGES */}

                <Route path="/category/sustainability-technology/" exact component={CatSustainability} /> 
                <Route path="/category/smartcities-infrastructure/" exact component={CatSmartCities} /> 
                <Route path="/category/green-building-technology/" exact component={CatGreenBuilding} /> 
                <Route path="/category/energy-efficiency-commissioning/" exact component={CatEnergyEfficiency} />  
                <Route path="/category/health-safety-environment-fire-safety/" exact component={CatHSCFireSafety} />  
                <Route path="/category/greenHouse-gases-climate-change/" exact component={CatGreenHouseClimateChange} />  
                <Route path="/category/emerging-design-technology/" exact component={CatEmergingDesign} />  


                
               
 


                <Route path="/EmergingDesign" component={EmergingDesign} />



                 
                <ProtectedRoute
                  
                  path="/knowledgelab/green-building-commissioning"
                  loggedIn={isLoggedIn}
                  exact={true} component={GreenBuildingCommissioning}
                />


      <ProtectedRoute
                  
                  path="/knowledgelab/sustainability-management"
                  loggedIn={isLoggedIn}
                  exact={true} component={SustainabilityManagement}
                />



              <ProtectedRoute
                  
                  path="/knowledgelab/construction-environmental-management-plan"
                  loggedIn={isLoggedIn}
                  exact={true} component={ConstructionEnvironmentalManagementPlan}
                />

                
              <ProtectedRoute
                  
                  path="/knowledgelab/environmental-impact-assessment"
                  loggedIn={isLoggedIn}
                  exact={true} component={EnvironmentalImpactAssessment}
                />


              <ProtectedRoute
                  
                  path="/knowledgelab/advanced-green-building-commissioning"
                  loggedIn={isLoggedIn}
                  exact={true} component={AdvancedGreenBuildingCommissioning}
                />

<ProtectedRoute
                  
                  path="/knowledgelab/artificial-intelligence"
                  loggedIn={isLoggedIn}
                  exact={true} component={ArtificialIntelligence}
                />


<ProtectedRoute
                  
                  path="/knowledgelab/blockchain-technology"
                  loggedIn={isLoggedIn}
                  exact={true} component={BlockchainTechnology}
                />



<ProtectedRoute
                  
                  path="/knowledgelab/digital-transformation"
                  loggedIn={isLoggedIn}
                  exact={true} component={DigitalTransformation}
                />


<ProtectedRoute
                  
                  path="/knowledgelab/internet-of-things"
                  loggedIn={isLoggedIn}
                  exact={true} component={InternetOfThings}
                />



<ProtectedRoute
                  
                  path="/knowledgelab/advanced-building-energy-modelling"
                  loggedIn={isLoggedIn}
                  exact={true} component={AdvancedBuildingEnergyModelling}
                />


<ProtectedRoute
                  
                  path="/knowledgelab/beyond-green-building-technology"
                  loggedIn={isLoggedIn}
                  exact={true} component={BeyondGreenBuildingTechnology}
                />


<ProtectedRoute
                  
                  path="/knowledgelab/beyond-smart-cities"
                  loggedIn={isLoggedIn}
                  exact={true} component={BeyondSmartCities}
                />



<ProtectedRoute
                  
                  path="/knowledgelab/dynamic-meditation"
                  loggedIn={isLoggedIn}
                  exact={true} component={DynamicMeditation}
                />






{/* 
<Route path="/loginn" component={Loginn} />
<SecureRoute path="/roomlist" component={RoomList} />
<SecureRoute path="/addroom" component={AddRoom} />
<SecureRoute path="/chatroom/:room" component={ChatRoom} /> */}


<Route path="/chathome" component={Homepage} />
{/* <Route path="/chats" component={Chatpage} /> */}



        <ProtectedRoute
                  exact
                  path="/chats"
                  loggedIn={isLoggedIn}
                  component={Chatpage}
                /> 
         







              </Switch>
            </ScrollToTop>
          </div>
          <ToastContainer transition={Slide} />
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  return {
    user,
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(App);








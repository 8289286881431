import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiUser,
  BiEnvelope,
  BiPhone,
  BiBadge,
  BiCalendar,
  BiPencil,
  BiCalendarStar,
  BiUserX,
  BiChair,
  BiLocationPlus,
  BiAccessibility,
  BiListCheck,
  BiCheckSquare,
  BiCheckboxSquare,
  BiCheckbox,
} from "react-icons/bi";

export const InfoTip = ({
  prevStep,
  nextStep,
  handleChange,
  values,
  selectedProjectType,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                      <Row>
                        <Col md={6}>
                          <h1 className="mt-4">Please Note</h1>    
                          <h2 className="pt-4 mt-4">In the next few steps you will be asked to identify the property submission category and tick appropriate certification parameters. You will also be asked to upload relevant supporting files in subsequent steps. </h2>
                     
                        </Col>     


                         <Col md={6}>

                      <h2><span style={{height:15, display:'inline-block',borderRadius:30,marginBottom:20,marginTop:40, width:'60%',backgroundColor:'#e3e3e3'}}></span></h2>
                     <BiCheckSquare style={{fontSize:40,color:'#e3e3e3'}}  /><span style={{height:10, display:'inline-block',borderRadius:30, width:200,backgroundColor:'#e3e3e3'}}></span><br/>
                     <BiCheckSquare style={{fontSize:40,color:'#e3e3e3'}} /><span style={{height:10, display:'inline-block',borderRadius:30, width:150,backgroundColor:'#e3e3e3'}}></span><br/>
                     <BiCheckSquare style={{fontSize:40,color:'#e3e3e3'}} /><span style={{height:10, display:'inline-block',borderRadius:30, width:180,backgroundColor:'#e3e3e3'}}></span><br/>
                     <BiCheckbox style={{fontSize:40,color:'#e3e3e3'}} /><span style={{height:10, display:'inline-block',borderRadius:30, width:140,backgroundColor:'#e3e3e3'}}></span><br/>
                        </Col>                    
                     


                   </Row>
                    </Form>


                    <Row className="text-center my-3">
                      <Col>  

                       <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>
                                    
                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>               

                 
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

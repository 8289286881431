import React, { Component } from 'react'
import { FaCompass, FaEuroSign, FaRupeeSign, FaUser, FaUserAlt, FaUserAstronaut, FaUserCircle, FaUserNinja, FaUsersCog } from 'react-icons/fa'

export default class HowToFindWork extends Component {
    render() {
        return (
            <div>
                  <div  class="Nature-banner inner-page">
           <div class="row mb-4">
               <div class="col-md-6 hire p-0">
                         <div class="hire-text-learn">
                              <h1 className='heroText1'>Work the way you want</h1>
                         <p className='heroText2'>Find the right work for you, with great clients, at the world’s work marketplace.</p>
                         <div class="solidBtn mt-3"><a class="fw-600" href="/dashboard">Create Profile</a></div>                       
                         </div>
                        
                  
               </div>
                 <div class="col-md-6 hire-img-layout p-0">
                    
                         <img src="assets/images/find-work.jpg" alt="img" />
                    
                   
               </div>
           </div>
           
           <div class="container px-md-5">
     
            <div class="row bmd-layout">
                   <h2 class="section-title p-tb-20 text-center heroText1a mb-4">How it works</h2>
                
               <div class="col-md-3 ">
                   <FaUsersCog className='float-end' style={{fontSize:60}} />
               </div>
               
                <div class="col-md-7">
                    
                        <h5 className='heroText1b'>Create your profile (it’s free)</h5>
                       <p>An eye-catching title and client-focused overview help us match you to the work you want. Include your work history, your skills, and your photo. Add more, like an introduction video, to create a profile that really stands out.</p>
               </div>
           </div>
             <div class="row bmd-layout pt-3">
               <div class="col-md-3">
               <FaCompass className='float-end' style={{fontSize:60}} />               </div>
               
                <div class="col-md-7">
                    
                        <h5  className='heroText1b'>Explore ways to earn</h5>
                        <p>Work and earn in different ways. Bid for jobs. Pitch your projects. Discuss your in-demand skills with our recruiters so they can find opportunities aligned with your passions and career goals. Do all three. Create a predictable pipeline and build your smart network.</p>
                      </div>
           </div>
             <div class="row bmd-layout pt-3 mb-4">
               <div class="col-md-3">
               <FaRupeeSign className='float-end' style={{fontSize:60}} />               </div>
               
                <div class="col-md-7">
                    
                        <h5 className='heroText1b'>Get paid securely</h5>
                      <p>Choose how you get paid. Our fixed-price protection releases client payments at project milestones. However you work, our service fees are the same. Spend less time chasing, more earning.</p>
                      </div>
           </div>
           
          
           
           
    </div>
           
              </div>
    

            </div>
        )
    }
}

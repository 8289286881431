import React, { Component } from 'react'

export default class PropertyResources extends Component {
    render() {
        return (
            <div>
                 <div  className="Nature-banner inner-page">
           
           <div className="container">
               
               
                          
          
           <div className="row mt-3 mb-4  Resources-layout">
                        <div className="col-md-4">
                         <h4>Resources</h4>
                           </div>
                             <div className="col-md-8">
                               <form>
                                  <input className="form-control " type="search" placeholder="Type to Search" aria-label="Search" />
                               </form>
                           </div>
                           </div>
          
          
          
             <div className="row Resources mb-4">
                 
            
             <div className="col-md-4 articles-btn">
                 
    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">  Addenda  </button>
      <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Bsas Resources</button>
      <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Calculators</button>
      <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Checklists </button>
        <button className="nav-link" id="v-pills-Certificatio-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Certificatio" type="button" role="tab" aria-controls="v-pills-Certificatio" aria-selected="false">  Certificatio   </button>
      <button className="nav-link" id="v-pills-Guidance-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Guidance" type="button" role="tab" aria-controls="v-pills-Guidance" aria-selected="false">Guidance</button>
      <button className="nav-link" id="v-pills-Ratingsystem-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Ratingsystem" type="button" role="tab" aria-controls="v-pills-Ratingsystem" aria-selected="false">Rating system</button>
      <button className="nav-link" id="v-pills-Standards-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Standards" type="button" role="tab" aria-controls="v-pills-Standards" aria-selected="false">  Standards </button>
     <button className="nav-link " id="v-pills-CredentialingResources-tab" data-bs-toggle="pill" data-bs-target="#v-pills-CredentialingResources" type="button" role="tab" aria-controls="v-pills-CredentialingResources" aria-selected="false">Credentialing Resources</button>
      <button className="nav-link" id="v-pills-CandidateHandbooks-tab" data-bs-toggle="pill" data-bs-target="#v-pills-CandidateHandbooks" type="button" role="tab" aria-controls="v-pills-CandidateHandbooks" aria-selected="false">Candidate Handbooks</button>
      <button className="nav-link" id="v-pills-Certificate-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Certificate" type="button" role="tab" aria-controls="v-pills-Certificate" aria-selected="false">Certificate</button>
      <button className="nav-link" id="v-pills-Studyguides-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Studyguides" type="button" role="tab" aria-controls="v-pills-Studyguides" aria-selected="false">Study guides</button>
     <button className="nav-link " id="v-pills-AdvocacyPolicy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-AdvocacyPolicy" type="button" role="tab" aria-controls="v-pills-AdvocacyPolicy" aria-selected="false">Advocacy & Policy</button>
      <button className="nav-link" id="v-pills-Reports-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Reports" type="button" role="tab" aria-controls="v-pills-Reports" aria-selected="false">Reports</button>
      <button className="nav-link" id="v-pills-PolicyCaseStudies-tab" data-bs-toggle="pill" data-bs-target="#v-pills-PolicyCaseStudies" type="button" role="tab" aria-controls="v-pills-PolicyCaseStudies" aria-selected="false">Policy Case Studies</button>
      <button className="nav-link" id="v-pills-PolicyBriefs-tab" data-bs-toggle="pill" data-bs-target="#v-pills-PolicyBriefs" type="button" role="tab" aria-controls="v-pills-PolicyBriefs" aria-selected="false">Policy Briefs </button>
      <button className="nav-link" id="v-pills-CommentandLetters-tab" data-bs-toggle="pill" data-bs-target="#v-pills-CommentandLetters" type="button" role="tab" aria-controls="v-pills-CommentandLetters" aria-selected="false">Comment and Letters</button>
    
    
    
    
    </div>  
    </div>
    <div className="col-md-8">
    <div className="tab-content" id="v-pills-tabContent">
      <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                       <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
      </div>
      <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
          
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
        
        
        
        
        
        
        
         </div>
      <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
          <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
      </div>
      <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
       <div className="tab-pane fade" id="v-pills-Certificatio" role="tabpanel" aria-labelledby="v-pills-Certificatio-tab">
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
  
      </div>
       <div className="tab-pane fade" id="v-pills-Guidance" role="tabpanel" aria-labelledby="v-pills-Guidance-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
       <div className="tab-pane fade" id="v-pills-Ratingsystem" role="tabpanel" aria-labelledby="v-pills-Ratingsystem-tab">
          <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
      </div>
      <div className="tab-pane fade" id="v-pills-Standards" role="tabpanel" aria-labelledby="v-pills-Standards-tab">
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
  
      </div>
       <div className="tab-pane fade" id="v-pills-CredentialingResources" role="tabpanel" aria-labelledby="v-pills-CredentialingResources-tab">
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
  
      </div>
       <div className="tab-pane fade" id="v-pills-CandidateHandbooks" role="tabpanel" aria-labelledby="v-pills-CandidateHandbooks-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
       <div className="tab-pane fade" id="v-pills-Certificate" role="tabpanel" aria-labelledby="v-pills-Certificate-tab">
          <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
      </div>
      <div className="tab-pane fade" id="v-pills-Studyguides" role="tabpanel" aria-labelledby="v-pills-Studyguides-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
       <div className="tab-pane fade" id="v-pills-AdvocacyPolicy" role="tabpanel" aria-labelledby="v-pills-AdvocacyPolicy-tab">
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
  
      </div>
       <div className="tab-pane fade" id="v-pills-Reports" role="tabpanel" aria-labelledby="v-pills-Reports-tab">
    <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
  
      </div>
       <div className="tab-pane fade" id="v-pills-PolicyCaseStudies" role="tabpanel" aria-labelledby="v-pills-PolicyCaseStudies-tab">
          <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
      </div>
      <div className="tab-pane fade" id="v-pills-PolicyBriefs" role="tabpanel" aria-labelledby="v-pills-PolicyBriefs-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
       <div className="tab-pane fade" id="v-pills-CommentandLetters" role="tabpanel" aria-labelledby="v-pills-CommentandLetters-tab">
    
  <div className="row mt-4">
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-1.png" alt="Beyond Smart Cities" />
                                         <p>Advocacy & Policy PDF</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-2.png" alt="Beyond Smart Cities" />
                                         <p>BSAS manual</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                        <img src="assets/images/resourse-3.png" alt="Beyond Smart Cities" />
                                         <p>BSAS guide</p>
                                     </div>
                                 </div>
                                 <div className="col-md-3">
                                     <div className="card-r">
                                         <img src="assets/images/resourse-4.png" alt="Beyond Smart Cities" />
                                         <p>Energy guide</p>
                                     </div>
                                 </div>
                                 
                             </div>
      
  
      </div>
  
    </div>
  </div>
           
      </div>
     </div>
  
               
               
               
                           
                  
                                      
                                      
                                       
                                     
                               
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
           </div>
            </div>
        )
    }
}

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import validator from "validator";
import { validate_Alpha_Length } from "../../../helpers/Utilities";
import { validate_Alpha_Email_Length } from "../../../helpers/Utilities";
import { validate_MobilePhone_Length } from "../../../helpers/Utilities";

export const IndoorEnvironment = ({
  prevStep,
  nextStep,
  handleChange,
  values,
}) => {
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3 pt-3">
                  <Container fluid>
                    <Form>
                    <Row className="property-checklist-holder">
                        <Col md={4} className="property-checklist-left-col">
                          <h2>INDOOR ENVIRONMENT(IE)</h2>
                          <Row>
                    <p className="mt-2">
                            The Indoor Environment category is concerned with
                            the quality of the indoor environment for the
                            comfort, health and well-being of occupants and
                            users. People spend most of their time indoors and
                            the quality of the indoor environment has a direct
                            impact on their health, comfort, well-being and
                            satisfaction.
                          </p>
                      </Row>

                          <img
                            className="img-fluid pt-3"
                            src="assets/images/indoorenvironment.png"
                          ></img>
                         
                        </Col>
                        <Col md={8} className="mt-4">
                          <Row>
                            <Col>
                              <h3 className="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1">
                                30 Possible Points
                              </h3>
                              <a href="#guidechart"  class="float-end bdr-1 px-3 py-2 bdr-rad-30 bg-green c1 me-3 cursor-pointer fw-600">Guide Chart</a>

                            </Col>
                          </Row>
                          <h3 className="mt-5 mb-3">
                            Check appropriate one here
                          </h3>
                          <Row>
                            <Col>
                              <Form.Check                                
                                label="Environmental Tobacco Smoke (ETS) Control"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Environmental Tobacco Smoke (ETS) Control"
                                id={`inline-checkbox-1`}
                              checked= {values?.indoorEnvironment?.includes('Environmental Tobacco Smoke (ETS) Control') ? true : false}
                              />

                              <Form.Check                                
                                label="Low-Emitting Materials"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Low-Emitting Materials"
                                id={`inline-checkbox-25`}
                              checked= {values?.indoorEnvironment?.includes('Low-Emitting Materials') ? true : false}
                              />

                              <Form.Check                                
                                label="Construction Indoor Air Quality Management Plan"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Construction Indoor Air Quality Management Plan"
                                id={`inline-checkbox-38`}
                              checked= {values?.indoorEnvironment?.includes('Construction Indoor Air Quality Management Plan') ? true : false}
                              />

                              <Form.Check                                
                                label="Daylight Harvesting"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Daylight Harvesting"
                                id={`inline-checkbox-44`}
                              checked= {values?.indoorEnvironment?.includes('Daylight Harvesting') ? true : false}
                              />

                              <Form.Check                                
                                label="Natural Ventilation"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Natural Ventilation"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Natural Ventilation') ? true : false}
                              />
                            </Col>
                            <Col>
                              <Form.Check                                
                                label="Mechanical Ventilation"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Mechanical Ventilation"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Mechanical Ventilation') ? true : false}
                              />

                              <Form.Check                                
                                label="Acoustic Performance"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Acoustic Performance"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Acoustic Performance') ? true : false}
                              />

                              <Form.Check                                
                                label="Glare Control"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Glare Control"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Glare Control') ? true : false}
                              />

                              <Form.Check                                
                                label="Quality Views"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Quality Views"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Quality Views') ? true : false}
                              />

                              <Form.Check                                
                                label="Indoor Chemical and Pollutant Source Control"
                                name="group1"
                                type={"checkbox"}
                                onChange={handleChange()}
                                name="Indoor Chemical and Pollutant Source Control"
                                id={`inline-checkbox-45`}
                              checked= {values?.indoorEnvironment?.includes('Indoor Chemical and Pollutant Source Control') ? true : false}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="text-center my-3">
                      <Col>
                        <Button
                          onClick={Previous}
                          type="submit"
                          className="btn btn-primary-trans btn-block me-2"
                        >
                          Previous
                        </Button>

                        <Button
                          onClick={Continue}
                          type="submit"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={false}
                          className="btn btn-primary btn-block c1"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>

                   

                    <Row id="guidechart" className="p-3">
                      <img
                        className="img-fluid pt-3"
                        src="assets/images/indoorenvironment-desc.png"
                      ></img>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

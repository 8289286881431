import React, { Fragment, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FiArchive, FiFile, FiUser } from "react-icons/fi";
import FormTopbar from '../SubmitProduct/FormTopbar'

export const UploadProductDocuments = ({
  prevStep,
  nextStep,
  handleChangeProductDocuments,
  values,
  addClick,
  removeClick,
  ImageUploading,
  maxNumber,
  onChangeImage,
  file,
  deleteFile,
  step
}) => {

  
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };





  return (
    <div>
      <section className="lightgrey-bg user-area log-in-area topadjust">
        <div className="container-fluid">
          <div className="user-form-content">
            <div className="row">
              <div className="col-md-12">
                <div className="form-card mt-3">
                <Row>
                      <FormTopbar step={step} />
                    </Row>
                  <Container>                  
                    <Form>
                      <Form.Group>
                        <Form.Label>Upload Product Documents</Form.Label>
                        <Form.Text className="text-muted">
                          <p>Maximum 4 images</p>
                        </Form.Text>{" "}
                      </Form.Group>

                      <Row className="align-item-center justify-content-center ">
                       
                          <div className="App">
                            <div class="ImageDropArea position-relative w-100 bg-4 bdr-dotted bdr-rad-30 p-4">
                              <form>
                                <div className="form-group preview row">
                                  {file.length > 0 &&
                                    file.map((item, index) => {
                                      return (
                                        <Col md={2}>
                                        <div key={item}>  
                                           <FiFile className="img-fluid mb-2 bdr-rad-4" style={{height:60,width:60}} />  
                                           <p style={{fontSize:14,wordBreak:'break-word'}}>{item?.name}</p>                                                                       
                                          {/* <img className="img-fluid bdr-3 mb-2 bdr-rad-4" style={{height:100}} src={item} alt="" />                                         */}
                                          <button
                                            type="button"
                                            className="delete-button"
                                            onClick={() => deleteFile(index)}
                                          >
                                            delete
                                          </button>
                                        </div>
                                        </Col>
                                      );
                                    })}
                                </div>

                                <div className="form-group mt-5 col-md-6 offset-md-3">
                                  <label className="mb-2" style={{fontSize:14,fontWeight:600}}>Choose pdf Document to Upload</label>
                                  <input
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    disabled={file.length === 4}
                                    className="form-control"
                                    onChange={handleChangeProductDocuments}
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                       
                      </Row>

                      <Row className="text-center my-3">
                        <Col>
                          <Button
                            onClick={Previous}
                            type="submit"
                            className="btn btn-primary-trans btn-block me-2"
                          >
                            Previous
                          </Button>

                          <Button
                            onClick={Continue}
                            type="submit"
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            disabled={false}
                            className="btn btn-primary btn-block c1"
                          >
                            Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

import React, { Component } from 'react'
import {Link} from 'react-router-dom';
export default class BsasRating extends Component {
    render() {

        return (
            <div>
                 <div  className="Nature-banner inner-page">
         
         <div className="basa-rating">
     
           <div className="patner-trs ">
               
                   
                   
                           <div className="row  text-center mobile-basa-rating-text py-1 ">
                            <h2 className="section-title p-tb-20 text-center heroText1 c1 pt-5">BSAS Rating System</h2>
                            <p className='="heroText2 c1'>Green Technology leadership is BSAS</p>
                            <div>
                            <div class="solidBtn mt-3 ms-3">
                  <a className="fw-600" href="/dashboard">Start a Project</a>
                </div>
                            
         </div>
  
    </div>
                  
               
          
          
                 
          </div> 
          </div>


    
 <div className="container mb-4 mobile-basa-rating">
               
           <div className="row">
       <div className="col-md-12 ">
           <h2 className="text-center heroText1a  py-5">Smart buildings equal smart lives</h2>
          
            <p className="text-center heroText2 c3">The  Beyond  Smart  Cities  is  leading  the  international  movement  to  transform  our  buildings,  schools  and  communities  in  ways  that  help  individuals  thrive.  Beyond  Smart Cities delivers the cutting-edge Beyond Sustainability Assessment System(BSAS)Standard, the leading international rating system and the f irst to be focused exclusively on the  ways  that  buildings,  and  everything  in  them,  can  improve  our  comfort,  drive  better  choices,  and  generally  enhance,  not  compromise,  our  health,  environment, sustainability & wellness.</p>
          
          <div className='bdr-2 py-4 my-5'>
            <h2 className="text-center heroText1a">Stay engaged</h2>
              <p className='text-center'>To get latest updates request us to <span style={{textDecoration:'underline'}} >newsletter@beyondsmartcities.in</span></p>
              </div> 
               
               {/* <div className="text-center">
                    <form className="email-form">
                  <input type="email" placeholder="Enter your email address*" requried />
              </form>
              <br/>
              <button><Link to="/signin">Sign Up</Link></button>
               </div> */}
              
           </div>
           
           
           
           
           
           
                 </div>
                 
              
                   
                 
                 <h2 className="text-center heroText1a py-5">A BSAS for every project</h2>
                   
                   <p className="text-center heroText2">BSAS  is  the  leading  tool  for  advancing,  environment,  health  and  well-being  in  buildings  globally.  Register  your  off ice,  school  building  or  other  space  to  leverage BSAS’s flexible f ramework for improving environment, health and human experience through design.BSAS is for all building types and all building phases including new construction, interior f it outs, operations and maintenance and core and shell.</p>
                  <hr />
                   <h2 className="text-center heroText1a py-5">How does BSAS work ?</h2>
                   
                   <p className="text-center heroText2">Projects  pursuing  BSAS  certif ication  earn  points  for  various  green  building  strategies  across  several  categories  based  on  the  number  of  points  achieved,  a  project earns one of six BSAS rating levels: <br/><br/> 1 ⭐ Star, 2 ⭐ Star,3⭐ Star,4 ⭐ Star,5 ⭐ Star and 6 ⭐ Star. </p>
                  
                  
                  <div className="row text-center mt-4">
                    
                           <div className="col-md-2 img-1">
                             <img src="assets/images/1-star.png" alt="smartcity" />
                              
                             <p>10-19 points earned</p>
                          </div>
                            <div className="col-md-2 img-2">
                             <img src="assets/images/2-stars.png" alt="smartcity" />
                           
                             <p>20-29 points earned</p>
                          </div> 
                             <div className="col-md-2 img-3">
                             <img src="assets/images/3-stars.png" alt="smartcity" />
                             
                             <p>30-39 points earned</p>
                          </div> 
                            <div className="col-md-2 img-4">
                             <img src="assets/images/4-stars.png" alt="smartcity" />
                           
                             <p>40-49 points earned</p>
                          </div>
                           <div className="col-md-2 img-5">
                             <img src="assets/images/5-stars.png" alt="smartcity" />
                         
                             <p>50-59 points earned</p>
                          </div> 
                           <div className="col-md-2 img-6">
                             <img src="assets/images/6-stars.png" alt="smartcity" />
                          
                             <p>60+ points earned</p>
                          </div> 
                          
                          
                        
                        
                            
                          <div>
                          <div class="ghostBtn mt-3 ms-3 mb-5">
                  <a href="/dashboard">Register as BSAS Project</a>
                </div>
                          </div>
                       
                         
                  </div>
                  
                  
                  
                  
                  
                  
                  
                  
                  
           
</div>
</div>
</div>

           
        )
    }
}

import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import Loader from "react-js-loader";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { BiCheck, BiRupee } from "react-icons/bi";
import { connect } from "react-redux";
import { setServiceCart } from "../../actions/cart";
import { Link } from "react-router-dom";

const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

class ServiceInformation extends Component {
  constructor(props) {
    super(props);
    this.state={
      serviceId:this.props.match.params.serviceid, 
      paymentId:Math.floor(Math.random() * 99999),
      specialistId:this.props.match.params.specialistid,
      serviceData:'',
      galleryItems:''   ,
      serviceList:'' 
    }
    // this.addNewProperty = this.addNewProperty.bind(this);
  }

  componentDidMount() {
    this.getServiceInformation();
    this.getSpecialistServices()
  }


  goToCreateOrder(packagename,price){
    const { dispatch, history } = this.props;

    let cartData={
      cartAmount:price,
      packagename:packagename,
      serviceId:this.state.serviceId,
      specialistId:this.state.specialistId,
      paymentId:this.state.paymentId,
      authorName:this.state.serviceData?.createdBy?.personalInfo.firstName + this.state.serviceData?.createdBy?.personalInfo.lastName,
      gigTitle:this.state.serviceData?.overview?.gigTitle
    }
    localStorage.setItem("cartItems", JSON.stringify(cartData));
    dispatch(setServiceCart(
      cartData
    ))
    .then(() => {
      this.setState({
        successful: true,
        spinner: false,
        submitProgress: false,
      });
    })
    localStorage.setItem('orderAmount', price)
    localStorage.setItem('packageName', packagename)
    let packageDetails=this.state.serviceData?.packageDetails.find( i => i.name==packagename )
    console.log('pd',this.state.serviceId)
    localStorage.setItem('packageDetails', JSON.stringify(packageDetails))
    this.props.history.push('/create-order/'+this.state.serviceData._id + '/' + this.state.specialistId + '/' +this.state.paymentId)
  }




  goToServiceDetail(serviceId,specId){
    console.log('p', serviceId, specId)
    const win = window.open('/service-information/'+serviceId+'/'+ specId, "_blank");
  win.focus();

//this.props.history.push('/service-information/'+serviceId+'/'+ specId)
  }

  async getSpecialistServices() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "specialist/" + this.state.specialistId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log("SPEC", response);

          if (response.data.success === true) {
            self.setState({             
              serviceList: response.data.serviceList
            });

  
 

            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }


  async getServiceInformation() {
    console.log('isi',this.state.serviceId)
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "service/" + this.state.serviceId, {
        headers: {         
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log('RRR',response);

          if (response.data.success === true) {
            self.setState({
              serviceData:response.data.serviceData
            });
            let gal=[]
            response.data.serviceData?.serviceGallery?.map(i=>
           
            gal.push({              
              "original":i.secureUrl,
              "thumbnail":i.secureUrl   
            })
                
            )
            console.log(gal)
            self.setState({
              galleryItems:gal,
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }


  goToSpecialistDetail(specialistId){
    console.log('p')
    this.props.history.push({
      pathname: '/specialist-information/'+specialistId           
    })

// this.props.history.push('/specialist-information')

  }

  render() {
    return (
      <div className="page-container">
        <div className="page-inner">
          <Container fluid>
          <Row>           
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item active>{this.state.serviceData?.overview?.gigTitle}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              
            </Row>

            <div className=" card-r p-4">
              <Row>
                <Col>
                <h2>{this.state.serviceData?.overview?.gigTitle}</h2>
                </Col>
                </Row>
            <Row class="mb-4"> 
              
             {this.state.galleryItems?.length?
                      <div class="col-md-5 p-2 gallery-image-holder">                             
                             <ImageGallery items={this.state.galleryItems} /> 
                         </div>
                         :
                         <div class="col-md-5 p-2 bg-4 gallery-image-holder" style={{minHeight:300}}>                             
                            <img src="https://picsum.photos/id/1031/5446/3063.jpg" className="img-fluid" />
                         </div>
  }
                         
                         <div class="col-md-7 ">
                             {/* PACKAGE SECTION */}

                             <Row className="p-3">
                                 <h3 className="c4">Select Pricing Package</h3>
                                 <Col>
                                 <div className="package-outer shd-1 bdr-rad-4">
                                     <ul className="packagetableList packList">
                                         
                                        {this.state.serviceData.packageDetails && this.state.serviceData?.packageDetails.length?
                                         <>
                                        <h3 className="bg-none">{this.state.serviceData?.packageDetails[0]?.name}</h3>
                                       
                                        <li>{this.state.serviceData?.packageDetails[0]?.featuresList[0]}</li>
                                         <li>{this.state.serviceData?.packageDetails[0]?.featuresList[1]}</li>
                                         <li>{this.state.serviceData?.packageDetails[0]?.featuresList[2]}</li>
                                         <li>{this.state.serviceData?.packageDetails[0]?.featuresList[3]}</li>                                         
                                         <h3 className="mt-3 bg-none"><BiRupee />{' '} {this.state.serviceData?.packageDetails[0]?.price}</h3>
                                         <h3 onClick={()=>this.goToCreateOrder(this.state.serviceData?.packageDetails[0]?.name,this.state.serviceData?.packageDetails[0]?.price)} className="basic mt-3 cursor-pointer">Order Now</h3>
                                         </>
                                         :null}
                                     </ul>
                                 </div>
                                 </Col>
                                 <Col>
                                 <div className="package-outer shd-1 bdr-rad-4">
                                     <ul className="packagetableList packList ">
                                     {this.state.serviceData.packageDetails && this.state.serviceData?.packageDetails.length?
                                        <>
                                     <h3 className="bg-none">{this.state.serviceData?.packageDetails[1]?.name}</h3>
                                        <li>{this.state.serviceData?.packageDetails[1]?.featuresList[0]}</li>
                                         <li>{this.state.serviceData?.packageDetails[1]?.featuresList[1]}</li>
                                         <li>{this.state.serviceData?.packageDetails[1]?.featuresList[2]}</li>
                                         <li>{this.state.serviceData?.packageDetails[1]?.featuresList[3]}</li>                                         
                                         <h3 className="mt-3 bg-none"><BiRupee />{' '}{this.state.serviceData?.packageDetails[1]?.price}</h3>
                                         <h3 onClick={()=>this.goToCreateOrder(this.state.serviceData?.packageDetails[1]?.name,this.state.serviceData?.packageDetails[1]?.price)} className="basic mt-3 cursor-pointer">Order Now</h3> 
                                         </>
                                         :null}                                        
                                     </ul>
                                 </div>
                                 </Col>
                                 <Col>
                                 <div className="package-outer shd-1 bdr-rad-4">
                                     <ul className="packagetableList packList">
                                     {this.state.serviceData.packageDetails && this.state.serviceData?.packageDetails.length?
                                        <>
                                     <h3 className="bg-none">{this.state.serviceData?.packageDetails[2]?.name}</h3>
                                        <li>{this.state.serviceData?.packageDetails[2]?.featuresList[0]}</li>
                                         <li>{this.state.serviceData?.packageDetails[2]?.featuresList[1]}</li>
                                         <li>{this.state.serviceData?.packageDetails[2]?.featuresList[2]}</li>
                                         <li>{this.state.serviceData?.packageDetails[2]?.featuresList[3]}</li>                                         
                                         <h3 className="mt-3 bg-none"><BiRupee />{' '}{this.state.serviceData?.packageDetails[2]?.price}</h3>
                                         <h3 onClick={()=>this.goToCreateOrder(this.state.serviceData?.packageDetails[2]?.name,this.state.serviceData?.packageDetails[2]?.price)} className="basic mt-3 cursor-pointer">Order Now</h3>
                                         </>
                                         :null}
                                     </ul>
                                 </div>
                                 </Col>
                                 <p className="fs-12">The Order amount mentioned above is exclusive of GST. GST applicable at 18%.</p>
                             </Row>
                             
                         </div>
                         
               </Row>
               </div>
               <Row>
                   <Col>
                   <div class="chip mt-4">
                  { this.state.serviceData?.createdBy?.personalInfo?.profilePic?.secureUrl?
                        <img
                          src={this.state.serviceData?.createdBy?.personalInfo?.profilePic?.secureUrl}
                          alt="Person"
                          width="96"
                          height="96"
                        />
                        :
                        <img
                        src="/assets/images/beyond-business-4.jpeg"
                        alt="Person"
                        width="96"
                        height="96"
                      />
                  }
                  <Link
                              onClick={() =>
                                this.goToSpecialistDetail(this.state.serviceData?.createdBy?._id)
                              }
                            >
                              {" "}
                     <span className="fw-700" >{this.state.serviceData?.createdBy?.personalInfo.firstName}{' '} {this.state.serviceData?.createdBy?.personalInfo.lastName}</span>
                     </Link>
                      </div>
                      </Col>
                   </Row>
               <Row>
               <div class="slider-content">
                               <h2 class="mb-2">Description</h2>
                               <p className="fw-700">{this.state.serviceData?.overview?.description}</p>
                            
                              
                             
                             </div>

               </Row>







               <Row>
                                <Col className="DetailTableList mx-0 mb-5 mt-5 p-4">
                                  <h2 className="mb-3">Frequently Asked Questions</h2>
                                  <Row>
                                    {this.state.serviceData &&
                                    this.state.serviceData.faq.length
                                      ? this.state.serviceData.faq.map(
                                          (item) => (
                                            <Col md={12}>
                                              <h3>
                                                <BiCheck /> {item.question}
                                              </h3>
                                              <p className="ms-1 ps-3">
                                                {/* <BiCircle className="me-2" /> */}
                                                {item.answer}
                                              </p>
                                            </Col>
                                          )
                                        )
                                      : "No FAQ's Submitted"}
                                  </Row>
                                </Col>
                              </Row>








               <Row class=" Sustainable-inner-design">
             
             <h2 className="headerwithBG">Services Published by Author</h2>

                    {this.state.serviceList?.length ?
                    this.state.serviceList.map((item)=>
                  <>
                     <div class="col-md-3 cursor-pointer mb-3">                    
                    <div class="card-r  shd-1">

                  { item.serviceGallery?.length ?
                    
                          <img
                          onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}
                            src={item?.serviceGallery[0]?.secureUrl}
                            alt="Person"                             
                            height="180"
                            style={{objectFit:'cover'}}
                          />
                   
                          :
                          
                          <img
                          onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}
                            src="/assets/images/beyond-business-4.jpeg"
                            alt="Person"
                            height="180"
                            style={{objectFit:'cover'}}
                          />
                    
                  }

                      <div class="card-content">
                      
                        <h3 className="mb-4 c3"
                        onClick={() => this.goToServiceDetail(item?.overview.slug,item?.createdBy)}>
                          {item?.overview?.gigTitle}                           
                        </h3>
                        <Row>
                          <Col md={4}>
                            <p>⭐4.9(1)</p>
                          </Col>
                          <Col md={1}>
                            {/* <i class="fas fa-heart"></i> */}
                          </Col>
                          <Col>
                            <h3 className="float-end c4">Starts{ } <BiRupee /> {item?.packageDetails[0].price}</h3>
                          </Col>
                         
                        </Row>
                      </div>
                      </div>
                      </div>
                      </>
                    )
                    : null
                }
                                
                       
          </Row>

          </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  const { cartItems } = state.cart;
  console.log(cartItems)
  return {
    user,
    isLoggedIn,
    cartItems
  };
}

export default connect(mapStateToProps)(ServiceInformation);


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "./../../config";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import  Categories  from '../../services/Categories'
import  SubCategories  from '../../services/SubCategories'
import SpecialistDashboardSidebar from "./SpecialistDashboardSidebar";
import ImageUploading from "react-images-uploading";

export default class ServiceGallery extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,
      categories:[],
      subcategories:[],
      gigTitleError:'',

      data: {
           ServiceGallery:'',
           demoVideo:''
         },        

      errorMessage: "",     
    };
  }
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.history.push("/dashboard");    
    }
    Categories.getCategories().then(responseCat=>{
        this.setState({categories:responseCat.categoriesList})
console.log(responseCat.categoriesList)
    })

    SubCategories.getSubCategories().then(responseSubcat=>{
        this.setState({subcategories:responseSubcat.subCategoriesList})
        console.log(responseSubcat)

    })
  }




   
 
  async handleUpdate(e) {
    e.preventDefault();
    
      this.setState({ spinner: true });
      var self = this;
      self.setState({
        submitLoading: true,
        errorMessage: "",
      });

    let formData = new FormData();   
    for (let i = 0; i < this.state.data.ServiceGallery.length; i++) {
    formData.append('serviceGallery', this.state.data.ServiceGallery[i].file);
    }
    const token = await JSON.parse(localStorage.getItem("token"));
      // Post the form, just make sure to set the 'Content-Type' header
      await axios
      .post(API_URL + "specialist/create", formData, {
        headers: {
          auth: token,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })

        .then(
          function (response) {
            console.log(response);
            //handle success
console.log(response)
            if (response.data.success === true) {
                
              localStorage.setItem(
                "user",
                JSON.stringify(response.data.userInfo)
              );
              setTimeout(() => {
                self.setState({
                  submitLoading: false,
                  isdataSubmitted: true,
                  spinner: false,
                });

                self.props.history.push("/profile");
                toast("Profile Updated Successfully", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }, 1000);
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                spinner: false,
              });
              toast("Thers is an Error in the input fields. Please check!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        );
    } 
  


  // Handle fields change
  handleImageUpload = (imageList, addUpdateIndex) =>  {
    let file = imageList.map(a => a.file);
    console.log('ONLYfile', file)

    console.log(imageList, addUpdateIndex); 
    this.setState((prevState) => ({
      data:{
        ...prevState.data, 
        ServiceGallery:imageList
    },
    }));

    console.log("PERS", this.state.data);
  };
 


 
  render() {

    if(this.state.categories===null){
     return null; //Or some other replacement component or markup
  }
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust specialist-dashboard">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
                      </Breadcrumb>

                      <Form>
                        <Row>
                          <Col
                            xs
                            lg="2"
                            style={{
                              backgroundColor: "#eee",
                              borderRadius: 6,
                            }}
                            className="d-block"
                          >
                            <SpecialistDashboardSidebar />
                          </Col>

                          <Col className="dashboard-content-area">
                            <div className="dashboard-content-actions">
                              <Row>
                                <Col>
                                  <div className="dashboard-content-title">
                                    <Link
                                      onClick={() =>
                                        this.props.history.goBack()
                                      }
                                    >
                                      {" "}
                                      <IoReturnUpBackOutline className="mx-3" />
                                    </Link>
                                    Update Service Gallery
                                  </div>
                                </Col>
                                <Col>
                                  <div className="dashboard-content-action float-end">
                                    <Button
                                      onClick={this.handleUpdate}
                                      type="submit"
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                      }}
                                    >
                                      {" "}
                                      {this.state.spinner ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <span className="d-inline p-2">
                                        Submit
                                      </span>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>                                                 

                      
                         

                      

                      </Col>
                        </Row>
                     

                      </Form>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}


import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Breadcrumb,
  Spinner,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  BiUserCircle,
  BiAperture,
  BiCart,
  BiCertification,
  BiChat,
  BiCog,
  BiCheck,
  BiCircle,
  BiDotsVertical,
  BiSad,
  BiAlarmExclamation,
} from "react-icons/bi";
import axios from "axios";
import { API_URL } from "./../../../config";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoReturnUpBackOutline } from "react-icons/io5";
import Categories from "../../../services/Categories";
import SubCategories from "../../../services/SubCategories";
import ImageUploading from "react-images-uploading";
import SpecialistDashboardSidebar from "../SpecialistDashboardSidebar";
import moment from "moment";
import Loader from "react-js-loader";
import DashboardSidebar from "../../DashboardSidebar";
const deleteText="DELETE"
export default class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.addNewProperty = this.addNewProperty.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      spinner: false,
      propertyId: this.props.match.params.id,
      notice: false,
      userData: JSON.parse(localStorage.getItem("user")),
      serviceId: this.props.match.params.id,
      // serviceslug: this.props.match.params.id,
      serviceData: "",
      showDeleteModal: false,
      deleteConfirmed: false,
      deleteTextConfirmed:false,
      data: {
        serviceGallery: [],
        demoVideo: "",
      },
    };
  }
  componentDidMount() {
 

    if (this.state.userData.isEmailVerified) {
      this.setState({ notice: false });
    } else {
      setTimeout(() => {
        this.setState({ notice: true });
      }, 3000);
    }

    this.getServiceDetails();
  }

  editProfile = () => {
    this.props.history.push("/profile-edit");
  };

  editService = () => {
    this.props.history.push("/service-edit/" + this.state.serviceId);
  };

  dismissNotice = () => {
    this.setState({ notice: !this.state.notice });
  };

  addNewProperty = () => {
    this.props.history.push("/submit-property");
  };
  validateField(fieldName) {
    if (fieldName === "dob") {
    }
  }

  deleteConfirm = () => {
    console.log('h')
    this.setState({
      showDeleteModal: true,
    });
  };

  hideDeleteModal(){
    this.setState({
      showDeleteModal: false,
    });
  };

  checkDeleteText = (e) => {
    console.log(e)
    if (e.target.value === "DELETE") {
      this.setState({
        deleteTextConfirmed: true,
      });
    } else {
      this.setState({
        deleteTextConfirmed: false,
      });
    }
  };

  deleteService = async (serviceId) => {
    console.log('hh')
    let self = this;
    if (this.state.deleteTextConfirmed) {
     
      const token = await JSON.parse(localStorage.getItem("token"));
      await axios
        .delete(API_URL + "service/" + serviceId, {
          headers: {
            auth: token,
            Accept: "application/json",
          },
        })

        .then(
          function (response) {
            console.log("DELETE", response.data.serviceData);

            if (response.data.success === true) {
              toast("Service Deleted Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });

              self.props.history.goBack();

              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                dataLoading: false,
              });
            } else {
              self.setState({
                errorMessage: response.data.error,
                submitLoading: false,
                dataLoading: false,
              });
            }
          },
          (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            // console.log(message)
            self.setState({
              errorMessage: message,
              submitLoading: false,
              dataLoading: false,
            });
          }
        );
    }
  };

  async getServiceDetails() {
    var self = this;
    self.setState({
      step: 10,
      submitLoading: true,
      errorMessage: "",
      dataLoading: true,
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    await axios
      .get(API_URL + "service/" + this.state.serviceId, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(
        function (response) {
          console.log("SD", response.data.serviceData);

          if (response.data.success === true) {
            self.setState({
              serviceData: response.data.serviceData,
            });
            self.setState({
              submitLoading: false,
              isdataSubmitted: true,
              dataLoading: false,
            });
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              dataLoading: false,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            dataLoading: false,
          });
        }
      );
  }

  async handleUpdate(e) {
    e.preventDefault();
    var self = this;
    self.setState({ spinner: true });
    console.log("SG", this.state.data.serviceGallery);
    self.setState({
      submitLoading: true,
      errorMessage: "",
    });

    let formData = new FormData();
    formData.append("specialistId", this.state.userData?.specialistId._id);
    for (let i = 0; i < this.state.data.serviceGallery.length; i++) {
      formData.append("serviceGallery", this.state.data.serviceGallery[i].file);
    }
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .put(API_URL + "service/" + this.state.serviceId, formData, {
        headers: {
          auth: token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      .then(
        function (response) {
          console.log("R", response);
          //handle success
          console.log(response);
          if (response.data.success === true) {
            setTimeout(() => {
              self.setState({
                submitLoading: false,
                isdataSubmitted: true,
                spinner: false,
              });

              //self.props.history.goBack();
              self.getServiceDetails();
              toast("Image Uploaded Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }, 1000);
          } else {
            self.setState({
              errorMessage: response.data.error,
              submitLoading: false,
              spinner: false,
            });
            toast("Thers is an Error in the input fields. Please check!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // console.log(message)
          self.setState({
            errorMessage: message,
            submitLoading: false,
            spinner: false,
          });
          toast("Thers is an Error in the input fields. Please check!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
  }

  // Handle fields change
  handleImageUpload = (imageList, addUpdateIndex) => {
    let file = imageList.map((a) => a.file);
    console.log("ONLYfile", file);

    console.log(imageList, addUpdateIndex);
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        serviceGallery: imageList,
      },
    }));

    console.log("PERS", this.state.data);
  };

  render() {
    const userData = this.state.userData;
    const createdDate = moment(userData.createdAt).format("LL");
    return (
      <div>
        <section className="lightgrey-bg user-area log-in-area topadjust specialist-dashboard">
          <div className="container-fluid">
            <div className="user-form-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-card mt-3">
                    <Container fluid>
                      <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          Service Details
                        </Breadcrumb.Item>
                      </Breadcrumb>

                      {/* <div className="row">
                            <div class="col">
                              <h2>Dashboard</h2>
                            </div>
                          </div> */}

                      <Row>
                        <Col xs lg="2" className="dashboard-sidebar">
                          <DashboardSidebar />
                        </Col>

                        <Col className="dashboard-content-area">
                          <div className="dashboard-content-actions">
                            <Row>
                              <Col className="col-md-9">
                                <div className="dashboard-content-title">
                                  <Link to="/service-lists">
                                    {" "}
                                    <IoReturnUpBackOutline className="mx-3" />
                                  </Link>{" "}
                                  {this.state.serviceData ? (
                                    this.state.serviceData.overview.gigTitle
                                  ) : (
                                    <span className="shadeLoader-1"></span>
                                  )}
                                </div>
                              </Col>
                              <Col className="col-md-2">
                                <div className="dashboard-content-action float-end">
                                  <Button
                                    onClick={this.editService}
                                    type="submit"
                                  >
                                    <span className="d-inline p-2">Edit</span>
                                  </Button>
                                </div>
                              </Col>

                              <Col className="col-md-1">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="ghost-action-btn"
                                  >
                                    <BiDotsVertical className="fs-22" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => this.deleteConfirm()}
                                    >
                                      Delete Service
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </div>

                          {!this.state.dataLoading ? (
                            <>
                              <Row>
                                <Col className="mt-3 DetailTableList">
                                  <h3>OverView</h3>
                                  <ul>
                                    <li>
                                      <h3> Title:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .gigTitle
                                        : "No Data"}
                                    </li>
                                    <li>
                                      <h3>Mega Data:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .gigMegaData
                                        : "No Data"}
                                    </li>
                                    <li>
                                      <h3>Search Tags:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .searchTags.toString()
                                        : "No Data"}
                                    </li>
                                    <li>
                                      <h3> Category Name:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .categoryId?.name
                                        : "No Data"}
                                    </li>
                                    <li>
                                      <h3>Sub Category Name:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .subCategoryId?.name
                                        : "No Data"}
                                    </li>
                                    <li>
                                      <h3>Category Service:</h3>
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .childCategoryId?.name
                                        : "No Data"}
                                    </li>
                                  
                               
                                                           
                                    <li>
                                    <h3>Description</h3>     
                                      {this.state.serviceData
                                        ? this.state.serviceData.overview
                                            .description
                                        : "No Data"}
                                    </li> 
                                    </ul>                                
                                </Col>
                              </Row>

                              <Row>
                                <Col className="DetailTableList">
                                  <Row className="p-3">
                                    <h3 className="c4">Pricing Package</h3>
                                    {this.state.serviceData?.packageDetails
                                      ? this.state.serviceData?.packageDetails.map(
                                          (item) => (
                                            <Col>
                                              <div className="package-outer shd-1 bdr-rad-4">
                                                <ul className="tableList packList">
                                                  <>
                                                    <h3 className="bg-none">
                                                      {item?.name}
                                                    </h3>
                                                    {item.featuresList.map(
                                                      (f_item) => (
                                                        <>
                                                          <li>{f_item}</li>
                                                        </>
                                                      )
                                                    )}

                                                    <h3 className="mt-3 bg-none">
                                                      Rs. {item.price}
                                                    </h3>
                                                  </>
                                                </ul>
                                              </div>
                                            </Col>
                                          )
                                        )
                                      : null}
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="align-item-center justify-content-center ">
                                <Col className="mt-3 p-3 DetailTableList">
                                  <h3 className="my-3">
                                    Upload Images of your work here to showcase
                                    to customers
                                  </h3>
                                  <div className="App">
                                    <ImageUploading
                                      multiple
                                      value={this.state.data.serviceGallery}
                                      onChange={this.handleImageUpload}
                                      maxNumber={4}
                                      dataURLKey="data_url"
                                      acceptType={["jpg", "png"]}
                                    >
                                      {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                        errors,
                                      }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                          {errors && (
                                            <div className="py-2">
                                              {errors.maxNumber && (
                                                <span>
                                                  Number of selected images
                                                  exceed maxNumber
                                                </span>
                                              )}
                                              {errors.acceptType && (
                                                <span>
                                                  Your selected file type is not
                                                  allowed
                                                </span>
                                              )}
                                              {errors.maxFileSize && (
                                                <span>
                                                  Selected file size exceed
                                                  maxFileSize
                                                </span>
                                              )}
                                              {errors.resolution && (
                                                <span>
                                                  Selected file is not match
                                                  your desired resolution
                                                </span>
                                              )}
                                            </div>
                                          )}
                                          <Button
                                            className="ImageDropArea position-relative w-100 bg-4 bdr-dotted"
                                            onKeyPress={(e) => {
                                              e.preventDefault();
                                            }}
                                            style={
                                              isDragging
                                                ? { color: "red" }
                                                : undefined
                                            }
                                            onClick={onImageUpload}
                                            {...dragProps}
                                          >
                                            <div className="text-dark bg-4 my-4">
                                              Click or Drop here
                                            </div>
                                            <div>
                                              <Row className="bg-4">
                                                {imageList.map(
                                                  (image, index) => (
                                                    <div
                                                      key={index}
                                                      className="col-md-2 drop-image-item bg-4 mt-3"
                                                    >
                                                      <img
                                                        src={image["data_url"]}
                                                        alt=""
                                                        width="100"
                                                      />
                                                      <div className="image-item__btn-wrapper">
                                                        <Button
                                                          className="my-2"
                                                          onKeyPress={(e) => {
                                                            e.preventDefault();
                                                          }}
                                                          onClick={() =>
                                                            onImageUpdate(index)
                                                          }
                                                        >
                                                          Update
                                                        </Button>
                                                        <Button
                                                          className="my-2"
                                                          onKeyPress={(e) => {
                                                            e.preventDefault();
                                                          }}
                                                          onClick={() =>
                                                            onImageRemove(index)
                                                          }
                                                        >
                                                          Remove
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </Row>
                                            </div>
                                          </Button>
                                          &nbsp;
                                          <Link
                                            className="mt-3 float-start"
                                            onKeyPress={(e) => {
                                              e.preventDefault();
                                            }}
                                            onClick={onImageRemoveAll}
                                          >
                                            Remove all images
                                          </Link>
                                        </div>
                                      )}
                                    </ImageUploading>
                                  </div>

                                  {/* ACTION BUTTON */}

                                  <div className="dashboard-content-actions">
                                    <Row>
                                      <Col></Col>
                                      <Col>
                                        <div className="dashboard-content-action float-end">
                                          <Button
                                            onClick={this.handleUpdate}
                                            type="submit"
                                            onKeyPress={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                          >
                                            {" "}
                                            {this.state.spinner ? (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <span className="d-inline p-2">
                                              Update
                                            </span>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col className="DetailTableList">
                                  <h3>Galleries</h3>
                                  <Row>
                                    {this.state.serviceData &&
                                    this.state.serviceData.serviceGallery
                                      .length ? (
                                      this.state.serviceData.serviceGallery.map(
                                        (item) => (
                                          <Col md={4}>
                                            <img
                                              src={item.secureUrl}
                                              className="img-fluid bdr-rad-4"
                                            />
                                          </Col>
                                        )
                                      )
                                    ) : (
                                      <div className="text-center">
                                        No Gallery Submitted
                                      </div>
                                    )}
                                  </Row>
                                </Col>
                              </Row>

                              <Row>
                                <Col className="DetailTableList">
                                  <h3>Demo Video</h3>
                                  <Row>
                                    {this.state.serviceData &&
                                    this.state.serviceData.demoVideo ? (
                                      <Col md={6}>
                                        <video
                                          className="img-fluid bdr-rad-4 px-3"
                                          width="600"
                                          height="480"
                                          controls
                                        >
                                          <source
                                            src={
                                              this.state.serviceData.demoVideo
                                                .secureUrl
                                            }
                                            type="video/mp4"
                                          />{" "}
                                        </video>
                                      </Col>
                                    ) : (
                                      <div className="text-center">
                                        No Video Submitted
                                      </div>
                                    )}
                                  </Row>
                                </Col>
                              </Row>

                              <Row>
                                <Col className="DetailTableList">
                                  <h3>Frequently Asked Questions</h3>
                                  <Row>
                                    {this.state.serviceData &&
                                    this.state.serviceData.faq.length
                                      ? this.state.serviceData.faq.map(
                                          (item) => (
                                            <Col md={12}>
                                              <h3>
                                                <BiCheck /> {item.question}
                                              </h3>
                                              <p className="ms-1 ps-3">
                                                {/* <BiCircle className="me-2" /> */}
                                                {item.answer}
                                              </p>
                                            </Col>
                                          )
                                        )
                                      : "No FAQ's Submitted"}
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <div className="content-spinner">
                              <Loader
                                type="spinner-default"
                                bgColor={"#0A989C"}
                                title={""}
                                color={"#0A989C"}
                                size={60}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Modal                                      
                        show={this.state.showDeleteModal}
                        onHide={()=>this.hideDeleteModal}
                      >
                        <Modal.Header closeButton >
                          <Modal.Title    className="px-5 py-3 text-center fw-700">
                            Are you you sure you want to delete this service?
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                          <h2 className="text-center c3"><BiAlarmExclamation className="text-center fs-48" /></h2>
                          <h2 className="text-center c3">Deletion is irreversible!<br/> All your data will be
                          erased.{" "}</h2>
                           <h3 className="text-center c3"> To confirm, Please enter <span className="c2">"DELETE"</span> in the below box</h3>
                           <Form.Group
                           style={{width:'30%'}}
                                  className="my-3 mx-auto text-center"
                                  controlId="formBasicEmail"
                                > 
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    onChange={(e)=>this.checkDeleteText(e)}                                   
                                    name="deleteTxt"                                    
                                  />
                                </Form.Group>
                                <p className="fs-12 text-center" style={{fontStyle:'italic'}}>Case Sensitive</p>

                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center">
                          <Button
                            variant="secondary"
                            onClick={()=>this.hideDeleteModal()}
                          >
                            Dont Delete
                          </Button>
                          <Button
                          disabled={!this.state.deleteTextConfirmed}
                            variant="primary"
                            onClick={()=>this.deleteService(this.state.serviceId)}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import React, { component } from "react";
import { NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { history } from "../../helpers/history";
import { logout } from "../../actions/auth";
import { clearMessage } from "../../actions/message";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {SwitchDashboards} from '../SwitchDashboards';

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state={
      userData: null,
    }
    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount(){
this.setState({
  userData:JSON.parse(localStorage.getItem('user'))
})
  }

  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    const { isLoggedIn } = this.props;
    return (
      <NavDropdown
      className="profile-drop"
        title={
          <a id="profile-header">
             {this.state.userData?.profile?.image?
            <img
            style={{width:42,height:42,objectFit:'cover',padding:0}}
              src={this.state.userData?.profile?.image?.secureUrl}
              alt="profile image"
            />
            :
            <img
              src="/assets/images/icons/Account.png"
              alt="profile image"
            />
             }
          </a>
        }
        id="profile-nav-dropdown"
      >
        <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
        <NavDropdown.Item >
         <Link to="dashboard">
         Dashboard
       </Link>
       </NavDropdown.Item>
       {this.state.userData ?
!this.state.userData.role.includes('manufacturer') &&
        <NavDropdown.Item href="/apply-manufacturer">
          Become Manufacturer
        </NavDropdown.Item>
  
  :''}

{this.state.userData ?
 !this.state.userData.role.includes('specialist') &&
        <NavDropdown.Item href="/apply-specialist">
          Become Specialist
        </NavDropdown.Item>
   :''}

        <NavDropdown.Item href="/help-support">Help & Support</NavDropdown.Item>
        <NavDropdown.Divider />

        {/* <SwitchDashboards 
         role={this.state.userData?.role ? this.state.userData.role :'' }                             
        /> */}
                           

        {isLoggedIn ? (
          <NavDropdown.Item onClick={this.logOut}>Logout</NavDropdown.Item>
        ) : (
          ""
        )}
      </NavDropdown>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  return {
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(ProfileDropdown);

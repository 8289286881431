import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  FaCompass,
  FaEuroSign,
  FaBox,
  FaPercent,
  FaPaperPlane,
  FaFileVideo,
  FaRupeeSign,
  FaUser,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCircle,
  FaUserNinja,
  FaUsersCog,
} from "react-icons/fa";
import { Accordion } from "react-bootstrap";

export default class SustainabilityToolsResources extends Component {
  render() {
    return (
      <div>
        <div className="Nature-banner inner-page">
          <div
            className="row bg-21 justify-contents-center align-items-center"
            style={{
              marginTop: -2,
              background:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(assets/images/engin-akyurt-3688262.jpg)",
              backgroundSize: "cover",
            }}
          >
            <div class="col-md-8 offset-md-2 p-0 text-center">
              <div class="p-5">
                <h1 className="heroText1 c1">
                    Sustainability Tools & Resources
                </h1>
                <div className="row">
                  <div className="col bg-21 py-5 px-2">
                    <span className="heroText2a">Green Building</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">Reference Manuals</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#manuals">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-22 py-5 px-2">
                    <span className="heroText2a">Green Building</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">Standards</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#standards">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-23 py-5 px-2">
                    <span className="heroText2a">Green Building</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">Newsletter</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="#newsletters">
                        Explore
                      </a>
                    </div>
                  </div>

                  <div className="col bg-24 py-5 px-2">
                    <span className="heroText2a">Green Building</span>
                    <br />
                    <hr />
                    <span className="heroText1c mt-4">FAQ</span>
                    <div class="solidBtn3 mt-3">
                      <a class="fw-600" href="/faqs">
                        Explore
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="manuals"
            className="row bg-21 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/manuals.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-21 p-5">
              <h1 className="heroText1">BSAS Green Building Reference Manuals</h1>
              <p>
              Here you will find extensive resources for meeting Beyond Smart Cities's Green Building
Standards and optimizing the performance of new buildings & existing buildings.
              </p>

              <div className="row p-4">
                <div className="col-md-3">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    New Green Building Reference Manuals
                  </a>
                </div>
                <div className="col-md-3">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Guidelines for using New Green Building Logo
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            id="standards"
            className="row bg-22 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/standards.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-22 p-5">
              <h1 className="heroText1">New Green Building Standards</h1>
              <p>
              New Green Building Standard helps consumers better understand the growing number of green building standards and Certification in
the marketplace. Their standards take a comprehensive life-cycle view of every building provide a ranking of degree of 'greenness'
explained with a simple symbol and a report.
              </p>

              <div className="row p-4">
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    BSAS Commercial{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    BSAS Residential{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    BSAS schools{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Beyond Smart Cities{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    BSAS Homes{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>




          <div
            id="newsletters"
            className="row bg-23 justify-contents-center align-items-center p-3"
          >
            <div class="col-md-4 hire-img-layout">
              <img
                className="bdr-rad-4"
                src="assets/images/newsletters.jpg"
                alt="img"
              />
            </div>

            <div className="col-md-8 bg-23 p-5">
              <h1 className="heroText1">New Green Building Newsletters</h1>
              <p>
              Green Building Newsletter is a periodic publication that provides information about certified products with their green features,
initiatives taken by Beyond Smart Cities for promoting green building technology in the market and the latest information on this
subject. It aims at creating awareness amongst all stakeholders about smart or green buildings, smart products, materials and
technologies and their benefits in building and manufacturing sectors.
              </p>

              <div className="row p-4">
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Smart Transportation{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Green Site{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Energy Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Water Efficiency{" "}
                  </a>
                </div>
                <div className="col-md-2">
                  <a
                    className="heroText2a"
                    href="https://knowledgelab.beyondsmartcities.in/"
                    target="_blank"
                  >
                    <img
                      style={{ width: 80 }}
                      src="assets/images/pdficon.webp"
                      alt="img"
                    />
                    <br />
                    Health and Wellness{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>





        </div>
      </div>
    );
  }
}

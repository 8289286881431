import React, { Component, Fragment } from "react";
import Confirmation from "./Confirmation";
import { CompanyInfo } from "./CompanyInfo";
import Success from "./Success";
import authHeader from "../../../services/auth-header";
import axios from "axios";
import { API_URL } from "../../../config";
import { ContactInfo } from "./ContactInfo";
import { Redirect } from "react-router-dom";

export default class ApplyManufacturer extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);

    this.state = {
      submitLoading: false,
      isdataSubmitted: false,
      errorMessage:'',
      step: 1,
      data: {
        applicantInfo: {
          nameOfCompany: "",
          companyWebsite: "",
          address: {
            street: "",
            city: "",
            state: "",
            country: "",
          },
        },
        contactPerson: {
          name: "",
          email: "",
          mobileNumber: "",
          title: "Mr/Ms",
          designation: "",
        },
        alternateContactPerson: {
          // title: "Mr/Ms",
          name: "",
          email: "",
          mobileNumber: "",
          designation: "",
        },
      },
    };
  }

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  firstStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 3 });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // Handle fields change
  handleChangeCompanyInfo = (input, type, value) => {
    
    console.log('val',value, type, input)
    if (type === "address") {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          applicantInfo: {
            ...prevState.data.applicantInfo,
            address: {
              ...prevState.data.applicantInfo.address,
              [input]:value,
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          applicantInfo: {
            ...prevState.data.applicantInfo,
            [input]: value,
          },
        },
      }));
    }

    
  };

  

  // Handle fields change
  handleChangeContactInfo = (input, type, value) => {
    
    if (type === "contactPerson") {
      var newState=''
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          contactPerson: {
            ...prevState.data.contactPerson,
            [input]: value,
          },
        },
      }), () => {                              
        //callback
        console.log("PERS", this.state.data);         
          
      });
      
    } else {
      let newState=''
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          alternateContactPerson: {
            ...prevState.data.alternateContactPerson,
            [input]: value,
          },
        },
      }), () => {                              
        //callback
        console.log("PERS", this.state.data);           
       
      });     
    }
    
   
  };


  async handleRegister(e) {

    console.log("DATREAL", this.state.data);
    var self = this;
    e.preventDefault();
    self.setState({
      step: 4,
      submitLoading: true,
      errorMessage:''
    });

    console.log("DATA", self.state.data);
    const token = await JSON.parse(localStorage.getItem("token"));
    // Post the form, just make sure to set the 'Content-Type' header
    await axios
      .post(API_URL + "manufacturer/new", self.state.data, {
        headers: {
          auth: token,
          Accept: "application/json",
        },
      })

      .then(function (response) {
        console.log(response);
        //handle success

        if (response.data.success === true) {
          self.setState({
            submitLoading: false,
            isdataSubmitted: true,
          });

          // setTimeout(function(){
          //   self.props.history.push("/profile");

          //  }, 4000);
        }
        else{
          self.setState({
            errorMessage:response.data.error,
            submitLoading: false,          
          });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
         // console.log(message)
          self.setState({
            errorMessage:message,
            submitLoading: false,          
          });
      }
      // .catch(function (response) {

        
      //   //handle error
      //   console.log(response);
      //   // this.setState({
      //   //     submitLoading:false
      //   // })
      // });
      );
      
  }

  render() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <CompanyInfo
            nextStep={this.nextStep}
            handleChangeCompanyInfo={this.handleChangeCompanyInfo}
            values={this.state.data.applicantInfo}            
          />
        );

      case 2:
        return (
          <ContactInfo
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChangeContactInfo={this.handleChangeContactInfo}
            addClick={this.addClick}
            removeClick={this.removeClick}
            values={this.state.data}
          />
        );

      case 3:
        return (
          <Confirmation
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleRegister={this.handleRegister}
            submitData={this.state.data}
            values={this.state.data}
          />
        );
      case 4:
        return (
          <Success
            submitLoading={this.state.submitLoading}
            isdataSubmitted={this.state.isdataSubmitted}
            errorMessage={this.state.errorMessage}
            handleRegister={this.handleRegister}
            firstStep={this.firstStep}
          />
        );
      default:
      // do nothing
    }
  }
}
